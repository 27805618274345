export const initialState = {
    error: false,
    fetching: false,
    results: [],
    searchTerm: '',
    companyType: 'BILLING',
    selectedSubCode: '',
    showResults: false
};

const actionTypes = {
    SET_ERROR: 'SET_ERROR',
    SET_FETCHING: 'SET_FETCHING',
    SET_RESULTS: 'SET_RESULTS',
    SET_SEARCH_TERM: 'SET_SEARCH_TERM',
    SET_COMPANY_TYPE: 'SET_COMPANY_TYPE',
    HIDE_RESULTS: 'HIDE_RESULTS',
    SET_SELECTED_SUBCODE: 'SET_SELECTED_SUBCODE'
};

export const actions = {
    setError() {
        return { type: actionTypes.SET_ERROR };
    },
    setFetching() {
        return {
            type: actionTypes.SET_FETCHING
        };
    },
    setSelectedSubCode(subCode) {
        return {
            type: actionTypes.SET_SELECTED_SUBCODE,
            payload: { subCode }
        };
    },
    setResults(results) {
        return {
            type: actionTypes.SET_RESULTS,
            payload: { results }
        };
    },
    hideResults() {
        return { type: actionTypes.HIDE_RESULTS };
    },
    setSearchTerm(term) {
        return {
            type: actionTypes.SET_SEARCH_TERM,
            payload: { term }
        };
    },
    setCompanyType(companyType) {
        return {
            type: actionTypes.SET_COMPANY_TYPE,
            payload: { companyType }
        };
    }
};

export const reducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_ERROR:
            return { ...state, error: true, fetching: false };
        case actionTypes.SET_FETCHING:
            return { ...state, error: false, fetching: true };
        case actionTypes.SET_SELECTED_SUBCODE:
            return { ...state, showResults: false, selectedSubCode: action.payload };
        case actionTypes.HIDE_RESULTS:
            return { ...state, showResults: false };
        case actionTypes.SET_RESULTS:
            return {
                ...state,
                error: false,
                fetching: false,
                results: action.payload.results
            };
        case actionTypes.SET_SEARCH_TERM:
            return {
                ...state,
                error: false,
                showResults: true,
                searchTerm: action.payload.term,
                selectedSubCode: ''
            };
        case actionTypes.SET_COMPANY_TYPE:
            return { ...state, companyType: action.payload.companyType };
    }
};
