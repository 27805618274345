import { Fragment, useState } from 'react';
import ReactTable from 'react-table';
import { getSummaryColumns } from './columns';
import { sortPolicyTypes, getPagination } from '../../utils';
import { NoLob } from './styles';

const ScorecardsTable = ({
    data,
    isLoading,
    currentPolicyType,
    onSelect,
    selectedItems,
    expandedRows,
    setExpandedRows
}) => {
    const [selectAll, setSelectAll] = useState(0);
    const [defaultSorted, setDefaultSorted] = useState([
        {
            id: 'wp-ytd',
            desc: true
        }
    ]);

    let columns = getSummaryColumns();
    const subColumns = getSummaryColumns(true);
    subColumns[0].columns[0] = {
        Header: 'Type',
        accessor: 'policyType',
        width: 245,
        Cell: ({ value, original }) => {
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'left',
                        width: '100%',
                        marginLeft: '50px'
                    }}
                >
                    {value}
                </div>
            );
        }
    };

    function toggleRow(uuid) {
        const newSelected = { ...selectedItems };
        newSelected[uuid] = !selectedItems[uuid];
        if (newSelected[uuid] === false) {
            delete newSelected[uuid];
        }
        onSelect(newSelected);
        if (Object.keys(newSelected).length === 0) {
            setSelectAll(0);
            return;
        }
        setSelectAll(2);
    }

    function toggleSelectAll() {
        let newSelected = {};

        if (selectAll === 0) {
            data.forEach((x) => {
                newSelected[x['carrierUuid']] = true;
            });
        }

        onSelect(newSelected);

        setSelectAll(selectAll === 0 ? 1 : 0);
    }

    function spliceColumns({ checkboxColumn, columns }) {
        const summaryColumnsCopy = columns.slice();
        return checkboxColumn.concat(summaryColumnsCopy);
    }

    let checkboxColumn = [
        {
            id: 'checkbox',
            accessor: '',
            headerClassName: 'sub-header',
            Cell: ({ original }) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={selectedItems[original['carrierUuid']] === true}
                            onChange={() => {
                                toggleRow(original['carrierUuid']);
                            }}
                        />
                    </div>
                );
            },
            Header: (x) => {
                return (
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={selectAll === 1}
                        ref={(input) => {
                            if (input) {
                                input.indeterminate = selectAll === 2;
                            }
                        }}
                        onChange={() => toggleSelectAll()}
                    />
                );
            },
            sortable: false,
            width: 45
        }
    ];

    return (
        <Fragment>
            <ReactTable
                minRows={1}
                onSortedChange={(newSorted) => {
                    setDefaultSorted([...newSorted]);
                }}
                columns={spliceColumns({ checkboxColumn, columns })}
                data={data}
                loading={isLoading}
                defaultPageSize={5}
                pageSizeOptions={getPagination(data.length)}
                defaultSorted={defaultSorted}
                defaultSortDesc={true}
                collapseOnDataChange={false}
                onExpandedChange={(expanded, index, event) => {
                    setExpandedRows(expanded);
                }}
                expanded={expandedRows}
                SubComponent={(rowInfo) => {
                    let subData = [];
                    currentPolicyType.children.forEach((pt) => {
                        if (rowInfo.original[pt.code]) {
                            subData.push({
                                ...rowInfo.original[pt.code],
                                policyType: pt.name
                            });
                        }
                    });
                    if (subData.length === 0) {
                        return <NoLob>Agency did not map to lowest LOB level</NoLob>;
                    }
                    return (
                        <div className="subtable">
                            <ReactTable
                                data={subData
                                    .filter(
                                        (x) =>
                                            x.writtenPremiumYTD !== 0 ||
                                            x.baseCommissionYTD !== 0 ||
                                            x.additionalCommissionYTD !== 0
                                    )
                                    .sort(sortPolicyTypes)}
                                columns={subColumns}
                                defaultSorted={[{ id: 'wp-ytd', desc: true }]}
                                pageSize={subData.length}
                                showPagination={false}
                            />
                        </div>
                    );
                }}
            />
        </Fragment>
    );
};

export default ScorecardsTable;
