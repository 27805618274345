import { abortOnSignal, request } from '../../utils/request';
import { useQuery } from '@tanstack/react-query';
import { groupBy, cloneDeep } from 'lodash-es';
import { useMemo } from 'react';
import { activePlanYearsQuery, networkPlanQuery } from '../../utils/apis';
import { calculateNewBusinessNumbers } from './utils';

function getDataBy(type, list, rollup, reportingDates) {
    const groupByType = groupBy(list, type);
    let data = {};
    let newBusinessGoalTotal = {};
    Object.keys(groupByType).forEach((key) => {
        const groupedByBlob = groupBy(groupByType[key], 'policyTypeCode');
        const planData = rollup.find((item) => item[type] === key);
        if (planData) {
            const pcPlanData = planData.detailsByStandards.find(
                (bData) => bData.policyTypeCode == 'P&C'
            );
            const dataByBlob = {};
            if (!newBusinessGoalTotal[key]) {
                newBusinessGoalTotal[key] = 0;
            }
            Object.keys(groupedByBlob).forEach((policyType) => {
                const planBlobData = planData.detailsByStandards.find(
                    (bData) => bData.policyTypeCode == policyType
                );
                const data = groupedByBlob[policyType].reduce(
                    (acc, obj) => {
                        const carrierData = reportingDates[obj.carrierUuid];
                        if (carrierData?.month) {
                            const newBusinessGoal = carrierData.month * (obj.apNewBusiness / 12);
                            if (newBusinessGoal > 0) {
                                newBusinessGoalTotal[key] =
                                    newBusinessGoalTotal[key] + newBusinessGoal;
                            }
                            return {
                                newBusinessGoal: acc.newBusinessGoal + newBusinessGoal
                            };
                        }
                        return acc;
                    },
                    {
                        newBusinessGoal: 0
                    }
                );
                dataByBlob[policyType] = {
                    ...planBlobData,
                    newBusinessGoal: data.newBusinessGoal
                };
            });
            dataByBlob['P&C'] = {
                ...pcPlanData,
                newBusinessGoal: newBusinessGoalTotal[key]
            };
            data[key] = dataByBlob;
        }
    });
    return data;
}
const nameKeys = {
    carrierUuid: 'carrierName',
    agencyUuid: 'agencyName'
};

/**
 * @param {any[]} data
 * @param {'carrierUuid'|'agencyUuid'} type
 * @return {Record<string,any>}
 */
function getRolledUpData(data, type) {
    const nameKey = nameKeys[type];
    let result = {};
    const groupedByType = groupBy(
        data.filter((item) => item[type] != null),
        type
    );

    Object.keys(groupedByType).forEach((key) => {
        const groupedByBlob = groupBy(groupedByType[key], 'broadLineOfBusinessStandardTypeCode');
        const blobs = {};
        let pcData = null;
        Object.keys(groupedByBlob).forEach((policyType) => {
            const blobData = groupedByBlob[policyType];
            blobData.forEach((item) => {
                if (!pcData) {
                    pcData = {
                        ...item,
                        name: item[nameKey]
                    };
                } else {
                    pcData = {
                        writtenPremiumYTD: pcData.writtenPremiumYTD + item.writtenPremiumYTD,
                        priorWrittenPremiumYTD:
                            pcData.priorWrittenPremiumYTD + item.priorWrittenPremiumYTD,
                        newWrittenPremiumYTD:
                            pcData.newWrittenPremiumYTD + item.newWrittenPremiumYTD,
                        month: pcData.month > item.month ? pcData.month : item.month,
                        name: item[nameKey],
                        carrierUuid: item.carrierUuid,
                        agencyUuid: item.agencyUuid,
                        agencyState: item.agencyState,
                        year: pcData.year > item.year ? pcData.year : item.year,
                        broadLineOfBusinessStandardTypeName:
                            item.broadLineOfBusinessStandardTypeName
                    };
                }
                if (!blobs[policyType]) {
                    blobs[policyType] = item;
                } else {
                    blobs[policyType] = {
                        writtenPremiumYTD:
                            blobs[policyType].writtenPremiumYTD + item.writtenPremiumYTD,
                        priorWrittenPremiumYTD:
                            blobs[policyType].priorWrittenPremiumYTD + item.priorWrittenPremiumYTD,
                        newWrittenPremiumYTD:
                            blobs[policyType].newWrittenPremiumYTD + item.newWrittenPremiumYTD,
                        month:
                            blobs[policyType].month > item.month
                                ? blobs[policyType].month
                                : item.month,
                        name: item[nameKey],
                        carrierUuid: item.carrierUuid,
                        agencyUuid: item.agencyUuid,
                        agencyState: item.agencyState,
                        year: pcData.year > item.year ? pcData.year : item.year,
                        broadLineOfBusinessStandardTypeName:
                            item.broadLineOfBusinessStandardTypeName
                    };
                }
            });
        });
        blobs['P&C'] = pcData;
        result[key] = blobs;
    });
    return result;
}

function calculateNetworkTotal(cprs, currentPlan, agency, keyName) {
    const defaultValues = {
        priorWrittenPremiumYTD: 0,
        writtenPremiumYTD: 0,
        newWrittenPremiumYTD: 0,
        newBusinessGoal: 0,
        goal: 0,
        varianceToGoal: 0,
        wpVarianceToGoal: 0,
        newBusinessGoalPercent: 0,
        goalValue: 0,
        contributors: 0
    };
    let data = {
        PL: { ...defaultValues, policyTypeCode: 'PL', name: agency.name },
        CL: { ...defaultValues, policyTypeCode: 'CL', name: agency.name },
        BOND: { ...defaultValues, policyTypeCode: 'BOND', name: agency.name },
        'P&C': { ...defaultValues, policyTypeCode: 'P&C', name: agency.name }
    };

    cprs.forEach((item) => {
        Object.keys(item).forEach((policyType) => {
            const key = item[policyType][keyName];
            if (data[policyType]) {
                let newBusinessGoal = data[policyType].newBusinessGoal;
                let goal = data[policyType].goal;
                let goalValue = data[policyType].goalValue;
                let varianceToGoal = data[policyType].varianceToGoal;
                let wpVarianceToGoal = data[policyType].wpVarianceToGoal;
                let newBusinessGoalPercent = data[policyType].newBusinessGoalPercent;
                let contributors = data[policyType].contributors;
                const priorWrittenPremiumYTD =
                    data[policyType].priorWrittenPremiumYTD +
                    item[policyType].priorWrittenPremiumYTD;
                const writtenPremiumYTD =
                    data[policyType].writtenPremiumYTD + item[policyType].writtenPremiumYTD;
                const newWrittenPremiumYTD =
                    data[policyType].newWrittenPremiumYTD + item[policyType].newWrittenPremiumYTD;

                if (!!currentPlan[key] && !!currentPlan[key][policyType]) {
                    const planData = currentPlan[key][policyType];
                    const { apNewBusiness, apPercentNewToBook } = calculateNewBusinessNumbers({
                        cpr: item[policyType],
                        planData
                    });
                    goal = planData.growthGoalPlan;
                    newBusinessGoal = apNewBusiness;
                    if (apPercentNewToBook) {
                        contributors = data[policyType].contributors + 1;
                        newBusinessGoalPercent =
                            data[policyType].newBusinessGoalPercent + apPercentNewToBook;
                    }

                    let wpGoalValue = item[policyType].priorWrittenPremiumYTD * (1 + goal / 100);
                    wpVarianceToGoal = item[policyType].writtenPremiumYTD - wpGoalValue;
                    goalValue = data[policyType].goalValue + wpGoalValue;
                    newBusinessGoal = newBusinessGoal + data[policyType].newBusinessGoal;
                    varianceToGoal = newWrittenPremiumYTD - newBusinessGoal;

                    const delta =
                        goalValue === 0
                            ? 0
                            : ((goalValue - priorWrittenPremiumYTD) * 100) / priorWrittenPremiumYTD;
                    wpGoalValue = priorWrittenPremiumYTD * (1 + delta / 100);
                    wpVarianceToGoal = writtenPremiumYTD - wpGoalValue;
                }
                data[policyType] = {
                    priorWrittenPremiumYTD,
                    writtenPremiumYTD,
                    newWrittenPremiumYTD,
                    newBusinessGoal,
                    goalValue,
                    goal,
                    varianceToGoal,
                    wpVarianceToGoal,
                    newBusinessGoalPercent,
                    contributors,
                    name: agency.name,
                    policyTypeCode: policyType,
                    policyTypeName: item[policyType].broadLineOfBusinessStandardTypeName
                };
            }
        });
    });
    const networkTotal = [];
    Object.keys(data).forEach((policyType) => {
        data[policyType].newBusinessGoalPercent =
            data[policyType].newBusinessGoalPercent / data[policyType].contributors;
        data[policyType].goal =
            data[policyType].goalValue == 0
                ? data[policyType].goal
                : ((data[policyType].goalValue - data[policyType].priorWrittenPremiumYTD) * 100) /
                  data[policyType].priorWrittenPremiumYTD;
        networkTotal.push(data[policyType]);
    });
    return networkTotal;
}

export function useGrowthMeasurementPlan(planId, agency) {
    const fullPlan = useQuery(
        ['growth-measurement-plan', planId],
        ({ signal }) => {
            const req = request.get(`/api/growth-measurement/plan/${planId}/full`);
            abortOnSignal(req, signal);
            return req.then(({ body }) => body);
        },
        {
            enabled: Boolean(planId)
        }
    );

    const reportingDates = useQuery(
        ['growth-measurement-reporting-dates'],
        ({ signal }) => {
            const req = request.get(`/api/growth-measurement/reporting-dates`);
            abortOnSignal(req, signal);
            return req.then(({ body }) => {
                return body.reduce((acc, item) => {
                    const mostRecentMonth =
                        new Date(item['mostRecentReportingDate']).getUTCMonth() + 1;
                    const month = mostRecentMonth > 12 ? 1 : mostRecentMonth;
                    acc[item['carrierUuid']] = {
                        month
                    };
                    return acc;
                }, {});
            });
        },
        {
            enabled: Boolean(planId)
        }
    );

    const activePlanQuery = useQuery(networkPlanQuery(planId));
    const ytdCPR = useQuery(
        ['growth-measurement-ytd', activePlanQuery.data?.year],
        ({ signal, queryKey: [, year] }) => {
            const req = request.get(`/api/growth-measurement/ytd-agency-carrier/v2`).query({
                year
            });
            abortOnSignal(req, signal);
            return req.then(({ body }) => body);
        },
        {
            enabled: Boolean(activePlanQuery.isSuccess)
        }
    );

    let planDataBy = useMemo(() => {
        if (fullPlan.data && reportingDates.data) {
            const { leafLevelData, carrierRollup, agencyRollup } = fullPlan.data;
            return {
                carrier: getDataBy(
                    'carrierUuid',
                    leafLevelData,
                    carrierRollup,
                    reportingDates.data
                ),
                agency: getDataBy('agencyUuid', leafLevelData, [agencyRollup], reportingDates.data)
            };
        }
    }, [fullPlan.data, reportingDates.data]);

    let { carrierCPRs, networkCPRs, agencySummary } = useMemo(() => {
        if (planDataBy && ytdCPR.data) {
            let filteredData = cloneDeep(ytdCPR.data);
            const byCarrier = getRolledUpData(filteredData, 'carrierUuid');
            const byAgency = getRolledUpData(filteredData, 'agencyUuid');
            const agencyList = byAgency[agency.uuid] ? [byAgency[agency.uuid]] : [];
            const carrierList = Object.keys(byCarrier).map((carrierUuid) => {
                return {
                    ...byCarrier[carrierUuid],
                    carrierUuid
                };
            });
            return {
                carrierCPRs: carrierList,
                agencySummary: calculateNetworkTotal(
                    agencyList,
                    planDataBy.agency,
                    agency,
                    'agencyUuid'
                ),
                networkCPRs: calculateNetworkTotal(
                    carrierList,
                    planDataBy.carrier,
                    agency,
                    'carrierUuid'
                )
            };
        } else {
            return {};
        }
    }, [ytdCPR.data, planDataBy]);

    return {
        isLoading: fullPlan.isLoading || reportingDates.isLoading || ytdCPR.isLoading,
        planUuid: planId,
        activePlan: activePlanQuery.data,
        plan: planDataBy?.carrier,
        carrierCPRs,
        networkCPRs,
        agencySummary
    };
}

export function useActivePlanYears() {
    return useQuery(activePlanYearsQuery());
}

export function useNetworkPlan() {}
