import { useEffect, useState } from 'react';
import { LoaderContainer } from './styles';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { request } from '../../utils/request';
import { splitUSPhoneNumber } from '../../utils/index';
import Loader from 'react-loader-spinner';
import MyModal from '../shared/form/modal';
import RegisterForm from './registerForm';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Portal from '../Portal';

const Register = () => {
    const state = useStoreState((state) => state.register);
    const actions = useStoreActions((actions) => actions.register);
    const [reason, setReason] = useState('');
    const [willBePrimary, setWillBePrimary] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchData(searchParams.get('token'));
    }, [searchParams]);

    async function onSubmit(values) {
        const phoneInfo = splitUSPhoneNumber(values.mobileNumber);
        actions.setError('');
        actions.setFetching(true);
        const userCreationPath = willBePrimary ? 'createPrimaryUser' : 'createUser';
        const result = await request.post(`/api/register/${userCreationPath}`).send({
            agencyId: state.agency.uuid,
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
            emailAddress: values.emailAddress,
            addressLine1: values.address1.trim(),
            addressLine2: values.address2.trim(),
            city: values.city.trim(),
            state: values.state.trim(),
            postalCode: values.postalCode.trim(),
            countryCode: phoneInfo.countryCode,
            areaCode: phoneInfo.areaCode,
            phoneNumber: phoneInfo.phoneNumber,
            country: 'United States',
            password: values.password,
            token: state.token
        });
        if (result.body && result.body.response && result.body.response.status !== 200) {
            let err = JSON.parse(result.body.response.text);
            actions.setError(err.message);
        } else {
            actions.setIsModalOpen(true);
            actions.setModalText('Account created successfully');
        }
        actions.setFetching(false);
    }

    async function fetchData(token) {
        actions.setFetching(true);
        try {
            const result = await request.get('/api/register/meta-data').query({
                token
            });
            if (result.body && result.body.response && result.body.response.status !== 200) {
                let err = JSON.parse(result.body.response.text);
                setReason(err.message);
                setShowErrorMessage(true);
            } else {
                const agency = {
                    uuid: result.body.subscriberUuid,
                    name: result.body.subscriberName
                };
                const network = {
                    uuid: result.body.agencyNetworkUuid,
                    name: result.body.agencyNetworkName
                };
                setWillBePrimary(result.body.willInviteeBePrimaryUser);
                actions.setNetwork(network || {});
                actions.setAgency(agency || {});
                actions.setEmailAddress(result.body.emailAddress || '');
                actions.setToken(result.body.accessToken || '');
            }
            actions.setFetching(false);
        } catch (e) {
            actions.setFetching(false);
            setReason(e.message);
            setShowErrorMessage(true);
        }
    }

    function handleModalClick() {
        actions.setIsModalOpen(false);
        navigate('/production');
    }

    function inputChanged() {
        actions.setError('');
    }

    return (
        <Portal isPublicPage={true}>
            {state.fetching && (
                <LoaderContainer>
                    <Loader type="Oval" color="#d0cfce" />
                </LoaderContainer>
            )}
            {!state.fetching && state.network && state.agency && (
                <RegisterForm
                    {...state}
                    onSubmit={onSubmit}
                    inputChanged={inputChanged}
                    showErrorMessage={showErrorMessage}
                    reason={reason}
                />
            )}
            <MyModal
                isModalOpen={state.isModalOpen}
                handleModalClick={handleModalClick}
                modalText={state.modalText}
            />
        </Portal>
    );
};

export default Register;
