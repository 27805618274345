import { Centered } from './index';
import { Icon } from '../../icons/icons';
import styled from '@emotion/styled';

const AccessDeniedMessage = styled.h2`
    display: flex;
    align-items: center;
    svg {
        margin-right: 20px;
    }
`;

const AccessDenied = () => (
    <Centered>
        <AccessDeniedMessage>
            <Icon type="delete-circle" iWidth="50px" iColor="red" />
            You do not have access to this feature. Please select an option from the menu on the
            left.
        </AccessDeniedMessage>
    </Centered>
);

export default AccessDenied;
