import { Fragment, useMemo, useState } from 'react';
import ReactTable from 'react-table';
import Loader from '../shared/Loader';
import classNames from 'classnames';
import { getColumnWidth } from '../../utils';
import { getSummaryColumns } from './columns';
import { setMissingDataFlags } from './utils';
import { useStoreState } from 'easy-peasy';
import { COLLABORATION_EXCLUSIONS } from '../../constants';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { useCarrierData, useCPRsBySubCode, useNetworkSummaryByCarrier, useStandardPolicyTypes } from './hooks';
import { getYearMonth } from './shared/yearMonth';
import { useEffect } from 'react';

export default function CarrierProductionList({
    expandedRows,
    setExpandedRows,
    data,
    carrierType,
    listType,
    latestMonth,
    policyType,
    searchTerm,
    refetch,
    selectedItems,
    setSelectedItems,
    selectAll,
    setSelectAll,
    tableDataType,
    uuidType,
    getSelectedKey,
    getSelectedTitle,
    timeframe,
    standardTypes,
    carrierReportedMonths,
    haveMissingCurrentData,
    haveMissingPriorData,
    setShowIncompleteDisclaimer
}) {
    const [collaborationMode] = useStoreState((state) => [state.collaborationMode]);
    const { findPolicyTypeByUuid } = useStandardPolicyTypes();
    const { data: carriersData } = useCarrierData();
    const [showSubCodes, setShowSubCodes] = useState(false);
    const { data: networkData, isLoading: isFetchingNetwork } = useNetworkSummaryByCarrier(
        timeframe,
        policyType.code,
        standardTypes
    );

    const summaryColumns = getSummaryColumns(
        networkData ?? {},
        tableDataType,
        getSelectedKey,
        getSelectedTitle,
        timeframe
    );

    const subColumns = [...summaryColumns];
    summaryColumns[0].columns[0].width = getColumnWidth(data, summaryColumns[0].columns[0], 14);

    const getLatestYearMonth = (carrierData) => {
        return carrierData ? getYearMonth(
            carrierData.mostRecentReportingDate || carrierData.manualMostRecentReportingDate)
            : { year: 0, month: 0 };
    }

    function processData(data, carriersData) {
        let processedData = [];
        data.forEach((item) => {
            const carrier = item[policyType.code];
            if (
                policyType &&
                carrier &&
                (carrier.earned !== 0 ||
                    carrier.priorEarned !== 0 ||
                    carrier.incurred !== 0 ||
                    carrier.newWrittenPremium !== 0 ||
                    carrier.writtenPremium !== 0 ||
                    carrier.priorWrittenPremium !== 0 ||
                    carrier.priorNewWrittenPremium !== 0)
            ) {
                item[policyType.code].haveMissingCurrentData = haveMissingCurrentData;
                item[policyType.code].haveMissingPriorData = haveMissingPriorData;

                if (tableDataType !== 'agencies') {
                    item[policyType.code] = setMissingDataFlags(carrier, carrierReportedMonths);
                    const latestYearMonth = getLatestYearMonth(carriersData.get(carrier.carrierUuid));
                    item['latestYearMonth'] = latestYearMonth;
                    item['latestYearMonthStamp'] = latestYearMonth.year * 12 + latestYearMonth.month;
                }

                if (standardTypes.length > 0) {
                    const totalFiltered = {
                        ...carrier,
                        earned: 0,
                        earnedWithManual: 0,
                        incurred: 0,
                        incurredWithManual: 0,
                        newWrittenPremium: 0,
                        newWrittenPremiumWithManual: 0,
                        priorEarned: 0,
                        priorEarnedWithManual: 0,
                        priorIncurred: 0,
                        priorIncurredWithManual: 0,
                        priorNewWrittenPremium: 0,
                        priorNewWrittenPremiumWithManual: 0,
                        priorWrittenPremium: 0,
                        priorWrittenPremiumWithManual: 0,
                        writtenPremium: 0,
                        writtenPremiumWithManual: 0
                    };
                    standardTypes.forEach((type) => {
                        const standard = findPolicyTypeByUuid(type);
                        const current = item[standard.code];
                        if (current) {
                            totalFiltered.earned += current.earned;
                            totalFiltered.earnedWithManual += current.earnedWithManual;
                            totalFiltered.incurred += current.incurred;
                            totalFiltered.incurredWithManual += current.incurredWithManual;
                            totalFiltered.newWrittenPremium += current.newWrittenPremium;
                            totalFiltered.newWrittenPremiumWithManual +=
                                current.newWrittenPremiumWithManual;
                            totalFiltered.priorEarned += current.priorEarned;
                            totalFiltered.priorEarnedWithManual += current.priorEarnedWithManual;
                            totalFiltered.priorIncurred += current.priorIncurred;
                            totalFiltered.priorIncurredWithManual +=
                                current.priorIncurredWithManual;
                            totalFiltered.priorNewWrittenPremium += current.priorNewWrittenPremium;
                            totalFiltered.priorNewWrittenPremiumWithManual +=
                                current.priorNewWrittenPremiumWithManual;
                            totalFiltered.priorWrittenPremium += current.priorWrittenPremium;
                            totalFiltered.priorWrittenPremiumWithManual +=
                                current.priorWrittenPremiumWithManual;
                            totalFiltered.writtenPremium += current.writtenPremium;
                            totalFiltered.writtenPremiumWithManual +=
                                current.writtenPremiumWithManual;
                        }
                    });
                    if (
                        totalFiltered.earned !== 0 ||
                        totalFiltered.priorEarned !== 0 ||
                        totalFiltered.incurred !== 0 ||
                        totalFiltered.newWrittenPremium !== 0 ||
                        totalFiltered.writtenPremium !== 0 ||
                        totalFiltered.priorWrittenPremium !== 0 ||
                        totalFiltered.priorNewWrittenPremium !== 0
                    ) {
                        processedData.push({
                            ...item,
                            ...totalFiltered,
                            latestMonth,
                            listType,
                            isFetchingNetwork
                        });
                    }
                } else if (policyType.code === 'P&C') {
                    const totalPC = {
                        earned: 0,
                        incurred: 0,
                        newWrittenPremium: 0,
                        priorEarned: 0,
                        priorIncurred: 0,
                        priorNewWrittenPremium: 0,
                        priorWrittenPremium: 0,
                        writtenPremium: 0
                    };

                    policyType.children.forEach((blob) => {
                        const carrier = item[blob.code];
                        if (carrier) {
                            totalPC.earned += carrier.earned;
                            totalPC.incurred += carrier.incurred;
                            totalPC.newWrittenPremium += carrier.newWrittenPremium;
                            totalPC.priorEarned += carrier.priorEarned;
                            totalPC.priorIncurred += carrier.priorIncurred;
                            totalPC.priorNewWrittenPremium += carrier.priorNewWrittenPremium;
                            totalPC.priorWrittenPremium += carrier.priorWrittenPremium;
                            totalPC.writtenPremium += carrier.writtenPremium;

                            item[blob.code].haveMissingCurrentData = haveMissingCurrentData;
                            item[blob.code].haveMissingPriorData = haveMissingPriorData;
                            if (tableDataType !== 'agencies') {
                                item[blob.code] = setMissingDataFlags(
                                    carrier,
                                    carrierReportedMonths
                                );
                            }
                        }
                    });
                    item[policyType.code] = {
                        ...item[policyType.code],
                        ...totalPC
                    };
                    processedData.push({
                        ...item,
                        ...item[policyType.code],
                        latestMonth,
                        listType,
                        isFetchingNetwork
                    });
                } else {
                    item[policyType.code] = {
                        ...item[policyType.code]
                    };
                    processedData.push({
                        ...item,
                        ...item[policyType.code],
                        latestMonth,
                        listType,
                        isFetchingNetwork
                    });
                }
            }
        });
        let hiddenInfo =
            collaborationMode.carriersUuid.length > 0 &&
            collaborationMode.shareType === COLLABORATION_EXCLUSIONS.ALL;
        const collaborationReturn = processedData?.filter((e) =>
            collaborationMode?.carriersUuid?.includes(e.carrierUuid)
        );

        return hiddenInfo ? collaborationReturn : processedData;
    }

    function toggleRow(originalRow) {
        const uuid =
            tableDataType === 'carriers' ? originalRow.carrierUuid : originalRow.agencyUuid;
        const newSelected = { ...selectedItems };
        newSelected[uuid] = !selectedItems[uuid];
        setSelectedItems(newSelected);
        setSelectAll(2);
    }

    function toggleSelectAll() {
        let newSelected = {};
        if (selectAll === 0) {
            data.forEach((x) => {
                newSelected[x[uuidType]] = true;
            });
        }

        setSelectedItems(newSelected);
        setSelectAll(selectAll === 0 ? 1 : 0);
    }

    const CHECKBOX_COLUMN_WIDTH = 45;

    /** @type{import('react-table').Column[]} */
    const columns = [
        {
            id: 'checkbox',
            accessor: '',
            headerClassName: 'sub-header',
            // eslint-disable-next-line react/prop-types
            Cell: ({ original }) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={selectedItems[original[uuidType]] === true}
                            onChange={() => toggleRow(original)}
                        />
                    </div>
                );
            },
            Header: () => {
                return (
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={selectAll === 1}
                        ref={(input) => {
                            if (input) {
                                input.indeterminate = selectAll === 2;
                            }
                        }}
                        onChange={() => {
                            toggleSelectAll();
                        }}
                    />
                );
            },
            sortable: false,
            width: CHECKBOX_COLUMN_WIDTH
        },
        ...summaryColumns
    ];

    const processedData = useMemo(
        () => (carriersData ? processData(data, carriersData) : []),
        [carriersData, networkData, data]
    );

    useEffect(() => {
        if (processedData)
            setShowIncompleteDisclaimer(processedData.some(x => x.haveMissingCurrentData));

    }, [processedData])


    return (
        <Fragment key={`${policyType ? policyType.code : 'undefined'}-${data.length}`}>
            {!refetch ? (
                <ReactTable
                    collapseOnDataChange={false}
                    minRows={5}
                    columns={columns}
                    noDataText={refetch ? '' : 'No data found'}
                    data={refetch === false ? processedData : []}
                    className={classNames('-highlight', {
                        'table--empty': data.length === 0
                    })}
                    getTdProps={(state, rowInfo, column) => {
                        return {
                            className: column.headerClassName
                        };
                    }}
                    onExpandedChange={(expanded, index) => {
                        let auxExpanded = [...expandedRows];
                        auxExpanded[index] = !auxExpanded[index];
                        setExpandedRows(auxExpanded);
                    }}
                    expanded={expandedRows}
                    showPagination={false}
                    pageSize={processedData.length}
                    defaultSortDesc={true}
                    defaultSorted={[{ id: 'wp-ytd', desc: true }]}
                    filtered={[{ id: 'name', value: searchTerm }]}
                    SubComponent={(rowInfo) => (
                        <SubTable
                            subColumns={subColumns}
                            rowInfo={rowInfo}
                            tableDataType={tableDataType}
                            policyType={policyType}
                            isFetchingNetwork={isFetchingNetwork}
                            standardTypes={standardTypes}
                            haveMissingCurrentData={haveMissingCurrentData}
                            haveMissingPriorData={haveMissingPriorData}
                            carrierReportedMonths={carrierReportedMonths}
                            timeframe={timeframe}
                            carrierType={carrierType}
                            labelColumnWidth={
                                summaryColumns[0].columns[0].width + CHECKBOX_COLUMN_WIDTH + (tableDataType !== 'agencies' ? 100 : 0)
                            }
                            showSubCodes={showSubCodes}
                            setShowSubCodes={setShowSubCodes}
                        />
                    )}
                />
            ) : (
                <Loader />
            )}
        </Fragment>
    );
}

CarrierProductionList.propTypes = {
    expandedRows: PropTypes.array,
    setExpandedRows: PropTypes.func,
    data: PropTypes.array,
    listType: PropTypes.string,
    latestMonth: PropTypes.number,
    policyType: PropTypes.shape({
        code: PropTypes.string,
        children: PropTypes.array
    }),
    searchTerm: PropTypes.string,
    refetch: PropTypes.bool,
    selectedItems: PropTypes.object,
    setSelectedItems: PropTypes.func,
    selectAll: PropTypes.oneOf([0, 1, 2]),
    setSelectAll: PropTypes.func,
    tableDataType: PropTypes.oneOf(['agencies', 'carriers']),
    uuidType: PropTypes.oneOf(['agencyUuid', 'carrierUuid']),
    getSelectedKey: PropTypes.func,
    getSelectedTitle: PropTypes.func,
    timeframe: PropTypes.shape({
        startDate: PropTypes.instanceOf(Date),
        endDate: PropTypes.instanceOf(Date)
    }),
    carrierType: PropTypes.shape({
        key: PropTypes.string,
        composites: PropTypes.object
    }),
    standardTypes: PropTypes.array,
    carrierReportedMonths: PropTypes.array,
    haveMissingCurrentData: PropTypes.bool,
    haveMissingPriorData: PropTypes.bool,
    setShowIncompleteDisclaimer: PropTypes.func
};

function SubTable({
    policyType,
    subColumns,
    tableDataType,
    rowInfo,
    carrierType,
    timeframe,
    isFetchingNetwork,
    standardTypes,
    haveMissingCurrentData,
    haveMissingPriorData,
    carrierReportedMonths,
    labelColumnWidth,
    showSubCodes,
    setShowSubCodes
}) {
    const subCodeQuery = useCPRsBySubCode({
        policyType,
        tableDataType,
        standardTypes,
        carrierComposites: carrierType.composites,
        timeframe
    });

    let subData = [];
    if (showSubCodes === true) {
        subColumns[0] = {
            accessor: 'policyType',
            Cell: RowLabelCell
        };
        const subCodesForCarrier = (subCodeQuery.data || []).filter((cpr) => {
            if (tableDataType === 'carriers') {
                return (
                    cpr[policyType.code] &&
                    cpr[policyType.code].carrierUuid === rowInfo.original.carrierUuid
                );
            } else if (tableDataType === 'agencies') {
                return (
                    cpr[policyType.code] &&
                    cpr[policyType.code].agencyUuid === rowInfo.original.agencyUuid
                );
            }
        });
        subCodesForCarrier.forEach((cpr) => {
            let carrier = cpr[policyType.code];
            carrier.haveMissingCurrentData = haveMissingCurrentData;
            carrier.haveMissingPriorData = haveMissingPriorData;
            if (tableDataType !== 'agencies') {
                carrier = setMissingDataFlags(carrier, carrierReportedMonths);
            }

            subData.push({
                ...carrier,
                policyType: `${cpr.name}`,
                isFetchingNetwork
            });
        });
    } else {
        subColumns[0] = {
            accessor: 'policyType',
            Cell: RowLabelCell
        };
        policyType.children.forEach((pt) => {
            let carrier = rowInfo.original[pt.code];
            if (
                (carrier && standardTypes.length === 0) ||
                (carrier && standardTypes.length > 0 && standardTypes.includes(pt.uuid))
            ) {
                carrier.haveMissingCurrentData = haveMissingCurrentData;
                carrier.haveMissingPriorData = haveMissingPriorData;
                if (tableDataType !== 'agencies') {
                    carrier = setMissingDataFlags(carrier, carrierReportedMonths);
                }

                subData.push({
                    ...carrier,
                    policyType: pt.name,
                    isFetchingNetwork
                });
            }
        });
    }
    subColumns[0].width = labelColumnWidth;
    subData = subData.filter(x => x.earned !== 0 ||
        x.priorEarned !== 0 ||
        x.incurred !== 0 ||
        x.newWrittenPremium !== 0 ||
        x.writtenPremium !== 0 ||
        x.priorWrittenPremium !== 0 ||
        x.priorNewWrittenPremium !== 0);

    return (
        <div className="subtable">
            <div className="options-container">
                <div className="pills" style={{ marginLeft: '20px' }}>
                    <div
                        className={classNames('pill', {
                            selected: showSubCodes === false
                        })}
                        onClick={() => {
                            if (showSubCodes === false) return;
                            setShowSubCodes(false);
                        }}
                    >
                        LOB
                    </div>
                    <div
                        className={classNames('pill', {
                            selected: showSubCodes === true
                        })}
                        onClick={() => {
                            if (showSubCodes === true) return;
                            setShowSubCodes(true);
                        }}
                    >
                        Subcode
                    </div>
                </div>
                {policyType.code !== 'P&C' && showSubCodes === false && (
                    <div className="message">
                        * Written Premium entries will only show in the Subcode section when
                        filtering by Broad Line of Business.
                    </div>
                )}
            </div>
            <ReactTable
                data={subData}
                columns={subColumns}
                defaultSortDesc={true}
                defaultSorted={[{ id: 'wp-ytd', desc: true }]}
                pageSize={subData.length}
                showPagination={false}
            />
        </div>
    );
}
SubTable.propTypes = {
    policyType: PropTypes.shape({
        code: PropTypes.string,
        children: PropTypes.array
    }),
    standardTypes: PropTypes.array,
    subColumns: PropTypes.array,
    tableDataType: PropTypes.oneOf(['agencies', 'carriers']),
    rowInfo: PropTypes.shape({
        original: PropTypes.object
    }),
    haveMissingCurrentData: PropTypes.bool,
    haveMissingPriorData: PropTypes.bool,
    carrierReportedMonths: PropTypes.any,
    labelColumnWidth: PropTypes.number,
    isFetchingNetwork: PropTypes.bool,
    carrierType: PropTypes.shape({
        key: PropTypes.string,
        composites: PropTypes.object
    }),
    timeframe: PropTypes.shape({
        startDate: PropTypes.instanceOf(Date),
        endDate: PropTypes.instanceOf(Date)
    }),
    showSubCodes: PropTypes.bool,
    setShowSubCodes: PropTypes.fn
};
function RowLabelCell({ value }) {
    return <div css={RowLabelCell.css}>{value}</div>;
}
RowLabelCell.css = css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    width: '100%',
    marginLeft: '50px'
});
RowLabelCell.propTypes = {
    value: PropTypes.node
};
