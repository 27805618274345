import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { queries } from '../../utils';
import { AgencyScorecardsPage, AgencyScorecardsYearSelector } from '../Scorecards';
import { REPORTS } from '../../constants'
import { Suspense } from 'react';
import Loader from '../shared/Loader';

export const ReportFallback = ({ reportKey }) => {
    switch (reportKey) {
        case REPORTS.AGENCY_PRODUCTION.key:
            return <Suspense fallback={<Loader />}>
                <AgencyScorecardsPage />
            </Suspense>;
    }

    return null;
}

ReportFallback.propTypes = {
    reportKey: PropTypes.string
};

const HandleWrapper = ({ reportKey, children }) => {

    const reportQuery = useQuery(queries.report(reportKey));
    if (reportQuery.isLoading || (!reportQuery.isLoading && reportQuery.data?.assignmentId))
        return;

    return children;
}

HandleWrapper.propTypes = {
    reportKey: PropTypes.string
};

export const handleReportRouteFallback = (reportKey) => {
    switch (reportKey) {
        case REPORTS.AGENCY_PRODUCTION.key:
            return {
                headerChildren:
                    <HandleWrapper reportKey={reportKey}>
                        <AgencyScorecardsYearSelector />
                    </HandleWrapper>
            }
    }

    return null;
}