import styled from '@emotion/styled';

import theme from '../../../theme';

export const SearchContainer = styled.div`
    width: 400px;
`;

export const SearchInput = styled.input`
    border-radius: 3px;
    border: 1px solid ${theme.colors.borderColor};
    font-size: 1.2em;
    padding: 8px 15px 7px;
`;

export const SearchAndResultsWrapper = styled.div`
    display: flex;
    max-width: 100%;
    position: relative;
    flex-direction: column;
    min-width: 50%;
`;

export const SearchHeading = styled.h2``;

export const ResultsContainer = styled.div`
    position: absolute;
    z-index: 100;
    margin-top: 42px;
    width: 100%;
    background-color: white;
    border: 1px solid ${theme.colors.borderColor};
    border-radius: 3px;
    padding: 0;
`;

export const ResultsList = styled.ul`
    list-style-type: none;
    max-height: 400px;
    overflow-y: scroll;
    padding: 0;
    margin: 0;
    & > a {
        color: ${theme.colors.darkGray};
        text-decoration: none;
    }
`;

export const Result = styled.li`
    padding: 10px;
    &:hover {
        background-color: ${theme.colors.backgroundLight};
        cursor: pointer;
    }
`;

export const NoResults = styled.div`
    padding: 18px;
    text-align: center;
    a {
        text-decoration: underline;
        color: blue;
        cursor: pointer;
    }
`;

export const CompanyTypeSelector = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
        margin-right: 15px;
        &:last-child {
            margin-right: 0;
        }
    }
`;
