import { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { request as superagent } from '../../../utils/request';
import { useDebounce } from '../../../utils/hooks';
import { initialState, actions, reducer } from './searchReducer';
import CarrierSearch from './CarrierSearch';

const CarrierSearchContainer = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const debouncedSearchTerm = useDebounce(state.searchTerm, 300);
    const {
        initialSelectedCarrier,
        onSelect,
        selectedCarrier,
        name,
        handleRequestNewCarrierModal
    } = props;

    useEffect(() => {
        dispatch(actions.setFetching());
        async function fetchCarriers() {
            try {
                const result = await superagent.get(
                    `/api/carriers/?search=${debouncedSearchTerm}&companyType=${state.companyType}`
                );
                dispatch(actions.setResults(result.body.slice(0, 8)));
            } catch (e) {
                dispatch(actions.setError());
            }
        }
        if (!debouncedSearchTerm.length || debouncedSearchTerm.length <= 2) return;
        fetchCarriers();
    }, [debouncedSearchTerm, state.companyType]);

    useEffect(() => {
        if (initialSelectedCarrier.name.length > 0) {
            dispatch(actions.setResults([]));
            dispatch(actions.setSearchTerm(initialSelectedCarrier.name));
            dispatch(actions.setSelectedCarrier(initialSelectedCarrier));
            onSelect(initialSelectedCarrier);
        }
    }, [initialSelectedCarrier]);

    const handleInputChange = (e) => {
        const { value } = e.target;
        if (value != selectedCarrier.name) {
            dispatch(actions.setSelectedCarrier(''));
            onSelect('');
        }
        dispatch(actions.setSearchTerm(value));

        if (!value.length) {
            return dispatch(actions.setResults([]));
        }
    };

    const handleSearchSelect = (carrier) => {
        dispatch(actions.setResults([]));
        dispatch(actions.setSearchTerm(carrier.name));
        dispatch(actions.setSelectedCarrier(carrier));
        onSelect(carrier);
    };

    return (
        <CarrierSearch
            {...state}
            inputName={name}
            onInputChange={handleInputChange}
            onSelect={handleSearchSelect}
            handleRequestNewCarrierModal={handleRequestNewCarrierModal}
        ></CarrierSearch>
    );
};
CarrierSearchContainer.propTypes = {
    initialSelectedCarrier: PropTypes.shape({
        name: PropTypes.string
    }),
    onSelect: PropTypes.func,
    selectedCarrier: PropTypes.any,
    name: PropTypes.string,
    handleRequestNewCarrierModal: PropTypes.func
};

export default CarrierSearchContainer;
