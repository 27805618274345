import { RadioButtonGroup } from '@agencykpi/components';

const typeOptions = (window.__INITIAL_STATE__ && window.__INITIAL_STATE__.policyTypeOptions) || [
    {
        label: 'Total',
        value: 'P&C'
    },
    {
        label: 'Personal',
        value: 'PL'
    },
    {
        label: 'Commercial',
        value: 'CL'
    },
    {
        label: 'Bond',
        value: 'BOND'
    }
];

const PolicyTypeSelector = ({ type, onChange, options = typeOptions, label, disabled }) => {
    return (
        <RadioButtonGroup
            label={label}
            onChange={onChange}
            name={'policyType'}
            items={options}
            selected={type}
            size="small"
            disabled={disabled}
        />
    );
};

export default PolicyTypeSelector;
