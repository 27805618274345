import { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography
} from '@mui/material';
import { Icon } from '../../icons/icons';
import Loader from '../shared/Loader';
import ActiveActions from './ActiveActions';
import { LoaderContainer } from './styles';
import { getSubscriberUsers } from '../../requests';
import { useQuery } from '@tanstack/react-query';
import { queries } from '../../utils';
import PropTypes from 'prop-types';
import { useStoreState } from 'easy-peasy';
import { request } from '../../utils/request';

const ActiveUsers = ({ adminUuid, agencyUuid }) => {
    const user = useStoreState((state) => state.user);
    const [activeUsersList, setActiveUsersList] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { data: subscriber } = useQuery(queries.subscriber(user.agency.uuid));
    const { data: isAdmin } = useQuery(queries.permission(user, 'read', 'admin'));

    const getAgencyUsers = useCallback(async () => {
        setIsLoading(true);
        try {
            const users = await getSubscriberUsers(agencyUuid);
            setActiveUsersList(users);
            setIsFetching(false);
            setIsLoading(false);
        } catch (err) {
            console.error(err);
            setIsFetching(false);
            setIsLoading(false);
        }
    }, [agencyUuid, isFetching]);

    useEffect(() => {
        if (isFetching) {
            getAgencyUsers();
        }
    }, [getAgencyUsers]);

    return (
        <>
            {isLoading && (
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            )}
            <TableContainer component={Paper}>
                <Table aria-label="active users table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            {/*<TableCell>Last Active On</TableCell>*/}
                            <TableCell>Primary</TableCell>
                            {isAdmin && <TableCell>Actions</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {activeUsersList.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell>{`${row.firstName} ${row.lastName}`}</TableCell>
                                <TableCell>{`${row.email}`}</TableCell>
                                {/*<TableCell>*/}
                                {/*    <Typography>*/}
                                {/*        {row.lastLoginDate*/}
                                {/*            ? moment(row.lastLoginDate).format('MMMM, Do, YYYY')*/}
                                {/*            : 'Never'}*/}
                                {/*    </Typography>*/}
                                {/*</TableCell>*/}
                                <TableCell>
                                    {subscriber?.adminUser.uuid === row.id && (
                                        <Icon type="check" iWidth={'30px'} iColor="green" />
                                    )}
                                </TableCell>
                                {isAdmin && (
                                    <TableCell>
                                        <ActiveActions
                                            setIsLoading={setIsLoading}
                                            setIsFetching={setIsFetching}
                                            user={row}
                                            adminUuid={adminUuid}
                                        />
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

ActiveUsers.propTypes = {
    adminUuid: PropTypes.string,
    agencyUuid: PropTypes.string
};

export default ActiveUsers;
