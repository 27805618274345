import styled from '@emotion/styled';
import { Tooltip, Box } from '@mui/material';
import { Icon } from '../../icons/icons';
import PropTypes from 'prop-types';

const CoreIcon = styled.span`
    color: #777;
    cursor: pointer;
    svg {
        font-size: 16px;
    }
`;

function CoreCarrierIcon({ tooltip }) {
    return (
        <Tooltip title={tooltip}>
            <Box>
                <CoreIcon data-border="true" data-type="light" data-border-color="lightgray">
                    <Icon
                        type="corecarrier"
                        iWidth="20px"
                        styles={{
                            width: '20px',
                            height: '20px',
                            marginRight: '10px',
                            opacity: '0.8'
                        }}
                    />
                </CoreIcon>
            </Box>
        </Tooltip>
    );
}
CoreCarrierIcon.propTypes = {
    tooltip: PropTypes.string
};

export default CoreCarrierIcon;
