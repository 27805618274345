import { useEffect, useState } from 'react';
import Loader from '../shared/Loader';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function FeatureFlags({ user, children }) {
    const client = useLDClient();
    const [clientIdentified, setClientIdentified] = useState(false);

    useEffect(() => {
        if (!user && !user.network && !user.agency) {
            return;
        }
        client
            .identify({
                kind: 'multi',
                user: {
                    key: user.userUuid,
                    name: user.displayName,
                    email: user.email,
                    userType: user.userType
                },
                agency: {
                    key: user.agency.uuid,
                    name: user.agency.name
                },
                network: {
                    key: user.network.uuid,
                    name: user.network.name,
                    abbreviation: user.network.abbreviation
                }
            })
            .then((_) => {
                setClientIdentified(true);
            })
            .catch((e) => {
                console.error(e);
            });
    }, [user]);

    if (!user || !clientIdentified) return <Loader />;

    if (user.bypassExternalSubscriber) {
        return children;
    }

    return <Navigate to="/no-subscription" />;
}
FeatureFlags.propTypes = {
    user: PropTypes.shape({
        userType: PropTypes.string,
        userUuid: PropTypes.string,
        email: PropTypes.string,
        displayName: PropTypes.string,
        network: PropTypes.shape({
            uuid: PropTypes.string,
            name: PropTypes.string,
            abbreviation: PropTypes.string
        }),
        bypassExternalSubscriber: PropTypes.bool,
        agency: PropTypes.shape({
            uuid: PropTypes.string,
            name: PropTypes.string
        }),
        subscriptions: PropTypes.arrayOf(
            PropTypes.shape({
                status: PropTypes.string
            })
        ),
        isPrimaryUserOfSubscriber: PropTypes.bool
    }),
    children: PropTypes.node
};
