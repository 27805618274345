import { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { find, groupBy, map, findIndex } from 'lodash-es';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons/faCheckCircle';
import { POLICY_TYPES } from '../../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { validationSchemaCarrierSearch, validationSchemaRemainder, shapeRadio } from './validators';
import { ELIGIBLE_YEAR, ELIGIBLE_MONTH } from '../../../constants';
import {
    FormSection,
    StyledForm,
    StyledSubmitDialog,
    StyledModalHeading,
    StyledModalLabel
} from './styles';

import {
    MyTextInput,
    MySelect,
    MyCarrierSearch,
    MySubCodeSearch,
    MyRadio,
    MyFormNavButtonContainer
} from './formInputs';

library.add(faCheckCircle);

const NewCarrierDataForm = ({ closeModal, ...props }) => {
    const formStagesInitial = [
        { stage: 'carriers', active: true },
        { stage: 'entries', active: false }
    ];
    const editEntry = props.editEntry;
    const handleRequestNewCarrierModal = props.handleRequestNewCarrierModal;
    const startStage = useStoreState((initialState) => initialState.manualEntryTable.currentView);
    const startStageIndex = findIndex(formStagesInitial, ['stage', startStage]);

    const [incurredLoss, setIncurredLoss] = useState(true);
    const [newSubCode, setNewSubCode] = useState(false);
    const [clearSubCodeSearchTerm, setClearSubCodeSearchTerm] = useState(false);
    const [lossRatio, setLossRatio] = useState(false);
    const [formStages, setFormStages] = useState(initializeStage);
    const [prevIsDisabled, setPrevIsDisabled] = useState(true);
    const submitFormAction = useStoreActions((actions) => actions.submitForm);
    const submittingForm = useStoreState((initialState) => initialState.submittingForm);
    const submitErrorMsg = useStoreState((initialState) => initialState.submitErrorMsg);

    const formValues = useStoreState((initialState) => initialState.manualEntryForm.formValues);

    const setPolicyForm = useStoreActions((actions) => actions.setPolicyForm);
    const policyTypeForm = useStoreState(
        (initialState) => initialState.manualEntryForm.formValues.policyType
    );

    const setCompanyForm = useStoreActions((actions) => actions.setCompanyForm);
    const companyNameForm = useStoreState(
        (initialState) => initialState.manualEntryForm.formValues.selectedCarrier.name
    );
    const companyForm = useStoreState(
        (initialState) => initialState.manualEntryForm.formValues.selectedCarrier
    );

    const setFormValues = useStoreActions((actions) => actions.setFormValues);
    const submittingFormMsg = useStoreState(
        (initialState) => initialState.manualEntryForm.submittingFormMsg
    );

    const setYearForm = useStoreActions((actions) => actions.setYearForm);
    const yearForm = useStoreState((initialState) => initialState.manualEntryForm.formValues.year);

    const selectedYear = useStoreState((initialState) => initialState.year);

    const [setSubmitErrorMsg, fetchSubCodes, setSubCodeForm, setSubCodes, setMonthForm] =
        useStoreActions((actions) => [
            actions.setSubmitErrorMsg,
            actions.fetchSubCodes,
            actions.setSubCodeForm,
            actions.setSubCodes,
            actions.setMonthForm
        ]);

    const [
        onboardedCarriers,
        subCodes,
        subCode,
        userAgency,
        manualFormattedEntryData,
        existingEntry
    ] = useStoreState((state) => [
        state.onboardedCarriers,
        state.subCodes,
        state.manualEntryForm.formValues.subCode,
        state.user.agency,
        state.manualEntryTable.manualFormattedEntryData,
        state.existingEntry
    ]);

    function submitForm(formFields) {
        let {
            mover = 0,
            newBusiness = 0,
            writtenPremium = 0,
            lossRatio = 0,
            earnedPremium = 0,
            incurredLoss = 0
        } = formFields;
        mover = mover === '' ? 0 : mover;
        newBusiness = newBusiness === '' ? 0 : newBusiness;
        writtenPremium = writtenPremium === '' ? 0 : writtenPremium;
        lossRatio = lossRatio === '' ? 0 : lossRatio;
        earnedPremium = earnedPremium === '' ? 0 : earnedPremium;
        incurredLoss = incurredLoss === '' ? 0 : incurredLoss;
        setPolicyForm({ policyTypeCode: formFields.policyType });
        formFields.carrierUuid = formFields.selectedCarrier.uuid;
        if (formFields.radio === 'lossRatio') {
            formFields.lossRatio = Number.parseFloat(lossRatio) ? Number.parseFloat(lossRatio) : 0;
            formFields.earnedPremium = 0;
            formFields.incurredLoss = 0;
        } else {
            formFields.lossRatio = 0;
            formFields.earnedPremium = Number.isInteger(earnedPremium)
                ? earnedPremium
                : parseInt(earnedPremium.replace(/\$|,/g, ''));
            formFields.incurredLoss = Number.isInteger(incurredLoss)
                ? incurredLoss
                : parseInt(incurredLoss.replace(/\$|,/g, ''));
        }
        formFields.mover = Number.isInteger(mover) ? mover : parseInt(mover.replace(/\$|,/g, ''));
        formFields.newBusiness = Number.isInteger(newBusiness)
            ? newBusiness
            : parseInt(newBusiness.replace(/\$|,/g, ''));
        formFields.writtenPremium = Number.isInteger(writtenPremium)
            ? writtenPremium
            : parseInt(writtenPremium.replace(/\$|,/g, ''));
        formFields.subCode = formFields.selectedSubCode.code;
        formFields.subCodeUuid = formFields.selectedSubCode.uuid;
        formFields.month = formFields.month === 0 ? 1 : formFields.month
        setFormValues(formFields);
        submitFormAction({ form: formFields, year: selectedYear });
    }

    function submitCarrier(values) {
        setCompanyForm({
            companyUuid: values.carrier.uuid,
            isCrop: values.carrier.isCrop,
            companyName: values.carrier.name
        });
        setSubCodeForm({
            uuid: values.subcodeParent,
            code: values.subcode.code
        });
        updateStage('forward');
    }

    function stepBackward() {
        updateStage('backward');
    }

    function stepForward() {
        updateStage('forward');
    }

    function initializeStage(props) {
        if (startStage === 'carriers') {
            return formStagesInitial;
        }
        let index = findIndex(formStagesInitial, ['active', true]);
        formStagesInitial[index].active = false;
        index = findIndex(formStagesInitial, ['stage', startStage]);
        formStagesInitial[index].active = true;
        return formStagesInitial;
    }

    function updateStage(direction) {
        const newFormStages = formStages.slice();
        const index = findIndex(newFormStages, ['active', true]);
        if (direction === 'forward') {
            if (index + 1 < newFormStages.length) {
                newFormStages[index].active = false;
                newFormStages[index + 1].active = true;
                setFormStages(newFormStages);
                setPrevIsDisabled(false);
                return;
            }
        }
        //back Case where form was started from dataTable deeper than first stage
        if (index - 1 <= startStageIndex) {
            setPrevIsDisabled(true);
        }
        if (index - 1 >= 0) {
            newFormStages[index].active = false;
            newFormStages[index - 1].active = true;
            setFormStages(newFormStages);
            if (index === 0) {
                setPrevIsDisabled(true);
            }
        }
    }

    function processPolicyTypes(carrier, types) {
        //Add to this function as the form matures
        //Crop processing
        return types.filter((type) => {
            return type.label !== 'Crop' || carrier.isCrop === true;
        });
    }

    function getYears() {
        const numOfYearsBack = 2;
        const array = [{ label: ELIGIBLE_YEAR, value: ELIGIBLE_YEAR }];
        for (let j = 1; j <= numOfYearsBack; j++) {
            let obj = {
                label: ELIGIBLE_YEAR - j,
                value: ELIGIBLE_YEAR - j
            };
            array.push(obj);
        }
        return array;
    }

    function getMonths() {
        const today = new moment();
        const currentMonth = today.month();
        const currentYear = today.year();
        const useYear = yearForm ? yearForm : selectedYear;
        let months = Array.apply(null, Array(useYear < currentYear ? 12 : currentMonth));
        months = months.map((month, index) => {
            return { label: index + 1, value: index + 1 };
        });
        months = months.length === 12 ? months : months.reverse();
        return months;
    }

    function isMonthValid(month) {
        const maxEligibleMonth = new moment().month();
        return parseInt(month, 10) <= maxEligibleMonth ? true : false;
    }

    function setRadioGroup(field, meta) {
        if (field.value === 'lossRatio' && field.checked) {
            setIncurredLoss(false);
            setLossRatio(true);
        }
        if (field.value === 'incurredLossEarnedPremium' && field.checked) {
            setIncurredLoss(true);
            setLossRatio(false);
        }
    }

    function onCarrierSearchSelect(selectedCarrier) {
        setSubCodes([]);
        setNewSubCode(false);
        checkOnboardedCarriers(selectedCarrier);
        if (!!selectedCarrier) {
            fetchSubCodes(selectedCarrier);
        }
    }

    function onSubCodeSearchSelect(selectedSubCode) {
        setNewSubCode(false);
        if (!!selectedSubCode) {
            setNewSubCode(isNewSubCode(selectedSubCode));
        }
    }

    function checkOnboardedCarriers(selectedCarrier) {
        setSubmitErrorMsg('');
        if (!!selectedCarrier.uuid && find(onboardedCarriers, ['uuid', selectedCarrier.uuid])) {
            setSubmitErrorMsg(
                'Notice: This carrier already has an entry previously onboarded by your network.'
            );
        }
    }

    function isNewSubCode(selectedSubCode) {
        const matchingSubCode = subCodes.filter((subCode) => {
            return subCode.code === selectedSubCode.code;
        });
        return matchingSubCode.length === 0 ? true : false;
    }

    function getUniqueSubCodeParentIds() {
        const groupedSubCodes = groupBy(subCodes, 'uuid');
        if (Object.keys(groupedSubCodes).length === 0) {
            const city = userAgency.city ? `- ${userAgency.City}` : '';
            const state = userAgency.state ? `- ${userAgency.state}` : '';
            const labelValue = `${userAgency.name} ${city} ${state}`;
            return [{ label: labelValue, value: userAgency.uuid }];
        }
        const items = map(groupedSubCodes, (item, key) => {
            const city = item[0].city ? `- ${item[0].city}` : '';
            const state = item[0].state ? `- ${item[0].state}` : '';
            const labelValue = `${item[0].name} ${city} ${state}`;
            return { label: labelValue, value: key };
        });
        return items;
    }

    function checkExistingEntries(selectedValue, inputType, selectedYear) {
        //if selectedYear is set then the year select input is also changing month
        const warningMsg =
            'An entry already exists that matches these parameters. Submitting will edit it';
        setSubmitErrorMsg('');
        if (!manualFormattedEntryData[formValues.subCode]) {
            return;
        }
        const existingEntries = manualFormattedEntryData[formValues.subCode].groupedPoliciesByCode;

        switch (inputType) {
            case 'policyType':
                if (
                    existingEntries[selectedValue] &&
                    find(existingEntries[selectedValue], ['year', formValues.year]) &&
                    find(existingEntries[selectedValue], ['month', formValues.month])
                ) {
                    setSubmitErrorMsg(warningMsg);
                }
                break;
            case 'year':
                if (
                    existingEntries[formValues.policyType] &&
                    find(existingEntries[formValues.policyType], [
                        'year',
                        parseInt(selectedValue, 10)
                    ]) &&
                    find(existingEntries[formValues.policyType], ['month', formValues.month])
                ) {
                    setSubmitErrorMsg(warningMsg);
                }
                break;
            case 'month':
                if (
                    existingEntries[formValues.policyType] &&
                    find(existingEntries[formValues.policyType], ['year', formValues.year]) &&
                    find(existingEntries[formValues.policyType], [
                        'month',
                        parseInt(selectedValue, 10)
                    ])
                ) {
                    setSubmitErrorMsg(warningMsg);
                }
                break;
            case 'monthAndYear':
                if (
                    existingEntries[formValues.policyType] &&
                    find(existingEntries[formValues.policyType], [
                        'year',
                        parseInt(selectedYear, 10)
                    ]) &&
                    find(existingEntries[formValues.policyType], [
                        'month',
                        parseInt(selectedValue, 10)
                    ])
                ) {
                    setSubmitErrorMsg(warningMsg);
                }
                break;
        }
    }

    return (
        <>
            {submittingForm ? (
                <StyledSubmitDialog>
                    {submittingFormMsg}
                    {submittingFormMsg === 'Success' && (
                        <div className="icon">
                            <FontAwesomeIcon color="green" icon={['far', 'check-circle']} />
                        </div>
                    )}
                </StyledSubmitDialog>
            ) : (
                <>
                    <StyledModalHeading>
                        {editEntry && <h1>Edit Existing Entry</h1>}
                        {!editEntry && <h1>New Carrier Data Entry</h1>}
                    </StyledModalHeading>
                    <StyledModalLabel>
                        <div style={{ height: '21px' }}>
                            {companyNameForm.length > 0 && <span>{companyNameForm}</span>}
                            {subCode && subCode.length > 0 && (
                                <>
                                    <span> / </span>
                                    <span>{subCode}</span>
                                    {/*<span>{subCode} - {formValues.selectedSubCode.name}</span>*/}
                                </>
                            )}
                            {policyTypeForm.length > 0 && (
                                <>
                                    <span> / </span>
                                    <span>{policyTypeForm}</span>
                                </>
                            )}
                        </div>
                        {editEntry && (
                            <>
                                <div>
                                    {formValues.month}/{formValues.year}
                                </div>
                            </>
                        )}
                    </StyledModalLabel>
                    {formStages[0].active && (
                        <Formik
                            initialValues={{
                                carrier: formValues.selectedCarrier,
                                subcode: formValues.selectedSubCode,
                                subcodeParent: getUniqueSubCodeParentIds()[0].value
                            }}
                            validateOnChange={true}
                            validationSchema={Yup.object(validationSchemaCarrierSearch).shape({
                                subcodeParent: Yup.string().when('subcode', {
                                    is: (subCode) => isNewSubCode(subCode),
                                    then: Yup.string(),
                                    otherwise: Yup.string()
                                })
                            })}
                            onSubmit={(values) => {
                                submitCarrier(values);
                            }}
                        >
                            {(props) => (
                                <StyledForm>
                                    <Form
                                        autoComplete="off"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            width: '100%'
                                        }}
                                    >
                                        <div style={{ height: '50px' }}></div>
                                        <FormSection>
                                            <MyCarrierSearch
                                                name="carrier"
                                                initialSelectedCarrier={formValues.selectedCarrier}
                                                onCarrierSelect={(selectedCarrier) => {
                                                    onCarrierSearchSelect(selectedCarrier);
                                                    props.setFieldTouched('subcode', true);
                                                    setTimeout(() =>
                                                        props.setFieldTouched('subcode', true)
                                                    );
                                                    props.setFieldValue('subcode', '');
                                                    setClearSubCodeSearchTerm(true);
                                                }}
                                                handleRequestNewCarrierModal={
                                                    handleRequestNewCarrierModal
                                                }
                                            />
                                        </FormSection>
                                        <FormSection>
                                            <MySubCodeSearch
                                                name="subcode"
                                                initialSelectedSubCode={formValues.selectedSubCode}
                                                carrierSubCodes={subCodes}
                                                onSubCodeSelect={onSubCodeSearchSelect}
                                                clearSearchTerm={clearSubCodeSearchTerm}
                                                resetClearSearchTerm={() => {
                                                    setClearSubCodeSearchTerm(false);
                                                }}
                                            />
                                        </FormSection>
                                        {newSubCode && (
                                            <FormSection>
                                                <MySelect
                                                    name="subcodeParent"
                                                    label="Associated sub agency"
                                                    items={getUniqueSubCodeParentIds()}
                                                />
                                            </FormSection>
                                        )}
                                        <MyFormNavButtonContainer
                                            prevIsDisabled={prevIsDisabled}
                                            stepForward={stepForward}
                                            stepBackward={stepBackward}
                                            closeModal={closeModal}
                                            formProps={props}
                                            formStages={formStages}
                                            formMsg={submitErrorMsg}
                                        />
                                    </Form>
                                </StyledForm>
                            )}
                        </Formik>
                    )}
                    {formStages[1].active && (
                        <Formik
                            initialValues={formValues}
                            validateOnChange={true}
                            //initialTouched={!!formValues.year ? {touched: true} : {}}
                            //initialTouched={formValues.policyType.length > 0 ? {touched: true} : {}}
                            validationSchema={Yup.object(validationSchemaRemainder).shape(
                                shapeRadio
                            )}
                            onSubmit={(values) => {
                                submitForm(values);
                            }}
                        >
                            {(props) => (
                                <StyledForm>
                                    <Form
                                        autoComplete="off"
                                        style={{
                                            width: '100%'
                                        }}
                                    >
                                        {!editEntry && (
                                            <FormSection>
                                                <MySelect
                                                    name="policyType"
                                                    items={POLICY_TYPES}
                                                    handleChange={(selectedValue) => {
                                                        setPolicyForm({
                                                            policyTypeCode: selectedValue
                                                        });
                                                        checkExistingEntries(
                                                            selectedValue,
                                                            'policyType'
                                                        );
                                                    }}
                                                />
                                                <MySelect
                                                    name="month"
                                                    handleBlur={props.handleBlur}
                                                    items={getMonths()}
                                                    handleChange={(selectedValue) => {
                                                        setMonthForm(selectedValue);
                                                        checkExistingEntries(
                                                            selectedValue,
                                                            'month'
                                                        );
                                                    }}
                                                />
                                                <MySelect
                                                    name="year"
                                                    items={getYears()}
                                                    handleChange={(selectedValue) => {
                                                        setYearForm(selectedValue);
                                                        checkExistingEntries(selectedValue, 'year');
                                                        const selectedMonthValue =
                                                            props.getFieldProps('month').value;
                                                        const selectedMonthMetaTouched =
                                                            props.getFieldMeta('month').touched;
                                                        if (
                                                            !isMonthValid(selectedMonthValue) &&
                                                            selectedMonthMetaTouched === true
                                                        ) {
                                                            props.setFieldValue(
                                                                'month',
                                                                ELIGIBLE_MONTH
                                                            );
                                                            setMonthForm(ELIGIBLE_MONTH);
                                                            checkExistingEntries(
                                                                ELIGIBLE_MONTH,
                                                                'monthAndYear',
                                                                selectedValue
                                                            );
                                                            setTimeout(() =>
                                                                props.setFieldTouched('month', true)
                                                            );
                                                        } else if (
                                                            selectedMonthMetaTouched === false
                                                        ) {
                                                            props.setFieldValue(
                                                                'month',
                                                                selectedMonthValue
                                                            );
                                                        }
                                                    }}
                                                />
                                            </FormSection>
                                        )}
                                        <FormSection>
                                            <MyTextInput
                                                label="Written Premium YTD"
                                                name="writtenPremium"
                                                className="writtenPremium"
                                                type="dollar"
                                                placeholder="$0"
                                            />
                                            <MyTextInput
                                                label="New Business Premium YTD"
                                                name="newBusiness"
                                                type="dollar"
                                                placeholder="$0"
                                            />
                                            <MyTextInput
                                                label="12 Month Mover"
                                                name="mover"
                                                type="dollar"
                                                placeholder="$0"
                                            />
                                        </FormSection>
                                        {false && (
                                            <FormSection>
                                                <MyRadio
                                                    name="radio"
                                                    label="Incurred Loss and Earned Premium"
                                                    value="incurredLossEarnedPremium"
                                                    setRadioGroup={setRadioGroup}
                                                ></MyRadio>
                                                <MyRadio
                                                    name="radio"
                                                    label="Loss Ratio"
                                                    value="lossRatio"
                                                    setRadioGroup={setRadioGroup}
                                                ></MyRadio>
                                            </FormSection>
                                        )}
                                        {incurredLoss && (
                                            <FormSection>
                                                <MyTextInput
                                                    label="Incurred Loss YTD"
                                                    name="incurredLoss"
                                                    type="dollar"
                                                    placeholder="$0"
                                                />
                                                <MyTextInput
                                                    label="Earned Premium YTD"
                                                    name="earnedPremium"
                                                    type="dollar"
                                                    placeholder="$0"
                                                />
                                            </FormSection>
                                        )}
                                        {!incurredLoss && (
                                            <FormSection>
                                                <MyTextInput
                                                    label="Loss Ratio %"
                                                    name="lossRatio"
                                                    type="percent"
                                                    placeholder="0%"
                                                    handleChange={() => {
                                                        setTimeout(() =>
                                                            props.setFieldTouched('month', true)
                                                        );
                                                    }}
                                                />
                                            </FormSection>
                                        )}
                                        <MyFormNavButtonContainer
                                            prevIsDisabled={prevIsDisabled}
                                            stepForward={stepForward}
                                            stepBackward={stepBackward}
                                            closeModal={closeModal}
                                            formProps={props}
                                            formStages={formStages}
                                            formMsg={submitErrorMsg}
                                        />
                                    </Form>
                                </StyledForm>
                            )}
                        </Formik>
                    )}
                </>
            )}
        </>
    );
};

export default NewCarrierDataForm;
