import { useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { Button } from '@agencykpi/components';
import { request } from '../../utils/request';
import toast from '../shared/toast';

const validateEmail = (email) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

const InviteUser = ({ setRefetch, setIsInviting, isInviting }) => {
    const [email, setEmail] = useState('');
    const [hasError, setHasError] = useState(false);

    const handleInvite = async () => {
        setIsInviting(true);
        try {
            const response = await request.post(`/api/invites/invite-user`).send({
                emails: [email],
                platformName: 'understanding'
            });
            if (response.body.status === 200) {
                toast('Invitation successfully sent!', 'success');
                setRefetch(true);
            } else {
                toast(response.body.message || 'There was an error inviting the user.', 'error');
            }
        } catch (err) {
            toast('There was an error when sending invite', 'error');
        }
        setIsInviting(false);
    };

    const handleChange = (value) => {
        if (validateEmail(value)) {
            setHasError(false);
        } else {
            setHasError(true);
        }
        setEmail(value);
    };

    return (
        <>
            <Grid item xs={12}>
                <Typography>Invite a new user to your agency</Typography>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    error={hasError ? true : false}
                    helperText={hasError ? 'Invalid Email' : ''}
                    fullWidth
                    id="invite-email"
                    value={email}
                    onChange={(e) => handleChange(e.target.value)}
                    variant="outlined"
                    size="small"
                />
            </Grid>
            <Grid item xs={3}>
                <Button
                    disabled={email.length === 0 || hasError || isInviting}
                    primary
                    style={{ margin: 0 }}
                    onClick={handleInvite}
                >
                    Invite User
                </Button>
            </Grid>
        </>
    );
};

export default InviteUser;
