import styled from '@emotion/styled';

export const StyledPolicyContainer = styled.div`
    margin: 4px 0 0 0;
    display: flex;
    height: 60px;
    flex-flow: column wrap;
    justify-content: space-evenly;
    align-content: space-between;
`;
export const StyledPolicyPill = styled.div`
    border: solid #ffffff00;
    border-radius: 30px;
    color: #777;
    padding: 0 6px;
    margin: 0 2px;
    text-align: center;
    &.exists {
        border: solid #ef1a1ae0;
        color: #333;
        &.current {
            border: solid #90cdf4;
            background: #90cdf4;
            color: #2a4365;
        }
    }
`;
