import { useField } from 'formik';
import { Input, Checkbox } from '@agencykpi/components';
import { Typography } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import { StyledLabel, StyledTextInput, StyledError, RequiredField, StyledCheckBox } from './styles';
import 'react-phone-input-2/lib/style.css';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function formatValueToPostalCode(value) {
    let newValue = value;
    if (!Number.isInteger(value)) {
        newValue = value.replace(/[^0-9 -]/g, '');
    }

    return newValue;
}

function formatValueToAlpha(value) {
    return value.replace(/[^a-zA-Z ]/g, '');
}

export const MyCheckbox = ({
    isRequired = false,
    onChange,
    onBlur,
    onFocus,
    description,
    ...props
}) => {
    const [field, meta, helpers] = useField(props.name);
    const checkboxProps = {
        ...props,
        checked: !!field.value,
        onChange: (event) => {
            helpers.setValue(event.target.checked);
            !!onChange && onChange(field.value);
        },
        onBlur: () => {
            helpers.setTouched(true);
            !!onBlur && onBlur(field.value);
        },
        onFocus: () => {
            !!onFocus && onFocus(field.value);
        }
    };

    return (
        <StyledCheckBox>
            <Checkbox isRequired={isRequired} className="inline" {...checkboxProps} />
            {description && description}
            {isRequired && <span style={{ color: 'red', display: 'inline-lock' }}>*</span>}
            {meta.value && (
                <Typography style={{ color: '#dc3545', fontSize: '0.9em', fontWeight: 300 }}>
                    {meta.error}
                </Typography>
            )}
        </StyledCheckBox>
    );
};

MyCheckbox.propTypes = {
    description: PropTypes.string,
    isRequired: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
};

export const MyTextInput = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props);
    return (
        <StyledTextInput>
            <StyledLabel htmlFor={props.id || props.name}>
                {label}
                <RequiredField isRequired={props.isRequired || false}>*</RequiredField>
            </StyledLabel>
            <Input
                className="text-input"
                {...field}
                {...props}
                onChange={(e) => {
                    if (props.onChange) {
                        props.onChange(e);
                    }
                    if (props.inputChanged) {
                        props.inputChanged();
                    }
                    switch (props && props.type) {
                        case 'postalcode':
                            helpers.setValue(formatValueToPostalCode(e.target.value, field.value));
                            break;
                        case 'alpha':
                            helpers.setValue(formatValueToAlpha(e.target.value, field.value));
                            break;
                        default:
                            helpers.setValue(e.target.value);
                    }
                }}
                error={meta.touched && meta.error ? meta.error : ''}
            />
        </StyledTextInput>
    );
};

MyTextInput.propTypes = {
    id: PropTypes.string,
    inputChanged: PropTypes.func,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.string
};

export const MyPhoneInput = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props);
    const newProps = {
        error: meta.touched && meta.error ? meta.error : '',
        name: props.name
    };
    const [phone, setPhone] = useState(field.value);
    useEffect(() => {
        if (props.inputChanged) {
            props.inputChanged();
        }
        helpers.setValue(phone);
    }, [phone]);

    return (
        <StyledTextInput>
            <StyledLabel htmlFor={props.id || props.name}>
                {label}
                <RequiredField isRequired={props.isRequired || false}>*</RequiredField>
            </StyledLabel>
            <PhoneInput
                {...field}
                inputProps={{ ...newProps }}
                country={'us'}
                onlyCountries={['us']}
                countryCodeEditable={false}
                onChange={(phone) => {
                    setPhone(phone);
                }}
                inputStyle={{
                    padding: '6px 28px 6px 48px',
                    fontSize: '14px',
                    width: '100%',
                    height: '40px',
                    border: '1px solid rgb(204, 204, 204)',
                    borderRadius: '3px'
                }}
                dropdownStyle={{ margin: '2px 0 10px -1px' }}
                style={{
                    border: meta.touched && meta.error ? '1px solid rgb(220, 53, 69)' : '',
                    borderRadius: '3px'
                }}
            />
            <StyledError>{meta.touched && meta.error ? meta.error : ''}</StyledError>
        </StyledTextInput>
    );
};

MyPhoneInput.propTypes = {
    id: PropTypes.string,
    inputChanged: PropTypes.func,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string
};
