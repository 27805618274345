import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const title = css({
    borderBottom: '1px solid #d1d5d9',
    paddingBottom: 10,
    marginBottom: 20
});
export const buttonLink = css({
    border: 0,
    padding: 0,
    marginTop: 0,
    outline: 'none'
});

export const LoaderContainer = styled.div`
    z-index: 999;
    position: absolute;
    top: 40%;
    left: 50%;
`;
