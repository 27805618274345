import { useRef, useState, useEffect } from 'react';
import { DropDownBox } from 'devextreme-react/drop-down-box';
import { TreeView } from 'devextreme-react/tree-view';

const CarriersFilters = ({ carriersList, carriersFiltersRef }) => {
    const [selectedCarriers, setSelectedCarriers] = useState(carriersList);
    const carrierTreeRef = useRef(null);
    const dropdownRef = useRef(null);

    const selectionChange = (e) => {
        const selectedNodes = e.getSelectedNodes().map((item) => item.itemData);
        const modifiedList = e.getDataSource().items();
        setSelectedCarriers(selectedNodes);
        carriersFiltersRef.current.selected = selectedNodes;
        carriersFiltersRef.current.all = modifiedList;
    };

    useEffect(() => {
        dropdownRef?.current?.instance.open();
        dropdownRef?.current?.instance.close();
        carriersFiltersRef.current.ref = carrierTreeRef;
    }, []);

    const TreeViewRender = () => {
        return (
            <TreeView
                id="carriers-treeview"
                dataStructure="plain"
                ref={carrierTreeRef}
                displayExpr="text"
                keyExpr="id"
                parentIdExpr="parentId"
                value={selectedCarriers}
                dataSource={carriersList}
                selectNodesRecursive={true}
                selectByClick={true}
                showCheckBoxesMode="normal"
                selectionMode="multiple"
                onSelectionChanged={(e) => selectionChange(e.component)}
                onInitialized={(e) => selectedCarriers.length === 0 && e.component.unselectAll()}
            />
        );
    };
    return (
        <DropDownBox
            ref={dropdownRef}
            style={{ height: '40px', width: '250px' }}
            value={selectedCarriers.filter((carrier) => carrier.selected)}
            dataSource={carriersList}
            keyExpr="key"
            displayExpr="text"
            placeholder={`All Carriers & Wholesalers`}
            showClearButton={true}
            onValueChanged={(e) => {
                if (e.value === null) {
                    setSelectedCarriers([]);
                    carriersFiltersRef.current.selected = [];
                    carrierTreeRef?.current?.instance.unselectAll();
                }
            }}
            contentRender={TreeViewRender}
        />
    );
};

export default CarriersFilters;
