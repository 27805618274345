import { useReducer, useEffect } from 'react';
import { filter } from 'lodash-es';
import PropTypes from 'prop-types';
import { useDebounce } from '../../../utils/hooks';
import { initialState, actions, reducer } from './searchReducer';
import SubCodeSearch from './SubCodeSearch';

const SubCodeSearchContainer = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const debouncedSearchTerm = useDebounce(state.searchTerm, 300);
    const {
        initialSelectedSubCode,
        onSelect,
        selectedSubCode,
        carrierSubCodes,
        name,
        clearSearchTerm
    } = props;

    useEffect(() => {
        if (!debouncedSearchTerm.length || debouncedSearchTerm.length < 1) return;
        const filteredCarrierSubCodes = filter(carrierSubCodes, (obj) => {
            const codeSearch = !obj.code
                ? false
                : obj.code.toLowerCase() == debouncedSearchTerm.toLowerCase();
            const nameSearch = !obj.name
                ? false
                : obj.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase());
            return obj.code && obj.name && (codeSearch || nameSearch);
        });
        if (filteredCarrierSubCodes.length === 0) {
            const newCode = {
                uuid: debouncedSearchTerm,
                code: debouncedSearchTerm,
                name: 'Click to create new subcode'
            };
            dispatch(actions.setResults([newCode]));
        } else {
            dispatch(actions.setResults(filteredCarrierSubCodes.slice(0, 6)));
        }
    }, [debouncedSearchTerm, state.companyType]);

    useEffect(() => {
        if (initialSelectedSubCode.length > 0) {
            dispatch(actions.setResults([]));
            dispatch(actions.setSearchTerm(initialSelectedSubCode));
            dispatch(actions.setSelectedSubCode(initialSelectedSubCode));
            onSelect(initialSelectedSubCode);
        }
    }, [initialSelectedSubCode]);

    useEffect(() => {
        if (clearSearchTerm) {
            dispatch(actions.setResults([]));
            dispatch(actions.setSearchTerm(''));
            dispatch(actions.setSelectedSubCode(''));
            onSelect('');
            props.resetClearSearchTerm();
        }
    }, [clearSearchTerm]);

    const handleInputChange = (e) => {
        const { value } = e.target;
        if (value != selectedSubCode.name) {
            dispatch(actions.setSelectedSubCode(''));
            onSelect('');
        }
        dispatch(actions.setSearchTerm(value));

        if (!value.length) {
            return dispatch(actions.setResults([]));
        }
    };

    const handleSearchSelect = (subCode) => {
        dispatch(actions.setResults([]));
        dispatch(actions.setSearchTerm(subCode.code));
        dispatch(actions.setSelectedSubCode(subCode.code));
        onSelect(subCode);
    };

    return (
        <SubCodeSearch
            {...state}
            inputName={name}
            onInputChange={handleInputChange}
            onSelect={handleSearchSelect}
        ></SubCodeSearch>
    );
};
SubCodeSearchContainer.propTypes = {
    initialSelectedSubCode: PropTypes.string,
    onSelect: PropTypes.func,
    selectedSubCode: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string
    }),
    carrierSubCodes: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            code: PropTypes.string
        })
    ),
    name: PropTypes.string,
    clearSearchTerm: PropTypes.bool
};

export default SubCodeSearchContainer;
