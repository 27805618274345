import AccessDenied from '../shared/AccessDenied';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

/// Renders its children if the required flags are enabled, otherwise `<AccessDenied />`
export function FeatureRequired({ children, flags, fallback }) {
    const flagSettings = useFlags();
    return isConsideredEnabled(flags, flagSettings) ? children : fallback ?? <AccessDenied />;
}

FeatureRequired.propTypes = {
    children: PropTypes.node,
    fallback: PropTypes.element,
    flags: PropTypes.arrayOf(PropTypes.string)
};

export function isConsideredEnabled(flags, flagSettings) {
    // Filter out null or undefined values
    flags = flags.filter(Boolean);
    return (
        // Providing no flags is interpreted as no flags are required
        !flags ||
        flags.length === 0 ||
        // Any disabled flag fails the whole requirement
        flags.reduce((result, flag) => result && flagSettings[flag], true)
    );
}
