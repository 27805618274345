import { groupBy, includes, map } from 'lodash-es';
import moment from 'moment';
const MONTH = moment().month();
const YEAR = moment().year();
const NOW = moment(new Date(YEAR, MONTH, 1));

function getNewestEntry(array) {
    let newestEntry = array[0];
    array.forEach((entry) => {
        const newestEntryDate = moment(new Date(newestEntry.year, newestEntry.month - 1, 1));
        const entryDate = moment(new Date(entry.year, entry.month - 1, 1));
        if (entryDate.isSameOrAfter(newestEntryDate)) {
            newestEntry = entry;
        }
    });
    return newestEntry;
}

export const groupTableDataBy = {
    carriers: (data) => {
        const carriers = groupBy(data, 'companyUuid');
        const carrierSummaries = map(carriers, (valuesArray) => {
            //evaluate if any entry is older than current month
            let upToDate = false;
            const currentStatuses = map(valuesArray, (entry) => {
                const entryDate = moment(new Date(entry.year, entry.month - 1, 1));
                return upToDate || entryDate.isSame(NOW);
            });
            const carrierCurrentStatus = includes(currentStatuses, true);

            //most recent entry
            let newestEntry = getNewestEntry(valuesArray);

            //Sum latest of each policy type for total written prem
            const groupedPolicies = groupBy(valuesArray, 'policyTypeUuid');
            const groupedSubCodes = groupBy(valuesArray, 'agencyCode');
            const subCodeTypes = Object.keys(groupedSubCodes);
            let total = 0;
            subCodeTypes.forEach((key) => {
                const groupedPolicies = groupBy(groupedSubCodes[key], 'policyTypeUuid');
                const newestEntries = map(groupedPolicies, getNewestEntry);
                newestEntries.forEach((entry) => {
                    total += entry.dwp;
                });
            });

            //Create policy status object. True/false and current status per policy
            const groupedPoliciesByCode = groupBy(valuesArray, 'policyTypeCode');
            map(groupedPoliciesByCode, (policyGroup) => {
                policyGroup.map((entry) => {
                    entry.date = `${entry.month}/${entry.year}`;
                });
            });
            const policyStatusObj = map(groupedPoliciesByCode, (policies, policyKey) => {
                const newestEntry = getNewestEntry(policies);
                const entryDate = moment(new Date(newestEntry.year, newestEntry.month - 1, 1));
                const lastMonthDate = moment(new Date(YEAR, MONTH - 1, 1));
                const status = entryDate.isSame(lastMonthDate);
                return {
                    type: policyKey,
                    current: status
                };
            });

            return {
                carrierCurrentStatus,
                policyCount: Object.keys(groupedPolicies).length,
                policyStatusObj: policyStatusObj,
                groupedPoliciesByCode,
                newestEntry,
                companyName: newestEntry.companyName,
                companyUuid: newestEntry.companyUuid,
                dwp: total
            };
        });
        return carrierSummaries;
    },
    entries: (data, companyUuid) => {
        const carriers = groupBy(data, 'companyUuid');
        const groupedByAgencyCode = groupBy(carriers[companyUuid], 'agencyCode');
        let policySummaries = {};
        map(groupedByAgencyCode, (valuesArray, key) => {
            //Create policy status object. True/false and current status per policy
            //most recent entry
            let newestEntry = getNewestEntry(valuesArray);
            const groupedPoliciesByCode = groupBy(valuesArray, 'policyTypeCode');
            map(groupedPoliciesByCode, (policyGroup) => {
                policyGroup.map((entry) => {
                    entry.date = `${entry.month}/${entry.year}`;
                });
            });
            const policyStatusObj = map(groupedPoliciesByCode, (policies, policyKey) => {
                const newestEntry = getNewestEntry(policies);
                const entryDate = moment(new Date(newestEntry.year, newestEntry.month - 1, 1));
                const lastMonthDate = moment(new Date(YEAR, MONTH - 1, 1));
                const status = entryDate.isSame(lastMonthDate);
                return {
                    type: policyKey,
                    current: status
                };
            });
            return (policySummaries[key] = {
                policyStatusObj: policyStatusObj,
                newestEntry,
                groupedPoliciesByCode
            });
        });
        return policySummaries;
    }
};
