import styled from '@emotion/styled';

import theme from '../../theme';

export const NavMenuHeader = styled.div`
    //padding-bottom: 20px;
    font-size: 28px;
    position: relative;
    &::after {
        content: '';
        border-top: 1px solid ${theme.colors.gr};
        width: 100%;
        height: 0;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    a {
        color: ${theme.colors.textDark};
        text-decoration: none;
        font-size: 16px;
        font-weight: 300;
        margin-right: 10px;
    }
    .akpi-tools__dropdown {
        a:hover,
        a:active,
        a:focus {
            color: ${theme.colors.textTint};
            background-color: ${theme.colors.tint};
            text-decoration: none;
        }
    }
    .akpi-tools {
        position: relative;
        .chevron-icon {
            font-size: 0.5em;
        }
    }
    .akpi-tools__trigger {
        padding-bottom: 20px;
        display: flex;
        align-items: center;
        &:hover {
            cursor: pointer;
        }
    }
    .akpi-tools__dropdown {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        white-space: nowrap;
        z-index: 5;
        display: block;
        transition: max-height 0.5s, margin 0.5s;
        max-height: 0px;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.05);
        cursor: pointer;
    }
    .akpi-tools__dropdown.open {
        display: block;
        max-height: 200px;
        margin-bottom: 20px;
    }
    .akpi-tools__dropdown li {
    }
    .akpi-tools__dropdown li:hover {
        color: ${theme.colors.textTint};
        background-color: ${theme.colors.tint};
        text-decoration: none;
    }
    .akpi-tools__dropdown a {
        color: ${theme.colors.textDark};
        width: 100%;
        padding: 10px;
        display: block;
    }
    .akpi-tools__dropdown a {
        text-decoration: none;
    }
`;

export const NavMenuBody = styled.div`
    //padding: 20px 0;
    & > ul {
        margin-left: 0;
        padding-left: 0;
        list-style-type: none;
        width: 100%;
    }
    & ul li a {
        padding: 20px 0;
        color: ${theme.colors.textDark};
        font-size: 16px;
        font-weight: 300;
        padding: 15px 20px;
        margin: 10px -20px;
        display: flex;
        align-items: center;
        text-decoration: none;
        transition: 'all 0.5s';
    }
    & ul li a i {
        flex-basis: 40px;
        font-size: 20px;
    }
    & ul li a:hover,
    & ul li a:active,
    & ul li a.active {
        color: ${theme.colors.textTint};
        background-color: ${theme.colors.tint};
        text-decoration: none;
    }
    ul li a.disabled {
        opacity: 0.65;
    }
    ul li a.disabled:hover {
        cursor: default;
        text-decoration: none;
    }
`;
