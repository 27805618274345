import { createStore } from 'easy-peasy';
import { model } from './models/model';
import { registerModel } from './models/registerModel';

export default createStore({
    register: {
        ...registerModel
    },
    ...model
});
