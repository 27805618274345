import { useEffect, useMemo, useState } from 'react';
import YearSelector from '../YearSelector';
import { AgencyCompensationWrap } from './styles';
import { findPolicyType, getDateStringFromKpi, getSummary } from '../../utils/index';
import AgencySummary from './AgencySummary';
import { KPI_FIELD_NAMES } from '../../constants';
import { useStoreState } from 'easy-peasy';
import ScorecardsTable from './ScorecardsTable';
import SelectedSummaryPanel from './SelectedSummaryPanel';
import { TableWrap } from '../../styles/tableStyle';
import PropTypes from 'prop-types';
import { useByCarrierList } from './queries';
import { TableForm, useFieldValue } from './TableForm';

const currentYear = new Date().getFullYear().toString();

export default function Scorecards({ agency, year }) {
    const [formValues, setFormValues] = useState(() => ({
        policyType: 'P&C',
        mode: currentYear === year ? 'trailing' : 'ytd',
        searchTerm: '',
        carrierType: 'all',
        standardTypes: [],
        expandedRows: {},
        selectedIds: []
    }));
    const [selectedItems, setSelectedItems] = useState({});
    const [allStandardTypes] = useStoreState((state) => [state.standardTypes]);

    function getSelectedSummary({ data, selectedItems, policyType }) {
        const selectedUuidType = 'carrierUuid';
        const selectedItemsByUuids = data.filter((item) => selectedItems[item[selectedUuidType]]);
        const selectedData = selectedItemsByUuids.reduce((acc, item) => {
            acc[item[selectedUuidType]] = { ...item };
            return acc;
        }, {});
        return getSummary(
            policyType,
            selectedData,
            'writtenPremiumYTD',
            'baseCommissionYTD',
            'additionalCommissionYTD',
            'totalCommissionYTD'
        );
    }

    function getLatestDate(items, type) {
        let string = 'Unknown';
        let month = 0;
        if (items) {
            Object.keys(items).map((id) => {
                const kpi = items[id][type];
                if (kpi) {
                    const monthString = kpi.month;
                    const currentMonth = parseInt(monthString, 10);
                    if (currentMonth > month) {
                        month = currentMonth;
                        string = getDateStringFromKpi(kpi);
                    }
                }
            });
        }
        return { latestDateString: string, latestMonth: month };
    }

    const carrierDataQuery = useByCarrierList(
        agency.uuid,
        { year },
        formValues.standardTypes,
        formValues.policyType
    );

    useEffect(() => {
        // Reset the form if the year or timeframe changes
        setFormValues((values) => ({
            ...values,
            policyType: 'P&C',
            mode: currentYear === year ? 'trailing' : 'ytd',
            searchTerm: '',
            carrierType: 'all',
            standardTypes: [],
            expandedRows: {}
        }));
    }, [year]);

    const { latestDateString } = getLatestDate(carrierDataQuery.data, formValues.policyType);

    const tableItems = useMemo(() => {
        const { data } = carrierDataQuery;
        if (!data) {
            return [];
        }
        const { policyType, carrierType, searchTerm, selectedIds } = formValues;
        return Object.keys(data)
            .map((uuid) => data[uuid])
            .filter((item) => {
                if (!item[policyType]) return false;
                return (
                    carrierType === 'all' ||
                    (item[policyType].isCoreCarrier && carrierType === 'core') ||
                    (!item[policyType].isCoreCarrier && carrierType === 'noncore')
                );
            })
            .filter((item) => {
                const itemValue = item[policyType].carrierName;
                return (
                    !searchTerm ||
                    (itemValue && itemValue.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
                );
            })
            .filter((item) => {
                const itemValue = item[policyType];
                return selectedIds.some((e) => e.id === itemValue.carrierUuid);
            })
            .map((item) => {
                return {
                    ...item,
                    ...item[policyType]
                };
            });
    }, [
        carrierDataQuery.data,
        formValues.carrierType,
        formValues.policyType,
        formValues.searchTerm,
        formValues.selectedIds
    ]);

    let currentPolicyType = findPolicyType(allStandardTypes, formValues.policyType);

    const selectedSummary = getSelectedSummary({
        data: tableItems,
        selectedItems,
        policyType: formValues.policyType
    });

    const [expandedRows, setExpandedRows] = useFieldValue(
        'expandedRows',
        formValues,
        setFormValues
    );

    return (
        <>
            <TableWrap>
                <AgencyCompensationWrap>
                    <div className="agency-scorecard">
                        <h3 className="text-center">
                            <span>{year}&nbsp;</span>
                            <span>Invoice Revenue for {agency ? agency.name : 'Agencies'}</span>
                        </h3>
                        <div className="scorecard-subtitle text-center">
                            <span>{`Latest Data: ${latestDateString}`}</span>
                        </div>
                        <AgencySummary
                            agencyUuid={agency.uuid}
                            timeframe={{ year }}
                            policyType={'P&C'}
                            premiumFields={KPI_FIELD_NAMES[formValues.mode]}
                        />
                    </div>
                    <SelectedSummaryPanel
                        selectedSummary={selectedSummary}
                        kpiFieldNames={KPI_FIELD_NAMES[formValues.mode]}
                    />
                    <div className="section">
                        <TableForm
                            values={formValues}
                            setValues={setFormValues}
                            showModeSelect={year === currentYear}
                            countRows={tableItems.length}
                            data={carrierDataQuery.data}
                        />
                        <ScorecardsTable
                            data={tableItems}
                            isLoading={carrierDataQuery.isLoading}
                            currentPolicyType={currentPolicyType}
                            selectedItems={selectedItems}
                            expandedRows={expandedRows}
                            setExpandedRows={setExpandedRows}
                            onSelect={(e) => {
                                setSelectedItems(e);
                            }}
                        />
                    </div>
                </AgencyCompensationWrap>
            </TableWrap>
        </>
    );
}

Scorecards.propTypes = {
    agency: PropTypes.shape({
        uuid: PropTypes.string,
        name: PropTypes.string
    }),
    year: PropTypes.number
};

export function AgencyScorecardsPage() {
    const [agency, year] = useStoreState((state) => [state.user.agency, state.year]);
    return <Scorecards agency={agency} year={year} />;
}

export function AgencyScorecardsYearSelector() {
    const [year] = useStoreState((state) => [state.year]);
    return <YearSelector year={year} />;
}
