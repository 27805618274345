import styled from '@emotion/styled';

export const PortalContainer = styled.div`
    width: 100%;
    height: 100%;
    //position: relative;
    display: flex;
    //flex-direction: column;
    flex: 1;
    overflow: auto;
`;

export const HeaderContainer = styled.div`
    position: absolute;
    background-color: white;
    padding: 10px 0px;
    width: 100%;
    height: 60px;
    display: flex;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: 999;
    .akpi-logo {
        img {
            width: 90%;
        }
        padding-left: 20px;
        flex-basis: 15%;
        flex-grow: 0;
        min-width: 220px;
        max-width: 275px;
    }
    .header__profile-menu-container {
        padding-right: 26px;
        .header__profile-icon {
            color: #545454;
            font-size: 1.4em;
            display: flex;
            padding: 10px;
            cursor: pointer;
        }
        .header__profile-icon:hover {
            opacity: 0.6;
        }
        .header__profile-dropdown {
            min-width: 200px;
            top: 50px;
            right: 22px;
            border: solid 1px #cecece;
            margin: 0;
            background: white;
            border-radius: 10px;
            max-height: 25rem;
            .loading {
                display: inline-block;
                img {
                    height: 22px;
                }
            }
            li {
                padding: 15px 25px;
            }
        }
    }
    .header__agency-name {
        font-size: 24px;
        color: #444;
        padding-left: 20px;
        justify-content: center;
        > span {
            margin-right: 15px;
        }
        .timeFrameBtnGroup {
            padding: 0 0 0 15px;
            margin: 14px 0 0 0;
            div {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
    .header-logo {
        padding-right: 20px;
    }
    .upload-notification {
        position: absolute;
        right: 20px;
        top: 20px;
        .bellIcon {
            cursor: pointer;
            font-size: 30px;
            color: rgb(96, 111, 123);
        }
        .fa-layers-counter {
            height: 1.5em;
            width: 1.5em;
            transform: scale(0.4);
        }
    }
`;

export const PortalContent = styled.div`
    position: relative;
    margin-top: 60px;
    flex-basis: 100%;
    padding: 20px;
    overflow: auto;
`;

export const DropdownSection = styled.div`
    margin: 10px 0;
    border-top: ${(props) =>
        props.border !== 'none' && props.border === 'top' ? '2px solid #f0f0f0' : ''};
    border-bottom: ${(props) =>
        props.border !== 'none' && props.border === 'bottom' ? '2px solid #f0f0f0' : ''};
    .disabled {
        opacity: 0.6;
        cursor: no-drop;
    }
`;

export const DropdownText = styled.a`
    vertical-align: super;
`;

export const modalStyles = {
    overlay: {
        zIndex: 999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        top: null,
        bottom: null,
        left: null,
        right: null,
        borderRadius: '8px',
        boxShadow: '3px 3px 10px -1px rgba(0,0,0,0.3)'
    }
};

export const CollaborationModeText = styled.span`
    color: #939698;
    font-size: 12px;
    margin-right: 8px;
    margin-left: -188px;
`;
