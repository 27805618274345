import * as Yup from 'yup';

export const validationSchemaCarrierSearch = {
    carrier: Yup.object({ name: Yup.string().min(1).required('Required') }),
    subcode: Yup.object({ name: Yup.string().min(1).required('Required') })
};

export const validationSchemaRemainder = {
    policyType: Yup.string().required('Required'),
    writtenPremium: Yup.string().matches(
        /^-?\$?\d+(,\d{3})*(\.[0-9]{2})?$/,
        'Not a valid dollar amount'
    ),
    newBusiness: Yup.string().matches(
        /^-?\$?\d+(,\d{3})*(\.[0-9]{2})?$/,
        'Not a valid dollar amount'
    ),
    mover: Yup.string().matches(/^-?\$?\d+(,\d{3})*(\.[0-9]{2})?$/, 'Not a valid dollar amount'),
    month: Yup.number().required('Required'),
    year: Yup.number().required('Required')
};

export const shapeRadio = {
    radio: Yup.string().required('Required'),
    incurredLoss: Yup.string().when('radio', {
        is: 'incurredLossEarnedPremium',
        then: Yup.string().matches(/^-?\$?\d+(,\d{3})*(\.[0-9]{2})?$/, 'Not a valid dollar amount'),
        otherwise: Yup.string()
    }),
    earnedPremium: Yup.string().when('radio', {
        is: 'incurredLossEarnedPremium',
        then: Yup.string().matches(/^-?\$?\d+(,\d{3})*(\.[0-9]{2})?$/, 'Not a valid dollar amount'),
        otherwise: Yup.string()
    }),
    lossRatio: Yup.string().when('radio', {
        is: 'lossRatio',
        //then: Yup.string().matches(/^[+-]?[.]?(\d{1,4})?(\.\d{1,4})? *%?$/, 'Not a valid percent'),
        then: Yup.string().matches(
            /^[+-]?[.]?\d+(,\d{3})?(\.\d{1,5})? *%?$/,
            'Not a valid percent'
        ),
        otherwiese: Yup.string()
    })
};
