import { toCurrency, toPercent } from '../../utils/index';
import { TOOL_TIPS } from '../../constants/tooltips';
import { processTotalData } from './utils';
import { Tooltip } from '@mui/material';
import {
    SummaryCard,
    CurrencyAndPercentCell,
    CurrencyCell,
    PercentCell
} from './shared/SummaryTables';
import * as formulas from './shared/formulas';
import PropTypes from 'prop-types';
import { useNetworkSummary } from './hooks';

const translations = {
    CL: 'Commercial',
    PL: 'Personal',
    'P&C': 'Total',
    BOND: 'Bond',
    CROP: 'Crop'
};

export const t = (key) => {
    return translations[key] || key;
};

const POLICY_TYPE_ORDER = {
    PL: 1,
    CL: 2,
    BOND: 3,
    'P&C': 4
};

const policyTypes = {
    WP: 'writtenPremium',
    NWP: 'newWrittenPremium',
    PNWP: 'priorNewWrittenPremium',
    PWP: 'priorWrittenPremium',
    EP: 'earned',
    IL: 'incurred',
    PEP: 'priorEarned',
    PIL: 'priorIncurred'
};

export default function CoreProductionSummary({
    networkCPRs,
    getSelectedKey,
    getSelectedTitle,
    timeframe,
    haveMissingCurrentData,
    haveMissingPriorData
}) {
    const { WP, NWP, PWP, PNWP, EP, IL } = policyTypes;
    const existingCprs = networkCPRs
        .filter((cpr) => {
            return (
                cpr.policyTypeCode == 'P&C' ||
                (cpr.policyTypeCode != 'P&C' &&
                    (cpr.earned !== 0 ||
                        cpr.incurred !== 0 ||
                        cpr.newWrittenPremium !== 0 ||
                        cpr.writtenPremium !== 0 ||
                        cpr.priorEarned !== 0 ||
                        cpr.priorIncurred !== 0 ||
                        cpr.priorNewWrittenPremium !== 0 ||
                        cpr.priorWrittenPremium !== 0))
            );
        })
        .sort((a, b) => {
            // default unknown codes to the top
            return (POLICY_TYPE_ORDER[a.policyType] || -1) - (POLICY_TYPE_ORDER[b.policyType] || 1);
        });

    const getToolTipPercentage = (amount) => {
        return amount === 0 ? 'N/A' : `Network ${toPercent(amount, 2)}`;
    };

    const { data: networkSummary, isLoading: isFetchingNetwork } = useNetworkSummary(timeframe);
    const { writtenPremium, newWrittenPremium, lossRatio } = networkSummary ?? {};

    const totalsData = processTotalData(existingCprs);

    return (
        <div className="planning-tool-cards variant">
            <SummaryCard
                title={getSelectedTitle('writtenPremium')}
                titleTooltip={TOOL_TIPS.writtenPremium}
                timeframe={timeframe}
                className="written-premium"
            >
                <table>
                    <thead>
                        <tr>
                            <th className="policy-type-header"></th>
                            <th>Current</th>
                            <th>Prior</th>
                            <Tooltip title={`${TOOL_TIPS.change}`}>
                                <th>Change</th>
                            </Tooltip>
                        </tr>
                    </thead>
                    <tbody>
                        {totalsData.map((row) => {
                            return (
                                <tr key={`wpytd-${row.policyType}`}>
                                    <th>{t(row.policyType)}</th>
                                    <CurrencyCell
                                        value={row[getSelectedKey('writtenPremium')]}
                                        isIncomplete={haveMissingCurrentData}
                                        networkValueTooltip={
                                            isFetchingNetwork
                                                ? 'Please Wait...'
                                                : getToolTipNumber(
                                                      writtenPremium[row.policyType]
                                                          ? writtenPremium[row.policyType].current
                                                          : 0,
                                                      row[getSelectedKey(WP)]
                                                  )
                                        }
                                    />
                                    <CurrencyCell
                                        value={row[getSelectedKey('priorWrittenPremium')]}
                                        isIncomplete={haveMissingPriorData}
                                        networkValueTooltip={
                                            isFetchingNetwork
                                                ? 'Please Wait...'
                                                : getToolTipNumber(
                                                      writtenPremium[row.policyType]
                                                          ? writtenPremium[row.policyType].prior
                                                          : 0,
                                                      row[getSelectedKey(PWP)]
                                                  )
                                        }
                                    />
                                    <CurrencyAndPercentCell
                                        currencyValue={
                                            row[getSelectedKey('writtenPremium')] -
                                            row[getSelectedKey('priorWrittenPremium')]
                                        }
                                        percentValue={formulas.calculateChangeRateAsPercent(
                                            row[getSelectedKey('writtenPremium')],
                                            row[getSelectedKey('priorWrittenPremium')]
                                        )}
                                        isIncomplete={
                                            haveMissingPriorData || haveMissingCurrentData
                                        }
                                        networkValueTooltip={
                                            isFetchingNetwork
                                                ? 'Please Wait...'
                                                : getToolTipNumber(
                                                      writtenPremium[row.policyType]
                                                          ? writtenPremium[row.policyType].prior
                                                          : 0,
                                                      writtenPremium[row.policyType]
                                                          ? writtenPremium[row.policyType].current -
                                                                writtenPremium[row.policyType].prior
                                                          : 0,
                                                      true
                                                  )
                                        }
                                    />
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </SummaryCard>
            <SummaryCard
                title={getSelectedTitle('newWrittenPremium')}
                titleTooltip={TOOL_TIPS.newBusiness}
                timeframe={timeframe}
                className="new-business"
            >
                <table>
                    <thead>
                        <tr>
                            <th className="policy-type-header"></th>
                            <th>Current</th>
                            <th>Prior</th>
                            <Tooltip title={`${TOOL_TIPS.change}`}>
                                <th>Change</th>
                            </Tooltip>
                            <Tooltip title={`${TOOL_TIPS.percentOfBook}`}>
                                <th>% of Book</th>
                            </Tooltip>
                        </tr>
                    </thead>
                    <tbody>
                        {totalsData.map((row) => {
                            return (
                                <tr key={`nb-${row.policyType}`}>
                                    <th>{t(row.policyType)}</th>
                                    <CurrencyCell
                                        value={row[getSelectedKey('newWrittenPremium')]}
                                        isIncomplete={haveMissingCurrentData}
                                        networkValueTooltip={
                                            isFetchingNetwork
                                                ? 'Please Wait...'
                                                : getToolTipNumber(
                                                      newWrittenPremium[row.policyType]
                                                          ? newWrittenPremium[row.policyType]
                                                                .current
                                                          : 0,
                                                      row[getSelectedKey(NWP)]
                                                  )
                                        }
                                    />
                                    <CurrencyCell
                                        value={row[getSelectedKey('priorNewWrittenPremium')]}
                                        isIncomplete={haveMissingPriorData}
                                        networkValueTooltip={
                                            isFetchingNetwork
                                                ? 'Please Wait...'
                                                : getToolTipNumber(
                                                      newWrittenPremium[row.policyType]
                                                          ? newWrittenPremium[row.policyType].prior
                                                          : 0,
                                                      row[getSelectedKey(PNWP)]
                                                  )
                                        }
                                    />
                                    <CurrencyAndPercentCell
                                        currencyValue={
                                            row[getSelectedKey('newWrittenPremium')] -
                                            row[getSelectedKey('priorNewWrittenPremium')]
                                        }
                                        percentValue={formulas.calculateChangeRateAsPercent(
                                            row[getSelectedKey('newWrittenPremium')],
                                            row[getSelectedKey('priorNewWrittenPremium')]
                                        )}
                                        isIncomplete={
                                            haveMissingPriorData || haveMissingCurrentData
                                        }
                                        networkValueTooltip={
                                            isFetchingNetwork
                                                ? 'Please Wait...'
                                                : getToolTipNumber(
                                                      newWrittenPremium[row.policyType]
                                                          ? newWrittenPremium[row.policyType].prior
                                                          : 0,
                                                      newWrittenPremium[row.policyType]
                                                          ? newWrittenPremium[row.policyType]
                                                                .current -
                                                                newWrittenPremium[row.policyType]
                                                                    .prior
                                                          : 0,
                                                      true
                                                  )
                                        }
                                    />
                                    <PercentCell
                                        value={
                                            (row[getSelectedKey('newWrittenPremium')] * 100) /
                                            row[getSelectedKey('writtenPremium')]
                                        }
                                        isIncomplete={false}
                                        networkValueTooltip={
                                            isFetchingNetwork
                                                ? 'Please Wait...'
                                                : getToolTipPercentage(
                                                      newWrittenPremium[row.policyType]
                                                          ? (newWrittenPremium[row.policyType]
                                                                .current *
                                                                100) /
                                                                writtenPremium[row.policyType]
                                                                    .current
                                                          : 0
                                                  )
                                        }
                                    />
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </SummaryCard>
            <SummaryCard
                title={getSelectedTitle('lossRatio')}
                titleTooltip={TOOL_TIPS.lossRatio}
                timeframe={timeframe}
                className="loss-ratio"
            >
                <table>
                    <thead>
                        <tr>
                            <th className="policy-type-header"></th>
                            <Tooltip title={`${TOOL_TIPS.ep}`}>
                                <th>EP</th>
                            </Tooltip>
                            <Tooltip title={`${TOOL_TIPS.il}`}>
                                <th>IL</th>
                            </Tooltip>
                            <th>Current</th>
                            <th>Prior</th>
                        </tr>
                    </thead>
                    <tbody>
                        {totalsData.map((row) => {
                            return (
                                <tr key={`lr-${row.policyType}`}>
                                    <th>{t(row.policyType)}</th>
                                    <CurrencyCell
                                        value={row[getSelectedKey('earned')]}
                                        isIncomplete={false}
                                        networkValueTooltip={
                                            isFetchingNetwork
                                                ? 'Please Wait...'
                                                : getToolTipNumber(
                                                      lossRatio['earned'][row.policyType]
                                                          ? lossRatio['earned'][row.policyType]
                                                                .current
                                                          : 0,
                                                      row[getSelectedKey(EP)]
                                                  )
                                        }
                                    />
                                    <CurrencyCell
                                        value={row[getSelectedKey('incurred')]}
                                        isIncomplete={false}
                                        networkValueTooltip={
                                            isFetchingNetwork
                                                ? 'Please Wait...'
                                                : getToolTipNumber(
                                                      lossRatio['incurred'][row.policyType]
                                                          ? lossRatio['incurred'][row.policyType]
                                                                .current
                                                          : 0,
                                                      row[getSelectedKey(IL)]
                                                  )
                                        }
                                    />
                                    <PercentCell
                                        value={formulas.calculateLossRatio(
                                            row[getSelectedKey('earned')],
                                            row[getSelectedKey('incurred')]
                                        )}
                                        isIncomplete={haveMissingCurrentData}
                                        coloured={true}
                                        colorThreasholds={(value) => ({
                                            negative: value > 55,
                                            positive: value < 45
                                        })}
                                        networkValueTooltip={
                                            isFetchingNetwork
                                                ? 'Please Wait...'
                                                : getToolTipPercentage(
                                                      formulas.calculateLossRatio(
                                                          lossRatio['earned'][row.policyType]
                                                              ?.current,
                                                          lossRatio['incurred'][row.policyType]
                                                              ?.current
                                                      )
                                                  )
                                        }
                                    />
                                    <PercentCell
                                        value={formulas.calculateLossRatio(
                                            row[getSelectedKey('priorEarned')],
                                            row[getSelectedKey('priorIncurred')]
                                        )}
                                        isIncomplete={haveMissingPriorData}
                                        coloured={true}
                                        colorThreasholds={(value) => ({
                                            negative: value > 55,
                                            positive: value < 45
                                        })}
                                        networkValueTooltip={
                                            isFetchingNetwork
                                                ? 'Please Wait...'
                                                : getToolTipPercentage(
                                                      formulas.calculateLossRatio(
                                                          lossRatio['earned'][row.policyType]
                                                              ?.prior,
                                                          lossRatio['incurred'][row.policyType]
                                                              ?.prior
                                                      )
                                                  )
                                        }
                                    />
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </SummaryCard>
        </div>
    );
}
CoreProductionSummary.propTypes = {
    networkCPRs: PropTypes.array,
    getSelectedKey: PropTypes.func,
    getSelectedTitle: PropTypes.func,
    timeframe: PropTypes.shape({
        displayText: PropTypes.string
    }),
    haveMissingCurrentData: PropTypes.bool,
    haveMissingPriorData: PropTypes.bool
};

function getToolTipNumber(netWorkTotal, amount, isChange = false) {
    if (netWorkTotal === 0) {
        return 'N/A';
    }

    const percentOfNetwork = (100 * amount) / netWorkTotal;
    if (isChange) {
        return `Network ${toPercent(percentOfNetwork)}`;
    }
    return `${toPercent(percentOfNetwork)} of Network Total ${toCurrency(netWorkTotal)}`;
}
