import { Fragment } from 'react';
import {
    SplashContainer,
    SplashImage,
    SplashImageContainer,
    SplashTextContainer,
    ClickHere
} from './styles';
import UnderstandingLogo from '../../../assets/understanding_large.png';

const SplashScreen = ({ agency }) => {
    return (
        <SplashContainer>
            <SplashImageContainer>
                <Fragment>
                    <SplashImage src={UnderstandingLogo} alt="splash" data-testid="splash" />
                    <SplashTextContainer>
                        <p>
                            Sorry, you are no longer subscribed to Understanding. If you feel you
                            have received this message in error please contact{' '}
                            <ClickHere href="mailto:help@agencykpi.com">
                                help@agencykpi.com
                            </ClickHere>
                            . To renew your subscription please contact us{' '}
                            <ClickHere href="https://www.agencykpi.com/contact-us" target="_blank">
                                here
                            </ClickHere>{' '}
                            to speak with our team about gaining access.
                        </p>
                        <p>
                            Please click <ClickHere href="/logout">here</ClickHere> to securely log
                            out.
                        </p>
                    </SplashTextContainer>
                </Fragment>
            </SplashImageContainer>
        </SplashContainer>
    );
};

export default SplashScreen;
