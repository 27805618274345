import { useEffect, useState } from 'react';
import GrowthPlanMeasurementList from './GrowthMeasurementList';
import NetworkGrowthPlanMeasurementTotal from './NetworkGrowthPlanMeasurementTotal';
import { TableContainer, TableFilterForm } from './styles';
import { findPolicyType } from '../../utils/index';
import { useStoreActions, useStoreState } from 'easy-peasy';
import PolicyTypeSelector from '../PolicyTypeSelector/PolicyTypeSelector';
import { Input } from '@agencykpi/components';
import YearSelector from '../YearSelector';
import { useGrowthMeasurementPlan } from './hooks';
import PropTypes from 'prop-types';
import Loader from '../shared/Loader';
import { TableWrap } from '../../styles/tableStyle';
import { useQuery } from '@tanstack/react-query';
import { activePlanYearsQuery } from '../../utils/apis';
import { ExpandAllButton } from '../shared/ExpandAllButton';

function GrowthMeasurement({ agency }) {
    const year = useStoreState((state) => state.year);
    const activePlanYears = useQuery(activePlanYearsQuery());
    const selectedPlanId = activePlanYears.data?.activePlans[year];

    return <GrowthPlanMeasurementActivePlan agency={agency} selectedPlanId={selectedPlanId} />;
}
GrowthMeasurement.propTypes = {
    agency: PropTypes.shape({
        name: PropTypes.string,
        uuid: PropTypes.string
    })
};

export function GrowthPlanMeasurementYearSelector() {
    const selectedYear = useStoreState((state) => state.year);
    const setSelectedYear = useStoreActions((actions) => actions.setYear);
    const { data: activePlanYears } = useQuery(activePlanYearsQuery());
    useEffect(() => {
        if (activePlanYears) {
            if (!activePlanYears.activePlans[selectedYear]) {
                // Find the most recent year that does have an active plan
                const otherYear = activePlanYears.yearOptions.reduce(
                    (a, b) => Math.max(a, b.value),
                    0
                );
                if (otherYear !== 0) {
                    setSelectedYear(otherYear);
                }
            }
        }
    }, [selectedYear, activePlanYears]);
    return activePlanYears ? (
        <YearSelector validYears={activePlanYears.yearOptions} onSelect={setSelectedYear} />
    ) : null;
}

function GrowthPlanMeasurementActivePlan({ agency, selectedPlanId }) {
    const [allStandardTypes] = useStoreState((state) => [state.standardTypes]);

    const { plan, isLoading, activePlan, carrierCPRs, networkCPRs } = useGrowthMeasurementPlan(
        selectedPlanId,
        agency
    );

    const pcPolicyType = findPolicyType(allStandardTypes, 'P&C');

    if (!activePlan) {
        return <Loader />;
    }

    return (
        <TableContainer key={selectedPlanId}>
            {activePlan && (
                <span
                    css={{
                        display: 'flex',
                        marginBottom: 20,
                        color: '#444',
                        fontSize: 24
                    }}
                >
                    {activePlan.name}
                </span>
            )}
            <TableWrap>
                <NetworkGrowthPlanMeasurementTotal
                    data={networkCPRs}
                    isLoading={isLoading}
                    policyType={pcPolicyType}
                />
                <GrowthPlanMeasurementCarrierList
                    plan={plan}
                    isLoading={isLoading}
                    carrierCPRs={carrierCPRs}
                />
            </TableWrap>
        </TableContainer>
    );
}

GrowthPlanMeasurementActivePlan.propTypes = {
    agency: PropTypes.shape({
        name: PropTypes.string,
        uuid: PropTypes.string
    }),
    selectedPlanId: PropTypes.string
};

function GrowthPlanMeasurementCarrierList({ carrierCPRs, isLoading, plan }) {
    const [allStandardTypes] = useStoreState((state) => [state.standardTypes]);
    const [resetSelected, setResetSelected] = useState(false);
    const [selectedItems, setSelectedItems] = useState({});
    const [policyType, setPolicyType] = useState('P&C');
    const [searchTerm, setSearchTerm] = useState('');
    const [standardTypes, setStandardTypes] = useState([]);

    let currentPolicyType = findPolicyType(allStandardTypes, policyType);

    const [allExpanded, setAllExpanded] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    const handleClickExpandAll = () => {
        let aux = !allExpanded;
        setAllExpanded(aux);
        if (aux) {
            let auxExpandedRows = carrierCPRs.map(() => {
                return true;
            });
            setExpandedRows(auxExpandedRows);
        } else {
            setExpandedRows([]);
        }
    };

    return (
        <>
            <TableFilterForm>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Input
                        style={{ width: 250, marginRight: '20px', marginTop: '20px' }}
                        id="list-search"
                        placeholder="Search by name"
                        value={searchTerm}
                        disabled={isLoading}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <PolicyTypeSelector
                        label="Line of Business"
                        type={policyType}
                        disabled={isLoading}
                        onChange={(e) => {
                            setPolicyType(e.target.value);
                            if (standardTypes.length > 0) setStandardTypes([]);
                        }}
                    />
                </div>
                <ExpandAllButton onToggle={handleClickExpandAll} expanded={allExpanded} />
            </TableFilterForm>
            <GrowthPlanMeasurementList
                expandedRows={expandedRows}
                setExpandedRows={setExpandedRows}
                data={carrierCPRs}
                isRefetching={isLoading}
                policyType={currentPolicyType}
                onSelect={(e) => {
                    setSelectedItems(e);
                }}
                selectedItems={selectedItems}
                plan={plan}
                searchTerm={searchTerm}
                resetSelected={resetSelected}
                setResetSelected={setResetSelected}
            />
        </>
    );
}
GrowthPlanMeasurementCarrierList.propTypes = {
    carrierCPRs: PropTypes.array,
    isLoading: PropTypes.bool,
    plan: PropTypes.object
};

export default GrowthMeasurement;
