import { Button } from '@agencykpi/components';
import styled from '@emotion/styled';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import ExpandIcon from '@mui/icons-material/Expand';
import PropTypes from 'prop-types';
import theme from '../../theme';

const StyledButton = styled(Button)`
    border: 1px solid ${theme.colors.borderColor};
    height: 36px;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    border-radius: 8px;
    display: flex;
    padding: 0 8px;
    font-family: Roboto, sans-serif;
    font-weight: bold;
`;

export function ExpandAllButton({ expanded, onToggle, className }) {
    return (
        <StyledButton hint="Expand" onClick={onToggle} className={className}>
            {expanded ? <UnfoldLessIcon /> : <ExpandIcon />}
            <span>{expanded ? 'Collapse all' : 'Expand all'}</span>
        </StyledButton>
    );
}
ExpandAllButton.propTypes = {
    expanded: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    className: PropTypes.string
};
