import { useStoreState } from 'easy-peasy';
import moment from 'moment';
import { StyledMonthsContainer, StyledMonth } from './styles';

const MONTHS_ABRV = [
    'Jan',
    'Feb',
    'Mar',
    'April',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec'
];

const Months = (props) => {
    const policies = props.policies;
    let monthStatuses = {};
    const today = new moment();
    const currentMonth = today.month();
    const currentYear = today.year();
    const selectedYear = useStoreState((initialState) => initialState.year);
    const months = Array.apply(null, Array(selectedYear < currentYear ? 12 : currentMonth));

    months.map((key, month) => {
        monthStatuses[MONTHS_ABRV[month]] = false;
        return Object.entries(policies).map((policyType) => {
            policyType[1].map((entry) => {
                if (month + 1 === entry.month) {
                    monthStatuses[MONTHS_ABRV[month]] = true;
                }
            });
        });
    });

    return (
        <StyledMonthsContainer>
            {Object.entries(monthStatuses).map((key, value) => {
                return (
                    <StyledMonth className={key[1] === true ? 'numberCircle' : ''}>
                        {key}
                    </StyledMonth>
                );
            })}
        </StyledMonthsContainer>
    );
};

export default Months;
