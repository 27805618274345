import styled from '@emotion/styled';

export const StyledPolicyCell = styled.div`
    text-align: center;
`;

export const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    & .newCprButton {
        margin-top: 0;
    }
    & svg.backArrowActive {
        transition: color 0.2s ease;
        cursor: pointer;
    }
    & svg.backArrowActive:hover {
        color: #444;
    }
`;

export const StyledCurrentCell = styled.div`
    text-align: center;
`;

export const StyledDollarCell = styled.div`
    text-align: right;
    margin-right: 10%;
    font-family: Roboto;
`;

export const TableContainer = styled.div`
    background-color: white;
    padding: 20px 0 0 0;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
`;

export const StyledEntryContainer = styled.div`
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 800px;
`;

export const StyledEntryTableHeader = styled.div`
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 12px;
    ${(props) => !props.noBorder && `border-bottom: 1px solid #ccc;`}
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

export const StyledCarrierEntry = styled.div`
    border-bottom: 1px solid #eee;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 3%;
    :not(.empty) {
        cursor: pointer;
    }
    :not(.empty):hover {
        background: #eee;
    }
    :last-child {
        border-bottom: none;
    }
`;

export const StyledCarrierEntryContainer = styled.div`
    border-bottom: 1px solid #eee;
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    :not(.empty) {
        cursor: pointer;
    }
    :not(.empty):hover {
        background: #eee;
    }
    :last-child {
        border-bottom: none;
    }
`;

export const StyledCompanyNameColumn = styled.div`
    width: 260px;
`;

export const StyledCompanyNameTableHeader = styled.div`
    width: 240px;
    margin-left: 5px;
`;

export const StyledCurrencyColumn = styled.div`
    width: 100px;
    text-align: right;
    font-family: Roboto;
`;

export const StyledPolicyStatusColumn = styled.div`
    width: 100px;
    text-align: right;
    font-family: Roboto;
`;

export const StyledPolicyColumn = styled.div`
    width: 100px;
    text-align: center;
`;

export const StyledDeleteIconColumn = styled.div`
    width: 100px;
    text-align: center;
    cursor: auto;
    height: 90px;
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const StyledLobColumn = styled.div`
    width: 30px;
    margin-left: 100px;
`;

export const StyledCompanyNameLabel = styled.div`
    margin: 0 0 0;
    text-align: center;
    font-size: xx-large;
`;

export const StyledSelect = styled.div`
    height: 75px;
    display: flex;
    justify-content: center;
`;

export const StyledPolicyEntry = styled.div``;

export const StyledLobEntry = styled.div`
    //margin-bottom: 20px;
    border-top: 1px solid #eee;
    padding: 25px 0;
    font-size: 14px;
    .lob-entry-header {
        border-bottom: none;
    }
`;

export const StyledPolicyRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 10px;
    font-size: large;
`;

export const StyledPolicyName = styled.div`
    width: 200px;
    font-size: large;
`;

export const StyledPolicyTable = styled.div`
    cursor: pointer;
    padding: 25px 60px;
    //background-color: #f9f9f9;
    .ReactTable .rt-tbody .rt-td {
        margin: auto;
    }
    //box-shadow: inset 0px 3px 10px -3px rgba(0, 0, 0, 0.2);
    .ReactTable {
        background-color: white;
        &.-highlight .rt-tr.-highlight--hovered:not(.-padRow) .rt-td {
            background: #f2f2f2;
            transition: background-color 0.2s ease;
        }
    }
`;

export const DeleteIcon = styled.div`
    cursor: pointer;
    width: fit-content;
    margin: auto;
    padding: 5px;
`;
