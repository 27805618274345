import { useState, useEffect } from 'react';
import { Button } from '@agencykpi/components';
import { Grid, Typography } from '@mui/material';
import * as styles from './styles';
import { request } from '../../utils/request';
import Loader from '../shared/Loader';
import ModifyPrimaryUserDialog from './ModifyPrimaryUserDialog';
import { Formik, Form } from 'formik';
import { MyTextInput } from '../shared/form/formInputs';
import { useStoreActions } from 'easy-peasy';
import PropTypes from 'prop-types';

const MySubscription = ({ currentUser, agencyUuid }) => {
    const [adminUser, setAdminUser] = useState({});
    const [plan, setPlan] = useState('');
    const [stripeUrl, setStripeUrl] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [primaryUserChange, setPrimaryUserChange] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [setUser] = useStoreActions((actions) => [actions.setUser]);

    const getPrimaryUserData = async () => {
        try {
            const { body } = await request
                .get(`/api/subscription/subscribers`)
                .query({ subscriberUuid: agencyUuid });
            setAdminUser(body.adminUser);
        } catch (err) {
            console.log(err);
        }
    };

    const getSubscriptionData = async () => {
        const currentUrl = window.location.href;
        try {
            const { body } = await request.post(`/api/subscription/by-subscriber`).send({
                successUrl: currentUrl,
                cancelUrl: currentUrl
            });
            const resultData = body[0].prices[0];
            const planText = resultData.product
                ? resultData.product.name
                : resultData.metadata.name;
            setPlan(planText);
            setStripeUrl(resultData.customerPortal.url);
        } catch (err) {
            console.log(err);
        }
    };

    const getUserData = async () => {
        setFetching(true);
        await getSubscriptionData();
        await getPrimaryUserData();
        setFetching(false);
    };

    const [saving, setSaving] = useState(false);
    const onSubmit = async (data) => {
        setSaving(true);
        const displayName = data.displayName;
        if (displayName && displayName.trim().length) {
            try {
                const {
                    body: { name }
                } = await request.put(`/api/subscription/agency-name`).send({ displayName });

                if (name) updateAgencyName(name);
            } catch (err) {
                console.log(err);
            } finally {
                setSaving(false);
            }
        }
    };

    const updateAgencyName = (name) => {
        currentUser.agency.name = name;
        setUser(currentUser);
    };

    useEffect(() => {
        getUserData();
    }, []);

    const [isAdmin, setIsAdmin] = useState(false);
    useEffect(() => {
        setIsAdmin(currentUser.isPrimaryUserOfSubscriber);
    }, [currentUser]);

    useEffect(() => {
        if (primaryUserChange) {
            getUserData();
            setPrimaryUserChange(false);
        }
    }, [primaryUserChange]);

    return (
        <>
            <Typography variant="h5" css={styles.title}>
                Subscription Details
            </Typography>

            {isAdmin && (
                <Formik
                    initialValues={{ displayName: currentUser.agency.name }}
                    onSubmit={onSubmit}
                >
                    {(props) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <MyTextInput
                                        label="Agency Display Name"
                                        name="displayName"
                                        type="alpha"
                                        isRequired={true}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <Button
                                        primary
                                        type="submit"
                                        disabled={!props.dirty || saving}
                                        css={styles.button}
                                    >
                                        Update
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            )}

            {fetching ? (
                <Loader />
            ) : (
                    <Grid container spacing={2}>
                        {!(currentUser.bypassExternalSubscriber === true) ?

                            <><Grid item xs={2}>
                                <Typography>Plan: </Typography>
                            </Grid>
                                <Grid item xs={5}>
                                    <Typography>{plan}</Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    {isAdmin && (
                                        <Button
                                            css={styles.buttonLink}
                                            onClick={() => (window.location.href = stripeUrl)}
                                        >
                                            Manage
                                        </Button>
                                    )}
                                </Grid>
                            </> : <></>}


                    <Grid item xs={2}>
                        <Typography>Primary User: </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography>{`${adminUser.firstName} ${adminUser.lastName} (${adminUser.emailAddress})`}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        {isAdmin && (
                            <Button css={styles.buttonLink} onClick={() => setShowModal(true)}>
                                Change
                            </Button>
                        )}
                    </Grid>
                </Grid>
            )}

            {showModal && (
                <ModifyPrimaryUserDialog
                    setPrimaryUserChange={setPrimaryUserChange}
                    showModal={showModal}
                    setShowModal={setShowModal}
                    adminUuid={adminUser.uuid}
                    agencyUuid={agencyUuid}
                />
            )}
        </>
    );
};

MySubscription.propTypes = {
    currentUser: PropTypes.shape({
        agency: PropTypes.shape({ name: PropTypes.string }),
        isPrimaryUserOfSubscriber: PropTypes.bool,
        bypassExternalSubscriber: PropTypes.bool
    }),
    agencyUuid: PropTypes.string
};

export default MySubscription;
