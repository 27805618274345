import { request } from '../utils/request';

export const getUser = () => request.get('/api/user');

export const getOnboardedCarriers = () => request.get('/api/carriers/onboarded');

export const getStandardTypes = () => request.get('/api/mapping/standard-types');

export function getSubscriberUsers(subscriberUuid) {
    return request
        .get(`/api/subscription/by-subscribers`)
        .query({ subscriberUuid })
        .then(({ body: { users } }) => users);
}
