import { useEffect, useState } from 'react';
import Portal from '../Portal';
import SubscriptionCard from './subscriptionCard';
import { Container, Title, SubscriptionList, LoaderContainer } from './styles';
import * as utils from './utils';
import Loader from 'react-loader-spinner';

const SubscriptionSelector = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [isLoading, setIsLoading] = useState([]);

    useEffect(() => {
        utils.getProducts(setSubscriptions, setIsLoading);
    }, []);

    return (
        <Portal isPublicPage={true}>
            {isLoading && (
                <LoaderContainer>
                    <Loader type="Oval" color="#d0cfce" />
                </LoaderContainer>
            )}
            <Container>
                <Title>Choose a subscription</Title>
                <SubscriptionList>
                    {subscriptions.map((product) => {
                        return product.prices.map((price) => {
                            return (
                                <SubscriptionCard
                                    name={product.name}
                                    amount={price.amount}
                                    description={product.description}
                                    imageUrls={product.imageUrls}
                                    interval={price.billedEvery}
                                    metadata={
                                        price.metadata.length > 0
                                            ? price.metadata
                                            : product.metadata
                                    }
                                    priceId={price.id}
                                    setIsLoading={setIsLoading}
                                />
                            );
                        });
                    })}
                </SubscriptionList>
            </Container>
        </Portal>
    );
};

export default SubscriptionSelector;
