import { useState, useEffect, useRef } from 'react';

// Hook
export function useDebounce(value, delay) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
}

// copied from stackoverflow
export function useTraceUpdate(props) {
    const prev = useRef(props);
    useEffect(() => {
        const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
            if (prev.current[k] !== v) {
                ps[k] = [prev.current[k], v];
            }
            return ps;
        }, {});
        if (Object.keys(changedProps).length > 0) {
            console.log('Changed props:', changedProps);
        }
        prev.current = props;
    });
}

/**
 * A hook that resets a flag when the error changes
 * @param {unknown} error
 * @return {[boolean, function(): void]}
 */
export function useDismissableError(error) {
    const [isDismissed, setIsDismissed] = useState(false);
    const dismiss = useCallback(() => {
        setIsDismissed(true);
    }, []);
    useEffect(() => {
        setIsDismissed(false);
    }, [error]);
    return [isDismissed, dismiss];
}
