import { action } from 'easy-peasy';

export const actions = {
    setError: action((state, error) => {
        state.error = error;
    }),
    setFetching: action((state, fetching) => {
        state.fetching = fetching;
    }),
    setAgency: action((state, agency) => {
        state.agency = agency;
    }),
    setNetwork: action((state, network) => {
        state.network = network;
    }),
    setIsModalOpen: action((state, isModalOpen) => {
        state.isModalOpen = isModalOpen;
    }),
    setModalText: action((state, modalText) => {
        state.modalText = modalText;
    }),
    setEmailAddress: action((state, emailAddress) => {
        state.emailAddress = emailAddress;
    }),
    setToken: action((state, token) => {
        state.token = token;
    })
};
