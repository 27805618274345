import styled from '@emotion/styled';
import { PageWithTableWrap } from '../shared';
import theme from '../../theme';

import '../../../node_modules/react-table/react-table.css';

export const UploadContainer = styled.div`
    // height: 100%;
    // width: 100%;
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // padding: 25px;
`;

export const TableDataApplyButtonWrap = styled.div`
    display: flex;
    gap: 10px;

    .table__ApplyButton {
        margin: 0;
        height: 38px;
        width: 90px;
        &.selected {
            opacity: 1;
            background-color: rgb(44, 82, 130);
            //border: 1px solid rgb(43, 108, 176);
            color: rgb(255, 255, 255);
        }
        :disabled {
            cursor: not-allowed;
        }
    }
`;
export const SelectedSummaryContainer = styled.div`
    height: 70px;
    .visible {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.5s linear;
    }
    .hidden {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0.2s, opacity 0.2s linear;
    }
    .section {
        animation: fadein 1s;
        background-color: white;
        border-top: 1px solid #ddd;
        padding: 10px 40px;
        margin: 14px 0 0 0;

        .scorecard-content {
            position: relative;
        }
        &.scorecard-actions {
            display: flex;
            justify-content: space-between;
        }
    }
    .selection-summary {
        display: flex;
        justify-content: space-between;
        text-align: center;
        align-items: center;
        span {
            display: block;
        }
        &__title {
            color: #666;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 14px;
        }
        &__subtitle {
            font-size: 12px;
        }
        &__label {
            color: #666;
        }
        &__amount {
            font-size: 16px;
            font-family: Roboto;
        }
    }
`;

export const CoreProductionTableWrap = styled.div`
    .subtable {
        padding: 10px 0px;
        .options-container {
            display: flex;
            .message {
                padding: 12px;
                font-size: 11px;
                color: red;
                text-align: center;
                width: 70%;
            }
        }
    }
    border: 1px solid #ddd;
    background: white;
    .filters-container {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 30px 20px;
        .container {
            display: flex;
            justify-content: end;
            .akpi-checkbox {
                margin-bottom: 0;
                .akpi-checkbox {
                    display: none;
                }
            }
        }
    }
    .__react_component_tooltip {
        box-shadow: 1px 1px 3px -1px rgba(0, 0, 0, 0.75);
    }
    .planning-tool-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        height: 438px;
        &.variant .planning-tool-card {
            &.written-premium-bg h3,
            &.new-business-bg h3,
            &.trailing-bg h3,
            &.loss-ratio-bg h3,
            &.goals-bg h3 {
                background-color: transparent;
            }
            &__header {
                border-bottom: 1px solid #f0f0f0;
                h3 {
                    margin: 0;
                }
            }
            &::after {
                content: '';
                width: 100%;
                height: 5px;
                position: absolute;
                top: 0px;
                left: 0;
            }
            &.written-premium {
                //width: 70%;
                //max-width: 500px;
            }
            &.new-business {
                //max-width: 500px;
            }
            &.trailing {
                //max-width: 500px;
            }
            &.loss-ratio {
                //width: 35%;
                //max-width: 500px;
            }
            &.written-premium::after {
                background-color: ${theme.colors.writtenPremium};
            }
            &.new-business::after {
                background-color: ${theme.colors.newBusiness};
            }
            &.trailing::after {
                background-color: ${theme.colors.trailing};
            }
            &.loss-ratio::after {
                background-color: ${theme.colors.lossRatio};
            }
            &.goals::after {
                background-color: #f5f5f9;
            }
        }
        .planning-tool-card {
            width: 45%;
            max-height: 230px;
            max-width: 550px;
            overflow: hidden;
            &__header {
                //background-color: #f5f5f9;
                //border-bottom: 1px solid #f0f0f0;
                padding-bottom: 10px;
            }
            &__body {
                padding-top: 10px;
                .policy-type-header {
                    width: 75px;
                }
            }
            h3 {
                font-size: 16px;
                font-family: 'Roboto';
                text-align: center;
                font-weight: normal;
            }
            table {
                * {
                    //font-family: 'Roboto';
                }
                thead th,
                tbody th {
                    font-weight: normal;
                    text-align: right;
                }
                thead th {
                    font-style: italic;
                }
                tbody th,
                td {
                    text-align: right;
                    padding: 5px 0;
                }
            }
        }

        .list-selector {
            display: flex;
            margin-bottom: 10px;
            &__button {
                cursor: pointer;
                padding: 8px 20px;
                border-radius: 20px;
                transition: background-color 0.2s;
                margin-right: 10px;
                &:hover {
                    background-color: #f0f0f0;
                }
                &.active {
                    background-color: #3490dc;
                    color: white;
                }
            }
        }
        .overview {
            position: relative;
        }
        .display-style-select {
            position: absolute;
            top: 10px;
            right: 20px;
            .akpi-select {
                display: flex;
                align-items: center;
            }
        }
        .list-table-search {
            flex-shrink: 0;
            flex-basis: 200px;
            margin-bottom: 10px;
            .slds-form-element {
                display: flex;
                align-items: center;
            }
        }
        .planning-tool-warning {
            color: #de751f;
            padding-left: 10px;
            cursor: pointer;
        }
        .planning-tool-subtitle svg {
            color: #2779bd;
            font-size: 16px;
            margin-left: 5px;
            cursor: pointer;
        }
    }
    .list-table-actions {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        align-items: center;
        gap: 20px;

        > div {
            padding-right: 0;
        }

        .timerange {
            font-weight: 500;
        }

        .list-table-search {
            flex-shrink: 0;
            flex-basis: 200px;
            .akpi-input {
                margin-bottom: 0;
            }
            .slds-form-element {
                display: flex;
                align-items: center;
            }
        }
        .list-table-policyType {
            div {
                margin-bottom: 0;
            }
        }
        .custom-radio label {
            height: 38px;
            align-items: center;
            display: flex;
        }
        .custom-radio label {
            padding-top: 6px;
            padding-bottom: 6px;
        }
        .multiSelect_StandardTypesContainer {
            display: flex;
            alignitems: center;
            max-width: 500px;
            padding-right: 0px;
            .multiSelect_StandardTypes {
                margin: 0 10px;
            }
            .multiSelect_ApplyButton {
                margin: 0;
                height: 34px;
                text-align: center;
                padding: 8px 18px;
            }
            .multiSelect_Message {
                color: gray;
                font-size: small;
            }
        }
        .carrier-composites {
            margin-top: 0px;
            display: flex;
            width: 420px;
            div.akpi-checkbox {
                min-width: 50%;
                margin: 4px 0 4px;
            }
            input:checked ~ .checkbox-custom::after {
                top: 1px;
                left: 4px;
            }
            > div {
                margin-left: 18px;
            }
            .group-title {
                width: 100%;
                margin: 0 0 5px 0;
            }
            .carrierTypes {
                display: flex;
                justify-content: space-between;
                width: 120px;
                border-right: 1px solid grey;
                flex-direction: column;
                display: flex;
            }
            .monolines {
                width: 232px;
            }
            .monoline-inputs {
                display: flex;
                justify-content: space-between;
                width: 100%;
                flex-wrap: wrap;
                display: flex;
            }
        }
        .timeFrameBtnGroup {
            margin-left: auto;
            div {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    .date-icon {
        float: right;
        margin-left: 10px;
        font-size: 10px;
        padding: 2px 7px;
        color: #112c3e;
        border-radius: 8px;
        &--default {
            background-color: #90cdf4;
        }
        &--success {
            background-color: #68d391;
            color: #11432c;
        }
        &--warning {
            background-color: #f6e05e;
            color: #441;
        }
        &--error {
            background-color: #fc8181;
            color: #331111;
        }
        &--trailing {
            background-color: #e4eaee;
        }
    }
    .percent-metric {
        & .negative {
            color: ${theme.colors.red};
        }
        & .positive {
            color: ${theme.colors.green};
        }
    }
    // .planning-tool-card__body {
    //     td {
    //         font-family: Roboto;
    //         font-weight: 400;
    //     }
    // }
`;

export const CoreProductionWrap = styled(PageWithTableWrap)`
    .akpi-checkbox {
        align-self: center;
        margin-right: 25px;
        .akpi-checkbox {
            display: none;
        }
    }
`;

export const FilterContainer = styled.div`
    padding: 10px 0 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: flex-end;

    .exclude-data-toggle {
        display: flex;
        align-items: center;
        flex: 1;
        gap: 5px;
        font-size: 16px;
        white-space: nowrap;

        .akpi-checkbox {
            margin: 0;
        }
    }

    .timeline-selector {
        margin: 0 -5px -10px 0; // Negates the built-in margin on the timeline selector. Gross.
    }

    .download {
        margin: 0;
        padding: 6px 10px;
        border-color: rgba(0, 0, 0, 0.2);
        transition: border-color 200ms ease;
    }

    .download:hover {
        border-color: rgba(0, 0, 0, 1);
    }
`;

export const DashboardControls = styled.div`
    margin: 5px 30px;
    width: max-content;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    .akpi-select {
        margin: 10px 0 0 0;
    }
`;

export const CarrierContainer = styled.div`
    margin-top: 29px;
`;

export const Filters = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-left: 20px;
    border-left: 1px solid #dddddd;
    flex-grow: 1;
    align-items: center;

    > div {
        padding: 0;
    }
`;

export const FiltersDisplay = styled.div`
    .title {
        display: flex;
        font-size: 12px;
        color: #4a4a4a;
        flex-wrap: wrap;
        margin-bottom: 5px;
        .text {
            margin-left: 5px;
            color: black;
            border-radius: 3px;
            background-color: #f9f9f9;
            text-align: center;
            border: 1px solid #ccc;
            padding: 1px;
            align-items: center;
        }
        align-items: center;
        padding-bottom: 10px;
    }
    .title:last-child {
        margin-bottom: 5px;
        padding-bottom: 0;
    }
`;

export const CertifiedIconContainer = styled.span`
    vertical-align: middle;
    div {
        svg {
            margin-left: 5px;
        }
    }
`;

export const HiddenInfo = styled.span`
    color: #aeaeb2;
`;

export const FiltersSection = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;

    & > div {
        :first-child {
            border-bottom-left-radius: 5px;
            border-top-left-radius: 5px;
        }
        :last-child {
            border-bottom-right-radius: 5px;
            border-top-right-radius: 5px;
        }
    }
`;

export const Option = styled.div`
    border: 1px solid rgb(204, 204, 204);
    // border-radius: 5px;
    font-size: 12px;
    line-height: 20px;
    padding: 10px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    background: ${(props) => (props.selected ? '#f9f0cd' : '#ffffff')};
    border-color: ${(props) => (props.selected ? '#e0b715' : '#e8e8e8')};
    font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
`;
