import { useStoreState } from 'easy-peasy';
import {
    findPolicyTypeByCode,
    findPolicyTypeByUuid,
    getClassificationLevelUuids
} from '../../utils/policyType';
import {
    carriersQuery,
    getCoreProductionByAgencyCode,
    getNetworkSummary,
    getNetworkSummaryByCarrier
} from './requests';
import { useQuery } from '@tanstack/react-query';
import { COLLABORATION_EXCLUSIONS } from '../../constants';

export function useStandardPolicyTypes() {
    const standardTypes = useStoreState((state) => state.standardTypes);

    return {
        findPolicyTypeByCode(code) {
            return findPolicyTypeByCode(standardTypes, code);
        },
        findPolicyTypeByUuid(uuid) {
            return findPolicyTypeByUuid(standardTypes, uuid);
        },
        getClassificationLevelUuids(code, includeParent) {
            return getClassificationLevelUuids(code, includeParent, standardTypes);
        }
    };
}

export function useCPRsBySubCode({
    policyType,
    timeframe,
    standardTypes,
    carrierComposites,
    tableDataType
}) {
    const { getClassificationLevelUuids } = useStandardPolicyTypes();
    return useQuery({
        queryKey: ['cpr-by-subcode', { timeframe, standardTypes, carrierComposites }],
        queryFn({ queryKey: [, { timeframe, standardTypes, carrierComposites }] }) {
            return getCoreProductionByAgencyCode({
                timeframe,
                standardTypes,
                carrierComposites: carrierComposites,
                levelUuid: getClassificationLevelUuids(policyType, false)
            });
        },
        select(byAgencyCode) {
            const byAgencyCodeList = [];
            Object.keys(byAgencyCode).forEach((code) => {
                Object.keys(byAgencyCode[code]).forEach((carrier) => {
                    const name =
                        tableDataType === 'carriers'
                            ? `${byAgencyCode[code][carrier].name} - (${code})`
                            : `${byAgencyCode[code][carrier]?.[policyType.code]?.carrierName} (${code})`;
                    byAgencyCodeList.push({
                        ...byAgencyCode[code][carrier],
                        name: name,
                        code
                    });
                });
            });
            return byAgencyCodeList;
        },
        refetchOnMount: false
    });
}

/**
 * Helper hook to hide carrier names when required by Collaboration Mode
 * @param {string} carrierUuid
 * @param {string} name
 * @return {{name: string, isHidden: boolean}}
 */
export function useCollaborationModeHiddenName(carrierUuid, name) {
    const [collaborationMode] = useStoreState((state) => [state.collaborationMode]);

    if (
        collaborationMode &&
        collaborationMode.shareType === COLLABORATION_EXCLUSIONS.NAMES &&
        collaborationMode.carriersUuid.length > 0 &&
        !collaborationMode.carriersUuid.includes(carrierUuid)
    ) {
        return {
            name: '[Hidden information]',
            isHidden: true
        };
    } else {
        return {
            name,
            isHidden: false
        };
    }
}

export function useCarrierData(carrierUuid) {
    return useQuery({
        ...carriersQuery,
        select(carriers) {
            return carrierUuid ?  carriers.get(carrierUuid) : carriers;
        }
    });
}

export function useNetworkSummary(timeframe) {
    return useQuery({
        queryKey: ['core-production', 'network/summary', { timeframe }],
        queryFn({ queryKey: [, , options] }) {
            return getNetworkSummary(options);
        },
        select(data) {
            return data.networkSummary;
        },
        staleTime: 60 * 60 * 1000
    });
}

export function useNetworkSummaryByCarrier(timeframe, policyTypeCode, standardTypes) {
    return useQuery({
        queryKey: [
            'core-production',
            'network/summary',
            { timeframe, policyTypeCode, standardTypes }
        ],
        queryFn({ queryKey: [, , options] }) {
            return getNetworkSummaryByCarrier(options);
        },
        select(data) {
            return data.byCarrierSummary;
        },
        staleTime: 60 * 60 * 1000
    });
}
