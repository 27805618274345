import styled from '@emotion/styled';

export const SelectableListSection = styled.div`
    --default-padding: 12px;
    --default-radius: 5px;
    --default-border: 1px solid lightgray;

    margin-bottom: var(--default-padding);
    border: var(--default-border);
    border-radius: var(--default-radius);

    header {
        padding: var(--default-padding);
        border-bottom: var(--default-border);

        input {
            padding: var(--default-padding);
            width: 100%;
            display: block;
            border: var(--default-border);
            border-radius: var(--default-radius);
            font-size: 1em;
        }
        svg {
            position: absolute;
            margin-top: 11px;
            margin-left: 10px;
            color: #3a3a3c;
            height: 13px;
        }
        .search-input {
            height: 36px;
            border-radius: 50px;
            padding-left: 35px;
        }
    }

    ul,
    ol {
        margin: 0;
        padding: 0;
        max-height: 50vh;
        list-style: none;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
    }

    li {
        padding: var(--default-padding);
        display: flex;
        border-bottom: var(--default-border);
        cursor: pointer;
        transition: background-color 200ms;
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.15px;
        color: #3a3a3c;

        &[data-selected='true'] {
            background-color: aliceblue;
        }

        &:hover {
            background-color: whitesmoke;
        }

        input,
        label {
            pointer-events: none;
        }

        label {
            padding-left: var(--default-padding);
            flex: 1;
            user-select: none;
        }
    }

    footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: calc(var(--default-padding) * 2);
        font-size: 0.9em;

        .totals {
            padding: calc(var(--default-padding) * 1.5);
            padding-right: 0;
            min-width: 180px;
            flex: 1;
            transition: opacity 200ms;

            &[data-total='0'] {
                opacity: 0;
            }
        }

        .control {
            display: flex;
            align-items: center;
            gap: calc(var(--default-padding) / 2);
        }

        .buttons {
            margin-right: calc(var(--default-padding) / 2);
            display: flex;
        }

        select {
            border: none;
        }

        button {
            width: calc(var(--default-padding) * 3);
            height: calc(var(--default-padding) * 3);
            display: flex;
            align-items: center;
            justify-content: center;
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            background: none;
            border: none;
            border-radius: 50%;
            cursor: pointer;
            transition: background-color 200ms;

            &:hover {
                background-color: whitesmoke;
            }

            &:active {
                background-color: lightgray;
            }

            &[disabled] {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
`;
