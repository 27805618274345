import styled from '@emotion/styled';

export const StyledTableContainer = styled.div`
    width: 90%;
`;

export const CancelIcon = styled.div`
    position: absolute;
    right: 26px;
    > .fa-times:hover {
        color: gray;
    }
`;
