import { action } from 'easy-peasy';
import { filter } from 'lodash-es';
import { isYearToDate } from '../../utils';
import { LOCAL_STORAGE } from '../../constants';

export const actions = {
    setNavigationExpanded: action((state, status) => {
        state.navigationExpanded = status;
        localStorage.setItem(LOCAL_STORAGE.EXPANDED_NAV, status);
    }),
    setUser: action((state, { user }) => {
        state.sessionExpired = false;
        state.user = user;
        state.fetchingUser = false;
        state.refreshTokenRevoked = false;
        state.userNotFound = user === null;
    }),
    setUserNotFound: action((state) => {
        state.userNotFound = true;
        state.fetchingUser = false;
    }),
    setUserErrorMessage: action((state, { errorMessage }) => {
        state.userErrorMessage = errorMessage;
        state.fetchingUser = false;
    }),
    setNetwork: action((state, { network }) => {
        state.network = network;
    }),
    setYear: action((state, year) => {
        state.year = parseInt(year, 10);
    }),
    setMonth: action((state, month) => {
        state.month = parseInt(month, 10);
    }),
    setYearForm: action((state, year) => {
        state.manualEntryForm.formValues.year = parseInt(year, 10);
    }),
    setMonthForm: action((state, month) => {
        state.manualEntryForm.formValues.month = parseInt(month, 10);
    }),
    setSessionExpired: action((state) => {
        state.sessionExpired = true;
    }),
    setServerError: action((state, value) => {
        state.serverError = value;
    }),
    setRefreshTokenRevoked: action((state) => {
        state.refreshTokenRevoked = true;
    }),
    setFetchingUser: action((state) => {
        state.fetchingUser = true;
    }),
    setSubmittingForm: action((state, value) => {
        state.submittingForm = value;
    }),
    setSubmitErrorMsg: action((state, error) => {
        state.submitErrorMsg = error;
    }),
    setFormModalOpen: action((state, value) => {
        state.formModalOpen = value;
    }),
    setManualEntryData: action((state, data) => {
        state.manualEntryTable.manualEntryData = data;
    }),
    setManualEntryDataByYear: action((state, data) => {
        state.manualEntryTable.manualEntryDataByYear = filter(data, ['year', state.year]);
    }),
    setFormattedManualEntryData: action((state, data) => {
        state.manualEntryTable.manualFormattedEntryData = data;
    }),
    setCompanyTable: action((state, company) => {
        state.manualEntryTable.companyUuid = company.companyUuid;
        state.manualEntryTable.companyName = company.companyName;
    }),
    setPolicyTable: action((state, policy) => {
        state.manualEntryTable.policyUuid = policy.policyUuid;
        state.manualEntryTable.policyTypeCode = policy.policyTypeCode;
    }),
    //Available subcodes after selecting carrier in table
    setSubCodesTable: action((state, subCodes) => {
        state.manualEntryTable.availableSubCodes = subCodes;
    }),
    //Initial subCode
    setSubCodeTable: action((state, subCode) => {
        state.manualEntryTable.subCodeUuid = subCode.uuid;
        state.manualEntryTable.subCode = subCode.code;
    }),
    setCompanyForm: action((state, company) => {
        state.manualEntryForm.formValues.selectedCarrier.uuid = company.companyUuid;
        state.manualEntryForm.formValues.selectedCarrier.name = company.companyName;
        state.manualEntryForm.formValues.selectedCarrier.isCrop = company.isCrop;
    }),
    setSubCodeForm: action((state, subCode) => {
        state.manualEntryForm.formValues.selectedSubCode.uuid = subCode.uuid;
        //state.manualEntryForm.formValues.selectedSubCode.name = subCode.name;
        state.manualEntryForm.formValues.selectedSubCode.code = subCode.code;
        state.manualEntryForm.formValues.subCode = subCode.code;
    }),
    setPolicyForm: action((state, policy) => {
        state.manualEntryForm.formValues.policyType = policy.policyTypeCode;
    }),
    setCurrentView: action((state, currentView) => {
        state.manualEntryTable.currentView = currentView;
    }),
    setInitialFormValues: action((state, entryInfo = {}) => {
        state.manualEntryForm.stage = state.manualEntryTable.currentView;
        state.manualEntryForm.formValues.selectedCarrier.name = state.manualEntryTable.companyName;
        state.manualEntryForm.formValues.selectedCarrier.uuid = state.manualEntryTable.companyUuid;

        //state.manualEntryForm.formValues.subCode = state.manualEntryForm.initialFormValues.subCode;
        state.manualEntryForm.formValues.subCode = state.manualEntryTable.subCode;
        state.manualEntryForm.formValues.selectedSubCode.code = state.manualEntryTable.subCode;
        //state.manualEntryForm.formValues.selectedSubCode.name = state.manualEntryTable.subName;
        state.manualEntryForm.formValues.selectedSubCode.uuid = state.manualEntryTable.subCodeUuid;

        state.manualEntryForm.formValues.policyType = state.manualEntryTable.policyTypeCode;

        state.manualEntryForm.formValues.writtenPremium =
            state.manualEntryForm.initialFormValues.writtenPremium;
        state.manualEntryForm.formValues.newBusiness =
            state.manualEntryForm.initialFormValues.newBusiness;
        state.manualEntryForm.formValues.mover = state.manualEntryForm.initialFormValues.mover;
        state.manualEntryForm.formValues.incurredLoss =
            state.manualEntryForm.initialFormValues.incurredLoss;
        state.manualEntryForm.formValues.earnedPremium =
            state.manualEntryForm.initialFormValues.earnedPremium;
        state.manualEntryForm.formValues.lossRatio =
            state.manualEntryForm.initialFormValues.lossRatio;
        state.manualEntryForm.formValues.year = state.year;
        state.manualEntryForm.formValues.month = state.month;
        if (entryInfo.year && entryInfo.month) {
            state.manualEntryForm.formValues.year = entryInfo.year;
            state.manualEntryForm.formValues.month = entryInfo.month;
            state.manualEntryForm.formValues.writtenPremium =
                entryInfo.dwp === null ? 0 : entryInfo.dwp;
            state.manualEntryForm.formValues.newBusiness =
                entryInfo.newDwp === null ? 0 : entryInfo.newDwp;
            state.manualEntryForm.formValues.mover =
                entryInfo['12Mm_dwp'] === null ? 0 : entryInfo.newDwp;
            state.manualEntryForm.formValues.incurredLoss =
                entryInfo.incurred === null ? 0 : entryInfo.incurred;
            state.manualEntryForm.formValues.earnedPremium =
                entryInfo.earned === null ? 0 : entryInfo.earned;
            state.manualEntryForm.formValues.lossRatio =
                entryInfo.lossRatio === null ? 0 : entryInfo.lossRatio;
            state.manualEntryForm.formValues.radio =
                entryInfo.lossRatio === 0 || entryInfo.lossRatio === null
                    ? 'incurredLossEarnedPremium'
                    : 'lossRatio';
            state.manualEntryForm.formValues.subCode = entryInfo.agencyCode;
            state.manualEntryForm.formValues.selectedSubCode.uuid = entryInfo.agencyUuid;
            state.manualEntryForm.formValues.selectedSubCode.code = entryInfo.agencyCode;
        }
    }),
    setFormValues: action((state, formValues) => {
        state.manualEntryForm.formValues = { ...formValues };
    }),
    resetManualEntryForm: action((state) => {
        state.manualEntryTable.formValues = {};
        Object.assign(state.manualEntryForm.formValues, state.manualEntryForm.initialFormValues);
    }),
    setImportUuid: action((state, importUuid) => {
        state.importUuid = importUuid;
    }),
    setSubmittingFormMsg: action((state, msg) => {
        state.manualEntryForm.submittingFormMsg = msg;
    }),
    setOnboardedCarriers: action((state, onboardedCarriers) => {
        state.onboardedCarriers = onboardedCarriers;
    }),
    setFormWarningMsg: action((state, warningMsg) => {
        state.manualEntryForm.formValues.warningMsg = warningMsg;
    }),
    setSubCodes: action((state, subCodes) => {
        state.subCodes = subCodes;
    }),
    setStandardTypes: action((state, standardTypes) => {
        state.standardTypes = standardTypes;
    }),
    setTimeframe: action((state, timeframe) => {
        timeframe.isYtd = isYearToDate(timeframe.startDate, timeframe.endDate);
        state.timeframe = timeframe;
    }),
    setCarriersList: action((state, carriers) => {
        state.carriersList = carriers;
    }),
    setLobsList: action((state, lobs) => {
        state.lobsList = lobs;
    }),
    setCollaborationMode: action((state, data) => {
        state.collaborationMode = data;
        sessionStorage.setItem('collaborationMode', JSON.stringify(data));
    })
};
