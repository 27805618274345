import { Icon } from '../../icons/icons';
import {
    ErrorMessageHeader,
    ErrorMessageIcon,
    ErrorMessageText,
    ErrorMessageReason,
    ErrorMessageContainer
} from './styles';
import PropTypes from 'prop-types';

const ErrorMessage = ({ title, reason, text, children }) => {
    if (title === undefined) {
        title = 'Unable to process request';
    }

    if (text === undefined && !children) {
        text = 'If you think you are receiving this message in error, please contact us.';
    }
    return (
        <ErrorMessageContainer>
            <ErrorMessageIcon>
                <Icon type="report-bug" iWidth="70px" iColor="#d9453d" />
            </ErrorMessageIcon>
            <ErrorMessageHeader>{title}</ErrorMessageHeader>
            {reason ? <ErrorMessageReason>Reason: {reason}</ErrorMessageReason> : null}
            <ErrorMessageText>{text ? text : children}</ErrorMessageText>
        </ErrorMessageContainer>
    );
};
ErrorMessage.propTypes = {
    title: PropTypes.string,
    reason: PropTypes.string,
    text: PropTypes.string,
    children: PropTypes.node
};

export default ErrorMessage;
