import PropTypes from 'prop-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Icon } from '../../icons/icons';
import { useQuery } from '@tanstack/react-query';
import { queries } from '../../utils';
import { NavLink } from 'react-router-dom';
import styled from '@emotion/styled';
import theme from '../../theme';
import { isConsideredEnabled } from '../App/featureRoute';
import { useEffect, useMemo } from 'react';

const ReportLink = ({ config, expanded, fallback, fallbackConditions = [] }) => {
    const flags = useFlags();
    const { data: assignments } = useQuery(queries.assignments());

    const reportEnabled = useMemo(() => {
        let enabled = false;
        assignments?.map((assignment) => {
            if (assignment.key === config.key && assignment.enabled) enabled = true;
        });

        return enabled && isConsideredEnabled([config.flag], flags);
    }, [config.flag, assignments]);

    // Only show the fallback if all reports in fallback conditions are disabled.
    const fallbackEnabled = useMemo(() => {
        if (reportEnabled) return false;
        if (!assignments) return true;

        // Check if there is a matching assignment for each condition.
        let conditionEnabled = false;
        for (const condition of fallbackConditions) {
            let assignmentEnabled = false;
            for (const assignment of assignments) {
                if (assignment.key === condition.key && assignment.enabled) {
                    assignmentEnabled = true;
                    break;
                }
            }

            // If the assignment and feature flag are enabled, the condition is enabled.
            conditionEnabled = assignmentEnabled && isConsideredEnabled([condition.flag], flags);
            if (conditionEnabled) break;
        }

        // If any condition was enabled, the fallback should not be.
        return !conditionEnabled;
    }, [reportEnabled, assignments]);

    // Find out if we have an assigned report for this network.
    const reportQuery = useQuery(
        {
            ...queries.report(config.key),
            enabled: Boolean(reportEnabled && config.key)
        },
        [config.key, reportEnabled]
    );

    return reportQuery.data ? (
        <ReportListItem>
            <NavLink to={`/${config.path}`}>
                {config.icon && <Icon type={config.icon} iWidth={'30px'} iColor={'black'} />}
                {expanded && (
                    <LinkLabel>
                        {config.label}
                        {config.badge && <strong>{config.badge}</strong>}
                    </LinkLabel>
                )}
            </NavLink>
        </ReportListItem>
    ) : (
        fallbackEnabled && fallback
    );
};

ReportLink.propTypes = {
    expanded: PropTypes.bool.isRequired,
    fallback: PropTypes.element,
    config: PropTypes.shape({
        flag: PropTypes.string,
        icon: PropTypes.string,
        badge: PropTypes.string,
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        path: PropTypes.string.isRequired
    }),
    fallbackConditions: PropTypes.arrayOf(
        PropTypes.shape({
            flag: PropTypes.string,
            key: PropTypes.string.isRequired
        })
    )
};

export default ReportLink;

const ReportListItem = styled.li`
    strong {
        padding: 0.33em 0.66em;
        background-color: ${theme.colors.textDark};
        border-radius: 1em;
        color: ${theme.colors.primary};
        font-size: 12px;
    }

    &:hover strong,
    a.active strong {
        background-color: ${theme.colors.textTint};
        color: ${theme.colors.tint};
    }
`;

const LinkLabel = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`;
