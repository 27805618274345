import moment from 'moment';
import { POLICY_TYPE_ORDER, ELIGIBLE_YEAR, CURRENT_YEAR, ELIGIBLE_YEARS } from '../constants';
import { findPolicyTypeByCode, findPolicyTypeByUuid } from './policyType';
import * as queries from './queries';

export { queries };

export function updateItem(maps, id, updates) {
    const index = maps.findIndex((t) => t.id === id);
    const updatedItem = {
        ...maps[index],
        ...updates
    };
    return [...maps.slice(0, index), updatedItem, ...maps.slice(index + 1)];
}

export const getPagination = (rows) => {
    let options = [5, 10, 20, 25, 50, 100, rows].filter((o) => o <= rows);
    return options;
};

export const findPolicyType = (types, code) => {
    return findPolicyTypeByCode(types, code);
};

export const toCurrency = (value, numDigits = 0) => {
    if (!value) {
        //console.error('Currency value is undefined');
        value = 0;
    }
    return value
        .toLocaleString(
            {},
            {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: numDigits,
                maximumFractionDigits: numDigits
            }
        )
        .replace('.00', '');
};

export const toAbbreviatedCurrency = (value, numDigits = 0) => {
    if (!value) {
        //console.error('Currency value is undefined');
        value = 0;
    }

    let roundedValue = value;
    if (value < 999999) {
        //K
        roundedValue = Math.round(roundedValue / 100) * 100;
    } else if (value < 999999999) {
        //M
        roundedValue = Math.round(roundedValue / 100000) * 100000;
    } else if (value < 999999999999) {
        //B
        roundedValue = Math.round(roundedValue / 100000000) * 100000000;
    } else if (value < 999999999999999) {
        //T
        roundedValue = Math.round(roundedValue / 100000000000) * 10000000000000;
    }

    const currencyValue = roundedValue
        .toLocaleString(
            {},
            {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: numDigits,
                maximumFractionDigits: numDigits
            }
        )
        .replace(',', '.');
    if (currencyValue.length > 17)
        return `${currencyValue.substring(0, currencyValue.length - 14)}T`;
    if (currencyValue.length > 13)
        return `${currencyValue.substring(0, currencyValue.length - 10)}B`;
    if (currencyValue.length > 9) return `${currencyValue.substring(0, currencyValue.length - 6)}M`;
    if (currencyValue.length > 5) return `${currencyValue.substring(0, currencyValue.length - 2)}K`;
    return currencyValue;
};

export const toPercent = (value, numDigits = 2) => {
    value = value || 0;
    if (value === 0) {
        numDigits = 0;
    }

    if (value === '-   -   -   -   -') {
        return value;
    }
    return `${value.toFixed(numDigits)}%`;
};

export const currencyShortener = (labelValue, moveDecimal = false) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
        ? '$' + Math.trunc(Math.abs(Number(labelValue)) / 1.0e9) + 'B'
        : // Six Zeroes for Millions
        Math.abs(Number(labelValue)) >= 1.0e6
        ? '$' + moveDecimalFunc(Math.trunc(Math.abs(Number(labelValue)) / 1.0e4), moveDecimal) + 'M'
        : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
        ? '$' + Math.trunc(Math.abs(Number(labelValue)) / 1.0e3) + 'K'
        : '$' + Math.abs(Number(labelValue));
};

export function moveDecimalFunc(n, moveDecimal) {
    if (!moveDecimal) return n;
    var l = n.toString().length - 1;
    var v = n / Math.pow(10, l);
    return v;
}

const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
];

export const toMonthName = (monthInt) => {
    if (!monthNames[monthInt - 1]) {
        console.error('Invalid month integer', monthInt);
    }
    return monthNames[monthInt - 1] || 'Unknown';
};

export const addZ = (n) => {
    return n < 10 ? '0' + n : '' + n;
};

export const getColumnWidth = (rows, column, magicSpacing = 14) => {
    const maxWidth = 400;
    const minWidth = 180;
    const cellLength = Math.max(
        ...rows.map((row) => (`${row[column.accessor]}` || '').length),
        column.Header.length
    );
    return cellLength * magicSpacing < minWidth
        ? minWidth
        : Math.min(maxWidth, cellLength * magicSpacing);
};

// Makes the subtable's column the same width as the parent's.
export const getSubtableColumnWidth = (parentColumn, includeExpandColumnWidth = true) => {
    const borderWidth = 1;
    const expandColumnWidth = 35;

    let width = parentColumn.width;
    if (includeExpandColumnWidth) width += expandColumnWidth - borderWidth;

    return width;
};

export const noop = () => null;

export async function asyncForEach(array, cb) {
    let arrayResult = [];
    for (let i = 0; i < array.length; i++) {
        arrayResult.push(await cb(array[i], i, array));
    }
    return arrayResult;
}

export const splitUSPhoneNumber = (phone) => {
    const phoneNumber = phone.slice(phone.length - 7);
    const areaCode = phone.substring(1, 4);
    const countryCode = '1';
    return { areaCode, countryCode, phoneNumber };
};

const translations = {
    CL: 'Commercial',
    PL: 'Personal',
    'P&C': 'Total',
    BOND: 'Bond',
    CROP: 'Crop'
};

export const t = (key) => {
    return translations[key] || key;
};

export const percentFormat = {
    type: 'percent',
    precision: 2
};

export const currencyFormat = {
    type: 'currency',
    precision: 0
};

export const findPolicyUuid = (uuid, types) => {
    return findPolicyTypeByUuid(types, uuid);
};

export const getTotal = (data, key) => {
    let total = 0;
    data.forEach((row) => {
        if (row[key]) total += row[key];
    });
    return <div>{toCurrency(total)}</div>;
};

export const getPercentTotal = (data, newKey, wpKey) => {
    let newKeyTotal = 0;
    let wpKeyTotal = 0;
    data.forEach((row) => {
        if (row._original[newKey]) newKeyTotal += row._original[newKey];
        if (row._original[wpKey]) wpKeyTotal += row._original[wpKey];
    });
    const totalPercent = (newKeyTotal * 100) / wpKeyTotal;
    return <div>{toPercent(totalPercent)}</div>;
};

export const getChange = (data, currentKey, priorKey) => {
    let currentKeyTotal = 0;
    let priorKeyTotal = 0;
    data.forEach((row) => {
        if (row[currentKey]) {
            currentKeyTotal += row[currentKey];
        }
        if (row[priorKey]) {
            priorKeyTotal += row[priorKey];
        }
    });
    let delta = priorKeyTotal === 0 ? 0 : ((currentKeyTotal - priorKeyTotal) * 100) / priorKeyTotal;
    return <div>{delta.toFixed(2)} %</div>;
};

export const getPercentBook = (data, newKey, wpKey) => {
    let newKeyTotal = 0;
    let wpKeyTotal = 0;
    data.forEach((row) => {
        if (row[newKey]) {
            newKeyTotal += row[newKey];
        }

        if (row[wpKey]) {
            wpKeyTotal += row[wpKey];
        }
    });
    let delta = wpKeyTotal === 0 ? 0 : (newKeyTotal * 100) / wpKeyTotal;
    return <div>{delta.toFixed(2)} %</div>;
};

export const sortPolicyTypes = (a, b) => {
    // default unknown codes to the top
    return (POLICY_TYPE_ORDER[a.policyTypeCode] || -1) - (POLICY_TYPE_ORDER[b.policyTypeCode] || 1);
};

export const yearOptions = (() => {
    const numOfYearsBack = 2;
    const array = [{ label: ELIGIBLE_YEAR, value: ELIGIBLE_YEAR }];
    for (let j = 1; j <= numOfYearsBack; j++) {
        let obj = {
            label: ELIGIBLE_YEAR - j,
            value: ELIGIBLE_YEAR - j
        };
        array.push(obj);
    }
    return array;
})();

export const currentYearOptions = (() => {
    const numOfYearsBack = 2;
    const array = [{ label: CURRENT_YEAR, value: CURRENT_YEAR }];
    for (let j = 1; j <= numOfYearsBack; j++) {
        let obj = {
            label: CURRENT_YEAR - j,
            value: CURRENT_YEAR - j
        };
        array.push(obj);
    }
    return array;
})();

export const getCoreSummary = ({
    byCarrier,
    policyType,
    writtenPremium,
    baseCommission,
    additionalCommission,
    totalCommission
}) => {
    if (!byCarrier) return {};
    return Object.keys(byCarrier).reduce(
        (acc, carrierUuid) => {
            if (byCarrier[carrierUuid][policyType]) {
                if (byCarrier[carrierUuid][policyType].isCoreCarrier) {
                    acc.core.premium += byCarrier[carrierUuid][policyType][writtenPremium];
                    acc.core.baseCommission += byCarrier[carrierUuid][policyType][baseCommission];
                    acc.core.additionalCommission +=
                        byCarrier[carrierUuid][policyType][additionalCommission];
                    acc.core.totalCommission += byCarrier[carrierUuid][policyType][totalCommission];
                } else {
                    acc.nonCore.premium += byCarrier[carrierUuid][policyType][writtenPremium];
                    acc.nonCore.baseCommission +=
                        byCarrier[carrierUuid][policyType][baseCommission];
                    acc.nonCore.additionalCommission +=
                        byCarrier[carrierUuid][policyType][additionalCommission];
                    acc.nonCore.totalCommission +=
                        byCarrier[carrierUuid][policyType][totalCommission];
                }
                acc.totalPremium += byCarrier[carrierUuid][policyType][writtenPremium];
            }
            return acc;
        },
        {
            core: {
                premium: 0,
                baseCommission: 0,
                additionalCommission: 0,
                totalCommission: 0
            },
            nonCore: {
                premium: 0,
                baseCommission: 0,
                additionalCommission: 0,
                totalCommission: 0
            },
            totalPremium: 0
        }
    );
};

export const getDateStringFromKpi = (kpi) => {
    if (kpi && kpi.month && kpi.year) {
        return `${kpi.month}-${kpi.year}`;
    }
    return 'Unknown';
};

export const getSummary = (
    type,
    dataByUuid,
    writtenPremium,
    baseCommission,
    additionalCommission,
    totalCommission
) => {
    if (!dataByUuid) return {};
    let summary = Object.keys(dataByUuid).reduce(
        (acc, uuid) => {
            const current = dataByUuid[uuid][type];
            if (!!current) {
                acc[writtenPremium] += current[writtenPremium];
                acc[baseCommission] += current[baseCommission];
                acc[additionalCommission] += current[additionalCommission];
                acc[totalCommission] += current[totalCommission];
                acc.count++;
            }
            return acc;
        },
        {
            [writtenPremium]: 0,
            [baseCommission]: 0,
            [additionalCommission]: 0,
            [totalCommission]: 0,
            count: 0
        }
    );
    summary.totalCommissionPercent = summary[writtenPremium]
        ? (summary[totalCommission] * 100) / summary[writtenPremium]
        : 0;
    summary.baseCommissionPercent = summary[writtenPremium]
        ? (summary[baseCommission] * 100) / summary[writtenPremium]
        : 0;
    summary.additionalCommissionPercent = summary[writtenPremium]
        ? (summary[additionalCommission] * 100) / summary[writtenPremium]
        : 0;
    return summary;
};

export const getYTDEndDate = () => {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    const month = date.getMonth();
    const day = date.getDate();
    if (day <= 15) {
        date.setMonth(month - 1);
        date.setDate(1);
    } else {
        date.setDate(1);
    }

    return date;
};

export const isCalenderYear = (timeframeOption) => {
    return ELIGIBLE_YEARS.includes(parseInt(timeframeOption, 10));
};

export const isCustomCalendarYear = (startDate, endDate) => {
    return (
        startDate.getFullYear() === endDate.getFullYear() &&
        startDate.getMonth() === 0 &&
        endDate.getMonth() === 11
    );
};

/**
 * Checks if the date range is a year to date selection.
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {boolean}
 */
export const isYearToDate = (startDate, endDate) => {
    const current = moment();

    const start = moment(startDate);
    const end = moment(endDate);

    if (start.year() === end.year() && start.dayOfYear() === 1) {
        if (end.dayOfYear() >= 365) return true;
        else if (start.year() === current.year()) {
            const monthDiff = current.month() - end.month();

            if(current.date() <= 15  && monthDiff === 1)
                return false;

            if(current.date() > 15 && monthDiff === 1)
                return true;

            if(current.date() <= 15 && monthDiff === 2)
                return true;
                
        }
    }

    return false;
};
