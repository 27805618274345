import {
    ELIGIBLE_YEAR,
    ELIGIBLE_MONTH,
    ELIGIBLE_YEARS,
    COLLABORATION_EXCLUSIONS
} from '../../constants';
import { actions } from '../actions/actions';
import { thunks } from '../thunks/thunks';
import moment from 'moment';
import { getYTDEndDate } from '../../utils/index';

export const initialState = {
    year: ELIGIBLE_YEAR, // Derived so that the next year is not available until it is Febuary
    years: ELIGIBLE_YEARS,
    month: ELIGIBLE_MONTH,
    navigationExpanded: null,
    network: {},
    user: {},
    userErrorMessage: '',
    standardTypes: [],
    fetchingUser: false,
    userNotFound: false,
    sessionExpired: false,
    serverError: {
        message: null,
        hasLogout: true,
        hasContinue: true
    },
    refreshTokenRevoked: false,
    submittingForm: false,
    submitErrorMsg: '',
    importUuid: '',
    onboardedCarriers: [],
    subCodes: [],
    manualEntryTable: {
        manualEntryData: [],
        manualEntryDataByYear: [],
        manualFormattedEntryData: [],
        subCode: '',
        availableSubCodes: [],
        subCodeUuid: '',
        companyUuid: '',
        companyName: '',
        policyUuid: '',
        policyTypeCode: '',
        currentView: 'carriers'
    },
    manualEntryForm: {
        submittingFormMsg: '',
        formValues: {
            radio: 'incurredLossEarnedPremium',
            carrier: '',
            subCode: '',
            selectedCarrier: {
                name: '',
                uuid: ''
            },
            selectedSubCode: {
                //name: '',
                code: '',
                uuid: ''
            }
        },
        initialFormValues: {
            radio: 'incurredLossEarnedPremium',
            carrier: '',
            selectedCarrier: {
                name: '',
                uuid: ''
            },
            selectedSubCode: {
                //name: '',
                code: '',
                uuid: ''
            },
            subCode: '',
            month: '',
            year: '',
            policyType: '',
            writtenPremium: '',
            newBusiness: '',
            mover: '',
            incurredLoss: '',
            earnedPremium: '',
            lossRatio: ''
        }
    },
    timeframe: {
        name: 'YTD',
        displayText: `Jan ${ELIGIBLE_YEAR} - ${moment(getYTDEndDate().setDate(0)).format(
            'MMM YYYY'
        )}`,
        isYtd: true,
        startDate: new Date(ELIGIBLE_YEAR, 0, 1),
        endDate: new Date(getYTDEndDate().setDate(0))
    },
    carriersList: [],
    lobsList: [],
    collaborationMode: {
        carriersUuid: [],
        carriersName: [],
        shareType: COLLABORATION_EXCLUSIONS.NAMES
    }
};

export const model = {
    ...initialState,
    ...actions,
    ...thunks
};
