import styled from '@emotion/styled';
import theme from '../../../theme';

export const StyledForm = styled.div`
    /*
    border: 1px solid rgba(80,84,135,0.51);
    box-shadow: 1px 1px 3px -1px rgba(0,0,0,0.75);
    border-radius: 3px;
    */
    display: flex;
    justify-content: center;
    width: 720px;
    height: 400px;
`;

export const StyledModalHeading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
`;

export const StyledSubmitDialog = styled.div`
    width: 800px;
    height: 400px;
    font-size: xx-large;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
        margin: 0 0 0 12px;
    }
`;

export const FormSection = styled.div`
    display: flex;
    flex-direction: row;
    margin: 10px 0;
    &.fade {
        opacity: 0;
        transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
        visibility: hidden;
    }
    &.fade {
        opacity: 1;
        visibility: visible;
        transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
    }
`;

export const StyledSelect = styled.div`
    min-width: 62px;
    height: 75px;
    margin: 0 16px 0 0;
`;

export const StyledRadio = styled.div`
    margin: 0 16px 0 0;
`;

export const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    margin-bottom: 24px;
`;

export const ButtonWrapper = styled.div`
    width: 260px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const StyledTextInput = styled.div`
    display: flex;
    margin: 0 16px 0 0;
    flex-direction: column;
    height: 75px;
    min-width: 80px;
    max-width: 100px
    align-items: left;
    &.fade {
        opacity:0;
        transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
        visibility: hidden;
    }
    &.fade {
        opacity:1;
        visibility: visible;
        transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
    }
`;

export const StyledErrorMessage = styled.div`
    color: #dc3545;
    font-size: 0.9em;
`;

export const StyledLabel = styled.label``;

export const StyledModalLabel = styled.div`
    display: flex;
    flex-direection: row;
    justify-content: space-between;
    width: 664px;
    font-size: large;
    font-weight: 600;
`;
