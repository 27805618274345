/**
 * @interface StandardType
 * @property {string} uuid
 * @property {string|null} parentUuid
 * @property {string} classificationUuid
 * @property {string} name
 * @property {string} code
 * @property {StandardType[]} children
 */

/**
 *
 * @param {string} policyTypeCode
 * @param {boolean} includeParent
 * @param {StandardType[]}standardTypes
 * @return {null}
 */
export function getClassificationLevelUuids(policyTypeCode, includeParent, standardTypes) {
    const currentParent = findPolicyTypeByCode(standardTypes, policyTypeCode);
    const currentLevel = currentParent && currentParent.children[0];
    let levelUuid = null;
    if (currentParent) levelUuid = [currentParent.classificationUuid];
    if (includeParent && currentParent.parentUuid) {
        const parent = findPolicyTypeByUuid(standardTypes, currentParent.parentUuid);
        if (parent) levelUuid.push(parent.classificationUuid);
    }
    if (currentLevel) levelUuid.push(currentLevel.classificationUuid);
    return levelUuid;
}

/**
 * @param {StandardType[]} types
 * @param {string} code
 * @return {StandardType|null}
 */
export function findPolicyTypeByCode(types, code) {
    for (const standardType of types) {
        if (standardType.code === code) {
            return standardType;
        }
        if (standardType.children) {
            const found = findPolicyTypeByCode(standardType.children, code);
            if (found) {
                return found;
            }
        }
    }
    return null;
}

/**
 * @param {StandardType[]} types
 * @param {string} uuid
 * @return {StandardType|null}
 */
export function findPolicyTypeByUuid(types, uuid) {
    for (const standardType of types) {
        if (standardType.uuid === uuid) {
            return standardType;
        }
        if (standardType.children) {
            const found = findPolicyTypeByUuid(standardType.children, uuid);
            if (found) {
                return found;
            }
        }
    }
    return null;
}
