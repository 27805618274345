import styled from '@emotion/styled';

export const StyledMonthsContainer = styled.div`
    width: 140px;
    height: 70px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 2px 0 0 9px;
`;

export const StyledMonth = styled.div`
    font: 12px Arial, sans-serif;
    height: 18px;
    width: 40px;
    margin: 0px 1px;
    border: 2px solid #ffffff00;
    text-align: center;
    color: #666;
    &.numberCircle {
        border-radius: 10px;
        background: #fff;
        //border: 2px solid #666;
        background-color: #9ae6b4;
        color: #22543d;
    }
`;

export const StyledMonth2 = styled.div`
    font: 12px Arial, sans-serif;
    height: 35px;
    width: 35px;
    margin: 0px 1px;
    border: 2px solid #ffffff00;
    display: flex;
    justify-content: center;
    align-items: center;
    &.numberCircle {
        border-radius: 30px;
        background: #fff;
        border: 2px solid #666;
    }
`;
