import * as yup from 'yup';

export const registerFormSchema = yup.object().shape({
    firstName: yup.string().trim().required('Required'),
    lastName: yup.string().trim().required('Required'),
    emailAddress: yup.string().trim().email('Invalid Email').required('Required'),
    mobileNumber: yup.string().when([], {
        is: (value) => value?.length > 2,
        then: (schema) => schema.min(11, 'Invalid Mobile Number'),
        otherwise: (schema) => schema.min(0)
    }),
    address1: yup.string().trim(),
    city: yup.string().trim(),
    state: yup.string().trim(),
    postalCode: yup.string().trim(),
    password: yup
        .string()
        .trim()
        .min(8, 'Password must contain at least 8 characters.')
        .matches(
            /^.*(?=.{8,})((?=.*[!\-_<.>?]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            'Password must contain at least one uppercase, one number and one special case character.'
        )
        .required('Required'),
    confirmPassword: yup
        .string()
        .trim()
        .when('password', {
            is: (password) => (password && password.length > 0 ? true : false),
            then: yup.string().oneOf([yup.ref('password')], "Password doesn't match")
        })
        .required('Required'),
    acceptedTerms: yup.boolean().oneOf([true], 'You must accept Terms & Conditions')
});
