import styled from '@emotion/styled';

export const StyledCheckBox = styled.div`
    .inline {
        display: inline;
    }
    p {
        vertical-align: sub;
    }
`;

export const StyledTextInput = styled.div`
    display: flex;
    margin: 0 16px 10px 0;
    flex-direction: column;
    height: 75px;
    min-width: 80px;
    width: 100%;
    align-items: left;
    &.fade {
        opacity: 0;
        transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
        visibility: hidden;
    }
    &.fade {
        opacity: 1;
        visibility: visible;
        transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
    }
`;

export const StyledLabel = styled.label`
    margin-bottom: 5px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    display: inline-block;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    margin-bottom: 0.125rem;
`;

export const StyledError = styled.div`
    color: rgb(220, 53, 69);
    font-size: 0.9em;
`;

export const RequiredField = styled.div`
    color: red;
    display: ${(props) => (props.isRequired ? 'inline-block' : 'none')};
`;
