import styled from '@emotion/styled';

export const modalStyles = {
    overlay: {
        zIndex: 999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        top: null,
        bottom: null,
        left: null,
        right: null,
        maxWidth: '1000px',
        borderRadius: '8px',
        boxShadow: '3px 3px 10px -1px rgba(0,0,0,0.3)'
    }
};

export const MenuContainer = styled.div`
    .dx-menu-item,
    .dx-menu-item-expanded {
        background: #3a3a3c;
        color: white;
        vertical-align: middle;
        border-radius: 4px;
    }
`;

export const MenuText = styled.div`
    vertical-align: middle;
    display: inline;
    padding: 5px;
    i {
        color: white;
        vertical-align: middle;
        margin-left: 5px;
    }
`;

export const Item = styled.div`
    padding: 4px;
    &:hover {
        background: gray;
    }
`;
