import { Route, createRoutesFromChildren, Outlet, Navigate } from 'react-router-dom';
import { Global, ThemeProvider } from '@emotion/react';
import { StoreProvider, useStoreState } from 'easy-peasy';
import styled from '@emotion/styled';
import { global } from './styles';
import theme from '../../theme';
import NotFound from '../NotFound';
import Auth from '../Auth';
import store from '../../store';
import { CoreProductionPage } from '../CoreProduction';
import BillingCarrier from '../BillingCarrier';
import MySubscription from '../MySubscription';
import GrowthMeasurement, { GrowthPlanMeasurementYearSelector } from '../GrowthMeasurement';
import Portal from '../Portal';
import HelpPage from '../HelpPage';
import SplashScreen from '../SplashScreen';
import SessionExpirationHandler from '../SessionExpirationHandler';
import ServerErrorHandler from '../ServerErrorHandler';
import Register from '../Register';
import MyProfile from '../MyProfile';
import Users from '../Users';
import SubscriptionSelector from '../SubscriptionSelector';
import FeatureFlags from '../FeatureFlags';
import { FeatureRequired } from './featureRoute';
import { REPORTS } from '../../constants';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import '@agencykpi/components/dist/main.css';
import { lazy, Suspense } from 'react';
import Loader from '../shared/Loader';
import { ErrorPage } from '../shared/ErrorPage';
import YearSelector from '../YearSelector';
import { handleReportRouteFallback, ReportFallback } from '../EmbededReportFallback';

const DashboardWrapper = styled.div`
    //display: flex;
    //min-height: 100vh;
    height: 100%;
    position: relative;
`;

const EmbeddedReport = lazy(() => import('../EmbeddedReport'));

function useUser() {
    return useStoreState((state) => state.user);
}

function NoSubscriptionPage() {
    const user = useUser();
    let subscriptionStatus = 'No Subscription';
    if (user.subscriptions && user.subscriptions[0]) {
        subscriptionStatus = user.subscriptions[0].status;
    }

    if (subscriptionStatus !== 'Active' || !user.bypassExternalSubscriber) {
        return <SplashScreen agency={user.agency} />;
    } else {
        return <Navigate to="/production" />;
    }
}

function UserPagesLayout() {
    const user = useUser();
    return (
        <FeatureFlags user={user}>
            <DashboardWrapper>
                <Portal>
                    <Outlet />
                </Portal>
                {user && <SessionExpirationHandler />}
                {user && <ServerErrorHandler />}
            </DashboardWrapper>
        </FeatureFlags>
    );
}
function MyProfilePage() {
    const user = useUser();
    return <MyProfile userUuid={user.userUuid} />;
}
function UsersPage() {
    const user = useUser();
    return <Users user={user} />;
}
function MySubscriptionPage() {
    const user = useUser();
    return <MySubscription currentUser={user} agencyUuid={user.agency.uuid} />;
}
function GrowthMeasurementPage() {
    const user = useUser();
    return (
        <FeatureRequired flags={['growthMeasurement']}>
            <GrowthMeasurement agencyUuid={user.agencyUuid} agency={user.agency} />
        </FeatureRequired>
    );
}

function AuthenticatedPage() {
    return (
        <Auth>
            <Outlet />
        </Auth>
    );
}

export const appRoutes = createRoutesFromChildren(
    <Route path="/" element={<Root />} ErrorBoundary={ErrorPage}>
        <Route path="register" element={<Register />} />
        <Route path="not-found" element={<NotFound />} />
        <Route element={<AuthenticatedPage />}>
            <Route path="subscriptions" element={<SubscriptionSelector />} />
            <Route path="no-subscription" element={<NoSubscriptionPage />} />
            <Route path="helpDesk" element={<HelpPage />} />
            <Route element={<UserPagesLayout />}>
                <Route path="production" element={<CoreProductionPage />} />
                <Route
                    path="scorecards"
                    element={
                        <FeatureRequired
                            fallback={<ReportFallback reportKey={REPORTS.AGENCY_PRODUCTION.key} />}
                            flags={[REPORTS.AGENCY_PRODUCTION.flag]}
                        >
                            <Navigate replace to="/agency-production" />
                        </FeatureRequired>
                    }
                    handle={handleReportRouteFallback(REPORTS.AGENCY_PRODUCTION.key)}
                />
                <Route
                    path="billingcarriers/*"
                    element={<BillingCarrier />}
                    handle={{ headerChildren: <YearSelector /> }}
                />
                <Route
                    path="dataviz"
                    lazy={async () => {
                        const imported = await import('../DataViz');
                        return { Component: imported.default };
                    }}
                />
                <Route path="success" element={<div>Success!</div>} />
                <Route path="cancel" element={<div>Transaction canceled</div>} />
                <Route path="my-profile" element={<MyProfilePage />} />
                <Route path="users" element={<UsersPage />} />
                <Route path="my-subscription" element={<MySubscriptionPage />} />
                <Route
                    path="growth-measurement"
                    element={<GrowthMeasurementPage />}
                    handle={{
                        headerChildren: <GrowthPlanMeasurementYearSelector />
                    }}
                />
                {Object.keys(REPORTS).map((key) => (
                    <Route
                        key={REPORTS[key].key}
                        path={REPORTS[key].path}
                        element={
                            <FeatureRequired flags={[REPORTS[key].flag]}>
                                <Suspense fallback={<Loader />}>
                                    <EmbeddedReport
                                        fallback={<ReportFallback reportKey={REPORTS[key].key} />}
                                        identifier={REPORTS[key].key}
                                    />
                                </Suspense>
                            </FeatureRequired>
                        }
                        handle={handleReportRouteFallback(REPORTS[key].key)}
                    />
                ))}
            </Route>
        </Route>
    </Route>
);

function Root() {
    return (
        <StoreProvider store={store}>
            <ThemeProvider theme={theme}>
                <Global styles={global} />
                <Outlet />
            </ThemeProvider>
        </StoreProvider>
    );
}
