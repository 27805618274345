import { useState } from 'react';
import { Button } from '@agencykpi/components';
import { Grid, Typography } from '@mui/material';
import * as styles from './styles';
import InviteUser from './InviteUser';
import ActiveUsers from './ActiveUsers';
import PendingInvites from './PendingInvites';
import { useQuery } from '@tanstack/react-query';
import { queries } from '../../utils';
import PropTypes from 'prop-types';

const Users = ({ user }) => {
    const [currentView, setCurrentView] = useState('active');
    const [refetch, setRefetch] = useState(false);
    const [isInviting, setIsInviting] = useState(false);
    const { data: isAdmin } = useQuery(queries.permission(user, 'read', 'admin'));

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} css={styles.title}>
                    <Typography variant="h5">Users</Typography>
                </Grid>
                {isAdmin && (
                    <>
                        <InviteUser
                            setIsInviting={setIsInviting}
                            isInviting={isInviting}
                            setRefetch={setRefetch}
                        />
                        <Grid item xs={12}>
                            <Button
                                primary={currentView === 'active'}
                                style={{ marginRight: 10 }}
                                onClick={() => setCurrentView('active')}
                            >
                                Active Users
                            </Button>
                            <Button
                                primary={currentView === 'pending'}
                                style={{ margin: 0 }}
                                onClick={() => setCurrentView('pending')}
                            >
                                Pending Invites
                            </Button>
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    {currentView === 'active' ? (
                        <ActiveUsers adminUuid={user.userUuid} agencyUuid={user.agency.uuid} />
                    ) : (
                        <PendingInvites
                            agencyUuid={user.agency.uuid}
                            refetch={refetch}
                            setRefetch={setRefetch}
                            isInviting={isInviting}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
};

Users.propTypes = {
    user: PropTypes.shape({
        userUuid: PropTypes.string,
        agency: PropTypes.shape({
            uuid: PropTypes.string
        })
    })
};

export default Users;
