import { getConfigValues } from './config';

export async function initializeOnRamp(user) {
    if (!user) return;

    const { config } = await getConfigValues();
    if (!config.onRamp.enabled) return;

    // prettier-ignore
    (function (w, d, s, o, f, js, fjs) {
        w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
        js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
        js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
    }(window, document, 'script', '_onramp_widget', 'https://app.onramp.us/api/embed_widget/static/onramp_inapp_sdk_v1.js'));
    window['_onramp_widget']({ organization: config.onRamp.orgId, user, scheme: 'plaintext' });
}
