import AverageMeter from './Metrics/AverageMeter';
import { getCoreSummary } from '../../utils';
import Loader from '../shared/Loader';
import { useAgencySummary, useByCarrierList, useNetworkSummaryStats } from './queries';
import PropTypes from 'prop-types';

export default function AgencySummary({ agencyUuid, policyType, premiumFields, timeframe }) {
    const { writtenPremium, baseCommission, additionalCommission, totalCommission } = premiumFields;
    const carrierListQuery = useByCarrierList(agencyUuid, timeframe, [], policyType);
    const coreSummary = getCoreSummary({
        byCarrier: carrierListQuery.data,
        policyType,
        writtenPremium,
        baseCommission,
        additionalCommission,
        totalCommission
    });
    const networkSummaryQuery = useNetworkSummaryStats(timeframe, policyType);
    const agencySummaryQuery = useAgencySummary(agencyUuid, timeframe, policyType);
    const agencySummary = agencySummaryQuery.data;

    const isLoading =
        carrierListQuery.isLoading || agencySummaryQuery.isLoading || networkSummaryQuery.isLoading;
    return (
        <div className="section meter-summary">
            {isLoading ? (
                <Loader overlay size="30" />
            ) : (
                <>
                    <AverageMeter
                        label="Written Premium"
                        averages={networkSummaryQuery.data}
                        agency={agencySummary}
                        field={writtenPremium}
                        secondary="core/noncore"
                        coreSummary={{
                            core: coreSummary.core.premium,
                            corePercent:
                                (coreSummary.core.premium * 100) / coreSummary.totalPremium,
                            nonCore: coreSummary.nonCore.premium,
                            nonCorePercent:
                                (coreSummary.nonCore.premium * 100) / coreSummary.totalPremium
                        }}
                    />
                    <AverageMeter
                        label="Base Commission"
                        averages={networkSummaryQuery.data}
                        agency={agencySummary}
                        field={baseCommission}
                        secondary="baseCommissionPercent"
                        coreSummary={{
                            core: coreSummary.core.baseCommission,
                            corePercent:
                                (coreSummary.core.baseCommission * 100) / coreSummary.core.premium,
                            nonCore: coreSummary.nonCore.baseCommission,
                            nonCorePercent:
                                (coreSummary.nonCore.baseCommission * 100) /
                                coreSummary.nonCore.premium
                        }}
                    />
                    <AverageMeter
                        label="Total Commission"
                        averages={networkSummaryQuery.data}
                        agency={agencySummary}
                        field={totalCommission}
                        secondary="totalCommissionPercent"
                        coreSummary={{
                            core: coreSummary.core.totalCommission,
                            corePercent:
                                (coreSummary.core.totalCommission * 100) / coreSummary.core.premium,
                            nonCore: coreSummary.nonCore.totalCommission,
                            nonCorePercent:
                                (coreSummary.nonCore.totalCommission * 100) /
                                coreSummary.nonCore.premium
                        }}
                    />
                </>
            )}
        </div>
    );
}

AgencySummary.propTypes = {
    agencyUuid: PropTypes.string.isRequired,
    policyType: PropTypes.string.isRequired,
    premiumFields: PropTypes.object,
    timeframe: PropTypes.oneOfType([
        PropTypes.shape({ year: PropTypes.number.isRequired }),
        PropTypes.shape({
            startDate: PropTypes.instanceOf(Date),
            endDate: PropTypes.instanceOf(Date)
        })
    ]).isRequired
};
