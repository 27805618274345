import styled from '@emotion/styled';
export const Container = styled.div`
    position: relative;
    display: inline-block;
    margin-right: 5px;
`;

export const Content = styled.div`
    position: absolute;
    padding: 15px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    border: 1px solid #0000001a;
    opacity: 1;
    z-index: 999;
    right: 0;
    margin-top: 5px;
    border-radius: 6px;
`;

export const Button = styled.button`
    text-align: left;
    font: normal normal normal 15px/18px Roboto;
    letter-spacing: 0px;
    color: ${(props) => props.color};
    border-radius: 6px;
    opacity: 1;
    height: 30px;
    background: ${(props) => props.background} 0% 0% no-repeat padding-box;
    border: 1px solid ${(props) => props.background};
    text-align: center;
    font: normal normal normal 14px/16px Rubik;
    letter-spacing: 0px;
    cursor: pointer;
    padding: 5px 10px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
`;

export const TimeframeButton = styled.button`
    display: flex;
    justify-content: end;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: ${(props) => `1px solid ${props.color}`};
    opacity: 1;
    font: normal normal medium 15px/18px Roboto;
    letter-spacing: 0px;
    color: #000000;
    border-radius: 6px;
    cursor: pointer;
    div {
        padding: 10px 15px;
        margin: -1px;
    }
    svg {
        margin: auto 10px;
        margin-left: 5px;
    }
    &:disabled {
        background-color: #ebebe4;
        cursor: not-allowed;
    }
`;

export const TimeframeText = styled.div`
    border-left: ${(props) => `1px solid ${props.color}`};
    opacity: 1;
`;
