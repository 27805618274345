import { getYTDEndDate, isYearToDate } from './index';
import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { ELIGIBLE_YEAR } from '../constants';

/**
 * Encoded timeframes use a subset of ISO-8601 to format ranges, with
 * a special exception for "YTD" because of special cases for manual entry
 * and the current year. Durations in ISO-8601 typically are encoded using `/`
 * as a separator, but because these values are going ino URLs we use the
 * alternate separator `--`.
 *
 * - Monthly: "YYYY-MM"
 * - 12MM: "P12M--YYYY-MM"
 * - 24MM: "P24M--YYYY-MM"
 * - 36MM: "P36M--YYYY-MM"
 * - Custom: "YYYY-MM--YYYY-MM"
 * - YTD: "ytd-YYYY"
 */

/**
 * @interface Timeframe
 * @property {string} name
 * @property {string} displayText
 * @property {Date} startDate
 * @property {Date} endDate
 * @property {boolean} isYtd
 */

/** @type {Timeframe} */
const defaultTimeframe = getYTDTimeframe(`ytd-${ELIGIBLE_YEAR}`);

/**
 *
 * @return {[Timeframe, function(Timeframe): void]}
 */
export function useTimeframe() {
    const [searchParams, setSearchParams] = useSearchParams();
    const encodedTimeframe = searchParams.get('timeframe');
    const queryParamTimeframe = useMemo(() => {
        return [
            deserializeTimeframe(encodedTimeframe) || defaultTimeframe,
            (timeframe) => {
                setSearchParams((searchParams) => {
                    searchParams.set('timeframe', serializeTimeframe(timeframe));
                    return searchParams;
                });
            }
        ];
    }, [encodedTimeframe]);

    return queryParamTimeframe;
}

/**
 * @param {number} decodedMonths
 * @param {number} fromIndex
 * @param {string} name
 * @param {string} encodedTimeframe
 * @return {Timeframe}
 */
function getMMTimeframe(decodedMonths, fromIndex, name, encodedTimeframe) {
    const endYear = decodeYear(encodedTimeframe, fromIndex);
    const endMonth = decodeMonth(encodedTimeframe, fromIndex + 5);
    const endDate = lastDayOfMonth(endYear, endMonth);
    const startDate = firstDayOfMonth(endYear, endMonth - decodedMonths + 1);
    return {
        name,
        displayText: `${formatMonthYear(startDate)} – ${formatMonthYear(endDate)}`,
        isYtd: true,
        startDate,
        endDate
    };
}
/**
 * @param {string} encodedTimeframe
 * @return {Timeframe}
 */
function getYTDTimeframe(encodedTimeframe) {
    const year = decodeYear(encodedTimeframe, 4);
    let endDate = getYTDEndDate();
    endDate.setDate(0); // move to last day of previous month
    if (year !== endDate.getFullYear()) {
        endDate = lastDayOfMonth(year, 12);
    }
    const startDate = firstDayOfMonth(year, 1);
    return {
        name: 'YTD',
        displayText: `${formatMonthYear(startDate)} – ${formatMonthYear(endDate)}`,
        isYtd: true,
        startDate,
        endDate
    };
}

/** @type{Record<string, function(string): Timeframe>} */
const monthMoverPeriods = {
    'P12M--': getMMTimeframe.bind(undefined, 12, 6, '12MM'),
    'P24M--': getMMTimeframe.bind(undefined, 24, 6, '24MM'),
    'P36M--': getMMTimeframe.bind(undefined, 36, 6, '36MM')
};

/**
 * @param {string} encodedTimeframe
 * @return {Timeframe|null}
 */
export function deserializeTimeframe(encodedTimeframe) {
    if (!encodedTimeframe || encodedTimeframe === 'unknown') {
        return null;
    }
    if (encodedTimeframe.startsWith('ytd-')) {
        return getYTDTimeframe(encodedTimeframe);
    }
    if (encodedTimeframe[0] === 'P') {
        const period = encodedTimeframe.substring(0, 6);
        const handler = monthMoverPeriods[period];
        if (handler) {
            return handler(encodedTimeframe);
        }
    }

    if (encodedTimeframe.length === 16) {
        return getCustomTimeframe(encodedTimeframe);
    }
    if (encodedTimeframe.length === 7) {
        return getMonthlyTimeframe(encodedTimeframe);
    }

    return null;
}

/**
 * @param {string} encodedTimeframe
 * @return {Timeframe}
 */
function getCustomTimeframe(encodedTimeframe) {
    // "YYYY-MM--YYYY-MM" - encodedTimeframe
    //  ^0   ^5  ^9   ^14 - start indexes
    const startDate = firstDayOfMonth(
        decodeYear(encodedTimeframe, 0),
        decodeMonth(encodedTimeframe, 5)
    );
    const endDate = lastDayOfMonth(
        decodeYear(encodedTimeframe, 9),
        decodeMonth(encodedTimeframe, 14)
    );
    return {
        name: 'Custom',
        displayText: `${formatMonthYear(startDate)} – ${formatMonthYear(endDate)}`,
        isYtd: false,
        startDate,
        endDate
    };
}

/**
 * @param {string} encodedTimeframe
 * @return {Timeframe}
 */
function getMonthlyTimeframe(encodedTimeframe) {
    // "YYYY-MM" - encodedTimeframe
    //  ^0   ^5 - start indexes
    const year = decodeYear(encodedTimeframe, 0);
    const month = decodeMonth(encodedTimeframe, 5);
    const startDate = firstDayOfMonth(year, month);
    const endDate = lastDayOfMonth(year, month);
    return {
        name: 'Monthly',
        displayText: `${formatMonthYear(startDate)}`,
        isYtd: false,
        startDate,
        endDate
    };
}

function decodeYear(encoded, startIndex) {
    return parseInt(encoded.substring(startIndex, startIndex + 4), 10);
}
function decodeMonth(encoded, startIndex) {
    return parseInt(encoded.substring(startIndex, startIndex + 2), 10);
}

function lastDayOfMonth(year, month) {
    // month here is actually month - 1 + 1, which cancels out.
    // We're asking for the 0th day of the next month. Keep it weird JS.
    return new Date(year, month, 0);
}
function firstDayOfMonth(year, month) {
    return new Date(year, month - 1, 1);
}

/**
 * @param {Timeframe} timeframe
 * @return {string}
 */
export function serializeTimeframe(timeframe) {
    if (isYearToDate(timeframe.startDate, timeframe.endDate)) {
        return `ytd-${timeframe.startDate.getFullYear()}`;
    }
    if (timeframe.name === '12MM') {
        return `P12M--${encodeYearMonth(timeframe.endDate)}`;
    }
    if (timeframe.name === '24MM') {
        return `P24M--${encodeYearMonth(timeframe.endDate)}`;
    }
    if (timeframe.name === '36MM') {
        return `P36M--${encodeYearMonth(timeframe.endDate)}`;
    }
    if (timeframe.name === 'Monthly') {
        return `${encodeYearMonth(timeframe.startDate)}`;
    }
    return `${encodeYearMonth(timeframe.startDate)}--${encodeYearMonth(timeframe.endDate)}`;
}
function encodeYearMonth(date) {
    // only the YYYY-MM part of the date
    return date.toISOString().substring(0, 7);
}

function formatMonthYear(date) {
    return date.toLocaleDateString(undefined, {
        month: 'short',
        year: 'numeric',
        timeZone: 'UTC'
    });
}
