import { request } from './request';

export function activePlanYearsQuery() {
    return {
        queryKey: ['plan-measurement-active-years'],
        async queryFn() {
            const { body } = await request.get('/api/growth-measurement/active-plan-years');
            return body;
        },
        staleTime: 5 * 60 * 1000
    };
}

export function networkPlanQuery(planId) {
    return {
        queryKey: ['network-plan', { planId }],
        async queryFn({ queryKey }) {
            const [, { planId }] = queryKey;
            const { body } = await request.get(`/api/growth-measurement/active-plans/${planId}`);
            return body;
        },
        enabled: Boolean(planId)
    };
}
