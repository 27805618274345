import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { SelectableListSection } from './styles';
import PropTypes from 'prop-types';

export default function SelectableList({
    selectedItems = [],
    setSelectedItems,
    itemType,
    itemTypeDynamic,
    modalRows,
    setSelectedItemsIds,
    selectedItemsIds,
    showPagination,
    showSelectAllCheckbox
}) {
    const selectAllCheckbox = useRef(null);
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [rows, setRows] = useState(modalRows);

    useEffect(() => {
        let aux = modalRows;
        if (searchText.length > 0) {
            aux = modalRows.filter((r) =>
                r.carrierName.toLowerCase().includes(searchText.toLowerCase())
            );
            setPage(0);
        }

        const itemsSelected = selectedItems.length > 0;
        const notAllSelected = selectedItems.length < aux.length;
        if (showSelectAllCheckbox) {
            selectAllCheckbox.current.indeterminate = itemsSelected && notAllSelected;
        }

        setRows(aux);
    }, [searchText]);

    const onItemSelect = (row) => {
        const name = row.carrierName;
        const uuid = row.carrierUUID;

        const selectedIndex = selectedItems.indexOf(name);
        const selectedIndexIds = selectedItemsIds.indexOf(uuid);
        let newSelected = [];
        let newSelectedIds = [];

        if (selectedIndex === -1 || selectedIndexIds === -1) {
            newSelected = newSelected.concat(selectedItems, name);
            newSelectedIds = newSelectedIds.concat(selectedItemsIds, uuid);
        } else if (selectedIndex === 0 || selectedIndexIds === 0) {
            newSelected = newSelected.concat(selectedItems.slice(1));
            newSelectedIds = newSelectedIds.concat(selectedItemsIds.slice(1));
        } else if (
            selectedIndex === selectedItems.length - 1 ||
            selectedIndexIds === selectedItemsIds.length - 1
        ) {
            newSelected = newSelected.concat(selectedItems.slice(0, -1));
            newSelectedIds = newSelectedIds.concat(selectedItemsIds.slice(0, -1));
        } else if (selectedIndex > 0 || selectedIndexIds > 0) {
            newSelected = newSelected.concat(
                selectedItems.slice(0, selectedIndex),
                selectedItems.slice(selectedIndex + 1)
            );

            newSelectedIds = newSelectedIds.concat(
                selectedItemsIds.slice(0, selectedIndexIds),
                selectedItemsIds.slice(selectedIndexIds + 1)
            );
        }

        const itemsSelected = newSelected.length > 0;
        const notAllSelected = newSelected.length < rows.length;
        if (showSelectAllCheckbox) {
            selectAllCheckbox.current.indeterminate = itemsSelected && notAllSelected;
        }

        setSelectedItems(newSelected);
        setSelectedItemsIds(newSelectedIds);
    };

    const onSelectAllClick = () => {
        selectAllCheckbox.current.checked = !selectAllCheckbox.current.checked;

        if (selectAllCheckbox.current.checked) {
            selectAllCheckbox.current.indeterminate = false;
            const newSelecteds = rows.map((n) => n.carrierName);
            const newSelectedsIds = rows.map((n) => n.carrierUUID);
            setSelectedItems(newSelecteds);
            setSelectedItemsIds(newSelectedsIds);
            return;
        }

        setSelectedItems([]);
        setSelectedItemsIds([]);
    };

    const processData = (rows) => {
        if (showPagination) {
            return rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
        }
        return rows;
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selectedItems.indexOf(name) !== -1;

    return (
        <SelectableListSection>
            <header>
                <FontAwesomeIcon icon={faSearch} />
                <input
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder={`Search for ${itemType.toLowerCase()}...`}
                    type="search"
                    className="search-input"
                    value={searchText}
                />
            </header>

            <ol style={{ height: '220px' }}>
                {showSelectAllCheckbox && (
                    <li onClick={onSelectAllClick}>
                        <input
                            checked={rows.length > 0 && rows.length <= selectedItems.length}
                            id="select-all"
                            readOnly
                            ref={selectAllCheckbox}
                            type="checkbox"
                        />
                        <label htmlFor="select-all">All {itemType}</label>
                    </li>
                )}

                {processData(rows).map((row) => {
                    const isItemSelected = isSelected(row.carrierName);

                    return (
                        <li
                            data-selected={isItemSelected}
                            key={row.carrierUUID}
                            onClick={() => onItemSelect(row)}
                        >
                            <input
                                checked={isItemSelected}
                                id={row.carrierUUID}
                                readOnly
                                type="checkbox"
                            />
                            <label htmlFor={row.carrierUUID}>{row.carrierName}</label>
                        </li>
                    );
                })}
            </ol>
            {showPagination && (
                <footer>
                    <div className="totals" data-total={selectedItems.length}>
                        {selectedItems.length} {itemTypeDynamic.toLowerCase()} selected
                    </div>

                    <div className="control">
                        <span>Rows per page:</span>
                        <select onChange={handleChangeRowsPerPage}>
                            <option>5</option>
                            <option>10</option>
                            <option>25</option>
                        </select>
                    </div>

                    <div className="control">
                        <span>
                            {`${page * rowsPerPage + 1}-${
                                (page + 1) * rowsPerPage > rows.length
                                    ? rows.length
                                    : (page + 1) * rowsPerPage
                            } of ${rows.length}`}
                        </span>
                        <div className="buttons">
                            <button
                                disabled={page == 0}
                                onClick={() => {
                                    setPage(page - 1);
                                }}
                            >
                                <FontAwesomeIcon icon={faAngleLeft} size="lg" />
                            </button>
                            <button
                                disabled={page == Math.floor(rows.length / rowsPerPage)}
                                onClick={() => {
                                    setPage(page + 1);
                                }}
                            >
                                <FontAwesomeIcon icon={faAngleRight} size="lg" />
                            </button>
                        </div>
                    </div>
                </footer>
            )}
        </SelectableListSection>
    );
}

SelectableList.propTypes = {
    selectedItems: PropTypes.array,
    setSelectedItems: PropTypes.func,
    itemType: PropTypes.string,
    itemTypeDynamic: PropTypes.string,
    modalRows: PropTypes.array,
    setSelectedItemsIds: PropTypes.func,
    selectedItemsIds: PropTypes.array,
    showPagination: PropTypes.bool,
    showSelectAllCheckbox: PropTypes.bool
};
