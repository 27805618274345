import { useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import Modal from 'react-modal';
import { LoginLink } from '../Login/styles';
import { Centered } from '../shared';

const modalStyles = {
    overlay: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        top: null,
        bottom: null,
        left: null,
        right: null,
        maxWidth: '700px'
    }
};

const SessionExpirationHandler = () => {
    const { sessionExpired, user, refreshTokenRevoked, serverError } = useStoreState(
        (state) => state
    );
    useEffect(() => {
        if (refreshTokenRevoked) {
            window.location.href = `/logout`;
        }
    }, [refreshTokenRevoked]);

    return (
        <>
            {user && user.userType && !refreshTokenRevoked && !serverError.message && (
                <Modal isOpen={sessionExpired} style={modalStyles}>
                    <Centered>
                        <h3>Your session has expired. Please refresh or click below to log in.</h3>

                        <LoginLink href="auth/auth0">Go to login</LoginLink>
                    </Centered>
                </Modal>
            )}
        </>
    );
};

export default SessionExpirationHandler;
