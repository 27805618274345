import classNames from 'classnames';

import { StyledPolicyPill, StyledPolicyContainer } from './styles';

const ALL_POLICIES = ['CL', 'PL', 'BOND', 'CROP'];

const Policies = (props) => {
    let policyStatusObj = props.policyStatusObj;
    policyStatusObj = ALL_POLICIES.map((policyType) => {
        let obj = { type: policyType, current: false, exists: false };
        policyStatusObj.map((policyObj) => {
            if (policyObj.type === policyType) {
                obj = { type: policyType, current: policyObj.current, exists: true };
            }
        });
        return obj;
    });

    return (
        <StyledPolicyContainer>
            {policyStatusObj.map((element) => {
                const policyClasses = classNames({
                    exists: element.exists,
                    current: element.current
                });
                return (
                    <StyledPolicyPill className={policyClasses}>{element.type}</StyledPolicyPill>
                );
            })}
        </StyledPolicyContainer>
    );
};

export default Policies;
