import Loader from 'react-loader-spinner';
import { Centered } from './index';

export default (props) => {
    props = {
        type: 'Oval',
        color: '#A0AEC0',
        ...props
    };
    return (
        <Centered>
            <Loader {...props} />
        </Centered>
    );
};
