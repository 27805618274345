import { CurrencyCell } from '../../../utils/tableUtils';
import { StyledDollarCell } from './styles';
import Policies from '../Policies';
import Months from '../Months';
import PropTypes from 'prop-types';

export const getTableColumns = (type, renderDeleteCarrier, handleCompanyRowClick) => {
    const LinkRow = ({ children, row }) => {
        return (
            <div
                style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
                onClick={() => handleCompanyRowClick(row)}
            >
                {children}
            </div>
        );
    };
    LinkRow.propTypes = {
        children: PropTypes.node,
        row: PropTypes.object
    };

    switch (type) {
        case 'entries':
            return [
                {
                    Header: 'Date',
                    accessor: 'date'
                },
                {
                    Header: 'Written Premium YTD',
                    accessor: 'dwp',
                    Cell: ({ value }) => {
                        return <StyledDollarCell>{CurrencyCell({ value })}</StyledDollarCell>;
                    }
                },
                {
                    Header: 'New Business YTD',
                    accessor: 'newDwp',
                    Cell: ({ value }) => {
                        return <StyledDollarCell>{CurrencyCell({ value })}</StyledDollarCell>;
                    }
                },
                {
                    Header: '12 Month Mover',
                    accessor: '12Mm_dwp',
                    Cell: ({ value }) => {
                        return <StyledDollarCell>{CurrencyCell({ value })}</StyledDollarCell>;
                    }
                },
                {
                    Header: 'Incurred Loss YTD',
                    accessor: 'incurred',
                    Cell: ({ value }) => {
                        return <StyledDollarCell>{CurrencyCell({ value })}</StyledDollarCell>;
                    }
                },
                {
                    Header: 'Earned Premium YTD',
                    accessor: 'earned',
                    Cell: ({ value }) => {
                        return <StyledDollarCell>{CurrencyCell({ value })}</StyledDollarCell>;
                    }
                },
                {
                    id: 'delete',
                    Header: '',
                    resizable: false,
                    maxWidth: 60,
                    anccessor: 'delete',
                    Cell: ({ row }) => {
                        return renderDeleteCarrier(row);
                    }
                }
            ];
        case 'policies':
            return [
                {
                    Header: 'Line of Business',
                    accessor: 'policyType'
                },
                {
                    Header: 'Written Premium',
                    accessor: 'dwp',
                    Cell: ({ value }) => {
                        return CurrencyCell({ value });
                    }
                },
                {
                    Header: 'Last Updated',
                    accessor: 'lastUpdated'
                }
            ];
        case 'carriers':
            return [
                {
                    Header: 'Carrier',
                    accessor: 'companyName',
                    Cell: (row) => {
                        return (
                            <LinkRow row={row.original}>
                                <span
                                    style={{
                                        whiteSpace: 'normal',
                                        width: '90%',
                                        textAlign: 'center'
                                    }}
                                >
                                    {row.value}
                                </span>
                            </LinkRow>
                        );
                    }
                },
                {
                    Header: 'Written Premium (YTD)',
                    accessor: 'dwp',
                    Cell: (row) => {
                        const value = row.value;
                        return <LinkRow row={row.original}>{CurrencyCell({ value })}</LinkRow>;
                    }
                },
                {
                    Header: 'Line of Business',
                    accessor: 'policyStatusObj',
                    Cell: (row) => {
                        return (
                            <LinkRow row={row.original}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Policies policyStatusObj={row.value}></Policies>
                                </div>
                            </LinkRow>
                        );
                    }
                },
                {
                    Header: 'Months Completed',
                    accessor: 'groupedPoliciesByCode',
                    Cell: (row) => {
                        return (
                            <LinkRow row={row.original}>
                                <Months policies={row.value}></Months>
                            </LinkRow>
                        );
                    }
                },
                {
                    Header: 'Delete',
                    accessor: 'policyCount',
                    Cell: (row) => {
                        return renderDeleteCarrier(row.original);
                    }
                }
            ];
        default:
            break;
    }
};
