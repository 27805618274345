import { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { useStoreState, useStoreActions } from 'easy-peasy';
import {
    Input,
    MultiSelect,
    Button,
    TimelineSelector,
    Checkbox,
    Disclaimer
} from '@agencykpi/components';
import { Tooltip } from '@mui/material';
import {
    CoreProductionTableWrap,
    CoreProductionWrap,
    UploadContainer,
    TableDataApplyButtonWrap,
    FilterContainer,
    DashboardControls,
    CarrierContainer,
    Filters,
    FiltersDisplay
} from './styles';
import CoreProductionSummary from './CoreProductionSummary';
import CoreProductionList from './CoreProductionList';
import PolicyTypeSelector from '../PolicyTypeSelector/PolicyTypeSelector';
import SelectedSummary from './SelectedSummary';
import Loader from '../shared/Loader';
import { TOOL_TIPS } from '../../constants/tooltips';
import { processNetworkCPR, removeIncompleteData, setMissingDataFlags } from './utils';
import FiltersSelector from '../shared/FiltersSelector';
import CarriersFilters from '../shared/CarriersFilters';
import CollaborationMenu from '../CollaborationMenu';
import { cloneDeep, isEmpty } from 'lodash-es';
import { Icon } from '../../icons/icons';
import { isCalenderYear, isCustomCalendarYear } from '../../utils/index';
import { TIMELINE_OPTIONS } from '../../constants/index';
import { TableWrap } from '../../styles/tableStyle';
import { ExpandAllButton } from '../shared/ExpandAllButton';
import {
    carriersQuery,
    getCoreProductionByAgency,
    getCoreProductionByCarrier,
    getCoreProductionReportedMonths
} from './requests';
import { useQueryClient } from '@tanstack/react-query';
import { useStandardPolicyTypes } from './hooks';
import { useTimeframe } from '../../utils/timeframes';
import PropTypes from 'prop-types';
import { request } from '../../utils/request';
import moment from 'moment/moment';

const CarrierTypeValue = {
    All: {
        key: 'All',
        composites: {
            isStandard: true,
            isMga: true,
            isCrop: true,
            isFlood: true,
            isSurety: true,
            isWorkersComp: true
        }
    },
    Carrier: {
        key: 'Carrier',
        composites: {
            isStandard: true,
            isMga: false,
            isCrop: true,
            isFlood: true,
            isSurety: true,
            isWorkersComp: true
        }
    },
    Wholesaler: {
        key: 'Wholesaler',
        composites: {
            isStandard: false,
            isMga: true,
            isCrop: false,
            isFlood: false,
            isSurety: false,
            isWorkersComp: false
        }
    }
};

const CoreProduction = () => {
    const year = useStoreState((state) => state.year);
    const [timeframe, setTimeframe] = useTimeframe();
    const [collaborationMode] = useStoreState((state) => [state.collaborationMode]);
    const [isLoading, setIsLoading] = useState(true);
    const [networkCPRs, setNetworkCPRs] = useState([]);
    const [networkCPRsWithoutIncomplete, setNetworkCPRsWithoutIncomplete] = useState([]);
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [policyType, setPolicyType] = useState('P&C');
    const [carrierType, setCarrierType] = useState(CarrierTypeValue.All);
    const [standardTypes, setStandardTypes] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const [selectedItems, setSelectedItems] = useState({});
    const [selectAll, setSelectAll] = useState(0);
    const [tableDataType, setTableDataType] = useState('carriers');
    const uuidType = tableDataType === 'carriers' ? 'carrierUuid' : 'agencyUuid';
    const [haveMissingCurrentData, setHaveMissingCurrentData] = useState(false);
    const [haveMissingPriorData, setHaveMissingPriorData] = useState(false);
    const [carrierReportedMonths, setCarrierReportedMonths] = useState(null);
    const [excludeIncompleteData, setExcludeIncompleteData] = useState(false);
    const [allExpanded, setAllExpanded] = useState(false);
    const [expandedRows, setExpandedRows] = useState([]);
    let selectedStandardTypes = [];

    const selectedFiltersRef = useRef([]);

    const [optionsList, setOptionsList] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [setServerError] = useStoreActions((actions) => [actions.setServerError]);
    const [warningMessage, setWarningMessage] = useState('');
    const [showIncompleteDisclaimer, setShowIncompleteDisclaimer] = useState(false);

    const { findPolicyTypeByCode, getClassificationLevelUuids } = useStandardPolicyTypes();

    const handleCarrierTypeSelector = (type) => {
        setRefetch(true);
        resetSelectedItems();
        switch (type) {
            case 'All':
                setCarrierType(CarrierTypeValue.All);
                return;
            case 'Carrier':
                setCarrierType(CarrierTypeValue.Carrier);
                return;
            case 'Wholesaler':
                setCarrierType(CarrierTypeValue.Wholesaler);
                return;
        }
    };
    function standardTypesItems() {
        const type = findPolicyTypeByCode(policyType);
        const children = type.children;
        return children.map((el) => ({ label: el.name, value: el.uuid }))
            .sort((a,b) => a.label > b.label ? 1 :(b.label > a.label ? -1 : 0))
    }

    function resetSelectedItems() {
        setSelectAll(0);
        setSelectedItems({});
    }

    async function fetchCarrierCPRs() {
        try {
            const levelUuid = getClassificationLevelUuids(policyType, false);
            let reportedData = {};

            switch (tableDataType) {
                case 'carriers': {
                    const byCarrier = await getCoreProductionByCarrier({
                        levelUuid,
                        timeframe,
                        standardTypes,
                        carrierComposites: carrierType.composites
                    });

                    try {
                        reportedData = await getCoreProductionReportedMonths({ timeframe });
                        setHaveMissingCurrentData(false);
                        setHaveMissingPriorData(false);
                        setCarrierReportedMonths(reportedData.carrierReportedMonths || null);
                    } catch (e) {
                        console.log('Missing month data not available.');
                    }

                    let hasMissingCurrentData = false;
                    let hasMissingPriorData = false;

                    const carrierList = Object.keys(byCarrier).map((carrierUuid) => {
                        const currentPolicyType = findPolicyTypeByCode(policyType);
                        let carrier;
                        if (
                            currentPolicyType.code &&
                            byCarrier[carrierUuid] &&
                            byCarrier[carrierUuid][currentPolicyType.code]
                        ) {
                            carrier = byCarrier[carrierUuid][currentPolicyType.code];
                        }

                        if (
                            reportedData.carrierReportedMonths &&
                            carrier &&
                            (carrier.earned !== 0 ||
                                carrier.priorEarned !== 0 ||
                                carrier.incurred !== 0 ||
                                carrier.newWrittenPremium !== 0 ||
                                carrier.writtenPremium !== 0 ||
                                carrier.priorWrittenPremium !== 0 ||
                                carrier.priorNewWrittenPremium !== 0)
                        ) {
                            if (!hasMissingPriorData || !hasMissingCurrentData) {
                                carrier = setMissingDataFlags(
                                    carrier,
                                    reportedData.carrierReportedMonths
                                );
                            }

                            if (!hasMissingCurrentData) {
                                hasMissingCurrentData = carrier.haveMissingCurrentData;
                            }

                            if (!hasMissingPriorData) {
                                hasMissingPriorData = carrier.haveMissingPriorData;
                            }
                        }
                        return {
                            ...byCarrier[carrierUuid],
                            carrierUuid
                        };
                    });

                    setHaveMissingCurrentData(hasMissingCurrentData);
                    setHaveMissingPriorData(hasMissingPriorData);
                    setData(carrierList);

                    if (networkCPRs.length === 0 && networkCPRsWithoutIncomplete.length === 0) {
                        setNetworkCPRs(
                            processNetworkCPR(
                                carrierList,
                                false,
                                findPolicyTypeByCode('P&C'),
                                reportedData.carrierReportedMonths || null
                            )
                        );

                        setNetworkCPRsWithoutIncomplete(
                            processNetworkCPR(
                                carrierList,
                                true,
                                findPolicyTypeByCode('P&C'),
                                reportedData.carrierReportedMonths || null
                            )
                        );
                    }
                    break;
                }

                case 'agencies': {
                    const byAgency = await getCoreProductionByAgency({
                        levelUuid,
                        timeframe,
                        standardTypes,
                        carrierComposites: carrierType.composites
                    });

                    const agencyList = Object.keys(byAgency).map((agencyUuid) => {
                        const agency = byAgency[agencyUuid];
                        const policy = agency?.[policyType];

                        const city = policy?.agencyCity ? `- ${agency[policyType].agencyCity}` : '';
                        const state = policy?.agencyState
                            ? `- ${agency[policyType].agencyState}`
                            : '';

                        // We only don't need to calculate missing data because the carriers list always loads first and it does the calculations. If we ever add the ability for the use to go straight to the agencies list, this will need to change.
                        if (policy) {
                            policy.haveMissingCurrentData = haveMissingCurrentData;
                            policy.haveMissingPriorData = haveMissingPriorData;
                        }

                        return {
                            ...agency,
                            agencyUuid,
                            name: `${agency.name} ${city} ${state}`,
                            haveMissingCurrentData,
                            haveMissingPriorData
                        };
                    });

                    setData(agencyList);
                    break;
                }
            }

            setIsLoading(false);
            setRefetch(false);
        } catch (e) {
            console.log(e);
            setServerError({
                message: 'Error loading by-carrier',
                hasLogut: false,
                hasContinue: true
            });
        }
    }

    const onOptionsChange = (data) => {
        if (
            data.selectedTimeframe !== TIMELINE_OPTIONS.YTD &&
            !isCalenderYear(data.selectedTimeframe) &&
            !isCustomCalendarYear(data.dropdownStartDate, data.dropdownEndDate)
        ) {
            setWarningMessage('*Manually entered Carrier Production Data will not be returned');
        } else {
            setWarningMessage('');
        }
    };
    const IconContent = () => {
        return <Icon type="timeframe-selector" iWidth="20px" iColor={'#000000'} />;
    };

    const processData = (info) => {
        return excludeIncompleteData
            ? removeIncompleteData(info, findPolicyTypeByCode(policyType))
            : info;
    };

    useEffect(() => {
        setNetworkCPRs([]);
        setNetworkCPRsWithoutIncomplete([]);
        setPolicyType('P&C');
        setStandardTypes([]);
        setIsLoading(true);
        handleCarrierTypeSelector('All');
    }, [timeframe]);

    useEffect(() => {
        if (!refetch) return;
        fetchCarrierCPRs();
    }, [refetch]);

    useEffect(() => {
        if (!allExpanded) setExpandedRows([]);
    }, [allExpanded, tableDataType]);

    let latestMonth = 0;
    let pcTotal = networkCPRs && networkCPRs.find((cpr) => cpr.policyType === 'P&C');

    if (pcTotal) {
        latestMonth = pcTotal.month;
    }
    let currentPolicyType = findPolicyTypeByCode(policyType);

    const queryClient = useQueryClient();
    const getCarriers = async () => {
        const carriers = await queryClient.fetchQuery(carriersQuery);
        return Array.from(carriers.values());
    };
    const fetchList = async () => {
        try {
            let result = [];
            if (tableDataType === 'carriers') {
                result = await getCarriers();
            } else {
                result = processData(data);
            }
            const parsedRows = result.map((row) => {
                let data = tableDataType === 'carriers' ? row : row[Object.keys(row)[0]];

                let parentId = 0;
                if (data.isMga) {
                    parentId = 3;
                } else {
                    parentId = 2;
                }
                return {
                    ...data,
                    parentId,
                    id: data[uuidType],
                    text: tableDataType === 'carriers' ? data.carrierName : data.agencyName,
                    selected: true,
                    isParent: false
                };
            });
            const sectionedData = [
                {
                    id: 1,
                    text:
                        tableDataType === 'carriers'
                            ? 'All Carriers & Wholesalers'
                            : 'All Agencies & Wholesalers',
                    expanded: true,
                    selected: true,
                    isParent: true
                },
                {
                    id: 2,
                    parentId: 1,
                    text: tableDataType === 'carriers' ? 'All Carriers' : 'All Agencies',
                    expanded: false,
                    selected: true,
                    isParent: true
                },
                {
                    id: 3,
                    parentId: 1,
                    text: 'All Wholesalers',
                    expanded: false,
                    selected: true,
                    isParent: true
                },
                ...parsedRows
            ];
            setOptionsList(sectionedData);
            selectedFiltersRef.current.selected = sectionedData;
            selectedFiltersRef.current.all = sectionedData;
            const selected = sectionedData.map((row) => {
                return {
                    uuid:
                        tableDataType === 'carriers'
                            ? row.carrierUuid === undefined
                                ? row.text
                                : row.carrierUuid
                            : row.agencyUuid === undefined
                            ? row.text
                            : row.agencyUuid,
                    isParent: row.isParent,
                    isCarrier: !row.isMga,
                    parentId: row.parentId,
                    id: row.id
                };
            });
            setSelectedIds(selected);
        } catch (e) {
            console.error(e);
        }
    };

    const FiltersContent = () => {
        return (
            <DashboardControls>
                <CarrierContainer>
                    <CarriersFilters
                        carriersList={cloneDeep(optionsList)}
                        carriersFiltersRef={selectedFiltersRef}
                    />
                </CarrierContainer>
            </DashboardControls>
        );
    };

    const applyFilters = () => {
        const ids = selectedFiltersRef.current?.selected?.map((selected) => {
            return {
                uuid: selected.uuid === undefined ? selected.text : selected.uuid,
                isParent: selected.isParent,
                isCarrier: !selected.isMga,
                parentId: selected.parentId,
                id: selected.id
            };
        });
        setSelectedIds(ids || []);
    };

    const setFilters = () => {
        setOptionsList(selectedFiltersRef.current.all);
    };

    useEffect(() => {
        if (!isEmpty(processData(data))) {
            applyFilters();
        }
    }, [optionsList]);

    useEffect(() => {
        fetchList();
    }, [data]);

    useEffect(() => {
        if (allExpanded) setExpandedRows(Array.from({ length: data.length }, () => true));
    }, [data.length, allExpanded]);

    const handleClickExpandAll = () => {
        if (allExpanded) setExpandedRows([]);

        setAllExpanded(!allExpanded);
    };

    const user = useStoreState((state) => state.user);
    const [isGenerating, setIsGenerating] = useState(false);
    async function downloadCSV() {
        setIsGenerating(true);

        try {
            const file = await request.get(`/api/core-production/agency/export`).query({
                startDate: moment(timeframe.startDate).format('YYYY-MM-DD'),
                endDate: moment(timeframe.endDate).format('YYYY-MM-DD'),
                displayText: timeframe.displayText
            });

            const blob = new Blob([file.text], {
                type: 'application/octet-stream'
            });

            const fileName =
                user && user.agency && user.agency.name
                    ? `${user.agency.name.replace(/ /g, '_')}_${timeframe.displayText}.csv`
                    : `${timeframe.displayText}.csv`;

            const url = URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.click();
            setTimeout(() => URL.revokeObjectURL(url), 150);
        } catch (err) {
            console.log(err);
        }

        setIsGenerating(false);
    }

    return (
        <>
            {
                <UploadContainer>
                    {isLoading && <Loader />}
                    {!isLoading && (
                        <CoreProductionWrap>
                            <FilterContainer>
                                <ExcludeIncompleteToggle
                                    isOn={excludeIncompleteData}
                                    setOn={setExcludeIncompleteData}
                                />

                                {showIncompleteDisclaimer && !excludeIncompleteData ? (
                                    <Disclaimer
                                        size="small"
                                        tooltip="Incomplete carrier data in your selected period will lead to unexpected prior values and % change ratios."
                                    >
                                        Incomplete Carrier Data
                                    </Disclaimer>
                                ) : null}

                                <div className="timeline-selector">
                                    <TimelineSelector
                                        warningMessage={warningMessage}
                                        timeframe={timeframe}
                                        setTimeframe={setTimeframe}
                                        iconContent={<IconContent />}
                                        applyAction={() => setTableDataType('carriers')}
                                        rangeColor="#e0b506"
                                        SelectedButtonBgColor="#faf4da"
                                        onOptionsChange={onOptionsChange}
                                        disclaimerMessage="Incomplete carrier data in your selected period will lead to unexpected prior values and % change ratios."
                                    />
                                </div>

                                <Tooltip
                                    title={
                                        isGenerating
                                            ? 'Generating download...'
                                            : `Download Carrier Production for ${timeframe.displayText}`
                                    }
                                >
                                    <Button
                                        className="download"
                                        onClick={downloadCSV}
                                        disabled={isGenerating}
                                    >
                                        <Icon type="download" iWidth="19px" />
                                    </Button>
                                </Tooltip>

                                {collaborationMode && collaborationMode.carriersUuid.length > 0 && (
                                    <CollaborationMenu />
                                )}
                            </FilterContainer>

                            <CoreProductionSummary
                                networkCPRs={
                                    excludeIncompleteData
                                        ? networkCPRsWithoutIncomplete
                                        : networkCPRs
                                }
                                getSelectedKey={getSelectedKey}
                                getSelectedTitle={getSelectedTitle}
                                timeframe={timeframe}
                                haveMissingCurrentData={
                                    haveMissingCurrentData && !excludeIncompleteData
                                }
                                haveMissingPriorData={
                                    haveMissingPriorData && !excludeIncompleteData
                                }
                                excludeIncompleteData={excludeIncompleteData}
                            />

                            <TableWrap>
                                <CoreProductionTableWrap>
                                    <div className="filters-container">
                                        <div className="list-table-actions">
                                            <ExpandAllButton
                                                onToggle={handleClickExpandAll}
                                                expanded={allExpanded}
                                            />
                                            <TableDataApplyButtonWrap>
                                                <Button
                                                    className={classNames({
                                                        table__ApplyButton: true,
                                                        selected: tableDataType === 'carriers'
                                                    })}
                                                    onClick={() => {
                                                        resetSelectedItems();
                                                        setRefetch(true);
                                                        setStandardTypes([]);
                                                        setTableDataType('carriers');
                                                    }}
                                                    disabled={
                                                        tableDataType === 'carriers' || refetch
                                                    }
                                                >
                                                    Carriers
                                                </Button>

                                                <Button
                                                    className={classNames({
                                                        table__ApplyButton: true,
                                                        selected: tableDataType === 'agencies'
                                                    })}
                                                    onClick={() => {
                                                        resetSelectedItems();
                                                        setRefetch(true);
                                                        setStandardTypes([]);
                                                        setTableDataType('agencies');
                                                    }}
                                                    disabled={
                                                        tableDataType === 'agencies' || refetch
                                                    }
                                                >
                                                    Agency
                                                </Button>
                                            </TableDataApplyButtonWrap>
                                            <div className="list-table-search">
                                                <Input
                                                    style={{ height: '36px' }}
                                                    id="list-search"
                                                    placeholder="Search by name"
                                                    value={searchTerm}
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                />
                                            </div>
                                            <div className="list-table-policyType">
                                                <PolicyTypeSelector
                                                    type={policyType}
                                                    disabled={refetch}
                                                    options={[
                                                        {
                                                            label: 'Total',
                                                            value: 'P&C',
                                                            tooltip: TOOL_TIPS.propertyAndCasualty
                                                        },
                                                        {
                                                            label: 'PL',
                                                            value: 'PL',
                                                            tooltip: TOOL_TIPS.personal
                                                        },
                                                        {
                                                            label: 'CL',
                                                            value: 'CL',
                                                            tooltip: TOOL_TIPS.commercial
                                                        },
                                                        {
                                                            label: 'Bond',
                                                            value: 'BOND'
                                                        },
                                                        {
                                                            label: 'Crop',
                                                            value: 'CROP'
                                                        }
                                                    ]}
                                                    onChange={(e) => {
                                                        /*
                                                    const type = allStandardTypes.find(
                                                        el => el.code === e.target.value
                                                    );
                                                    */
                                                        setRefetch(true);
                                                        resetSelectedItems();
                                                        setPolicyType(e.target.value);
                                                        setStandardTypes([]);
                                                        //setStandardTypes(type ? [type.uuid] : []);
                                                    }}
                                                />
                                            </div>
                                            {policyType !== 'P&C' && (
                                                <div className="multiSelect_StandardTypesContainer">
                                                    <div className="multiSelect_StandardTypes">
                                                        <MultiSelect
                                                            key={`${tableDataType}_${policyType}`}
                                                            label="Default select"
                                                            name="default-select"
                                                            placeholder="Select standard types"
                                                            blankItem={{
                                                                label: 'Select policy types'
                                                            }}
                                                            items={standardTypesItems()}
                                                            id="default-id"
                                                            disabled={refetch}
                                                            onChange={(values) => {
                                                                if (values.length === 0) {
                                                                    selectedStandardTypes = [];
                                                                    setStandardTypes([]);
                                                                    setRefetch(true);
                                                                } else {
                                                                    selectedStandardTypes = values;
                                                                }
                                                            }}
                                                        />
                                                        <div className="multiSelect_Message">
                                                            * Manual entries will not be shown in
                                                            results below
                                                        </div>
                                                    </div>
                                                    <Button
                                                        className={'multiSelect_ApplyButton'}
                                                        onClick={() => {
                                                            if (
                                                                standardTypes.length > 0 &&
                                                                selectedStandardTypes.length === 0
                                                            ) {
                                                                setStandardTypes(standardTypes);
                                                            } else {
                                                                setStandardTypes(
                                                                    selectedStandardTypes
                                                                );
                                                            }
                                                            resetSelectedItems();
                                                            setRefetch(true);
                                                        }}
                                                        disabled={refetch}
                                                    >
                                                        Apply
                                                    </Button>
                                                </div>
                                            )}

                                            <Filters>
                                                <FiltersDisplay>
                                                    <div className="title">
                                                        Filters:
                                                        {getSelectedCarrierNames(
                                                            optionsList,
                                                            selectedIds
                                                        ).map((carrierName) => {
                                                            return (
                                                                <div
                                                                    key={carrierName}
                                                                    className="text"
                                                                >
                                                                    {carrierName}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </FiltersDisplay>
                                                <FilterContainer>
                                                    <FiltersSelector
                                                        content={<FiltersContent />}
                                                        applyAction={() => setFilters()}
                                                        displayText="Filters"
                                                        iconType="filter"
                                                        buttonText="Clear Filters"
                                                        buttonAction={() => {
                                                            selectedFiltersRef.current.ref?.current?.instance.selectAll();
                                                        }}
                                                        applyButtonText="Apply Filters"
                                                    />
                                                </FilterContainer>
                                            </Filters>

                                            <div className="timerange">{timeframe.displayText}</div>
                                        </div>
                                    </div>
                                    <SelectedSummary
                                        uuidType={uuidType}
                                        policyType={policyType}
                                        selectedItems={selectedItems}
                                        data={processData(data)}
                                        getSelectedKey={getSelectedKey}
                                    ></SelectedSummary>

                                    <CoreProductionList
                                        expandedRows={expandedRows}
                                        setExpandedRows={setExpandedRows}
                                        selectedYear={year}
                                        uuidType={uuidType}
                                        setShowIncompleteDisclaimer={setShowIncompleteDisclaimer}
                                        data={
                                            selectedIds.length > 0
                                                ? processData(data).filter((data) =>
                                                      selectedIds
                                                          .map((selected) => selected?.id)
                                                          .includes(
                                                              data[Object.keys(data)[0]][uuidType]
                                                          )
                                                  )
                                                : processData(data)
                                        }
                                        carrierType={carrierType}
                                        tableDataType={tableDataType}
                                        refetch={refetch}
                                        listType="carrier"
                                        policyType={currentPolicyType}
                                        searchTerm={searchTerm}
                                        latestMonth={latestMonth}
                                        selectedItems={selectedItems}
                                        selectAll={selectAll}
                                        setSelectAll={setSelectAll}
                                        setSelectedItems={setSelectedItems}
                                        getSelectedKey={getSelectedKey}
                                        getSelectedTitle={getSelectedTitle}
                                        isLoading={isLoading}
                                        timeframe={timeframe}
                                        standardTypes={standardTypes}
                                        carrierReportedMonths={carrierReportedMonths}
                                        haveMissingCurrentData={haveMissingCurrentData}
                                        haveMissingPriorData={haveMissingPriorData}
                                    />
                                </CoreProductionTableWrap>
                            </TableWrap>
                        </CoreProductionWrap>
                    )}
                </UploadContainer>
            }
        </>
    );
};

export function CoreProductionPage() {
    return <CoreProduction />;
}

export default CoreProduction;

function ExcludeIncompleteToggle({ isOn, setOn }) {
    return (
        <div className="exclude-data-toggle">
            <Checkbox
                className="akpi-checkbox"
                size="small"
                key={'exclude-incomplete-data'}
                label={'Hide incomplete data'}
                name={'exclude-incomplete-data'}
                checked={isOn}
                onChange={(e) => {
                    setOn(e.target.checked);
                }}
            />
            <Tooltip title="Hides missing Carrier data from the selected timeframe.">
                <span>
                    <Icon type="info-fill" iWidth="20px" />
                </span>
            </Tooltip>
        </div>
    );
}

ExcludeIncompleteToggle.propTypes = {
    isOn: PropTypes.bool,
    setOn: PropTypes.func
};

function getSelectedCarrierNames(list, selectedIds) {
    const selectedNames = [];
    const parents = selectedIds.filter(
        (selected) => selected.parentId !== undefined && selected.isParent
    );

    selectedIds.forEach((selected) => {
        if (selected.id !== 1) {
            const parent = parents.find((p) => p.id === selected.id || p.id == selected.parentId);
            const data = list.find((aux) => aux.id === selected.id);
            const name = parent ? parent.uuid : data.text;
            if (!selectedNames.includes(name)) {
                selectedNames.push(name);
            }
        }
    });
    return selectedNames;
}

function getSelectedTitle(requestedKey) {
    switch (requestedKey) {
        case 'writtenPremium':
            return 'Written Premium';
        case 'newWrittenPremium':
            return 'New Business Premium';
        case 'lossRatio':
            return 'Loss Ratio';
    }
}

function getSelectedKey(requestedKey) {
    switch (requestedKey) {
        case 'writtenPremium':
            return 'writtenPremium';
        case 'priorWrittenPremium':
            return 'priorWrittenPremium';
        case 'newWrittenPremium':
            return 'newWrittenPremium';
        case 'priorNewWrittenPremium':
            return 'priorNewWrittenPremium';
        case 'earned':
            return 'earned';
        case 'incurred':
            return 'incurred';
        case 'priorEarned':
            return 'priorEarned';
        case 'priorIncurred':
            return 'priorIncurred';
    }
}
