import { toCurrency, toPercent } from '../../../utils';

import { AverageMeterWrap } from './styles.js';

const AverageMeter = ({ agency, field, label, format, coreSummary, secondary }) => {
    const current = (agency && agency[field]) || 0;
    const formatFunc = (val) => {
        return format === 'percent' ? toPercent(val, 2) : toCurrency(val, 0);
    };

    return (
        <AverageMeterWrap>
            <div className="average-meter">
                <div className="label">{label}</div>

                <div className="meters">
                    <div className="">
                        <div className="metric-total">
                            {formatFunc(current)}
                            {/* only show percent if field name is valid. This allows core/noncore percents to show */}
                            {secondary &&
                                agency &&
                                agency[secondary] &&
                                ` (${toPercent(agency[secondary] || 0)})`}
                        </div>
                    </div>
                    {coreSummary && (
                        <div className="agency-summary">
                            <div className="summary-labels">
                                <span>Core</span>
                                <span>Non-core</span>
                            </div>
                            <div className="summary-values">
                                <span>
                                    {formatFunc(coreSummary.core)}
                                    <span className="values left">
                                        {secondary && ` (${toPercent(coreSummary.corePercent)})`}
                                    </span>
                                </span>
                                <span>
                                    {formatFunc(coreSummary.nonCore)}
                                    <span className="values right">
                                        {secondary && ` (${toPercent(coreSummary.nonCorePercent)})`}
                                    </span>
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </AverageMeterWrap>
    );
};

export default AverageMeter;
