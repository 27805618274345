export const TOOL_TIPS = {
    writtenPremium: 'Reported by Carrier',
    newBusiness: 'Reported by Carrier',
    writtenPremium12MM: 'Previous 12 Month Premium',
    lossRatio: 'Reported By Carrier',
    change: 'Current Period Gains over the Prior Year Period',
    percentOfBook: 'New Business as Part of Total Written Premium',
    ep: 'Earned Premium',
    il: 'Incurred Losses',
    commercial: 'Commercial Lines',
    personal: 'Personal Lines',
    propertyAndCasualty: 'Property & Casualty'
};
