import { request } from './request';

let _config = {};

export const getConfigValues = () => {
    return request.get('/akpi-env').then(({ body }) => {
        _config = body.config;
        window.AKPI_ENV = body.config;
        return {
            config: body.config,
            get(key, defaultValue) {
                if (key in body.config) {
                    return body.config[key];
                } else {
                    return defaultValue;
                }
            }
        };
    });
};

const config = {
    get: (key) => {
        return _config[key];
    }
};

export default config;
