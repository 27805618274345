import superagent from 'superagent';

import store from '../store';

function unauthorizedHandler(req) {
    req.on('response', (res) => {
        // add any global error handling or logging here

        if (res.status === 401) {
            store.getActions().setSessionExpired();
        }
        // if (res.status === 500) {
        //     console.log(res.error);
        //     store
        //         .getActions()
        //         .setServerError({ message: res.error.message, hasLogut: true, hasContinue: true });
        // }
        if (res.body && res.body.refreshTokenRevoked) {
            store.getActions().setRefreshTokenRevoked();
        }
    });
}

export const request = superagent.agent().use(unauthorizedHandler);

/**
 * Adds AbortSignal support to superagent requests.
 * @param {import('superagent').Request} req
 * @param {AbortSignal} signal
 */
export function abortOnSignal(req, signal) {
    signal?.addEventListener(
        'abort',
        () => {
            req.abort();
        },
        { once: true }
    );
    return req;
}
