import { css } from '@emotion/react';

import theme from '../../theme';

export const global = css`
    * {
        box-sizing: border-box;
    }
    body,
    html {
        margin: 0;
        padding: 0;
        font-family: ${theme.fonts.default};
        font-weight: 300;
        background-color: ${theme.colors.backgroundLight};
        width: 100%;
        height: 100%;
    }
    #root {
        height: 100%;
    }
    & .notificationBell {
        float: right;
        margin: 5px;
    }
    & .logOutButton {
        float: right;
        margin: 5px;
    }
    .ReactTable,
    table {
        .number {
            font-family: Roboto;
        }
        .pointer {
            cursor: pointer;
        }
    }

    .date-icon {
        float: right;
        margin-left: 10px;
        font-size: 10px;
        padding: 2px 7px;
        color: #112c3e;
        border-radius: 8px;
        &--default {
            background-color: #90cdf4;
        }
        &--success {
            background-color: #68d391;
            color: #11432c;
        }
        &--warning {
            background-color: #f6e05e;
            color: #441;
        }
        &--error {
            background-color: #fc8181;
            color: #331111;
        }
        &--trailing {
            background-color: #e4eaee;
        }
    }
`;
