import { SelectedSummaryContainer } from './styles';
import { toCurrency } from '../../utils';
import classNames from 'classnames';

function getSelectedSummary(data, selectedItems, policyType, uuidType, getSelectedKey) {
    let accumulator = {
        writtenPremium: 0,
        priorWrittenPremium: 0,
        earned: 0,
        incurred: 0,
        newWrittenPremium: 0,
        priorNewWrittenPremium: 0,
        count: 0
    };
    if (Object.keys(selectedItems).length === 0) return accumulator;
    data.forEach((cpr) => {
        Object.keys(selectedItems).forEach((uuid) => {
            if (selectedItems[uuid] && cpr[uuidType] && cpr[uuidType] === uuid) {
                accumulator.writtenPremium +=
                    (cpr[policyType] && cpr[policyType][getSelectedKey('writtenPremium')]) || 0;
                accumulator.priorWrittenPremium +=
                    (cpr[policyType] && cpr[policyType][getSelectedKey('priorWrittenPremium')]) ||
                    0;
                accumulator.newWrittenPremium +=
                    (cpr[policyType] && cpr[policyType][getSelectedKey('newWrittenPremium')]) || 0;
                accumulator.priorNewWrittenPremium +=
                    (cpr[policyType] &&
                        cpr[policyType][getSelectedKey('priorNewWrittenPremium')]) ||
                    0;
                accumulator.earned +=
                    (cpr[policyType] && cpr[policyType][getSelectedKey('earned')]) || 0;
                accumulator.incurred +=
                    (cpr[policyType] && cpr[policyType][getSelectedKey('incurred')]) || 0;
                if (cpr[policyType]) accumulator.count++;
            }
        });
    });
    return accumulator;
}

export default function SelectedSummary({
    data,
    selectedItems,
    policyType,
    uuidType,
    getSelectedKey
}) {
    const selectedSummary = getSelectedSummary(
        data,
        selectedItems,
        policyType,
        uuidType,
        getSelectedKey
    );
    return (
        <SelectedSummaryContainer style={{ display: selectedSummary.count === 0 ? 'none' : '' }}>
            <div className={classNames('section selection-summary')}>
                <div>
                    <div className="selection-summary__title">Selection Summary</div>
                    <div className="selection-summary__subtitle">{`${selectedSummary.count} selected`}</div>
                </div>
                <div className="">
                    <span className="selection-summary__label">{'Total WP'}</span>
                    <span className="selection-summary__amount">
                        {toCurrency(selectedSummary.writtenPremium)}
                    </span>
                </div>
                {/* {(policyType === 'P&C' || policyType === 'CL') && (
                    <div className="">
                        <span className="selection-summary__label">Commercial</span>
                        <span className="selection-summary__amount">
                            {toCurrency(selectedSummary.cl)}
                        </span>
                    </div>
                )}
                {(policyType === 'P&C' || policyType === 'PL') && (
                    <div className="">
                        <span className="selection-summary__label">Personal</span>
                        <span className="selection-summary__amount">
                            {toCurrency(selectedSummary.pl)}
                        </span>
                    </div>
                )} */}

                <div className="">
                    <span className="selection-summary__label">{'Prior WP'}</span>
                    <span className="selection-summary__amount">
                        {toCurrency(selectedSummary.priorWrittenPremium)}
                    </span>
                </div>

                <div className="">
                    <span className="selection-summary__label">{'NB'}</span>
                    <span className="selection-summary__amount">
                        {toCurrency(selectedSummary.newWrittenPremium)}
                    </span>
                </div>

                <div className="">
                    <span className="selection-summary__label">{'Prior NB'}</span>
                    <span className="selection-summary__amount">
                        {toCurrency(selectedSummary.priorNewWrittenPremium)}
                    </span>
                </div>
                <div>
                    <span className="selection-summary__label">{'EP'}</span>
                    <span className="selection-summary__amount">
                        {toCurrency(selectedSummary.earned)}
                    </span>
                </div>
                <div>
                    <span className="selection-summary__label">{'IL'}</span>
                    <span className="selection-summary__amount">
                        {toCurrency(selectedSummary.incurred)}
                    </span>
                </div>
            </div>
        </SelectedSummaryContainer>
    );
}
