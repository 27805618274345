import { useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import {
    TitleContainer,
    RadioInputContainer,
    ButtonContainer,
    RadioInput,
    TxtContainer,
    modalStyles,
    CancelIcon,
    Content
} from './styles';
import SelectableList from '../../SelectableList';
import { request as superagent } from '../../../utils/request';
import Modal from 'react-modal';
import { Button } from '@agencykpi/components';
import { Icon } from '../../../icons/icons';
import { COLLABORATION_EXCLUSIONS } from '../../../constants';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';

const CarrierSelectionModal = ({ isOpen, setIsOpen, setIsExitModal }) => {
    const collaborationMode = useStoreState((state) => state.collaborationMode);
    const setCollaborationMode = useStoreActions((actions) => actions.setCollaborationMode);

    const [selectedItems, setSelectedItems] = useState(collaborationMode.carriersName || []);
    const [selectedItemsIds, setSelectedItemsIds] = useState(collaborationMode.carriersUuid || []);
    const [selectedDataMasked, setSelectedDataMasked] = useState(collaborationMode.shareType);

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleSubmit = () => {
        if (collaborationMode.carriersUuid.length > 0 && selectedItemsIds.length <= 0) {
            setIsExitModal(true);
        } else {
            const collaborationData = {
                carriersUuid: selectedItemsIds,
                carriersName: selectedItems,
                shareType: selectedDataMasked
            };

            setCollaborationMode(collaborationData);
            setIsOpen(false);
        }
    };

    useEffect(() => {
        setSelectedItemsIds(collaborationMode.carriersUuid);
        setSelectedItems(collaborationMode.carriersName);
    }, [collaborationMode]);

    const carriersQuery = useQuery({
        queryKey: ['collaboration-mode', 'carriers'],
        queryFn() {
            return superagent
                .get(`/api/agency-planning/carriers?from=CarrierSelectionModal`)
                .then(({ body }) => body);
        },
        enabled: isOpen,
        select(carriers) {
            if (collaborationMode.carriersUuid.length > 0) {
                return carriers.map((item) => {
                    return {
                        ...item,
                        selected:
                            collaborationMode.carriersUuid.some(
                                (carrier) => carrier === item.uuid
                            ) || false
                    };
                });
            } else {
                return carriers;
            }
        }
    });

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
            ariaHideApp={false}
            style={modalStyles}
        >
            <TitleContainer>
                <h1>Collaboration Mode</h1>
                <CancelIcon onClick={() => setIsOpen(false)}>
                    <Icon type="close" iWidth="20px" iColor="gray" />
                </CancelIcon>
            </TitleContainer>
            <Content>
                <div className="title">Select the carriers you want to display:</div>
                {carriersQuery.isLoading ? (
                    <p>Loading</p>
                ) : (
                    <SelectableList
                        selectedItems={selectedItems}
                        setSelectedItems={setSelectedItems}
                        itemType={'Carriers'}
                        itemTypeDynamic={'Carriers'}
                        modalRows={carriersQuery.data}
                        setSelectedItemsIds={setSelectedItemsIds}
                        selectedItemsIds={selectedItemsIds}
                        showPagination={false}
                        showSelectAllCheckbox={false}
                    />
                )}
                <TxtContainer>
                    <p>For the other carriers, please choose what should be hidden:</p>
                </TxtContainer>
                <RadioInputContainer>
                    <label>
                        <RadioInput
                            type="radio"
                            name="collaboration-exclusions"
                            value={COLLABORATION_EXCLUSIONS.NAMES}
                            checked={selectedDataMasked === COLLABORATION_EXCLUSIONS.NAMES}
                            onChange={(e) => setSelectedDataMasked(e.target.value)}
                        />
                        Carrier Names
                    </label>
                    <label>
                        <RadioInput
                            type="radio"
                            name="collaboration-exclusions"
                            value={COLLABORATION_EXCLUSIONS.ALL}
                            checked={selectedDataMasked === COLLABORATION_EXCLUSIONS.ALL}
                            onChange={(e) => setSelectedDataMasked(e.target.value)}
                        />
                        All Data
                    </label>
                </RadioInputContainer>
            </Content>
            <ButtonContainer>
                <Button
                    isOutlined
                    onClick={handleClose}
                    disable={carriersQuery.isLoading}
                    style={{ marginRight: '5px' }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    disabled={
                        carriersQuery.isLoading || (selectedItems && selectedItems.length == 0)
                    }
                    primary
                >
                    Enter
                </Button>
            </ButtonContainer>
        </Modal>
    );
};
CarrierSelectionModal.propTypes = {
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    setIsExitModal: PropTypes.func
};

export default CarrierSelectionModal;
