import { cloneDeep } from 'lodash-es';

export const processCarrierCPRs = (cprs, timeframeName, includeManualEntries = true) => {
    if (timeframeName === 'YTD' && includeManualEntries) {
        cprs.forEach((cpr) => {
            Object.keys(cpr).forEach((policyType) => {
                if (
                    policyType !== 'name' &&
                    policyType !== 'reportedMonths' &&
                    policyType !== 'carrierUuid' &&
                    policyType !== 'agencyUuid' &&
                    policyType !== 'policyTypeUuid' &&
                    policyType !== 'code'
                ) {
                    cpr[policyType].earned = cpr[policyType].earnedWithManual;
                    cpr[policyType].newWrittenPremium = cpr[policyType].newWrittenPremiumWithManual;
                    cpr[policyType].incurred = cpr[policyType].incurredWithManual;
                    cpr[policyType].priorEarned = cpr[policyType].priorEarnedWithManual;
                    cpr[policyType].priorIncurred = cpr[policyType].priorIncurredWithManual;
                    cpr[policyType].priorNewWrittenPremium =
                        cpr[policyType].priorNewWrittenPremiumWithManual;
                    cpr[policyType].priorWrittenPremium =
                        cpr[policyType].priorWrittenPremiumWithManual;
                    cpr[policyType].writtenPremium = cpr[policyType].writtenPremiumWithManual;
                }
            });
        });
    }
    return cprs;
};

export const processTotalCPRs = (cprs, timeframeName, includeManualEntries = true) => {
    if (timeframeName === 'YTD' && includeManualEntries) {
        cprs.forEach((cpr) => {
            cpr.earned = cpr.earnedWithManual;
            cpr.newWrittenPremium = cpr.newWrittenPremiumWithManual;
            cpr.incurred = cpr.incurredWithManual;
            cpr.priorEarned = cpr.priorEarnedWithManual;
            cpr.priorIncurred = cpr.priorIncurredWithManual;
            cpr.priorNewWrittenPremium = cpr.priorNewWrittenPremiumWithManual;
            cpr.priorWrittenPremium = cpr.priorWrittenPremiumWithManual;
            cpr.writtenPremium = cpr.writtenPremiumWithManual;
        });
    }
    return cprs;
};

export const processTotalData = (existingCprs) => {
    const cprs = cloneDeep(existingCprs);
    const totalPC = {
        earned: 0,
        incurred: 0,
        newWrittenPremium: 0,
        priorEarned: 0,
        priorIncurred: 0,
        priorNewWrittenPremium: 0,
        priorWrittenPremium: 0,
        writtenPremium: 0
    };
    let pcIndex = -1;
    existingCprs.forEach((blob, index) => {
        if (blob.policyType !== 'P&C') {
            totalPC.earned += blob.earned;
            totalPC.incurred += blob.incurred;
            totalPC.newWrittenPremium += blob.newWrittenPremium;
            totalPC.priorEarned += blob.priorEarned;
            totalPC.priorIncurred += blob.priorIncurred;
            totalPC.priorNewWrittenPremium += blob.priorNewWrittenPremium;
            totalPC.priorWrittenPremium += blob.priorWrittenPremium;
            totalPC.writtenPremium += blob.writtenPremium;
        } else {
            pcIndex = index;
        }
    });
    cprs[pcIndex] = {
        ...cprs[pcIndex],
        ...totalPC
    };
    return cprs;
};

// Check if the reported dates include the months we're trying to show from our selected timeframe and set flags on the provided carrier to report if data is missing or not.
// Does not currently account for manual entries.
export const setMissingDataFlags = (carrier, allReportedMonths) => {
    if (carrier && allReportedMonths) {
        const months = Object.keys(carrier).filter((key) => key.includes('-'));
        const carrierReportedMonths = allReportedMonths.find(
            (report) => report.carrierId == carrier.carrierUuid
        );

        const dates = carrierReportedMonths ? carrierReportedMonths.reportedMonths : [];

        carrier.haveMissingCurrentData = months.some((key) => {
            const [month, year] = getMonthAndYearFromKey(key);
            return !dates.includes(`${year}-${month < 10 ? `0${month}` : month}`);
        });

        carrier.haveMissingPriorData = months.some((key) => {
            const [month, year] = getMonthAndYearFromKey(key);
            return !dates.includes(`${year - 1}-${month < 10 ? `0${month}` : month}`);
        });
    }

    return carrier;
};

function getMonthAndYearFromKey(key) {
    const date = key.split('-');
    const month = parseInt(date[0].replace(/^\D+/g, ''));
    const year = parseInt(date[1].replace(/^\D+/g, ''));
    return [month, year];
}

export const processNetworkCPR = (
    data,
    excludeIncompleteData,
    policyType,
    carrierReportedMonths
) => {
    let processedData = [];
    let resultData = {};
    policyType = data.forEach((item) => {
        if (
            policyType &&
            item[policyType.code] &&
            (item[policyType.code].earned !== 0 ||
                item[policyType.code].priorEarned !== 0 ||
                item[policyType.code].incurred !== 0 ||
                item[policyType.code].newWrittenPremium !== 0 ||
                item[policyType.code].writtenPremium !== 0 ||
                item[policyType.code].priorWrittenPremium !== 0 ||
                item[policyType.code].priorNewWrittenPremium !== 0)
        ) {
            const totalPC = {
                earned: 0,
                incurred: 0,
                newWrittenPremium: 0,
                priorEarned: 0,
                priorIncurred: 0,
                priorNewWrittenPremium: 0,
                priorWrittenPremium: 0,
                writtenPremium: 0
            };

            policyType.children.forEach((blob) => {
                let carrier = item[blob.code];
                if (!carrier) return;

                carrier = setMissingDataFlags(carrier, carrierReportedMonths);
                const isMissingCurrentData = carrier.haveMissingCurrentData;
                const isMissingPriorData = carrier.haveMissingPriorData;
                const isMissingData = isMissingCurrentData || isMissingPriorData;

                if (!excludeIncompleteData || (excludeIncompleteData && !isMissingData)) {
                    totalPC.earned += carrier.earned;
                    totalPC.incurred += carrier.incurred;
                    totalPC.newWrittenPremium += carrier.newWrittenPremium;
                    totalPC.priorEarned += carrier.priorEarned;
                    totalPC.priorIncurred += carrier.priorIncurred;
                    totalPC.priorNewWrittenPremium += carrier.priorNewWrittenPremium;
                    totalPC.priorWrittenPremium += carrier.priorWrittenPremium;
                    totalPC.writtenPremium += carrier.writtenPremium;

                    if (!resultData[blob.code]) {
                        resultData[blob.code] = {
                            ...carrier
                        };
                    } else {
                        resultData[blob.code].earned += carrier.earned;
                        resultData[blob.code].incurred += carrier.incurred;
                        resultData[blob.code].newWrittenPremium += carrier.newWrittenPremium;
                        resultData[blob.code].priorEarned += carrier.priorEarned;
                        resultData[blob.code].priorIncurred += carrier.priorIncurred;
                        resultData[blob.code].priorNewWrittenPremium +=
                            carrier.priorNewWrittenPremium;
                        resultData[blob.code].priorWrittenPremium += carrier.priorWrittenPremium;
                        resultData[blob.code].writtenPremium += carrier.writtenPremium;
                    }
                }
            });
            resultData[policyType.code] = {
                ...item[policyType.code],
                ...totalPC
            };
        }
    });

    Object.keys(resultData).forEach((type) => {
        processedData.push(resultData[type]);
    });
    return processedData;
};

export const removeIncompleteData = (list, lob) => {
    return list.filter((item) => {
        const dataOwner = item[lob.code] || item;
        return !(dataOwner.haveMissingCurrentData || dataOwner.haveMissingPriorData);
    });
};
