import * as yup from 'yup';

export const profileFormSchema = yup.object().shape({
    firstName: yup.string().trim().required('Required'),
    lastName: yup.string().trim().required('Required'),
    emailAddress: yup.string().trim().email('Invalid Email').required('Required'),
    mobileNumber: yup.string().when([], {
        is: (value) => value?.length > 2,
        then: (schema) => schema.min(11, 'Invalid Mobile Number'),
        otherwise: (schema) => schema.min(0)
    }),
    address1: yup.string().trim(),
    city: yup.string().trim(),
    state: yup.string().trim(),
    zipCode: yup.string().trim()
});
