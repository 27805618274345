import styled from '@emotion/styled';
import theme from '../theme';

export const PageWithTableWrap = styled.div`
    padding: 0px;
    .flex {
        display: flex;
        &.flex-align-center {
            align-items: center;
        }
        &.flex-align-end {
            align-items: flex-end;
        }
    }
    .titleSection {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 5px;
        height: 100%;
        width: 100%;
        & .name {
            flex-grow: 1;
            text-align: left;
        }
    }
    .actionsSection {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px solid rgba(0, 0, 0, 0.1);
        align-items: center;
    }
    .name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 220px;
        display: block;
    }
    .subtable {
        margin-left: 35px;
        .ReactTable {
            background-color: white;
            .rt-thead {
                display: none;
                &.-header {
                    box-shadow: none;
                }
            }
            .rt-tfoot {
                display: none;
            }
            .rt-tbody {
                overflow: hidden;
            }
        }
    }
    .footer {
        display: flex;
        align-items: center;
        height: 100%;
        margin-left: 4px;
    }
    .ReactTable {
        //max-height: 800px;
        font-size: 14px;
        background-color: white;
        & .rt-tfoot {
            position: sticky;
            bottom: 0;
            z-index: 2;
            border-top: solid 1px #d8d8d8;
            background-color: #f5f5f9;
            font-weight: bold;
            & .rt-td {
                padding: 12px;
            }
            > div .negative {
                color: ${theme.colors.red};
            }
            > div .positive {
                color: ${theme.colors.green};
            }
        }
        & .rt-thead .rt-th {
            font-weight: normal;
            &.sub-header {
                background-color: #f5f5f9;
                color: black;
            }
            ,
            &.fixed-sub-header {
                background-color: #f5f5f9;
                color: black;
                position: sticky;
                z-index: 10;
                left: 0px;
            }
            &.goal-target-subheader {
                border-bottom: 4px solid rgba(52, 144, 220);
            }
            &.goal-plan-subheader {
                border-bottom: 4px solid rgba(56, 193, 114);
            }
            &.written-premium-column-group {
                background-color: ${theme.colors.writtenPremium};
            }
            &.new-business-column-group {
                background-color: ${theme.colors.newBusiness};
            }
            &.trailing-column-group {
                background-color: ${theme.colors.trailing};
            }
            &.loss-ratio-column-group {
                background-color: ${theme.colors.lossRatio};
            }
        }
        & .rt-tbody .rt-td {
            &.goal-target-group {
                background-color: rgba(52, 144, 220, 0.2);
            }
            &.goal-plan-group {
                background-color: rgba(56, 193, 114, 0.2);
            }
            &.written-premium-column-group {
                background-color: ${theme.colors.writtenPremiumTint};
            }
            &.new-business-column-group {
                background-color: ${theme.colors.newBusinessTint};
            }
            &.trailing-column-group {
                background-color: ${theme.colors.trailingTint};
            }
            &.loss-ratio-column-group {
                background-color: ${theme.colors.lossRatioTint};
            }
        }
        &.-highlight .rt-tbody .rt-tr:not(.-padRow) {
            transition: background-color 0.2s ease;
        }
        &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover,
        &.-highlight .rt-tr.-highlight--hovered:not(.-padRow),
        &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover .rt-td,
        &.-highlight .rt-tr.-highlight--hovered:not(.-padRow) .rt-td {
            transition: background-color 0.2s ease;
        }
        .rt-tbody {
            overflow: initial;
        }
        .rt-thead {
            &.-header {
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
                position: sticky;
                top: 27px;
                min-height: 28px;
                z-index: 3;
            }
            &.-headerGroups {
                position: sticky;
                top: 0px;
                z-index: 3;
            }
            & .rt-th.-sort-desc,
            & .rt-td.-sort-desc {
                box-shadow: inset 0 -3px 0 0 rgba(28, 61, 90, 0.6);
            }
            & .rt-th.-sort-asc,
            & .rt-td.-sort-asc {
                box-shadow: inset 0 3px 0 0 rgba(28, 61, 90, 0.6);
            }
        }
        &.table--empty .rt-tbody {
            min-height: 100px;
        }
        .rt-td {
            margin: 0;
        }
        a {
            text-decoration: underline;
        }
    }
`;

export const TableWrap = styled(PageWithTableWrap)`
    margin: 0;
    .ReactTable {
        margin-bottom: 0;
        min-width: 980px;
        max-height: 700px;
        @media (max-width: 1300px) {
            min-width: 100%;
        }
        .delete-icon {
            cursor: pointer;
        }
    }
    .sticky {
        padding: 13px 0 0 4px !important;
    }
    .ReactTable {
        .number {
            padding: 0px 0px 0px 10px;
        }
        .rt-expander {
            padding: 7px 5px;
        }

        .rt-td {
            text-align: center;
            align-items: center;
            display: flex;
            justify-content: center;
            min-height: 45px;
        }
        .rt-thead {
            .rt-th.sub-header {
                background-color: #eef6fb;
                color: black;
                &.goal-target-subheader {
                    border-bottom: 4px solid rgba(52, 144, 220);
                }
                &.goal-plan-subheader {
                    border-bottom: 4px solid rgba(56, 193, 114);
                }
            }
            & .rt-th {
                font-weight: bold;
                background-color: #035fa1;
                text-align: center;
                border-right: solid 1px #ccc;
                &.sub-header {
                    background-color: #eeeeee;
                    color: black;
                }
            }
        }
        .rt-tbody .rt-td {
            &.goal-target-group {
                background-color: rgba(52, 144, 220, 0.2);
            }
            &.goal-plan-group {
                background-color: rgba(56, 193, 114, 0.2);
            }
        }
        .rt-noData {
            background: none;
        }
    }
    .subtable {
        z-index: 0 !important;
        .ReactTable .rt-thead .rt-th {
            background-color: rgb(245, 245, 249);
            color: black;
        }
        .ReactTable .rt-table {
            overflow: hidden;
        }
    }
    .change-container {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
    }
    .change-percent {
        display: flex;
        justify-content: flex-end;
        margin-left: 3px;
    }
    .negative {
        color: #cc1f1a;
    }
    .positive {
        color: #017d01;
    }
    .required input {
        border-color: red;
        border-radius: 3px;
    }
`;
