import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import NewCarrierDataForm from './NewCarrierDataForm/NewCarrierDataForm';
import Modal from 'react-modal';
import { useStoreActions, useStoreState } from 'easy-peasy';
import ManualEntryTables from './ManualEntryTables/ManualEntryTables';
import RequestNewCarrierForm from './RequestNewCarrierForm/RequestNewCarrierForm';
import { StyledTableContainer, CancelIcon } from './styles';
import { Icon } from '../../icons/icons';

const modalStyles = {
    overlay: {
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        top: null,
        bottom: null,
        left: null,
        right: null,
        maxWidth: '900px',
        borderRadius: '8px',
        boxShadow: '3px 3px 10px -1px rgba(0,0,0,0.3)'
    }
};

const BillingCarrier = () => {
    const [
        formModalOpen,
        year,
        manualEntryDataByYear,
        networkUuid,
        onboardedCarriers,
        email,
        firstName,
        lastName
    ] = useStoreState((state) => [
        state.formModalOpen,
        state.year,
        state.manualEntryTable.manualEntryDataByYear,
        state.user.network.uuid,
        state.onboardedCarriers,
        state.user.email,
        state.user.firstName,
        state.user.lastName
    ]);
    //const setManualEntryDataByYear = useStoreActions(actions => actions.setManualEntryDataByYear);
    //const setYear = useStoreActions(actions => actions.setYear);
    //const setMonth = useStoreActions(actions => actions.setMonth);

    const [
        setFormModalOpen,
        fetchManualEntries,
        fetchOnboardedCarriers,
        setInitialFormValues,
        setCompanyForm,
        setPolicyForm,
        setYearForm,
        setPolicyTable,
        setSubmitErrorMsg,
        setSubCodeForm,
        setCurrentView
    ] = useStoreActions((actions) => [
        actions.setFormModalOpen,
        actions.fetchManualEntries,
        actions.fetchOnboardedCarriers,
        actions.setInitialFormValues,
        actions.setCompanyForm,
        actions.setPolicyForm,
        actions.setYearForm,
        actions.setPolicyTable,
        actions.setSubmitErrorMsg,
        actions.setCompanyForm,
        actions.setCurrentView
    ]);
    const [editEntry, setEditEntry] = useState(false);
    const [requestNewCarrierModalOpen, setNewCarrierModalOpen] = useState(false);

    function handleModal(e, entryInfo = {}, edittingEntry, currentView = 'carriers') {
        setEditEntry(edittingEntry !== undefined ? edittingEntry : editEntry);
        setCurrentView(currentView);
        const isCanceling =
            e.target.value === 'cancel' || e.currentTarget.classList.contains('cancelIcon')
                ? true
                : false;
        //reset formState when started from beginning.
        if (isCanceling) {
            setCompanyForm({ companyUuid: '', companyName: '' });
            setPolicyTable({ policyUuid: '', policyTypeCode: '' });
            setPolicyForm({ policyUuid: '', policyTypeCode: '' });
            setSubCodeForm({ uuid: '', code: '' });
            setYearForm(null);
            setSubmitErrorMsg('');
            setEditEntry(false);
        }
        setInitialFormValues(entryInfo, edittingEntry);

        if (onboardedCarriers.length === 0) {
            fetchOnboardedCarriers(networkUuid);
        }
        setFormModalOpen(!isCanceling);
    }

    function handleRequestNewCarrierModal(close) {
        if (close === 'close') return setNewCarrierModalOpen(false);
        setNewCarrierModalOpen(true);
        setFormModalOpen(false);
    }

    useEffect(() => {
        fetchManualEntries(year);
    }, [year]);

    return (
        <>
            <StyledTableContainer>
                <Routes>
                    <Route
                        exact
                        path="/"
                        element={
                            <ManualEntryTables
                                className={'companyTable'}
                                manualEntryData={manualEntryDataByYear}
                                handleModal={handleModal}
                                currentView="carriers"
                            />
                        }
                    />
                    <Route
                        path="edit"
                        element={
                            <ManualEntryTables
                                className={'companyTable'}
                                manualEntryData={manualEntryDataByYear}
                                handleModal={handleModal}
                                currentView="entries"
                            />
                        }
                    />
                </Routes>
            </StyledTableContainer>
            <Modal isOpen={formModalOpen} ariaHideApp={false} style={modalStyles}>
                <CancelIcon className="cancelIcon" onClick={handleModal}>
                    <Icon type="close" iWidth="20px" iColor="gray" />
                </CancelIcon>
                <NewCarrierDataForm
                    editEntry={editEntry}
                    closeModal={handleModal}
                    handleRequestNewCarrierModal={handleRequestNewCarrierModal}
                ></NewCarrierDataForm>
            </Modal>
            <Modal isOpen={requestNewCarrierModalOpen} ariaHideApp={false} style={modalStyles}>
                <CancelIcon className="cancelIcon" onClick={() => setNewCarrierModalOpen(false)}>
                    <Icon type="close" iWidth="20px" iColor="gray" />
                </CancelIcon>
                <RequestNewCarrierForm
                    email={email}
                    name={`${firstName} ${lastName}`}
                    handleRequestNewCarrierModal={handleRequestNewCarrierModal}
                ></RequestNewCarrierForm>
            </Modal>
        </>
    );
};
export default BillingCarrier;
