/**
 * This file and others in ./shared must be portable between Harmony and Understanding
 * Periodically this file will be copied to the other project
 */
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { toCurrency, toPercent } from '../../../utils';
import Loader from '../../shared/Loader';
import classNames from 'classnames';

export function SummaryCard({ className, title, titleTooltip, timeframe, isLoading, children }) {
    return (
        <div className={`card planning-tool-card ${className}`}>
            <div className="planning-tool-card__header">
                <Tooltip title={titleTooltip}>
                    <h3>{title}</h3>
                </Tooltip>
                <div className="range">{timeframe.displayText}</div>
            </div>
            <div className="planning-tool-card__body">
                {isLoading && <Loader />}
                {!isLoading && children}
            </div>
        </div>
    );
}
SummaryCard.propTypes = {
    title: PropTypes.string,
    titleTooltip: PropTypes.string,
    timeframe: PropTypes.shape({
        displayText: PropTypes.string
    }),
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    children: PropTypes.node
};

const incompleteStyleTreatment = css`
    cursor: default;
    color: #aeaeb2;
`;

export function CurrencyCell({ value, isIncomplete, networkValueTooltip }) {
    return (
        <Tooltip
            title={
                isIncomplete
                    ? 'Carrier data is incomplete for the selected timeframe.'
                    : networkValueTooltip
            }
        >
            <td className="number pointer" css={isIncomplete ? incompleteStyleTreatment : null}>
                {toCurrency(value)}
            </td>
        </Tooltip>
    );
}
CurrencyCell.propTypes = {
    value: PropTypes.number,
    isIncomplete: PropTypes.bool,
    networkValueTooltip: PropTypes.string
};

export function PercentCell({ value, isIncomplete, networkValueTooltip, coloured = false, colorThreasholds = null }) {

    const colouredClass = coloured ? classNames(
        colorThreasholds ? colorThreasholds(value) : {
            negative: value < 0,
            positive: value > 0
        }) : '';

    return (
        <Tooltip
            title={
                isIncomplete
                    ? 'Carrier data is incomplete for the selected timeframe.'
                    : networkValueTooltip
            }
        >
            <td
                className="number pointer percent-metric"
                css={
                    isIncomplete
                        ? [incompleteStyleTreatment, { textDecoration: 'underline dotted' }]
                        : null
                }
            >
                {isIncomplete ? '%' : <div className={colouredClass}>{toPercent(value)}</div>}
            </td>
        </Tooltip>
    );
}
PercentCell.propTypes = {
    value: PropTypes.number,
    isIncomplete: PropTypes.bool,
    networkValueTooltip: PropTypes.string,
    coloured: PropTypes.bool,
    colorThreasholds: PropTypes.object
};

export function CurrencyAndPercentCell({
    currencyValue,
    percentValue,
    isIncomplete,
    networkValueTooltip
}) {
    return (
        <Tooltip
            title={
                isIncomplete
                    ? 'Carrier data is incomplete for the selected timeframe.'
                    : networkValueTooltip
            }
        >
            <td
                className="number pointer"
                css={
                    isIncomplete
                        ? [incompleteStyleTreatment, { textDecoration: 'underline dotted' }]
                        : null
                }
            >
                {isIncomplete ? (
                    '%'
                ) : (
                    <>
                        <span>{toCurrency(currencyValue)}</span>&nbsp;(
                        <PercentSpan value={percentValue} showNegativeParenthesis={false} />)
                    </>
                )}
            </td>
        </Tooltip>
    );
}
CurrencyAndPercentCell.propTypes = {
    currencyValue: PropTypes.number,
    percentValue: PropTypes.number,
    isIncomplete: PropTypes.bool,
    networkValueTooltip: PropTypes.string
};

function PercentSpan({ value, numDigits, showNegativeParenthesis = true }) {
    const percentSpanClass = classNames({
        negative: value < 0,
        positive: value > 0
    });
    const hasParenthesis = showNegativeParenthesis && value < 0;
    return (
        <span className="percent-metric">
            <span className={percentSpanClass}>
                {hasParenthesis ? `(${toPercent(value, numDigits)})` : toPercent(value, numDigits)}
            </span>
        </span>
    );
}
PercentSpan.propTypes = {
    value: PropTypes.number,
    numDigits: PropTypes.number,
    showNegativeParenthesis: PropTypes.bool
};
