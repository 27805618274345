import { request } from './request';
import { Permissions } from './permissions';

// Get all Report Assignments
export function assignments() {
    return {
        queryKey: ['reports'],
        async queryFn() {
            const { body } = await request.get(`/api/reports/assignments`);
            return body;
        },
        staleTime: Infinity
    };
}

export function permission(user, action, thing) {
    return {
        queryKey: ['permissions', { user, action, thing }],
        async queryFn() {
            const permissions = new Permissions({ user });
            return await permissions.check(action, thing);
        },
        staleTime: Infinity
    };
}

// Get an Assigned Report
export function report(key) {
    return {
        queryKey: ['report', { key }],
        async queryFn() {
            const { body } = await request.get(`/api/reports/${key}`);
            return body;
        },
        staleTime: Infinity
    };
}

// Get an Assigned Report's Embed Configuration
export function reportConfig(assignmentId, live) {
    return {
        queryKey: ['report-config', { assignmentId }],
        async queryFn() {
            const { body } = await request
                .get(`/api/reports/${assignmentId}/config`)
                .query({ live });
            return body;
        },
        staleTime: Infinity
    };
}

// Get Subscriber information
export function subscriber(subscriberUuid) {
    return {
        queryKey: ['subscriber', { subscriberUuid }],
        async queryFn() {
            const { body } = await request
                .get(`/api/subscription/subscribers`)
                .query({ subscriberUuid });
            return body;
        },
        staleTime: Infinity
    };
}
