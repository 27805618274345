import styled from '@emotion/styled';

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: -20px;

    h1 {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 160%;
        letter-spacing: 0.15px;
        color: #3a3a3c;
    }
`;

export const RadioInputContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: baseline;
    margin-bottom: 12px;
    flex-direction: column;

    label {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.15px;
        color: #3a3a3c;
        margin: 7px 0;
        display: flex;
        input {
            margin-right: 8px;
        }
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: end;
`;

export const Button = styled.button`
    width: 110px;
    height: 40px;
    background: ${(p) => (p.isOutlined ? 'transparent' : '#3490DC')};
    border: ${(p) => (p.isOutlined ? '2px solid #3490DC' : 'none')};
    color: ${(p) => (p.isOutlined ? '#3490DC' : '#fff')};
    border-radius: 5px;
    cursor: ${(p) => (p.disable ? 'not-allowed' : 'pointer')};
    font-size: 16px;

    ${(p) => (p.disable ? 'pointer-events: none; opacity: 0.65;' : '')}
`;

export const RadioInput = styled.input`
    cursor: pointer;
`;

export const TxtContainer = styled.div`
    padding-top: 17px;
    p {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: #636366;
    }
`;

export const modalStyles = {
    overlay: {
        zIndex: 999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        top: null,
        bottom: null,
        left: null,
        right: null,
        borderRadius: '8px',
        boxShadow: '3px 3px 10px -1px rgba(0,0,0,0.3)',
        width: '600px'
    }
};

export const CancelIcon = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;
    > .fa-times:hover {
        color: #000000;
    }
    cursor: pointer;
`;

export const Content = styled.div`
    border-top: 1px solid #d1d1d6;
    border-bottom: 1px solid #d1d1d6;
    padding: 15px 0;
    .title {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        color: #636366;
        margin-bottom: 15px;
    }
`;

export const Information = styled.div`
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143%;
    letter-spacing: 0.15px;
    color: #3a3a3c;
    display: flex;
    border: 1px solid #b0bffd;
    border-radius: 5px;
    padding: 10px;
    align-items: center;
`;
