import { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import classNames from 'classnames';
import { noop, sortPolicyTypes } from '../../../utils';
import { getSummaryColumns } from './columns';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import { calculateNewBusinessNumbers } from '../utils';

const summaryColumns = getSummaryColumns();
const subColumns = [...summaryColumns];
subColumns[0] = {
    Header: 'Type',
    accessor: 'policyType',
    width: summaryColumns[0].columns[0].width,
    Cell: ({ value, original }) => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'left',
                    width: '100%',
                    marginLeft: '5px'
                }}
            >
                {value}
            </div>
        );
    }
};

const GrowthPlanMeasurementList = ({
    data,
    expandedRows,
    setExpandedRows,
    policyType,
    isRefetching,
    onSelect,
    selectedItems,
    resetSelected,
    setResetSelected,
    plan,
    searchTerm
}) => {
    const [selectAll, setSelectAll] = useState(1);
    const [defaultSorted, setDefaultSorted] = useState([
        {
            id: 'wp-ytd',
            desc: true
        }
    ]);

    useEffect(() => {
        if (resetSelected) {
            setSelectAll(1);
            setResetSelected(false);
        }
    }, [resetSelected]);

    const checkboxColumn = [
        {
            id: 'checkbox',
            accessor: '',
            show: false, // adding this line to hide checkboxes because the charts will be hidden.
            Cell: ({ original }) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            height: '100%',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <input
                            type="checkbox"
                            className="checkbox"
                            checked={selectedItems[original.carrierUuid] === true}
                            onChange={() => toggleRow(original.carrierUuid)}
                        />
                    </div>
                );
            },
            Header: (x) => {
                return (
                    <input
                        type="checkbox"
                        className="checkbox"
                        checked={selectAll === 1}
                        disabled={isRefetching || data.length === 0}
                        ref={(input) => {
                            if (input) {
                                input.indeterminate = selectAll === 2;
                            }
                        }}
                        onChange={() => toggleSelectAll()}
                    />
                );
            },
            sortable: false,
            width: 45
        }
    ];

    function processData(data) {
        let processedData = [];
        if (isRefetching) {
            return processedData;
        }
        const type = 'carrierUuid';
        data.forEach((item) => {
            if (policyType && item[policyType.code]) {
                const name = item[policyType.code].name || item[policyType.code].carrierName;
                const uuid = item[policyType.code][type];
                let newBusinessGoal = 0;
                let goal = 0;
                let varianceToGoal = 0;
                let wpVarianceToGoal = 0;
                let newBusinessGoalPercent = 0;
                let wpGoalPlan = 0;
                let rationalGoal = null;

                if (!!plan[uuid] && !!plan[uuid][policyType.code]) {
                    const planData = plan[uuid][policyType.code];
                    const { apNewBusiness, apPercentNewToBook } = calculateNewBusinessNumbers({
                        cpr: item[policyType.code],
                        planData
                    });
                    goal = planData.growthGoalPlan;
                    rationalGoal = { n: planData.wpGoalPlan, d: planData.trailing12WrittenPremium };
                    newBusinessGoal = apNewBusiness;
                    newBusinessGoalPercent = apPercentNewToBook;
                    varianceToGoal = item[policyType.code].newWrittenPremiumYTD - newBusinessGoal;

                    wpGoalPlan = item[policyType.code].priorWrittenPremiumYTD * (1 + goal / 100);
                    wpVarianceToGoal = item[policyType.code].writtenPremiumYTD - wpGoalPlan;
                }
                processedData.push({
                    ...item,
                    ...item[policyType.code],
                    name,
                    newBusinessGoal,
                    goal,
                    varianceToGoal,
                    wpVarianceToGoal,
                    newBusinessGoalPercent,
                    wpGoalPlan,
                    rationalGoal
                });
            }
        });
        return processedData;
    }

    const toggleRow = (uuid) => {
        const newSelected = { ...selectedItems };
        newSelected[uuid] = !selectedItems[uuid];
        if (newSelected[uuid] === false) {
            delete newSelected[uuid];
        }
        onSelect(newSelected);
        if (Object.keys(newSelected).length === 0) {
            setSelectAll(0);
            return;
        }
        setSelectAll(2);
    };

    const toggleSelectAll = () => {
        let newSelected = {};
        if (selectAll === 0) {
            data.forEach((x) => {
                newSelected[x.carrierUuid] = true;
            });
        }

        onSelect(newSelected);

        setSelectAll(selectAll === 0 ? 1 : 0);
    };

    const spliceColumns = ({ checkboxColumn, summaryColumns }) => {
        const summaryColumnsCopy = summaryColumns.slice();
        return checkboxColumn.concat(summaryColumnsCopy);
    };

    const processedData = processData(data);

    return (
        <>
            {policyType?.code === 'P&C' ? (
                <ReactTable
                    onSortedChange={(newSorted) => {
                        setDefaultSorted([...newSorted]);
                    }}
                    minRows={1}
                    columns={spliceColumns({ checkboxColumn, summaryColumns })}
                    noDataText={isRefetching ? '' : 'No data found'}
                    data={isRefetching === false ? processedData : []}
                    className={classNames('-highlight', {
                        'table--empty': isEmpty(data),
                        '-highlight': true
                    })}
                    onExpandedChange={(expanded, index, event) => {
                        let auxExpanded = [...expandedRows];
                        auxExpanded[index] = !auxExpanded[index];
                        setExpandedRows(auxExpanded);
                    }}
                    expanded={expandedRows}
                    loading={isRefetching}
                    defaultPageSize={data ? (data.length > 25 ? 25 : data.length) : 25}
                    resizable={false}
                    showPagination={data?.length > 25}
                    defaultSortDesc={true}
                    filtered={[{ id: 'name', value: searchTerm, state: [] }]}
                    defaultSorted={defaultSorted}
                    SubComponent={(rowInfo) => {
                        if (policyType.code === 'P&C') {
                            let subData = [];
                            policyType.children.forEach((pt) => {
                                let newBusinessGoal = 0;
                                let goal = 0;
                                let varianceToGoal = 0;
                                let wpVarianceToGoal = 0;
                                let newBusinessGoalPercent = 0;
                                if (rowInfo.original[pt.code]) {
                                    const type = 'carrierUuid';
                                    const uuid = rowInfo.original[pt.code][type];
                                    if (plan[uuid] && plan[uuid][pt.code]) {
                                        const planData = plan[uuid][pt.code];
                                        const { apNewBusiness, apPercentNewToBook } =
                                            calculateNewBusinessNumbers({
                                                cpr: rowInfo.original[pt.code],
                                                planData
                                            });
                                        goal = planData.growthGoalPlan;
                                        newBusinessGoal = apNewBusiness;
                                        newBusinessGoalPercent = apPercentNewToBook;
                                        varianceToGoal =
                                            rowInfo.original[pt.code].newWrittenPremiumYTD -
                                            newBusinessGoal;
                                        const wpGoalValue =
                                            rowInfo.original[pt.code].priorWrittenPremiumYTD *
                                            (1 + goal / 100);
                                        wpVarianceToGoal =
                                            rowInfo.original[pt.code].writtenPremiumYTD -
                                            wpGoalValue;
                                    }
                                    subData.push({
                                        ...rowInfo.original[pt.code],
                                        policyType: pt.name,
                                        goal,
                                        newBusinessGoal,
                                        varianceToGoal,
                                        wpVarianceToGoal,
                                        newBusinessGoalPercent
                                    });
                                }
                            });
                            return (
                                <div className="subtable">
                                    <ReactTable
                                        data={subData.sort(sortPolicyTypes)}
                                        columns={subColumns}
                                        defaultSorted={
                                            policyType?.code === 'P&C'
                                                ? []
                                                : [{ id: 'wp-ytd', desc: true }]
                                        }
                                        pageSize={subData.length}
                                        resizable={false}
                                        showPagination={false}
                                        TheadComponent={noop}
                                        TfootComponent={noop}
                                    />
                                </div>
                            );
                        }
                    }}
                />
            ) : (
                <ReactTable
                    onSortedChange={(newSorted) => {
                        setDefaultSorted([...newSorted]);
                    }}
                    minRows={1}
                    columns={spliceColumns({ checkboxColumn, summaryColumns })}
                    noDataText={isRefetching ? '' : 'No data found'}
                    data={isRefetching === false ? processedData : []}
                    className={classNames('-highlight', {
                        'table--empty': isEmpty(data),
                        '-highlight': true
                    })}
                    loading={isRefetching}
                    defaultPageSize={data.length > 25 ? 25 : data.length}
                    showPagination={data.length > 25}
                    defaultSortDesc={true}
                    filtered={[{ id: 'name', value: searchTerm, state: [] }]}
                    defaultSorted={defaultSorted}
                    SubComponent={null}
                />
            )}
        </>
    );
};

GrowthPlanMeasurementList.propTypes = {
    data: PropTypes.array,
    isRefetching: PropTypes.bool
};

export default GrowthPlanMeasurementList;
