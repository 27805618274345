import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Loader from '../shared/Loader';
import { useStoreActions, useStoreState } from 'easy-peasy';
import {
    SplashContainer,
    SplashImage,
    SplashImageContainer,
    SplashTextContainer
} from '../SplashScreen/styles';
import UnderstandingLogo from '../../../assets/understanding_large.png';
import { usePendoTracking } from '../../utils/pendo';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { initializeOnRamp } from '../../utils/onramp';

function isEmpty(obj = {}) {
    return obj !== null ? !Object.keys(obj).length : true;
}

const Auth = ({ children }) => {
    const [user, fetchingUser, userNotFound, userErrorMessage, standardTypes] = useStoreState(
        (state) => [
            state.user,
            state.fetchingUser,
            state.userNotFound,
            state.userErrorMessage,
            state.standardTypes
        ]
    );
    const [fetchUser, fetchStandardTypes] = useStoreActions((actions) => [
        actions.fetchUser,
        actions.fetchStandardTypes
    ]);
    usePendoTracking(user);
    useEffect(() => {
        if (!user) {
            Sentry.setUser(null);
            return;
        }

        void initializeOnRamp(user.email);
        Sentry.setUser({
            id: user.userUuid,
            email: user.email,
            ip_address: '{{auto}}',
            userType: user.userType
        });
        return () => Sentry.setUser(null);
    }, [user]);

    useEffect(() => {
        if (isEmpty(standardTypes)) {
            fetchStandardTypes();
        }
    }, []);

    useEffect(() => {
        if (fetchingUser) return;
        if (userErrorMessage) return;
        if (userNotFound) {
            const parts = window.location.pathname.split('/');
            if (!parts[1] || parts[1] !== 'login') {
                sessionStorage.setItem('auth:redirect', window.location.pathname);
                const redirect = `/auth/auth0`;

                window.location.href = redirect;
            }
            return;
        }
        if (user === null || (user !== null && isEmpty(user))) {
            fetchUser();
        }
    }, [user, userNotFound]);

    if (!fetchingUser && (user == null || isEmpty(user)) && !userErrorMessage) return null;

    if (fetchingUser) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
                <Loader type="ThreeDots" />
            </div>
        );
    }

    if (userErrorMessage) {
        return (
            <SplashContainer>
                <SplashImageContainer>
                    <SplashImage src={UnderstandingLogo} alt="splash" data-testid="splash" />
                    <SplashTextContainer>
                        <h1>Error</h1>
                        <p>{userErrorMessage}</p>
                    </SplashTextContainer>
                </SplashImageContainer>
            </SplashContainer>
        );
    }

    return children;
};
Auth.propTypes = {
    children: PropTypes.node
};

export default Auth;
