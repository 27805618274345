import { thunk } from 'easy-peasy';
import { request } from '../../utils/request';
import { getUser, getOnboardedCarriers, getStandardTypes } from '../../requests';
import { DELAY } from '../../constants';

export const thunks = {
    fetchOnboardedCarriers: thunk(async (actions, networkUuid) => {
        try {
            const onboardedCarriers = await getOnboardedCarriers(networkUuid);
            actions.setOnboardedCarriers(onboardedCarriers.body);
        } catch (e) {
            console.error(e);
        }
    }),

    fetchUser: thunk(async (actions) => {
        try {
            actions.setFetchingUser();
            const res = await getUser();
            const user = res && res.body && res.body.user;
            const error = res && res.body && res.body.errorMessage;
            if (user === null) {
                if (error) {
                    const errorMessage = error.replace(/\"/g, '') + '. Please contact support.';
                    actions.setUserErrorMessage({ errorMessage });
                    //alert(errorMessage);
                }
                actions.setUser({ user: null });
                actions.setUserNotFound();
            } else if (user.userType === 'agency') {
                actions.setUser({ user });
            } else {
                actions.setUser({ user });
            }
        } catch (e) {
            console.error(e);
            actions.setUserNotFound();
        }
    }),
    fetchStandardTypes: thunk(async (actions) => {
        try {
            const { body } = await getStandardTypes();
            actions.setStandardTypes(body);
        } catch (e) {
            console.error(e);
        }
    }),
    fetchManualEntries: thunk(async (actions, year) => {
        try {
            const results = await request.get(`/api/carrierentry/fetch?year=${year}`);
            actions.setManualEntryData(results.body);
            actions.setManualEntryDataByYear(results.body);
        } catch (e) {
            console.error(e);
        }
    }),
    fetchSubCodes: thunk(async (actions, selectedCarrier) => {
        try {
            const results = await request.get(
                `/api/subcodes/fetch?carrierUuid=${selectedCarrier.uuid}`
            );
            actions.setSubCodes(results.body);
        } catch (e) {
            console.error(e);
        }
    }),
    submitForm: thunk(async (actions, { form, year }) => {
        actions.setSubmitErrorMsg('');
        actions.setSubmittingForm(true);
        actions.setSubmittingFormMsg('Processing...');
        window.setTimeout(async () => {
            try {
                const result = await request.post('/api/carrierentry/new').send(form);
                if (result.body.recordset[0]) {
                    actions.setPolicyTable({ policyUuid: '', policyTypeCode: '' });
                    actions.setSubmittingFormMsg('Success');
                    window.setTimeout(() => {
                        actions.setSubmittingForm(false);
                        actions.setSubmittingFormMsg('');
                        actions.setFormModalOpen(false);
                    }, DELAY);
                    actions.resetManualEntryForm();
                    actions.fetchManualEntries(year);
                }
            } catch (e) {
                actions.setSubmitErrorMsg(
                    JSON.parse(JSON.parse(e.response.text).error.response.text).error
                );
                actions.setSubmittingForm(false);
                console.error(e);
            }
        }, DELAY);
    })
};
