import styled from '@emotion/styled';

export const QuestionContainer = styled.div`
    width: 400px;
    padding: 20px 30px;

    .title-container {
        width: 100%;
        text-align: center;
    }

    .buttons-container {
        display: flex;
        justify-content: space-between;
    }
`;
export const IconContainer = styled.div`
    width: 100%;
    text-align: center;

    svg {
        width: 70px;
        g path {
            stroke: ${(props) => props.iconColor};
        }
    }
`;

export const LoaderContainer = styled.div`
    position: absolute;
    width: 90%;
    height: 90%;
    display: grid;
    z-index: 999;
`;

export const CancelIcon = styled.div`
    position: absolute;
    right: 26px;
    > .fa-times:hover {
        color: gray;
    }
    cursor: pointer;
`;
