import { useState } from 'react';
import { Icon } from '../../icons/icons';
import { MenuContainer, MenuText, Item } from './styles';
import Menu from 'devextreme-react/menu';
import CollaborationModal from '../Modals/CarrierSelectionModal';
import ExitColaborationModal from '../Modals/ExitColaborationModal';

export default function CollaborationMenu() {
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isCollaborationModalOpen, setIsCollaborationModalOpen] = useState(false);

    function renderMenuItem(itemData) {
        return (
            <Item>
                <Icon
                    type={itemData.icon}
                    styles={{
                        width: '15px',
                        height: '15px',
                        marginRight: '5px',
                        display: 'inline-flex',
                        verticalAlign: 'middle'
                    }}
                    iColor={itemData.iconColor}
                />
                <MenuText>
                    {itemData.text} <i className="dx-icon-spindown"></i>
                </MenuText>
            </Item>
        );
    }

    const options = [
        {
            text: 'Collaboration mode active',
            icon: 'circle',
            iconColor: '#6FF937',
            items: [
                {
                    text: 'Exit collaboration mode',
                    icon: 'exit',
                    iconColor: '#3A3A3C',
                    onClick: () => {
                        setIsConfirmationOpen(true);
                    }
                },
                {
                    text: 'Collaboration mode settings',
                    icon: 'configuration',
                    iconColor: '#3A3A3C',
                    onClick: () => {
                        setIsCollaborationModalOpen(true);
                    }
                }
            ]
        }
    ];

    function onMenuItemClick(e) {
        if (e.itemData.onClick) {
            e.itemData.onClick();
        }
    }

    return (
        <MenuContainer>
            <Menu
                dataSource={options}
                displayExpr="text"
                orientation="horizontal"
                focusStateEnabled={false}
                showFirstSubmenuMode="onClick"
                onItemClick={onMenuItemClick}
                itemRender={renderMenuItem}
                adaptivityEnabled={true}
            />
            <ExitColaborationModal isOpen={isConfirmationOpen} setIsOpen={setIsConfirmationOpen} />
            {isCollaborationModalOpen && (
                <CollaborationModal
                    isOpen={isCollaborationModalOpen}
                    setIsOpen={setIsCollaborationModalOpen}
                    setIsExitModal={setIsConfirmationOpen}
                />
            )}
        </MenuContainer>
    );
}
