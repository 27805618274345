import { Button } from '@agencykpi/components';
import { QuestionContainer, LoaderContainer, IconContainer, CancelIcon } from './styles';
import Loader from '../Loader';
import { Icon } from '../../../icons/icons';
import Modal from 'react-modal';

const ConfirmationModal = ({
    isOpen,
    setIsOpen,
    onSubmit,
    message,
    isLoading = false,
    iconType,
    iconColor,
    headerText,
    cancelButtonText,
    confirmationButtonText
}) => {
    const modalStyles = {
        overlay: {
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        content: {
            top: null,
            bottom: null,
            left: null,
            right: null,
            maxWidth: '900px',
            borderRadius: '8px',
            boxShadow: '3px 3px 10px -1px rgba(0,0,0,0.3)'
        }
    };
    return (
        <div>
            {isLoading && (
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            )}
            <Modal
                isOpen={isOpen}
                onRequestClose={() => setIsOpen(false)}
                ariaHideApp={false}
                style={modalStyles}
            >
                <CancelIcon onClick={() => setIsOpen(false)}>
                    <Icon type="close" iWidth="20px" iColor="gray" />
                </CancelIcon>
                <QuestionContainer>
                    <div>
                        <IconContainer iconColor={iconColor}>
                            <Icon type={iconType} iWidth="70px" iColor={iconColor} />
                        </IconContainer>

                        <div className="title-container">
                            <h2>{headerText}</h2>
                        </div>

                        <div className="subtitle-container">
                            <p>{message}</p>
                        </div>

                        <div className="buttons-container">
                            <Button
                                onClick={() => {
                                    setIsOpen(false);
                                }}
                                type="button"
                                value="cancel"
                                style={{ width: '120px', marginTop: '20px' }}
                            >
                                {cancelButtonText}
                            </Button>
                            <Button
                                type={'submit'}
                                style={{
                                    backgroundColor: 'red',
                                    width: '120px',
                                    marginTop: '20px',
                                    color: 'white',
                                    border: 'none'
                                }}
                                onClick={onSubmit}
                            >
                                {confirmationButtonText}
                            </Button>
                        </div>
                    </div>
                </QuestionContainer>
            </Modal>
        </div>
    );
};

ConfirmationModal.propTypes = {};

export default ConfirmationModal;
