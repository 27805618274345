import { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography
} from '@mui/material';
import styled from '@emotion/styled';
import { Button } from '@agencykpi/components';
import { useStoreActions } from 'easy-peasy';
import { request } from '../../utils/request';
import Loader from '../shared/Loader';
import * as styles from './styles';
import { getSubscriberUsers } from '../../requests';

const StyledTableCell = styled(TableCell)({
    head: {
        fontSize: 16,
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        lineHeight: '1.2',
        textTransform: 'uppercase',
        fontFamily: 'Roboto, sans-serif',
        verticalAlign: 'top',
        color: '#869191'
    },
    body: {
        color: '#364343',
        fontFamily: 'Roboto, sans-serif',
        fontSize: 14
    }
});

const ModifyPrimaryUserDialog = ({
    adminUuid,
    agencyUuid,
    showModal,
    setShowModal,
    setPrimaryUserChange
}) => {
    const fetchUser = useStoreActions((actions) => actions.fetchUser);
    const [userList, setUserlist] = useState([]);
    const [fetching, setFetching] = useState(true);
    const [isConfirmation, setIsConfirmation] = useState(false);
    const [selectedUser, setSelectedUser] = useState(adminUuid);

    const handleListItemClick = (id) => {
        setSelectedUser(id);
    };

    const handleSave = async () => {
        setFetching(true);
        try {
            await request.post(`/api/subscription/change-primary-user`).send({
                subscriberUuid: agencyUuid,
                executorUuid: adminUuid,
                adminUserUuid: selectedUser
            });
            fetchUser();
            setFetching(false);
            setPrimaryUserChange(true);
            setShowModal(false);
        } catch (err) {
            console.log(err);
        }
    };

    const getAgencyUsers = async () => {
        try {
            const users = await getSubscriberUsers(agencyUuid);
            setUserlist(users);
            setFetching(false);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getAgencyUsers();
    }, []);

    return (
        <div>
            {fetching ? (
                <Loader />
            ) : (
                <Dialog maxWidth="md" open={showModal} onClose={() => setShowModal(true)}>
                    <DialogTitle id="alert-dialog-title">{'Change Primary User'}</DialogTitle>
                    <DialogContent>
                        {isConfirmation ? (
                            <>
                                <div>
                                    <Typography variant="h5">
                                        Are you sure you want to change the Primary User?
                                    </Typography>
                                </div>
                                <div css={styles.confirmationDiv}>
                                    <Button
                                        onClick={() => setIsConfirmation(false)}
                                        css={styles.confirmationButton}
                                        style={{ backgroundColor: '#f44336' }}
                                    >
                                        No
                                    </Button>
                                    <Button
                                        onClick={handleSave}
                                        css={styles.confirmationButton}
                                        style={{ backgroundColor: '#4caf50' }}
                                    >
                                        Yes
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Name</StyledTableCell>
                                            <StyledTableCell>Email</StyledTableCell>
                                            <StyledTableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {userList.map((row) => (
                                            <TableRow
                                                hover
                                                key={row.id}
                                                selected={selectedUser === row.id}
                                                onClick={() => handleListItemClick(row.id)}
                                            >
                                                <StyledTableCell component="th" scope="row">
                                                    {`${row.firstName} ${row.lastName}`}
                                                </StyledTableCell>
                                                <StyledTableCell>{`${row.email}`}</StyledTableCell>
                                                {adminUuid === row.id ? (
                                                    <StyledTableCell
                                                        style={{ whiteSpace: 'nowrap' }}
                                                    >
                                                        Primary User (You)
                                                    </StyledTableCell>
                                                ) : (
                                                    <StyledTableCell />
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </DialogContent>
                    {!isConfirmation && (
                        <DialogActions>
                            <Button onClick={() => setShowModal(false)}>Cancel</Button>
                            <Button
                                disabled={adminUuid === selectedUser}
                                onClick={() => setIsConfirmation(true)}
                            >
                                Make Primary User
                            </Button>
                        </DialogActions>
                    )}
                </Dialog>
            )}
        </div>
    );
};

export default ModifyPrimaryUserDialog;
