import styled from '@emotion/styled';

export const HeaderContainer = styled.div`
    position: sticky;
    top: 0;
    background-color: white;
    padding: 10px 0px;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
    & > div {
        flex: 1 0 33%;
        display: flex;
        align-items: center;
        :first-child {
            justify-content: flex-start;
        }
        :last-child {
            max-width: 290px;
            justify-content: flex-end;
        }
    }
    .akpi-logo {
        img {
            width: 90%;
        }
        padding-left: 20px;
        flex-basis: 15%;
        flex-grow: 0;
        min-width: 220px;
        max-width: 275px;
    }
    .header-logo {
        padding-right: 20px;
    }
`;

export const CollapsibleGroup = styled.div`
    padding: 0px 150px 0px 150px;
    .Collapsible {
        background-color: #3391c4;
        color: white;
        cursor: pointer;
        border: none;
        text-align: left;
        outline: none;
        font-size: 15px;
        .Collapsible__trigger {
            display: block;
            font-weight: 400;
            text-decoration: none;
            position: relative;
            border: 1px solid white;
            padding: 18px;
            color: white;
        }
        .Collapsible__contentOuter {
            background-color: rgb(245, 245, 249);
            color: black;
            ol li a {
                padding-right: 2px;
                padding-left: 2px;
            }
        }
    }
    .active,
    .Collapsible:hover {
        background-color: #477299;
    }
    .helpButton {
        background-color: #3391c4;
        color: white;
        margin: 4px 2px;
        border: none;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 14px;
        margin: 4px 2px;
        cursor: pointer;
    }
    .helpButton:hover {
        background-color: #477299;
    }
`;

export const H2 = styled.h2`
    font-size: 24px;
    color: #444;
    text-align: center;
`;

export const H3 = styled.h3`
    font-size: 12px;
    font-family: 'Arial';
    text-align: center;
    font-weight: normal;
    a {
        font-size: 16px;
        padding-left: 2px;
    }
`;
