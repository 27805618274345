import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Button } from '@agencykpi/components';

const modalStyles = {
    overlay: {
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        top: null,
        bottom: null,
        left: null,
        right: null,
        maxWidth: '900px',
        borderRadius: '8px',
        boxShadow: '3px 3px 10px -1px rgba(0,0,0,0.3)',
        textAlign: 'center'
    }
};

const confirmationDivStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
};
const buttonStyles = {
    width: '100px',
    color: '#FFF',
    border: 'none',
    marginRight: 8,
    padding: '12px 30px'
};

const MyModal = ({
    isConfirmation = false,
    isModalOpen,
    modalText,
    handleModalClick,
    ...props
}) => {
    return (
        <Modal isOpen={isModalOpen} ariaHideApp={false} style={modalStyles}>
            <div>{modalText}</div>
            {!isConfirmation ? (
                <Button
                    primary
                    style={{
                        width: '100px',
                        marginTop: '20px'
                    }}
                    onClick={handleModalClick}
                >
                    Ok
                </Button>
            ) : (
                <div style={confirmationDivStyles}>
                    <Button
                        primary
                        style={{ ...buttonStyles, backgroundColor: '#f44336' }}
                        onClick={props.handleModalDecline}
                    >
                        No
                    </Button>
                    <Button
                        primary
                        style={{ ...buttonStyles, backgroundColor: '#4caf50' }}
                        onClick={props.handleModalConfirmation}
                    >
                        Yes
                    </Button>
                </div>
            )}
        </Modal>
    );
};

MyModal.propTypes = {
    handleModalClick: PropTypes.func,
    isModalOpen: PropTypes.bool,
    modalText: PropTypes.string
};

export default MyModal;
