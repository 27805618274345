import { useStoreState } from 'easy-peasy';
import Modal from 'react-modal';
import { LoginLink } from '../Login/styles';
import { useStoreActions } from 'easy-peasy';
import { Centered } from '../shared';
import styled from '@emotion/styled';
import { request } from '../../utils/request';

const modalStyles = {
    overlay: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        top: null,
        bottom: null,
        left: null,
        right: null,
        maxWidth: '700px'
    }
};

export const Message = styled.h4`
    width: -webkit-fill-available;
`;

const ServerErrorHandler = (props) => {
    const { user, refreshTokenRevoked, serverError } = useStoreState((state) => state);
    const { message, hasLogut, hasContinue } = serverError;
    const [setServerError] = useStoreActions((actions) => [actions.setServerError]);

    return (
        <>
            {serverError.message && user.userType && !refreshTokenRevoked && (
                <Modal isOpen={message} style={modalStyles}>
                    <Centered>
                        <h3>The server has encountered an error.</h3>
                        <Message>{message}</Message>
                        <div>
                            {hasLogut && <LoginLink href="/logout">Go to login</LoginLink>}
                            {hasContinue && (
                                <LoginLink
                                    onClick={() =>
                                        setServerError({
                                            message: null,
                                            hasLogut: false,
                                            hasContinue: false
                                        })
                                    }
                                >
                                    Continue
                                </LoginLink>
                            )}
                        </div>
                    </Centered>
                </Modal>
            )}
        </>
    );
};

export default ServerErrorHandler;
