import styled from '@emotion/styled';

import theme from '../../theme';

const { primary } = theme.colors;

export const Button = styled.button`
    background-color: ${(props) => (props.primary ? primary : '#FFF')};
    border: 1px solid ${primary};
    border-radius: 5px;
    color: ${(props) => (props.primary ? 'white' : primary)};
    margin-top: 20px;
    padding: 10px 15px;
    font-size: 14px;
    &:hover {
        background-color: ${(props) => (props.primary ? '#2779BD' : primary)};
        color: white;
        cursor: pointer;
        transition: background-color, color 0.4s;
    }
    &:disabled {
        //background-color: ${theme.colors.gray};
        //border: 1px solid ${theme.colors.gray};
        opacity: 0.5;
        color: white;
        cursor: not-allowed;
    }
`;

export const Select = styled.select`
    display: block;
    font-size: 16px;
    font-family: sans-serif;
    //font-weight: 700;
    color: #444;
    line-height: 1.3;
    padding: 0.6em 1.4em 0.5em 0.8em;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 3px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
        linear-gradient(to bottom, #ffffff 0%, #fcfcfc 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 0.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;

    &::-ms-expand {
        display: none;
    }
    &:hover {
        border-color: #888;
    }
    &:focus {
        border-color: #aaa;
        box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
        box-shadow: 0 0 0 3px -moz-mac-focusring;
        color: #222;
        outline: none;
    }
    option {
        font-weight: normal;
    }
`;

export const Centered = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const DropdownContainer = styled.div`
    margin-bottom: 25px;
`;

export const PageWithTableWrap = styled.div`
    .card {
        font-size: 14px;
        background-color: white;
        padding: 10px;
        margin-bottom: 20px;
        border-radius: 4px;
        position: relative;
        border: 0;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.2);
    }
    table {
        width: 100%;
    }
    padding: 0px;
    .flex {
        display: flex;
        &.flex-align-center {
            align-items: center;
        }
        &.flex-align-end {
            align-items: flex-end;
        }
    }
    .pills {
        display: flex;
        margin-bottom: 15px;
    }
    .pill.selected {
        background-color: rgb(52, 144, 220);
        color: white;
    }
    .pill {
        margin-right: 5px;
        padding: 10px 15px;
        border-radius: 20px;
        cursor: pointer;
    }
    .pill:not(.selected):hover {
        background-color: #eee;
    }

    .subtable {
        padding: 15px 15px 15px 35px;
        background-color: #fafafe;
        box-shadow: inset 0px 3px 11px -6px rgba(0, 0, 0, 0.7);
        .ReactTable {
            background-color: white;
            .rt-thead {
                &.-header {
                    box-shadow: none;
                }
                .rt-th {
                    background-color: white;
                    box-shadow: none;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                }
            }
            .rt-tfoot {
                display: none;
            }
        }
    }
    .ReactTable {
        max-height: 800px;
        font-size: 14px;
        background-color: white;
        .text-right {
            text-align: right;
        }
        & .rt-tfoot {
            border-top: solid 1px #d8d8d8;
            background-color: #f5f5f9;
            font-weight: bold;
            > div .negative {
                color: ${theme.colors.red};
            }
            > div .positive {
                color: ${theme.colors.green};
            }
        }
        & .rt-thead .rt-th {
            font-weight: normal;
            &.written-premium-column-group {
                background-color: ${theme.colors.writtenPremium};
            }
            &.new-business-column-group {
                background-color: ${theme.colors.newBusiness};
            }
            &.trailing-column-group {
                background-color: ${theme.colors.trailing};
            }
            &.loss-ratio-column-group {
                background-color: ${theme.colors.lossRatio};
            }
        }
        & .rt-tbody .rt-td {
            &.written-premium-column-group {
                background-color: ${theme.colors.writtenPremiumTint};
            }
            &.new-business-column-group {
                background-color: ${theme.colors.newBusinessTint};
            }
            &.trailing-column-group {
                background-color: ${theme.colors.trailingTint};
            }
            &.loss-ratio-column-group {
                background-color: ${theme.colors.lossRatioTint};
            }
        }
        &.-highlight .rt-tbody .rt-tr:not(.-padRow) {
            transition: background-color 0.2s ease;
        }
        &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover,
        &.-highlight .rt-tr.-highlight--hovered:not(.-padRow),
        &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover .rt-td,
        &.-highlight .rt-tr.-highlight--hovered:not(.-padRow) .rt-td {
            background: rgba(255, 255, 0, 0.1);
            transition: background-color 0.2s ease;
        }
        .rt-thead {
            &.-header {
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
            }
            & .rt-th {
                background-color: #f5f5f9;
            }
            & .rt-th.-sort-desc,
            & .rt-td.-sort-desc {
                box-shadow: inset 0 -3px 0 0 rgba(28, 61, 90, 0.6);
            }
            & .rt-th.-sort-asc,
            & .rt-td.-sort-asc {
                box-shadow: inset 0 3px 0 0 rgba(28, 61, 90, 0.6);
            }
        }
        &.table--empty .rt-tbody {
            min-height: 100px;
        }
        .rt-td {
            padding: 10px;
        }
        a {
            text-decoration: underline;
        }
    }
    .slds-text-heading_medium {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    &-actions {
        display: flex;
        > * {
            margin-right: 40px;
        }
    }
    .ReactTable {
        margin-bottom: 30px;
    }
    .goals-input {
        width: 100%;
        text-align: right;
        padding-right: 5px;
    }
    .planning-tool-cards {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: space-around;
        //height: 438px;
        &.variant .planning-tool-card {
            &.written-premium-bg h3,
            &.new-business-bg h3,
            &.trailing-bg h3,
            &.loss-ratio-bg h3,
            &.goals-bg h3 {
                background-color: transparent;
            }
            &__header {
                border-bottom: 1px solid #f0f0f0;
                display: flex;
                justify-content: space-between;
                padding: 10px;
                h3 {
                    margin: 0;
                }
            }
            &::after {
                content: '';
                width: 100%;
                height: 5px;
                position: absolute;
                top: 0px;
                left: 0;
            }
            &.written-premium::after {
                background-color: ${theme.colors.writtenPremium};
            }
            &.new-business::after {
                background-color: ${theme.colors.newBusiness};
            }
            &.trailing::after {
                background-color: ${theme.colors.trailing};
            }
            &.loss-ratio::after {
                background-color: ${theme.colors.lossRatio};
            }
            &.goals::after {
                background-color: #f5f5f9;
            }
        }

        .planning-tool-card {
            flex: 1;
            max-height: 230px;
            max-width: 550px;
            min-width: 350px;
            overflow: hidden;

            &__header {
                padding-bottom: 10px;
            }
            &__body {
                padding-top: 10px;
                .policy-type-header {
                    width: 75px;
                }
            }
            h3 {
                font-size: 16px;
                font-family: 'Roboto';
                text-align: center;
                font-weight: normal;
            }
            table {
                thead th,
                tbody th {
                    font-weight: normal;
                    text-align: right;
                }
                thead th {
                    font-style: italic;
                }
                tbody th,
                td {
                    text-align: right;
                    padding: 5px 0;
                }
            }
        }

        .list-selector {
            display: flex;
            margin-bottom: 10px;
            &__button {
                cursor: pointer;
                padding: 8px 20px;
                border-radius: 20px;
                transition: background-color 0.2s;
                margin-right: 10px;
                &:hover {
                    background-color: #f0f0f0;
                }
                &.active {
                    background-color: #3490dc;
                    color: white;
                }
            }
        }
        .overview {
            position: relative;
        }
        .display-style-select {
            position: absolute;
            top: 10px;
            right: 20px;
            .akpi-select {
                display: flex;
                align-items: center;
            }
        }
        .list-table-search {
            flex-shrink: 0;
            flex-basis: 200px;
            margin-bottom: 10px;
            .slds-form-element {
                display: flex;
                align-items: center;
            }
        }
        .planning-tool-warning {
            color: #de751f;
            padding-left: 10px;
            cursor: pointer;
        }
        .planning-tool-subtitle svg {
            color: #2779bd;
            font-size: 16px;
            margin-left: 5px;
            cursor: pointer;
        }
    }
    .list-table-actions {
        display: flex;
        font-size: 14px;
        & > div {
            padding-right: 10px;
        }

        .list-table-search {
            flex-shrink: 0;
            flex-basis: 300px;
            .slds-form-element {
                display: flex;
                align-items: center;
            }
        }
        .custom-radio label {
            padding-top: 6px;
            padding-bottom: 6px;
        }
        .multiSelect_StandardTypesContainer {
            display: flex;
            alignitems: center;
            max-width: 500px;
            padding-right: 0px;
            .multiSelect_StandardTypes {
                margin: 0 10px;
            }
            .multiSelect_ApplyButton {
                margin: 0;
                height: 34px;
                text-align: center;
                padding: 8px 18px;
            }
            .multiSelect_Message {
                color: gray;
                font-size: small;
            }
        }
        .carrier-composites {
            display: flex;
            flex-wrap: wrap;
            width: 410px;
            input:checked ~ .checkbox-custom::after {
                top: 1px;
                left: 4px;
            }
            > div {
                min-width: 25%;
                margin-left: 10px;
            }
        }
        .timeFrameBtnGroup {
            margin-left: auto;
            div {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    .date-icon {
        float: right;
        margin-left: 10px;
        font-size: 10px;
        padding: 2px 7px;
        color: #112c3e;
        border-radius: 8px;
        &--default {
            background-color: #90cdf4;
        }
        &--success {
            background-color: #68d391;
            color: #11432c;
        }
        &--warning {
            background-color: #f6e05e;
            color: #441;
        }
        &--error {
            background-color: #fc8181;
            color: #331111;
        }
        &--trailing {
            background-color: #e4eaee;
        }
    }
    .percent-metric {
        & .negative {
            color: ${theme.colors.red};
        }
        & .positive {
            color: ${theme.colors.green};
        }
    }
`;
