import { modalStyles, CancelIcon, ConfirmationModalContainer } from './styles';
import Modal from 'react-modal';
import { useStoreActions } from 'easy-peasy';
import { Icon } from '../../../icons/icons';
import { Button } from '@agencykpi/components';
import { COLLABORATION_EXCLUSIONS } from '../../../constants';

const ExitColaborationModal = ({ isOpen, setIsOpen }) => {
    const setCollaborationMode = useStoreActions((actions) => actions.setCollaborationMode);

    const handleExitCollaborationMode = () => {
        setCollaborationMode({
            carriersUuid: [],
            carriersName: [],
            shareType: COLLABORATION_EXCLUSIONS.NAMES
        });
        setIsOpen(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={() => {
                setIsOpen(false);
            }}
            ariaHideApp={false}
            style={modalStyles}
        >
            <CancelIcon onClick={() => setIsOpen(false)}>
                <Icon type="close" iWidth="20px" iColor="gray" />
            </CancelIcon>

            <ConfirmationModalContainer>
                <div className="title-container">
                    <strong>Are you sure you would like to exit collaboration mode?</strong>
                </div>
                <div className="buttons-container">
                    <Button
                        onClick={() => setIsOpen(false)}
                        type="button"
                        value="cancel"
                        style={{ width: '120px', marginTop: '20px' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type={'submit'}
                        style={{
                            width: '120px',
                            marginTop: '20px',
                            border: 'none',
                            background: 'red',
                            color: 'white'
                        }}
                        onClick={() => {
                            handleExitCollaborationMode();
                        }}
                    >
                        Yes, exit
                    </Button>
                </div>
            </ConfirmationModalContainer>
        </Modal>
    );
};

export default ExitColaborationModal;
