import { toCurrency, toPercent } from '../../utils/index';
import classNames from 'classnames';
import { SelectedSummaryContainer } from './styles';

export default function SelectedSummaryPanel({ selectedSummary, kpiFieldNames }) {
    const { writtenPremium, baseCommission, additionalCommission, totalCommission } = kpiFieldNames;
    return (
        <SelectedSummaryContainer>
            <div
                className={classNames('section selection-summary', {
                    hidden: selectedSummary.count === 0,
                    visible: selectedSummary.count > 0
                })}
            >
                <div>
                    <div className="selection-summary__title">Selection Summary</div>
                    <div className="selection-summary__subtitle">{`${selectedSummary.count} selected`}</div>
                </div>
                <div className="">
                    <span className="selection-summary__label">Total Written Premium</span>
                    <span className="selection-summary__amount">
                        {toCurrency(selectedSummary[writtenPremium])}
                    </span>
                </div>
                <div className="">
                    <span className="selection-summary__label">Base Commission</span>
                    <span className="selection-summary__amount">
                        {toCurrency(selectedSummary[baseCommission])} (
                        {toPercent(selectedSummary.baseCommissionPercent)})
                    </span>
                </div>
                <div className="">
                    <span className="selection-summary__label">Additional Compensation</span>
                    <span className="selection-summary__amount">
                        {toCurrency(selectedSummary[additionalCommission])} (
                        {toPercent(selectedSummary.additionalCommissionPercent)})
                    </span>
                </div>
                <div className="">
                    <span className="selection-summary__label">Total Commission</span>
                    <span className="selection-summary__amount">
                        {toCurrency(selectedSummary[totalCommission])} (
                        {toPercent(selectedSummary.totalCommissionPercent)})
                    </span>
                </div>
            </div>
        </SelectedSummaryContainer>
    );
}
