import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { Centered } from '../../shared';
import {
    NoResults,
    Result,
    ResultsContainer,
    ResultsList,
    SearchAndResultsWrapper,
    SearchContainer,
    SearchInput
} from './styles';

const SubCodeSearch = ({
    results,
    searchTerm,
    onSelect,
    selectedSubCode,
    showResults,
    ...props
}) => {
    return (
        <SearchContainer>
            <SearchAndResultsWrapper>
                <SearchInput
                    name={props.inputName}
                    onChange={props.onInputChange}
                    placeholder="Search existing or enter new subcode..."
                    value={searchTerm}
                    type="text"
                    selectedSubCode={selectedSubCode}
                    maxLength={40}
                />
                {!!searchTerm.length && showResults && (
                    <ResultsContainer>
                        <Results
                            {...props}
                            results={results}
                            searchTerm={searchTerm}
                            onSelect={onSelect}
                        />
                    </ResultsContainer>
                )}
            </SearchAndResultsWrapper>
        </SearchContainer>
    );
};

const Results = ({ fetching, results, searchTerm, onSelect }) => {
    if (fetching)
        return (
            <Centered>
                <Loader type="Oval" color="#f0f0f9" />
            </Centered>
        );
    if (!results.length)
        return <NoResults>No subcodes found for &apos;{searchTerm}&apos;</NoResults>;

    return (
        <ResultsList>
            {results.map((subCode) => (
                <a onClick={onSelect.bind(null, subCode)} key={subCode.code}>
                    <Result>{`${subCode.code} - ${subCode.name}`}</Result>
                </a>
            ))}
        </ResultsList>
    );
};

Results.propTypes = {
    fetching: PropTypes.bool,
    results: PropTypes.array,
    searchTerm: PropTypes.string,
    onSelect: PropTypes.func
};

SubCodeSearch.propTypes = {
    searchTerm: PropTypes.string,
    companyType: PropTypes.string,
    results: PropTypes.array,
    Results: PropTypes.object,
    onTypeChange: PropTypes.func,
    handleInputChange: PropTypes.func,
    onSelect: PropTypes.func,
    selectedSubCode: PropTypes.any,
    showResults: PropTypes.bool,
    inputName: PropTypes.string,
    onInputChange: PropTypes.func
};

export default SubCodeSearch;
