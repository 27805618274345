import styled from '@emotion/styled';
import theme from '../../theme';
import '../../../node_modules/react-table/react-table.css';

export const TableFilterForm = styled.fieldset`
    display: flex;
    padding: 10px 20px;
    gap: 20px;
    align-items: center;
    background-color: white;
    justify-content: space-between;
    border: 1px solid rgb(221, 221, 221);
    border-bottom: none;
    margin: 20px 0 0;
`;

export const TableContainer = styled.div`
    .ReactTable {
        font-size: 14px;
        background-color: white;
        & .rt-tfoot {
            border-top: solid 1px #d8d8d8;
            background-color: #f5f5f9;
            font-weight: bold;
            > div .negative {
                color: ${theme.colors.red};
            }
            > div .positive {
                color: ${theme.colors.green};
            }
        }
        & .rt-thead .rt-th {
            font-weight: normal;
            &.written-premium-column-group {
                background-color: ${theme.standartColors.lightGreen};
            }
            &.new-business-column-group {
                background-color: ${theme.standartColors.lightBlue};
            }
            &.estimated-new-business-column-group {
                background-color: ${theme.standartColors.lightYellow};
            }
        }
        &.-highlight .rt-tbody .rt-tr:not(.-padRow) {
            transition: background-color 0.2s ease;
        }
        &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover,
        &.-highlight .rt-tr.-highlight--hovered:not(.-padRow),
        &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover .rt-td,
        &.-highlight .rt-tr.-highlight--hovered:not(.-padRow) .rt-td {
            background: rgba(255, 255, 0, 0.1);
            transition: background-color 0.2s ease;
        }
        .rt-thead {
            &.-header {
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
            }
            & .rt-th {
                background-color: #f5f5f9;
            }
            & .rt-th.-sort-desc,
            & .rt-td.-sort-desc {
                box-shadow: inset 0 -3px 0 0 rgba(28, 61, 90, 0.6);
            }
            & .rt-th.-sort-asc,
            & .rt-td.-sort-asc {
                box-shadow: inset 0 3px 0 0 rgba(28, 61, 90, 0.6);
            }
        }
        &.table--empty .rt-tbody {
            min-height: 100px;
        }
        .rt-td {
            padding: 10px;
            font-variant-numeric: tabular-nums;
        }
        a {
            text-decoration: underline;
        }
    }

    .subtable {
        .rt-table {
            background: hsl(240deg 25% 98%);
        }
    }
`;

export const HeaderWithIcon = styled.div`
    display: flex;
    justify-content: center;
`;
