import styled from '@emotion/styled';
import { Select } from '@agencykpi/components';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { ELIGIBLE_YEAR, ELIGIBLE_MONTH } from '../../constants';
import { yearOptions } from '../../utils';
import PropTypes from 'prop-types';

export const StyledYearSelect = styled.div`
    display: flex;
    justify-content: center;
    & .yearSelect {
        width: 101px;
        & > label {
            display: none;
        }
    }
`;

export default function YearSelector(props) {
    const year = useStoreState((state) => state.year);
    const [setYear, setMonth] = useStoreActions((actions) => [actions.setYear, actions.setMonth]);

    function handleYearSelect(selectedValue) {
        setYear(selectedValue);
        parseInt(selectedValue, 10) === ELIGIBLE_YEAR ? setMonth(ELIGIBLE_MONTH) : setMonth(1);
        if (props.onSelect) {
            props.onSelect(selectedValue);
        }
    }
    return (
        <StyledYearSelect>
            <Select
                items={props.validYears || yearOptions}
                className={'yearSelect'}
                value={year}
                onSelect={handleYearSelect}
                disabled={props.disabled}
            />
        </StyledYearSelect>
    );
}

YearSelector.propTypes = {
    validYears: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.any,
            value: PropTypes.any
        })
    ),
    onSelect: PropTypes.func,
    disabled: PropTypes.bool
};
