import { useMemo } from 'react';
import ReactTable from 'react-table';
import classNames from 'classnames';
import { noop, sortPolicyTypes } from '../../../utils';
import { getSummaryColumns } from './columns';
import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';

export default function NetworkGrowthPlanMeasurementTotal({ data, policyType, isLoading }) {
    let summaryColumns = getSummaryColumns();

    const subColumns = useMemo(() => {
        const subColumns = [...summaryColumns];

        subColumns[0] = {
            Header: 'Type',
            accessor: 'policyTypeName',
            width: summaryColumns[0].columns[0].width,
            Cell: ({ value, original }) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'left',
                            width: '100%',
                            marginLeft: '5px'
                        }}
                    >
                        {value}
                    </div>
                );
            }
        };

        return subColumns;
    }, [data]);

    const primaryRows = useMemo(() => {
        return data ? data.filter((item) => item.policyTypeCode === 'P&C') : [];
    }, [data]);
    const secondaryRows = useMemo(() => {
        return data
            ? data
                  .filter((item) => item.policyTypeCode !== 'P&C' && Boolean(item.policyTypeName))
                  .sort(sortPolicyTypes)
            : [];
    }, [data]);

    return (
        <ReactTable
            minRows={1}
            columns={summaryColumns}
            noDataText={isLoading ? '' : 'No data found'}
            data={primaryRows}
            className={classNames('-highlight', {
                'table--empty': isEmpty(data),
                '-highlight': true
            })}
            loading={isLoading}
            resizable={false}
            showPagination={false}
            showFooter={false}
            sortable={false}
            SubComponent={(rowInfo) => {
                if (policyType.code === 'P&C') {
                    return (
                        <div className="subtable">
                            <ReactTable
                                data={secondaryRows}
                                columns={subColumns}
                                pageSize={secondaryRows.length}
                                resizable={false}
                                showPagination={false}
                                TheadComponent={noop}
                                TfootComponent={noop}
                            />
                        </div>
                    );
                }
            }}
        />
    );
}

NetworkGrowthPlanMeasurementTotal.propTypes = {
    isLoading: PropTypes.bool,
    data: PropTypes.array,
    policyType: PropTypes.object
};
