import { getTotal, getChange, toCurrency, toPercent, addZ } from '../../../utils';
import { CurrencyCell, PercentCell } from '../../Table/CellTypes';
import { Icon } from '../../../icons/icons';
import classNames from 'classnames';
import { HeaderWithIcon } from '../styles';
import { Tooltip } from '@mui/material';
import { HeaderWithInfoTooltip } from '../shared';

function computeTotalRationalGoal(data) {
    if (data.length === 0) {
        return null;
    }
    const totalRational = data.reduce(
        (acc, row) => {
            const rational = row._original.rationalGoal;
            if (rational) {
                acc.n += rational.n;
                acc.d += rational.d;
            }
            return acc;
        },
        { n: 0, d: 0 }
    );
    const value = totalRational.n / totalRational.d;
    return <PercentCell value={(value - 1) * 100} />;
}

function getWpVarianceTotal(data, priorField, goalField, wpField) {
    let goalTotal = 0;
    let priorTotal = 0;
    let wpTotal = 0;
    data.forEach((row) => {
        if (row[priorField]) {
            priorTotal += row[priorField];
        }
        if (row[wpField]) {
            wpTotal += row[wpField];
        }
        if (row[goalField] && row[priorField]) {
            goalTotal += row[priorField] * (1 + row[goalField] / 100);
        }
    });
    let delta = goalTotal === 0 ? 0 : ((goalTotal - priorTotal) * 100) / priorTotal;
    const wpGoalValue = priorTotal * (1 + delta / 100);
    const wpVarianceToGoal = wpTotal - wpGoalValue;
    return <div>{toCurrency(wpVarianceToGoal)}</div>;
}

const currentDate = new Date();

export const getSummaryColumns = () => {
    const summaryColumns = [
        {
            id: 'name-group',
            Header: '',
            fixed: 'left',
            columns: [
                {
                    id: 'name',
                    Header: 'Name',
                    headerClassName: 'sub-header',
                    accessor: 'name',
                    width: 300,
                    Footer: <span className="footer">Totals:</span>,
                    Cell: ({ value, original }) => {
                        const name = value;
                        const currentMonth = parseInt(original.month, 10);
                        const latestMonth = parseInt(original.latestMonth, 10);
                        const isBehind = latestMonth - currentMonth > 1;
                        const recordDate = new Date(
                            `${original.year}-${addZ(original.month)}-01T12:00:00Z`
                        );
                        let dateIcon = null;
                        if (
                            recordDate.getFullYear() < currentDate.getFullYear() - 1 ||
                            (recordDate.getFullYear() === currentDate.getFullYear() - 1 &&
                                currentDate.getMonth() > 4)
                        ) {
                            dateIcon = (
                                <div className="date-icon date-icon--trailing">
                                    {recordDate.getFullYear()}
                                </div>
                            );
                        } else {
                            dateIcon = (
                                <div
                                    className={classNames({
                                        'date-icon': true,
                                        'date-icon--trailing': !isBehind,
                                        'date-icon--warning': isBehind,
                                        'date-icon--error': latestMonth - currentMonth > 4
                                    })}
                                >
                                    {recordDate.getMonth() + 1}/
                                    {recordDate.getFullYear().toString().substring(2)}
                                </div>
                            );
                        }
                        return (
                            <div className="titleSection">
                                <span className="planning-tool-warning name">{name}</span>
                                {dateIcon}
                            </div>
                        );
                    },
                    defaultSortDesc: false,
                    filterMethod: (filter, row, column, original) => {
                        if (!filter || (filter.value.length === 0 && filter.state.length === 0))
                            return true;
                        const textSearchFilter =
                            row.name.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
                        const stateSearchFilter = filter.state.includes(row._original.agencyState);
                        if (filter.value.length > 0 && filter.state.length > 0) {
                            return stateSearchFilter && textSearchFilter;
                        } else if (filter.value && filter.state.length === 0) {
                            return textSearchFilter;
                        } else {
                            return stateSearchFilter;
                        }
                    }
                }
            ]
        },
        {
            id: 'wpYtd',
            Header: 'Written Premium YTD',
            headerClassName: 'written-premium-column-group',
            columns: [
                {
                    id: 'wp-ytd',
                    Header: 'Current',
                    accessor: 'writtenPremiumYTD',
                    headerClassName: 'written-premium-column-group',
                    Footer: (row) => <span>{getTotal(row.data, 'wp-ytd')}</span>,
                    Cell: CurrencyCell
                },
                {
                    id: 'wp-ytd-prior',
                    show: false,
                    Header: 'Prior',
                    accessor: 'priorWrittenPremiumYTD',
                    headerClassName: 'written-premium-column-group',
                    Footer: (row) => <span>{getTotal(row.data, 'wp-ytd-prior')}</span>,
                    Cell: CurrencyCell
                },
                {
                    id: 'wp-change',
                    Header: 'Change',
                    accessor: (row) =>
                        row.priorWrittenPremiumYTD === 0 || row.priorWrittenPremiumYTD < 0
                            ? null
                            : ((row.writtenPremiumYTD - row.priorWrittenPremiumYTD) * 100) /
                              row.priorWrittenPremiumYTD,
                    headerClassName: 'written-premium-column-group',
                    Footer: (row) => <span>{getChange(row.data, 'wp-ytd', 'wp-ytd-prior')}</span>,
                    Cell: (row) => {
                        const dataTipMessage = 'Prior value is either 0 or negative';
                        if (row.value === null) {
                            return (
                                <Tooltip title={dataTipMessage}>
                                    <div>{'N/A'}</div>
                                </Tooltip>
                            );
                        } else {
                            return (
                                <div>
                                    {`${row.value.toFixed(2)}%`}
                                    {row.value === 0 && (
                                        <div
                                            style={{
                                                marginLeft: '10px',
                                                verticalAlign: 'middle',
                                                display: 'initial'
                                            }}
                                        >
                                            -
                                        </div>
                                    )}
                                    {row.value !== 0 &&
                                        (row.value > row.original.goal ? (
                                            <Icon
                                                type="arrow-up"
                                                iWidth="20px"
                                                iColor="#56ef44"
                                                styles={{
                                                    marginLeft: '10px',
                                                    verticalAlign: 'middle',
                                                    display: 'initial'
                                                }}
                                            />
                                        ) : (
                                            <Icon
                                                type="arrow-down"
                                                iWidth="20px"
                                                iColor="red"
                                                styles={{
                                                    marginLeft: '10px',
                                                    verticalAlign: 'middle',
                                                    display: 'initial'
                                                }}
                                            />
                                        ))}
                                </div>
                            );
                        }
                    }
                },
                {
                    id: 'wp-goal',
                    Header: () => {
                        return (
                            <HeaderWithInfoTooltip
                                title="Goal"
                                tooltip="Year-over-year written premium growth goal. (Current YTD - Prior YTD) / Prior YTD"
                            />
                        );
                    },
                    accessor: (row) => row.goal,
                    headerClassName: 'estimated-new-business-column-group',
                    Footer: (row) => <span>{computeTotalRationalGoal(row.data)}</span>,
                    Cell: (props) => {
                        const goalValue =
                            props.original.priorWrittenPremiumYTD * (1 + props.value / 100);
                        return (
                            <Tooltip title={`Written Premium Goal YTD: ${toCurrency(goalValue)}`}>
                                <div>
                                    <PercentCell {...props} />
                                </div>
                            </Tooltip>
                        );
                    }
                },
                {
                    id: 'wp-goal-plan',
                    Header: 'WP',
                    show: false,
                    accessor: 'wpGoalPlan',
                    Footer: (row) => <span>{getTotal(row.data, 'wp-goal-plan')}</span>,
                    Cell: CurrencyCell
                },
                {
                    id: 'wp-variance-to-goal',
                    Header: 'Variance to Goal',
                    accessor: 'wpVarianceToGoal',
                    headerClassName: 'estimated-new-business-column-group',
                    Footer: (row) => (
                        <span>
                            {getWpVarianceTotal(row.data, 'wp-ytd-prior', 'wp-goal', 'wp-ytd')}
                        </span>
                    ),
                    Cell: (row) => {
                        const dataTipMessage = 'Prior value is either 0 or negative';
                        if (row.value === null) {
                            return (
                                <Tooltip title={dataTipMessage}>
                                    <div>{'N/A'}</div>
                                </Tooltip>
                            );
                        } else {
                            return (
                                <div>
                                    {`${toCurrency(row.value)}`}
                                    {row.value === 0 && (
                                        <div
                                            style={{
                                                marginLeft: '10px',
                                                verticalAlign: 'middle',
                                                display: 'initial'
                                            }}
                                        >
                                            -
                                        </div>
                                    )}
                                    {row.value !== 0 &&
                                        (row.value > 0 ? (
                                            <Icon
                                                type="arrow-up"
                                                iWidth="20px"
                                                iColor="#56ef44"
                                                styles={{
                                                    marginLeft: '10px',
                                                    verticalAlign: 'middle',
                                                    display: 'initial'
                                                }}
                                            />
                                        ) : (
                                            <Icon
                                                type="arrow-down"
                                                iWidth="20px"
                                                iColor="red"
                                                styles={{
                                                    marginLeft: '10px',
                                                    verticalAlign: 'middle',
                                                    display: 'initial'
                                                }}
                                            />
                                        ))}
                                </div>
                            );
                        }
                    }
                }
            ]
        },
        {
            id: 'nbYtd',
            Header: 'New Business YTD',
            headerClassName: 'new-business-column-group',
            columns: [
                {
                    id: 'nb-ytd',
                    Header: 'Current',
                    accessor: 'newWrittenPremiumYTD',
                    headerClassName: 'new-business-column-group',
                    Footer: (row) => <span>{getTotal(row.data, 'nb-ytd')}</span>,
                    Cell: CurrencyCell
                }
            ]
        },
        {
            id: 'estNbYtd',
            Header: 'Estimated New Business',
            headerClassName: 'estimated-new-business-column-group',
            columns: [
                {
                    id: 'new-business-goal',
                    Header: () => {
                        return (
                            <HeaderWithInfoTooltip
                                title="Goal"
                                tooltip="The amount of new written premium needed year-to-date to be on track to hit your yearly new business goal. Pro-rated for the current month."
                            />
                        );
                    },
                    accessor: 'newBusinessGoal',
                    headerClassName: 'estimated-new-business-column-group',
                    Footer: (row) => <span>{getTotal(row.data, 'new-business-goal')}</span>,
                    Cell: (props) => {
                        return (
                            <Tooltip
                                title={`Annual New Business Goal: ${toPercent(
                                    props.original.newBusinessGoalPercent
                                )}`}
                            >
                                <div>
                                    <CurrencyCell {...props} />
                                </div>
                            </Tooltip>
                        );
                    }
                },
                {
                    id: 'variance-to-goal',
                    Header: 'Variance to Goal',
                    accessor: 'varianceToGoal',
                    headerClassName: 'estimated-new-business-column-group',
                    Footer: (row) => <span>{getTotal(row.data, 'variance-to-goal')}</span>,
                    Cell: (row) => {
                        return (
                            <div>
                                {
                                    <div style={{ display: 'inline-flex' }}>
                                        {toCurrency(row.value)}
                                    </div>
                                }
                                {row.value === 0 && (
                                    <div
                                        style={{
                                            marginLeft: '10px',
                                            verticalAlign: 'middle',
                                            display: 'initial'
                                        }}
                                    >
                                        -
                                    </div>
                                )}
                                {row.value !== 0 &&
                                    (row.value > 0 ? (
                                        <Icon
                                            type="arrow-up"
                                            iWidth="20px"
                                            iColor="#56ef44"
                                            styles={{
                                                marginLeft: '10px',
                                                verticalAlign: 'middle',
                                                display: 'initial'
                                            }}
                                        />
                                    ) : (
                                        <Icon
                                            type="arrow-down"
                                            iWidth="20px"
                                            iColor="red"
                                            styles={{
                                                marginLeft: '10px',
                                                verticalAlign: 'middle',
                                                display: 'initial'
                                            }}
                                        />
                                    ))}
                            </div>
                        );
                    }
                }
            ]
        }
    ];

    return summaryColumns;
};
