import { createTheme } from '@mui/material';

export default createTheme({
    colors: {
        primary: 'rgb(232, 183, 0)',
        secondary: '#305778',
        gray: '#606f7b',
        green: '#017d01',
        red: '#cc1f1a',
        textLight: '#f0f0f9',
        textBlue: '#8ba3b6',
        backgroundLight: '#fbf9f4',
        borderColor: '#cccccf',
        darkGray: '#333',
        textDark: '#423400',
        tint: 'rgb(244,233,183)',
        textTint: '#4d3c00',
        // writtenPremium: '#008c47', //'#38c172',
        // writtenPremiumTint: '#ecf9f2', //'#f9fef9',
        // newBusiness: '#0094e5', //'#3490dc',
        // newBusinessTint: '#e8f5fc', //'#eff8ff',
        // trailing: '#893cc4', //'#6574cd',
        // trailingTint: '#f4e5ff', //'#e6e8ff',
        // lossRatio: '#ff6a1e', //'#f6993f',
        // lossRatioTint: '#fde8dd' //'#fff5eb'
        writtenPremium: '#b5b562',
        writtenPremiumTint: '#b5b56214',
        newBusiness: '#6d96bc',
        newBusinessTint: '#6d96bc14',
        trailing: '#887fa0',
        trailingTint: '#e6e8ff',
        lossRatio: '#d6747b',
        lossRatioTint: '#d6747b14'
    },
    standartColors: {
        lightGreen: 'rgba(56, 193, 114, 0.8)', //'#38c172',
        lightBlue: 'rgba(52, 144, 220, 0.8)', // '#3490dc',
        lightYellow: '#ffd866'
    },
    fonts: {
        default: 'Rubik, Roboto, sans serif'
    }
});
