import moment from 'moment';
import { toCurrency, toMonthName } from '../../utils';
import CoreCarrierIcon from '../CoreCarrierIcon';
import classNames from 'classnames';
import { TOOL_TIPS } from '../../constants/tooltips';
import { Icon } from '../../icons/icons';
import { CertifiedIconContainer, HiddenInfo } from './styles';
import * as formulas from './shared/formulas';
import { ChangeCell } from '../Table/CellTypes';
import PropTypes from 'prop-types';
import { Tooltip, Box } from '@mui/material';
import { useCollaborationModeHiddenName } from './hooks';
import {
    formatYearMonth,
    getYearMonth,
    isEmptyYearMonth,
    statusCarrierReportDateInTimeframe
} from './shared/yearMonth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';

const CurrencyCell = (props) => {
    const toolTipText = props.toolTipText || '';
    const value = props.value || 0;
    return (
        <Tooltip title={toolTipText}>
            <div style={toolTipText.length > 0 ? { cursor: 'pointer' } : { cursor: 'default' }}>
                {toCurrency(value)}
            </div>
        </Tooltip>
    );
};

const getToolTipTextDollar = (networkCarrierTotals, original, metricKey, tableDataType) => {
    if (tableDataType !== 'carriers') return '';
    if (original.isFetchingNetwork) {
        return 'Please wait...';
    }
    if (
        !networkCarrierTotals[original.carrierUuid] ||
        !networkCarrierTotals[original.carrierUuid][original.policyTypeCode] ||
        networkCarrierTotals[original.carrierUuid][original.policyTypeCode][metricKey] === 0
    )
        return 'N/A';
    let percentOfNetwork = 0;
    const networkCarrierTotal =
        networkCarrierTotals[original.carrierUuid] &&
            networkCarrierTotals[original.carrierUuid][original.policyTypeCode]
            ? networkCarrierTotals[original.carrierUuid][original.policyTypeCode][metricKey]
            : 0;
    const agencyCarrierTotal = original[metricKey];
    percentOfNetwork = ((agencyCarrierTotal / networkCarrierTotal) * 100).toFixed(2);
    return `${percentOfNetwork}% of Network Total ${toCurrency(networkCarrierTotal)}`;
};

const getToolTipTextBookPercent = (
    networkCarrierTotals,
    original,
    currentMetricKey,
    priorMetricKey,
    tableDataType
) => {
    if (tableDataType !== 'carriers') return '';
    if (original.isFetchingNetwork) {
        return 'Please wait...';
    }
    if (
        !networkCarrierTotals[original.carrierUuid] ||
        !networkCarrierTotals[original.carrierUuid][original.policyTypeCode] ||
        (networkCarrierTotals[original.carrierUuid][original.policyTypeCode][currentMetricKey] ===
            0 &&
            networkCarrierTotals[original.carrierUuid][original.policyTypeCode][priorMetricKey] ===
            0) ||
        networkCarrierTotals[original.carrierUuid][original.policyTypeCode][priorMetricKey] === 0
    )
        return 'N/A';
    // Network
    const newBusinessTotal =
        networkCarrierTotals[original.carrierUuid][original.policyTypeCode][currentMetricKey];
    const writtenPremiumTotal =
        networkCarrierTotals[original.carrierUuid][original.policyTypeCode][priorMetricKey];

    const networkChange = (newBusinessTotal * 100) / writtenPremiumTotal;

    return `Network ${networkChange.toFixed(2)}%`;
};

const getToolTipTextLossRatioPercent = (
    networkCarrierTotals,
    original,
    currentMetricKey,
    priorMetricKey,
    tableDataType
) => {
    if (tableDataType !== 'carriers') return '';
    if (original.isFetchingNetwork) {
        return 'Please wait...';
    }
    if (
        !networkCarrierTotals[original.carrierUuid] ||
        !networkCarrierTotals[original.carrierUuid][original.policyTypeCode] ||
        (networkCarrierTotals[original.carrierUuid][original.policyTypeCode][currentMetricKey] ===
            0 &&
            networkCarrierTotals[original.carrierUuid][original.policyTypeCode][priorMetricKey] ===
            0) ||
        networkCarrierTotals[original.carrierUuid][original.policyTypeCode][priorMetricKey] === 0
    )
        return 'N/A';
    // Network
    const incuredLostTotal =
        networkCarrierTotals[original.carrierUuid][original.policyTypeCode][currentMetricKey];
    const earningPremiumTotal =
        networkCarrierTotals[original.carrierUuid][original.policyTypeCode][priorMetricKey];
    const networkChange = (incuredLostTotal * 100) / earningPremiumTotal;

    return `Network ${networkChange.toFixed(2)}%`;
};

const getToolTipTextChangePercent = (
    networkCarrierTotals,
    original,
    currentMetricKey,
    priorMetricKey,
    tableDataType
) => {
    if (tableDataType !== 'carriers') return '';
    if (original.isFetchingNetwork) {
        return 'Please wait...';
    }
    if (
        !networkCarrierTotals[original.carrierUuid] ||
        !networkCarrierTotals[original.carrierUuid][original.policyTypeCode] ||
        (networkCarrierTotals[original.carrierUuid][original.policyTypeCode][currentMetricKey] ===
            0 &&
            networkCarrierTotals[original.carrierUuid][original.policyTypeCode][priorMetricKey] ===
            0) ||
        networkCarrierTotals[original.carrierUuid][original.policyTypeCode][priorMetricKey] === 0
    )
        return 'N/A';
    // Network
    const currentNetworkCarrierTotal =
        networkCarrierTotals[original.carrierUuid][original.policyTypeCode][currentMetricKey];
    const priorNetworkCarrierTotal =
        networkCarrierTotals[original.carrierUuid][original.policyTypeCode][priorMetricKey];
    const networkChange =
        ((currentNetworkCarrierTotal - priorNetworkCarrierTotal) * 100) / priorNetworkCarrierTotal;

    return `Network ${networkChange.toFixed(2)}%`;
};

const PercentCell = (props) => {
    // This SHOULD be a temporary change to handle malformatted manual entry data. The `value` of these rows is not a number like we expect, it's the entire object. We could work around it by finding the specific year/month we need from that object, but currently all values are 0.
    // A fix for https://agencykpi.atlassian.net/browse/BUGS-444 should eliminate the need for this.
    if (isNaN(Number(props.value))) return IncompleteDataCell();

    const value = Number(props.value) || 0;
    const toolTipText = props.toolTipText || '';
    const percentClasses = props.noColor ? '' : classNames('percentMetric', classNames(
        props.colorThreasholds ? props.colorThreasholds(value) : {
            negative: value < 0,
            positive: value > 0
        }));
    return (
        <Tooltip title={toolTipText}>
            <div
                className={percentClasses}
                style={toolTipText.length > 0 ? { cursor: 'pointer' } : { cursor: 'default' }}
            >
                {value.toFixed(2)} %
            </div>
        </Tooltip>
    );
};

/**
 *
 * @param {any[]} data
 * @param {string} key
 * @param {object} [options]
 * @param {boolean} [options.dependsOnPrior]
 * @param {boolean} [options.dependsOnCurrent]
 * @return {JSX.Element}
 */
function getTotal(data, key, options) {
    const { dependsOnPrior, dependsOnCurrent } = Object.assign(
        {
            dependsOnCurrent: false,
            dependsOnPrior: false
        },
        options
    );
    let total = 0;
    let incomplete = false;
    for (const row of data) {
        if (row[key]) {
            total += row[key];
        }
        if (!incomplete && dependsOnPrior && row['incomplete-prior']) {
            incomplete = true;
        }
        if (!incomplete && dependsOnCurrent && row['incomplete-current']) {
            incomplete = true;
        }
    }
    if (incomplete) {
        return MissingDataCell(total);
    }
    return <div>{toCurrency(total)}</div>;
}

/**
 * @param {any[]} data
 * @param {string} currentKey
 * @param {string} priorKey
 * @return {JSX.Element}
 */
function getChange(data, currentKey, priorKey) {
    let currentKeyTotal = 0;
    let priorKeyTotal = 0;
    let incomplete = false;
    for (const row of data) {
        if (row[currentKey]) {
            currentKeyTotal += row[currentKey];
        }
        if (row[priorKey]) {
            priorKeyTotal += row[priorKey];
        }
        if (!incomplete && (row['incomplete-current'] || row['incomplete-prior'])) {
            incomplete = true;
        }
    }
    if (incomplete) {
        return IncompleteDataCell();
    }
    let delta = priorKeyTotal === 0 ? 0 : ((currentKeyTotal - priorKeyTotal) * 100) / priorKeyTotal;
    return <div>{delta.toFixed(2)} %</div>;
}

const getPercentBook = (data, newKey, wpKey) => {
    let newKeyTotal = 0;
    let wpKeyTotal = 0;
    data.forEach((row) => {
        if (row[newKey]) {
            newKeyTotal += row[newKey];
        }

        if (row[wpKey]) {
            wpKeyTotal += row[wpKey];
        }
    });
    let delta = wpKeyTotal === 0 ? 0 : (newKeyTotal * 100) / wpKeyTotal;
    return <div>{delta.toFixed(2)} %</div>;
};

/**
 *
 * @param {any[]} data
 * @param {string} earnedKey
 * @param {string} incurredKey
 * @param {object} [options]
 * @param {boolean} [options.dependsOnPrior]
 * @param {boolean} [options.dependsOnCurrent]
 * @return {JSX.Element}
 */
function getCumulativeLossRatio(data, earnedKey, incurredKey, options) {
    const { dependsOnPrior, dependsOnCurrent } = Object.assign(
        {
            dependsOnCurrent: false,
            dependsOnPrior: false
        },
        options
    );
    let totalIncurred = 0;
    let totalEarned = 0;
    let incomplete = false;
    for (const row of data) {
        if (row[incurredKey]) {
            totalIncurred += row[incurredKey];
        }
        if (row[earnedKey]) {
            totalEarned += row[earnedKey];
        }
        if (!incomplete && dependsOnPrior && row['incomplete-prior']) {
            incomplete = true;
        }
        if (!incomplete && dependsOnCurrent && row['incomplete-current']) {
            incomplete = true;
        }
    }
    if (incomplete) {
        return IncompleteDataCell();
    }
    let lossRatio = formulas.calculateLossRatio(totalEarned, totalIncurred);
    return <div>{lossRatio.toFixed(2)} %</div>;
}

const MissingDataCell = (value) => {
    return (
        <Tooltip title="Carrier data is incomplete for the selected timeframe.">
            <div style={{ cursor: 'default', color: '#AEAEB2' }}>{toCurrency(value)}</div>
        </Tooltip>
    );
};

const IncompleteDataCell = () => {
    return (
        <Tooltip title="Carrier data is incomplete for the selected timeframe.">
            <div
                style={{ cursor: 'default', color: '#AEAEB2', textDecoration: 'underline dotted' }}
            >
                %
            </div>
        </Tooltip>
    );
};

function ReportRecencyBadge({
    timeframe,
    latestYearMonth,
    carrierName,
    carrierReportedMonths,
    requiredReportedMonths
}) {

    const status = latestYearMonth ? statusCarrierReportDateInTimeframe(latestYearMonth, timeframe) : 'ok';

    const dataHealthSummary = latestYearMonth ?
        carrierReportHealthSummaryText(requiredReportedMonths, carrierReportedMonths,
            status === 'error' ? { year: 0, month: 0 } : latestYearMonth, carrierName) : '';

    return (
        <Tooltip title={dataHealthSummary}>
            <div
                className={classNames({
                    'date-icon': true,
                    'date-icon--trailing': status === 'ok',
                    'date-icon--warning': status === 'warning',
                    'date-icon--error': status === 'error'
                })}
                style={{ marginLeft: 0 }}
            >
                {formatYearMonth(latestYearMonth)}
            </div>
        </Tooltip>
    );
}

function CertifiedCarrierBadge() {
    return (
        <Tooltip title={'AKPI Certified'}>
            <Box>
                <CertifiedIconContainer>
                    <Icon type="certified" iColor="#3caaff" iWidth="15px" />
                </CertifiedIconContainer>
            </Box>
        </Tooltip>
    );
}

function carrierReportHealthSummaryText(
    requiredReportedMonths,
    reportedMonths,
    reportMonth,
    carrierName
) {
    if (isEmptyYearMonth(reportMonth)) {
        return `${carrierName} has no data in the selected time period.`;
    }
    const reportedMonthsString =
        reportedMonths && reportedMonths !== requiredReportedMonths
            ? `with ${reportedMonths} of ${Number(requiredReportedMonths)} months reported`
            : '';
    const dataTipString = `${carrierName} last reported in ${toMonthName(
        reportMonth.month
    )} ${reportedMonthsString}`;

    return dataTipString;
}

function NameCell({ name, nameIsHidden, badges }) {
    return (
        <div className="titleSection">
            <span className="planning-tool-warning name">
                {nameIsHidden ? <HiddenInfo>{name}</HiddenInfo> : <span>{name}</span>}
            </span>

            {badges}
        </div>
    );
}
NameCell.propTypes = {
    name: PropTypes.string,
    nameIsHidden: PropTypes.bool,
    badges: PropTypes.node
};
function AgencyNameCell({ value }) {
    return <NameCell name={value} />;
}

function CarrierNameCell({ value, original, column: { requiredReportedMonths, timeframe } }) {
    const { name, isHidden: hideName } = useCollaborationModeHiddenName(
        original.carrierUuid,
        value
    );

    return (
        <NameCell
            name={name}
            nameIsHidden={hideName}
            badges={
                <>
                    {original.isAKPICertified ? <CertifiedCarrierBadge /> : null}
                    {original.isCoreCarrier ? <CoreCarrierIcon tooltip={'Key Relationship'} /> : null}
                </>} />);
}

function CarrierBadgeCell({ value, original, column: { requiredReportedMonths, timeframe } }) {

    const { name } = useCollaborationModeHiddenName(original.carrierUuid, original.carrierName);

    return <ReportRecencyBadge
        latestYearMonth={original.latestYearMonth}
        carrierUuid={original.carrierUuid}
        carrierName={name}
        timeframe={timeframe}
        requiredReportedMonths={requiredReportedMonths}
        carrierReportedMonths={original.reportedMonths}
    />
}

export const getSelectedColumns = function () {
    const summaryColumns = [
        {
            id: 'name-group',
            Header: '',
            fixed: 'left',
            columns: [
                {
                    id: 'name',
                    Header: 'Selection Summary',
                    headerClassName: 'sub-header',
                    accessor: 'count',
                    Cell: (row) => {
                        return (
                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                <span>{row.value} selected</span>
                            </div>
                        );
                    }
                }
            ]
        },
        {
            id: 'wpYtd',
            Header: 'Written Premium',
            headerClassName: 'written-premium-column-group',
            columns: [
                {
                    id: 'wp-ytd',
                    Header: 'Current',
                    width: 120,
                    accessor: 'writtenPremium',
                    headerClassName: 'written-premium-column-group',
                    Cell: CurrencyCell
                },
                {
                    id: 'wp-ytd-prior',
                    Header: 'Prior',
                    width: 120,
                    accessor: 'priorWrittenPremium',
                    headerClassName: 'written-premium-column-group',
                    Cell: CurrencyCell
                },
                {
                    id: 'wp-change',
                    Header: 'Change',
                    accessor: 'changeWrittenPremium',
                    headerClassName: 'written-premium-column-group',
                    Cell: (row) => ChangeCell(row)
                }
            ]
        },
        {
            id: 'nbYtd',
            Header: 'New Business',
            headerClassName: 'new-business-column-group',
            columns: [
                {
                    id: 'nb-ytd',
                    Header: 'Current',
                    accessor: 'newWrittenPremium',
                    headerClassName: 'new-business-column-group',
                    Cell: CurrencyCell
                },
                {
                    id: 'nb-ytd-prior',
                    Header: 'Prior',
                    accessor: 'priorNewWrittenPremium',
                    headerClassName: 'new-business-column-group',
                    Cell: CurrencyCell
                },
                {
                    id: 'nb-ytd-change',
                    Header: 'Change',
                    accessor: 'changeNewBusiness',
                    headerClassName: 'new-business-column-group',
                    Cell: (row) => ChangeCell(row)
                },
                {
                    id: 'nb-change',
                    Header: '% of Book',
                    accessor: 'percentageOfBookNewBusiness',
                    headerClassName: 'new-business-column-group',
                    Cell: (row) => ChangeCell(row)
                }
            ]
        },
        {
            id: 'lossRatio',
            Header: 'Loss Ratio',
            headerClassName: 'loss-ratio-column-group',
            columns: [
                {
                    id: 'earned',
                    Header: 'EP',
                    accessor: 'earned',
                    headerClassName: 'loss-ratio-column-group',
                    Cell: CurrencyCell
                },
                {
                    id: 'incurred',
                    Header: 'IL',
                    accessor: 'incurred',
                    headerClassName: 'loss-ratio-column-group',
                    Cell: CurrencyCell
                },
                {
                    id: 'lossRatioSubheader',
                    Header: 'Current',
                    accessor: 'currentLossRatio',
                    headerClassName: 'loss-ratio-column-group',
                    Cell: (row) => ChangeCell(row)
                },
                {
                    id: 'priorLossRatioSubheader',
                    Header: 'Prior',
                    accessor: 'priorLossRatio',
                    headerClassName: 'loss-ratio-column-group',
                    Cell: (row) => ChangeCell(row)
                }
            ]
        }
    ];

    return summaryColumns;
};

export const getSummaryColumns = function (
    networkCarrierTotals,
    tableDataType,
    getSelectedKey,
    getSelectedTitle,
    timeframe
) {
    const monthsDifference = moment(moment(timeframe.endDate)).diff(timeframe.startDate, 'months');
    const requiredReportedMonths = monthsDifference + 1;

    const nameGroupCols = [{
        id: 'name',
        Header: 'Name',
        headerClassName: 'sub-header',
        accessor: 'name',
        Footer: <span className="footer">Totals:</span>,
        Cell: tableDataType === 'carriers' ? CarrierNameCell : AgencyNameCell,
        timeframe: {
            start: getYearMonth(timeframe.startDate),
            end: getYearMonth(timeframe.endDate)
        },
        requiredReportedMonths,
        defaultSortDesc: false,
        filterMethod: (filter, row) => {
            if (!filter || !filter.value) return true;
            return row.name.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
        }
    }];

    if (tableDataType === 'carriers')
        nameGroupCols.push(
            {
                id: 'badgeName',
                Header: <FontAwesomeIcon icon={faCalendarAlt} />,
                headerClassName: 'sub-header',
                accessor: 'latestYearMonthStamp',
                Cell: CarrierBadgeCell,
                timeframe: {
                    start: getYearMonth(timeframe.startDate),
                    end: getYearMonth(timeframe.endDate)
                },
                requiredReportedMonths,
                defaultSortDesc: false,
                filterMethod: (filter, row) => {
                    if (!filter || !filter.value) return true;
                    return row.name.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
                }
            });

    return [
        {
            id: 'name-group',
            Header: '',
            fixed: 'left',
            columns: nameGroupCols
        },
        {
            id: 'incomplete-current',
            accessor: 'haveMissingCurrentData',
            show: false
        },
        {
            id: 'incomplete-prior',
            accessor: 'haveMissingPriorData',
            show: false
        },
        {
            id: 'wpYtd',
            Header: () => (
                <Tooltip title={TOOL_TIPS.writtenPremium}>
                    <div>{getSelectedTitle('writtenPremium')}</div>
                </Tooltip>
            ),
            headerClassName: 'written-premium-column-group',
            columns: [
                {
                    id: 'wp-ytd',
                    Header: 'Current',
                    accessor: getSelectedKey('writtenPremium'),
                    headerClassName: 'written-premium-column-group',
                    Cell: ({ value, original }) => {
                        const metricKey = getSelectedKey('writtenPremium');
                        const toolTipText = getToolTipTextDollar(
                            networkCarrierTotals,
                            original,
                            metricKey,
                            tableDataType
                        );

                        if (!original.haveMissingCurrentData) {
                            return CurrencyCell({ value, toolTipText: toolTipText });
                        }

                        return MissingDataCell(value);
                    },
                    Footer: (row) => (
                        <span>{getTotal(row.data, 'wp-ytd', { dependsOnCurrent: true })}</span>
                    )
                },
                {
                    id: 'wp-ytd-prior',
                    Header: 'Prior',
                    accessor: getSelectedKey('priorWrittenPremium'),
                    headerClassName: 'written-premium-column-group',
                    Cell: ({ value, original }) => {
                        const metricKey = getSelectedKey('priorWrittenPremium');
                        const toolTipText = getToolTipTextDollar(
                            networkCarrierTotals,
                            original,
                            metricKey,
                            tableDataType
                        );
                        if (!original.haveMissingPriorData) {
                            return CurrencyCell({ value, toolTipText: toolTipText });
                        }
                        return MissingDataCell(value);
                    },
                    Footer: (row) => (
                        <span>{getTotal(row.data, 'wp-ytd-prior', { dependsOnPrior: true })}</span>
                    )
                },
                {
                    id: 'wp-change',
                    Header: () => (
                        <Tooltip title={TOOL_TIPS.change}>
                            <div>Change</div>
                        </Tooltip>
                    ),

                    accessor: (row) => {
                        return row[getSelectedKey('priorWrittenPremium')]
                            ? ((row[getSelectedKey('writtenPremium')] -
                                row[getSelectedKey('priorWrittenPremium')]) *
                                100) /
                            row[getSelectedKey('priorWrittenPremium')]
                            : 0;
                    },
                    headerClassName: 'written-premium-column-group',
                    Cell: ({ value, original }) => {
                        const currentMetricKey = getSelectedKey('writtenPremium');
                        const priorMetricKey = getSelectedKey('priorWrittenPremium');
                        const toolTipText = getToolTipTextChangePercent(
                            networkCarrierTotals,
                            original,
                            currentMetricKey,
                            priorMetricKey,
                            tableDataType
                        );
                        if (original.haveMissingPriorData || original.haveMissingCurrentData) {
                            return IncompleteDataCell();
                        }
                        return PercentCell({ value, toolTipText: toolTipText });
                    },
                    Footer: (row) => <span>{getChange(row.data, 'wp-ytd', 'wp-ytd-prior')}</span>
                }
            ]
        },
        {
            id: 'nbYtd',
            Header: () => (
                <Tooltip title={TOOL_TIPS.newBusiness}>
                    <div> {getSelectedTitle('newWrittenPremium')}</div>
                </Tooltip>
            ),
            headerClassName: 'new-business-column-group',
            columns: [
                {
                    id: 'nb-ytd',
                    Header: 'Current',
                    accessor: getSelectedKey('newWrittenPremium'),
                    headerClassName: 'new-business-column-group',
                    Cell: ({ value, original }) => {
                        const metricKey = getSelectedKey('newWrittenPremium');
                        const toolTipText = getToolTipTextDollar(
                            networkCarrierTotals,
                            original,
                            metricKey,
                            tableDataType
                        );

                        if (!original.haveMissingCurrentData) {
                            return CurrencyCell({ value, toolTipText: toolTipText });
                        }

                        return MissingDataCell(value);
                    },
                    Footer: (row) => (
                        <span>{getTotal(row.data, 'nb-ytd', { dependsOnCurrent: true })}</span>
                    )
                },
                {
                    id: 'nb-ytd-prior',
                    Header: 'Prior',
                    accessor: getSelectedKey('priorNewWrittenPremium'),
                    headerClassName: 'new-business-column-group',
                    Cell: ({ value, original }) => {
                        const metricKey = getSelectedKey('priorNewWrittenPremium');
                        const toolTipText = getToolTipTextDollar(
                            networkCarrierTotals,
                            original,
                            metricKey,
                            tableDataType
                        );
                        if (!original.haveMissingPriorData) {
                            return CurrencyCell({ value, toolTipText: toolTipText });
                        }
                        return MissingDataCell(value);
                    },
                    Footer: (row) => (
                        <span>{getTotal(row.data, 'nb-ytd-prior', { dependsOnPrior: true })}</span>
                    )
                },
                {
                    id: 'nb-ytd-change',
                    Header: 'Change',
                    accessor: (row) =>
                        row[getSelectedKey('priorNewWrittenPremium')] <= 0
                            ? null
                            : ((row[getSelectedKey('newWrittenPremium')] -
                                row[getSelectedKey('priorNewWrittenPremium')]) *
                                100) /
                            row[getSelectedKey('priorNewWrittenPremium')],
                    headerClassName: 'new-business-column-group',
                    Footer: (row) => <span>{getChange(row.data, 'nb-ytd', 'nb-ytd-prior')}</span>,
                    Cell: (row) =>
                        row.original.haveMissingPriorData || row.original.haveMissingCurrentData
                            ? IncompleteDataCell()
                            : PercentCell(row)
                },
                {
                    id: 'nb-change',
                    Header: () => (
                        <Tooltip title={TOOL_TIPS.percentOfBook}>
                            <div>% of Book</div>
                        </Tooltip>
                    ),
                    accessor: (row) => {
                        return row[getSelectedKey('writtenPremium')]
                            ? (row[getSelectedKey('newWrittenPremium')] * 100) /
                            row[getSelectedKey('writtenPremium')]
                            : 0;
                    },
                    headerClassName: 'new-business-column-group',
                    //Cell: PercentCell,
                    Cell: ({ value, original }) => {
                        const currentMetricKey = getSelectedKey('newWrittenPremium');
                        const priorMetricKey = getSelectedKey('writtenPremium');
                        const toolTipText = getToolTipTextBookPercent(
                            networkCarrierTotals,
                            original,
                            currentMetricKey,
                            priorMetricKey,
                            tableDataType
                        );
                        return PercentCell({ value, toolTipText: toolTipText, noColor: true });
                    },
                    Footer: (row) => <span>{getPercentBook(row.data, 'nb-ytd', 'wp-ytd')}</span>
                }
            ]
        },
        {
            id: 'lossRatio',
            Header: () => (
                <Tooltip title={TOOL_TIPS.lossRatio}>
                    <div>{getSelectedTitle('lossRatio')}</div>
                </Tooltip>
            ),
            headerClassName: 'loss-ratio-column-group',
            columns: [
                {
                    id: 'earnedPremium',
                    Header: () => (
                        <Tooltip title={TOOL_TIPS.ep}>
                            <div>EP</div>
                        </Tooltip>
                    ),
                    accessor: getSelectedKey('earned'),
                    headerClassName: 'loss-ratio-column-group',
                    Cell: ({ value, original }) => {
                        const metricKey = getSelectedKey('earned');
                        const toolTipText = getToolTipTextDollar(
                            networkCarrierTotals,
                            original,
                            metricKey,
                            tableDataType
                        );
                        return CurrencyCell({ value, toolTipText: toolTipText });
                    },
                    Footer: (row) => <span>{getTotal(row.data, 'earnedPremium')}</span>
                },
                {
                    id: 'incurredLosses',
                    Header: () => (
                        <Tooltip title={TOOL_TIPS.il}>
                            <div>IL</div>
                        </Tooltip>
                    ),
                    accessor: getSelectedKey('incurred'),
                    headerClassName: 'loss-ratio-column-group',
                    Cell: ({ value, original }) => {
                        const metricKey = getSelectedKey('incurred');
                        const toolTipText = getToolTipTextDollar(
                            networkCarrierTotals,
                            original,
                            metricKey,
                            tableDataType
                        );
                        return CurrencyCell({ value, toolTipText: toolTipText });
                    },
                    Footer: (row) => <span>{getTotal(row.data, 'incurredLosses')}</span>
                },
                {
                    id: 'lossRatioSubheader',
                    Header: 'Current',
                    accessor: (row) =>
                        formulas.calculateLossRatio(
                            row[getSelectedKey('earned')],
                            row[getSelectedKey('incurred')]
                        ),
                    headerClassName: 'loss-ratio-column-group',

                    Cell: ({ value, original }) => {
                        const currentMetricKey = getSelectedKey('incurred');
                        const priorMetricKey = getSelectedKey('earned');
                        // const currentMetricKey =
                        //     selectedTimeframe === 'ytd' ? 'incurredYTD' : 'incurred';
                        // const priorMetricKey = selectedTimeframe === 'ytd' ? 'earnedYTD' : 'earned';
                        const toolTipText = getToolTipTextLossRatioPercent(
                            networkCarrierTotals,
                            original,
                            currentMetricKey,
                            priorMetricKey,
                            tableDataType
                        );

                        if (!original.haveMissingCurrentData) {
                            return PercentCell({
                                value, toolTipText: toolTipText,
                                colorThreasholds: (value) => ({
                                    negative: value > 55,
                                    positive: value < 45
                                })
                            });
                        }

                        return <IncompleteDataCell />;
                    },
                    Footer: (row) =>
                        getCumulativeLossRatio(row.data, 'earnedPremium', 'incurredLosses', {
                            dependsOnCurrent: true
                        })
                },
                {
                    id: 'earned-prior',
                    accessor: getSelectedKey('priorEarned'),
                    show: false
                },
                {
                    id: 'incurred-prior',
                    accessor: getSelectedKey('priorIncurred'),
                    show: false
                },
                {
                    id: 'priorLossRatioSubheader',
                    Header: 'Prior',
                    accessor: (row) =>
                        formulas.calculateLossRatio(
                            row[getSelectedKey('priorEarned')],
                            row[getSelectedKey('priorIncurred')]
                        ),
                    headerClassName: 'loss-ratio-column-group',

                    Cell: ({ value, original }) => {
                        const currentMetricKey = getSelectedKey('priorIncurred');
                        const priorMetricKey = getSelectedKey('priorEarned');
                        // const currentMetricKey =
                        //     selectedTimeframe === 'ytd' ? 'priorIncurredYTD' : 'priorIncurred';
                        // const priorMetricKey =
                        //     selectedTimeframe === 'ytd' ? 'priorEarnedYTD' : 'priorEarned';
                        const toolTipText = getToolTipTextLossRatioPercent(
                            networkCarrierTotals,
                            original,
                            currentMetricKey,
                            priorMetricKey,
                            tableDataType
                        );
                        if (!original.haveMissingPriorData) {
                            return PercentCell({
                                value, toolTipText: toolTipText,
                                colorThreasholds: (value) => ({
                                    negative: value > 55,
                                    positive: value < 45
                                })
                            });
                        }
                        return <IncompleteDataCell />;
                    },
                    Footer: (row) =>
                        getCumulativeLossRatio(row.data, 'earned-prior', 'incurred-prior', {
                            dependsOnPrior: true
                        })
                }
            ]
        }
    ];
};
