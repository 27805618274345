import { Fragment } from 'react';
import { Icon } from '../../icons/icons';
import { Grid, Typography, Tooltip } from '@mui/material';
import styled from '@emotion/styled';
import { TooltipText } from './styles';

export const HtmlTooltip = styled(Tooltip)(({ theme }) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        borderRadius: '5px',
        padding: '15px',
        maxWidth: '370px'
    },
    arrow: {
        color: '#dadde9'
    }
}));

export const getPasswordTooltipText = ({ password, matchPassword, showPasswordMatch = false }) => {
    const hasEightCharacters = password.length >= 8;
    const hasOneLowercase = /[a-z]/.test(password);
    const hasOneUppercase = /[A-Z]/.test(password);
    const hasOneSpecialCharacter = /[\.\-\_\?!<>]/.test(password);
    const hasOneNumber = /[0-9]/.test(password);
    const passwordMatch = password === matchPassword && password;
    return (
        <Fragment>
            <Typography color="inherit" style={{ marginBottom: '10px' }}>
                Password must meet the following requirements:{' '}
            </Typography>
            <Grid
                xs={12}
                style={{ color: hasEightCharacters ? 'limegreen' : 'red', paddingLeft: '15px' }}
            >
                <Icon
                    iWidth="15px"
                    type={hasEightCharacters ? 'check2' : 'stop'}
                    iColor={hasEightCharacters ? 'limegreen' : 'red'}
                />
                <TooltipText>Be at least 8 characters</TooltipText>
            </Grid>
            <Grid
                xs={12}
                style={{ color: hasOneLowercase ? 'limegreen' : 'red', paddingLeft: '15px' }}
            >
                <Icon
                    iWidth="15px"
                    type={hasOneLowercase ? 'check2' : 'stop'}
                    iColor={hasOneLowercase ? 'limegreen' : 'red'}
                />
                <TooltipText>At least one lowercase</TooltipText>
            </Grid>
            <Grid
                xs={12}
                style={{ color: hasOneUppercase ? 'limegreen' : 'red', paddingLeft: '15px' }}
            >
                <Icon
                    iWidth="15px"
                    type={hasOneUppercase ? 'check2' : 'stop'}
                    iColor={hasOneUppercase ? 'limegreen' : 'red'}
                />
                <TooltipText>At least one uppercase</TooltipText>
            </Grid>
            <Grid
                xs={12}
                style={{ color: hasOneNumber ? 'limegreen' : 'red', paddingLeft: '15px' }}
            >
                <Icon
                    iWidth="15px"
                    type={hasOneNumber ? 'check2' : 'stop'}
                    iColor={hasOneNumber ? 'limegreen' : 'red'}
                />
                <TooltipText>At least one number</TooltipText>
            </Grid>
            <Grid
                xs={12}
                style={{ color: hasOneSpecialCharacter ? 'limegreen' : 'red', paddingLeft: '15px' }}
            >
                <Icon
                    iWidth="15px"
                    type={hasOneSpecialCharacter ? 'check2' : 'stop'}
                    iColor={hasOneSpecialCharacter ? 'limegreen' : 'red'}
                />
                <TooltipText>
                    At least one special characters {"( '.' , '_' , '-' , '<' , '>' , '?' , '!' )"}
                </TooltipText>
            </Grid>
            {showPasswordMatch && (
                <Grid
                    xs={12}
                    style={{ color: passwordMatch ? 'limegreen' : 'red', paddingLeft: '15px' }}
                >
                    <Icon
                        iWidth="15px"
                        type={passwordMatch ? 'check2' : 'stop'}
                        iColor={passwordMatch ? 'limegreen' : 'red'}
                    />
                    <TooltipText>
                        {passwordMatch ? 'Password match' : 'Password does not match'}
                    </TooltipText>
                </Grid>
            )}
        </Fragment>
    );
};

export const validate = (password) => {
    const hasEightCharacters = password.length >= 8;
    const hasOneLowercase = /[a-z]/.test(password);
    const hasOneUppercase = /[A-Z]/.test(password);
    const hasOneSpecialCharacter = /[\.\-\_\?!<>]/.test(password);
    const hasOneNumber = /[0-9]/.test(password);

    return (
        hasEightCharacters &&
        hasOneLowercase &&
        hasOneUppercase &&
        hasOneSpecialCharacter &&
        hasOneNumber
    );
};
