import ReactTable from 'react-table';

const Table = (props) => {
    return (
        <ReactTable
            {...props}
            getTdProps={props.getTdPropsFunction}
            data={props.data}
            columns={props.columns}
            className={props.className}
            defaultSorted={props.defaultSorted}
            minRows={props.minRows}
        />
    );
};

export default Table;
