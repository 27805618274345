import styled from '@emotion/styled';

export const StyledSelect = styled.div`
    .select {
        margin: 0;
        label {
            margin: 0;
        }
    }
`;

export const FormSection = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 600px;
    .textInput {
        width: 275px;
    }
    .select {
        width: 275px;
    }
    .websiteInput {
        width: 584px;
    }
    .infoInput {
        width: 584px;
    }
`;

export const SubmitMsg = styled.div`
    max-width: 600px;
    font-size: x-large;
`;

export const StyledMsgContainer = styled.div`
    width: 720px;
    margin: 0 0 100px;
    text-align: center;
    line-height: 40px;
    height: 380px;
    flex-direction: column;
    font-size: xx-large;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
        font-size: xx-large;
        margin: 0 0 20px 0;
    }
`;

export const ButtonContainer = styled.div`
    bottom: 40px;
    position: absolute;
`;

export const StyledModalHeading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 20px;
`;

export const MaxCharIndicator = styled.div`
    position: relative;
    right: 19px;
    bottom: -62px;
    font-size: small;
    color: slategrey;
`;
