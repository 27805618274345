import { Button, MultiSelect } from '@agencykpi/components';
import { StandardTypeSelectorWrap } from './styles';
import { findPolicyType } from '../../utils';

function standardTypesItems({ policyType, allStandardTypes }) {
    const type = findPolicyType(allStandardTypes, policyType);
    const children = type.children;
    return children.map((el) => ({ label: el.name, value: el.uuid }))
        .sort((a,b) => a.label > b.label ? 1 :(b.label > a.label ? -1 : 0));
}

const StandardTypeSelector = ({
    policyType,
    allStandardTypes,
    onSelect,
    onApply,
    canApplyStandardTypes
}) => {
    return (
        <StandardTypeSelectorWrap className="multiSelect_StandardTypesContainer">
            <div className="multiSelect_StandardTypes">
                <MultiSelect
                    key={policyType}
                    label="Default select"
                    name="default-select"
                    placeholder="Select standard types"
                    blankItem={{ label: 'Select policy types' }}
                    items={standardTypesItems({ policyType, allStandardTypes })}
                    id="default-id"
                    onChange={(values) => {
                        onSelect(values);
                    }}
                />
            </div>
            <Button
                className={'multiSelect_ApplyButton'}
                onClick={() => {
                    onApply();
                }}
                disabled={!canApplyStandardTypes}
            >
                Apply
            </Button>
        </StandardTypeSelectorWrap>
    );
};

export default StandardTypeSelector;
