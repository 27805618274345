import styled from '@emotion/styled';

export const StyledForm = styled.div`
    margin: 0 20%;
    padding: 20px 0 0 0;
`;

export const FormBody = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
`;

export const FormActions = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

export const FormGroup = styled.div`
    display: flex;
    flex-direction: row;
    flex-basis: 50%;
    padding: 5px;
    position: relative;
    & > select {
        width: 75%;
    }
    justify-content: center;
`;

export const FormTitle = styled.h1`
    width: 100%;
    text-align: center;
`;

export const LoaderContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    position: absolute;
    place-content: center;
    z-index: 100;
`;

export const ErrorMessageIcon = styled.div`
    text-align: center;
`;

export const ErrorMessageHeader = styled.div`
    font-size: 25px;
    color: black;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
`;

export const ErrorMessageText = styled.div`
    font-size: 16px;
    color: #7c818a;
    text-align: center;
    margin-top: 30px;
`;

export const ErrorMessageReason = styled.div`
    font-size: 20px;
    color: #61676b;
    text-align: center;
    font-weight: 400;
`;

export const ErrorMessageContainer = styled.div`
    margin: auto;
    margin-top: 3%;
    background-color: white;
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
    padding: 2% 5%;
    width: 500px;
`;

export const TooltipText = styled.span`
    vertical-align: top;
    margin-left: 5px;
`;
