import { createRef, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import {
    Container,
    Content,
    Button,
    ButtonContainer,
    TimeframeButton,
    TimeframeText
} from './styles';
import { Icon } from '../../../icons/icons';

const FiltersSelector = ({
    content,
    displayText,
    applyAction,
    iconType,
    cancelButtonText,
    applyButtonText,
    effectAction,
    isDisabled = false,
    buttonText,
    buttonAction
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const timeframeRef = createRef();

    document.addEventListener('mousedown', handleClickOutside);

    useEffect(() => {
        window.addEventListener('mousedown', handleClickOutside, false);
        return function cleanup() {
            window.removeEventListener('mousedown', handleClickOutside);
        };
    });

    useEffect(() => {
        if (effectAction) {
            effectAction();
        }
    }, [isVisible]);

    const handleIsTimeframeOpen = (setIsVisible, isVisible) => {
        setIsVisible(isVisible === false);
    };

    function handleClickOutside(e) {
        if (
            isVisible &&
            timeframeRef.current !== null &&
            !timeframeRef.current.contains(e.target) &&
            !e.target.className.includes('dx-item-content') &&
            !e.target.className.includes('dx-button-content') &&
            !e.target.className.includes('dx-icon-chevronright') &&
            !e.target.className.includes('dx-icon-chevronleft') &&
            !e.target.className.includes('dx-calendar-cell') &&
            !e.target.className.includes('dx-button-text') &&
            !e.target.className.includes('dx-overlay-content') &&
            !e.target.className.includes('dx-calendar-navigator') &&
            !e.target.className.includes('dx-checkbox-icon') &&
            !e.target.className.includes('dx-texteditor-input') &&
            !e.target.className.includes('dx-treeview-node') &&
            !e.target.className.includes('dx-treeview-item') &&
            !e.target.className.includes('dx-treeview-toggle-item-visibility') &&
            !e.target.className.includes('dx-popup-content') &&
            !e.target.className.includes('rw-input-reset') &&
            !e.target.className.includes('rw-list-option') &&
            e.target.className !== ''
        ) {
            setIsVisible(false);
        }
    }

    return (
        <Container ref={timeframeRef}>
            <TimeframeButton
                onClick={() => {
                    handleIsTimeframeOpen(setIsVisible, isVisible);
                }}
                color={isVisible ? '#000000' : '#d9d9d9'}
                width="200px"
            >
                <Icon
                    type={iconType}
                    iWidth="20px"
                    iColor={'#000000'}
                    iColor={isVisible ? '#000000' : '#d9d9d9'}
                />
                <TimeframeText color={isVisible ? '#000000' : '#d9d9d9'}>
                    {displayText}
                </TimeframeText>
            </TimeframeButton>
            {isVisible && (
                <Content>
                    <Grid container spacing={2}>
                        {content}
                        <Grid item xs={12}>
                            <ButtonContainer>
                                {cancelButtonText && (
                                    <Button
                                        onClick={() => {
                                            handleIsTimeframeOpen(setIsVisible, isVisible);
                                        }}
                                        color="#222222"
                                        background="#ffffff"
                                    >
                                        {cancelButtonText}
                                    </Button>
                                )}
                                {buttonText && buttonAction && (
                                    <Button
                                        onClick={() => {
                                            buttonAction();
                                        }}
                                        color="#222222"
                                        background="#ffffff"
                                    >
                                        {buttonText}
                                    </Button>
                                )}
                                <Button
                                    disabled={isDisabled}
                                    primary
                                    type="submit"
                                    color="#dedede"
                                    background="#222222"
                                    onClick={() => {
                                        applyAction();
                                        setIsVisible(false);
                                    }}
                                >
                                    {applyButtonText}
                                </Button>
                            </ButtonContainer>
                        </Grid>
                    </Grid>
                </Content>
            )}
        </Container>
    );
};

export default FiltersSelector;
