import styled from '@emotion/styled';

export const StandardTypeSelectorWrap = styled.div`
    display: flex;
    .multiSelect_StandardTypes {
        //width: 400px;
        margin: 0 10px;
    }
    .multiSelect_ApplyButton {
        margin: 0;
        height: 39px;
        text-align: center;
        padding: 8px 18px;
    }
    .multiSelect_Message {
        color: gray;
        font-size: small;
    }
`;
