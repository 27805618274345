import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { request } from '../../utils/request';
import { useStoreState } from 'easy-peasy';
import { findPolicyType } from '../../utils';
import { isEmpty } from 'lodash-es';

const DEFAULT_COMPOSITES = {
    isStandard: true,
    isMga: true,
    isFlood: true,
    isCrop: true,
    isWorkersComp: true,
    isSurety: true
};

function timeframeToParams(timeframe) {
    return 'year' in timeframe
        ? { year: timeframe.year }
        : {
              startDate: moment(timeframe.startDate).format('YYYY-MM-DD'),
              endDate: moment(timeframe.endDate).format('YYYY-MM-DD')
          };
}

export function useAgencySummary(agencyUuid, timeframe, policyType) {
    const query = useQuery({
        queryKey: ['scorecards-by-agency', { timeframe: timeframeToParams(timeframe) }],
        async queryFn({ queryKey: [, { timeframe }] }) {
            const { body } = await request.get(`/api/scorecards/by-agency`).query({
                ...timeframe,
                ...DEFAULT_COMPOSITES
            });
            return body;
        },
        select(data) {
            if (isEmpty(data)) {
                return null;
            }
            return data[agencyUuid][policyType];
        }
    });

    return query;
}

function policyTypeToParams(policyType, standardTypes) {
    const currentParent = findPolicyType(standardTypes, policyType);
    const levelUuid = [];
    if (currentParent) levelUuid.push(currentParent.classificationUuid);
    if (currentParent?.children) levelUuid.push(currentParent.children[0].classificationUuid);
    return {
        parentTypeCode: currentParent?.code,
        levelUuid: levelUuid
    };
}

export function useByCarrierList(agencyUuid, timeframe, standardTypes, policyType) {
    const allStandardTypes = useStoreState((store) => store.standardTypes);
    const policyTypeParams = policyTypeToParams(policyType, allStandardTypes);
    const query = useQuery({
        queryKey: [
            'scorecards-by-carrier',
            {
                timeframe: timeframeToParams(timeframe),
                agencyUuid,
                standardTypes,
                ...policyTypeParams
            }
        ],
        async queryFn({ queryKey: [, params] }) {
            const { body } = await request.get(`/api/scorecards/by-carrier`).query({
                ...params.timeframe,
                ...DEFAULT_COMPOSITES,
                levelUuid: params.levelUuid,
                standardTypes: params.standardTypes.join(','),
                selectedAgencyUuid: params.agencyUuid,
                parentTypeCode: params.parentTypeCode
            });
            return body;
        }
    });
    return query;
}

export function useNetworkSummaryStats(timeframe, policyType) {
    const query = useQuery({
        queryKey: ['scorecards-stats', { timeframe: timeframeToParams(timeframe) }],
        async queryFn({ queryKey: [, { timeframe }] }) {
            const { body } = await request.get(`/api/scorecards/stats`).query(timeframe);
            return body;
        },
        select(data) {
            return data.summary[policyType];
        }
    });
    return query;
}
