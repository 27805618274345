import { request } from '../../utils/request';

export const getProducts = async (setSubscriptions, setIsLoading) => {
    setIsLoading(true);
    const result = await request.get('/api/subscription/products');
    setSubscriptions(result.body || []);
    setIsLoading(false);
};

export const getFeatures = (metadata) => {
    let features = [];
    Object.keys(metadata).forEach((prop) => {
        if (prop.includes('feature_')) {
            features.push(metadata[prop]);
        }
    });
    return features;
};

export const goToCheckoutPage = async (priceId, setIsLoading) => {
    setIsLoading(true);
    const result = await request.post('/api/stripe/create-checkout-session').send({
        priceId,
        successUrl: window.location.origin,
        cancelUrl: window.location.origin + window.location.pathname
    });
    setIsLoading(false);
    window.location.href = result.body.checkoutSessionUrl;
};
