import { actions } from '../actions/registerActions';

export const initialState = {
    error: '',
    fetching: true,
    agency: {},
    network: {},
    isModalOpen: false,
    modalText: '',
    emailAddress: '',
    token: ''
};

export const registerModel = {
    ...initialState,
    ...actions
};
