import { useState, useCallback, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { NavMenuBody, NavMenuHeader } from './styles';
import { Icon } from '../../icons/icons';
import { NavLink } from 'react-router-dom';
import { getUploadPortUrl } from '../../utils/redirectionUtils';
import { useQuery } from '@tanstack/react-query';
import { activePlanYearsQuery } from '../../utils/apis';
import { Box } from '@mui/material';
import config from '../../utils/config';
import { useStoreActions, useStoreState } from 'easy-peasy';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import PropTypes from 'prop-types';
import ReportLink from './ReportLink';
import { REPORTS } from '../../constants';
import { useClarityUser } from '../../utils/useClarityUser';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NavIcon({ icon, children }) {
    return (
        <div style={{ marginRight: '10px', width: '30px' }}>
            {icon ? <FontAwesomeIcon icon={icon} size="lg" /> : null}
            {children}
        </div>
    );
}
NavIcon.propTypes = {
    icon: PropTypes.object,
    children: PropTypes.node
};

function NavIconSecondary({ icon }) {
    return (
        <div style={{ marginLeft: '10px', width: '20px' }}>
            <FontAwesomeIcon icon={icon} size="sm" />
        </div>
    );
}
NavIconSecondary.propTypes = {
    icon: PropTypes.object
};

function ToolsNav({ expanded }) {
    const [isToolsMenuOpen, setToolsMenuOpen] = useState(false);
    const userQuery = useClarityUser();
    const [userType]=useStoreState((state) => [state.user.userType]);

    const hasClarity = userQuery.isSuccess && userQuery.data.permittedToUpload;
    const onTrigger = useCallback((e) => {
        e.preventDefault();
        setToolsMenuOpen((state) => !state);
    }, []);

    const openUploadPortal = () => {
        const url = getUploadPortUrl();
        window.open(url, '_blank');
    };
    useEffect(() => {
        setToolsMenuOpen(false);
    }, [expanded]);

    return (
        <NavMenuHeader>
            <nav className="akpi-tools">
                <div className="akpi-tools__trigger" onClick={onTrigger}>
                    <Icon type="toolbox" iWidth={'30px'} iColor={'black'} />
                    {expanded && (
                        <>
                            <a href="#">AKPI Tools</a>
                            {isToolsMenuOpen ? (
                                <ExpandLessRoundedIcon />
                            ) : (
                                <ExpandMoreRoundedIcon />
                            )}
                        </>
                    )}
                </div>
                <ul className={`akpi-tools__dropdown ${isToolsMenuOpen ? 'open' : ''}`}>
                    <li>
                        <a onClick={openUploadPortal}>AMS Data Upload</a>
                    </li>
                    <ReportLink config={REPORTS.AMS_DATA_HEALTH} expanded={expanded} />
                    <li>
                        <NavLink to="/billingcarriers">
                            Input Additional <br />
                            Carrier Production
                        </NavLink>
                    </li>

                    {userType === 'agency' || userType === 'internal' && hasClarity ? (
                        <li>
                            <NavLink
                                target="_blank"
                                to={config.get('clarityAppUrl') + '/customer-accounts'}
                            >
                                <div>Customer Accounts</div>

                            </NavLink>
                        </li>
                    ) : null}
                </ul>
            </nav>
        </NavMenuHeader>
    );
}

ToolsNav.propTypes = {
    expanded: PropTypes.bool
};

function GrowthMeasurementNavItem({ expanded }) {
    const hasActivePlan = useQuery({
        ...activePlanYearsQuery(),
        select(data) {
            return data.yearOptions.length > 0;
        }
    });
    if (hasActivePlan.isLoading || !hasActivePlan.data) {
        return null;
    }
    return (
        <li>
            <NavLink to="/growth-measurement">
                <Icon type="plan-measurement" iWidth={'30px'} iColor={'black'} />
                {expanded && <>Growth Plan Measurement</>}
            </NavLink>
        </li>
    );
}

GrowthMeasurementNavItem.propTypes = {
    expanded: PropTypes.bool
};

function MainNav({ expanded }) {
    const flags = useFlags();
    return (
        <NavMenuBody>
            <ul>
                <li>
                    <NavLink to="/production">
                        <Icon type="carrier-production" iWidth={'30px'} iColor={'black'} />
                        {expanded && 'Carrier Production'}
                    </NavLink>
                </li>

                <ReportLink
                    config={REPORTS.AGENCY_PRODUCTION}
                    expanded={expanded}
                    fallbackConditions={[REPORTS.AP_TEASER]}
                    fallback={
                        <li>
                            <NavLink to="/scorecards">
                                <Icon type="agency-compensation" iWidth={'30px'} iColor={'black'} />
                                {expanded && 'Agency Production'}
                            </NavLink>
                        </li>
                    }
                />
                <ReportLink config={REPORTS.AP_TEASER} expanded={expanded} />

                <li>
                    <NavLink to="/dataviz">
                        <Icon type="performance-chart" iWidth={'30px'} iColor={'black'} />
                        {expanded && (
                            <>
                                Performance <br /> Charts &amp; Graphs
                            </>
                        )}
                    </NavLink>
                </li>
                {flags.growthMeasurement ? <GrowthMeasurementNavItem expanded={expanded} /> : null}

                <ReportLink config={REPORTS.MARKET_ASSISTANCE} expanded={expanded} />
                <ReportLink config={REPORTS.MA_TEASER} expanded={expanded} />
                <ReportLink config={REPORTS.UNAFFILIATED_MA} expanded={expanded} />

                <ReportLink config={REPORTS.REVENUE_TUNER} expanded={expanded} />
                <ReportLink config={REPORTS.RT_TEASER} expanded={expanded} />
                <ReportLink config={REPORTS.UNAFFILIATED_RT} expanded={expanded} />
            </ul>
        </NavMenuBody>
    );
}

MainNav.propTypes = {
    expanded: PropTypes.bool
};

export default function SideNav() {
    const [isHovered, setIsHovered] = useState(false);
    const navigationExpanded = useStoreState((state) => state.navigationExpanded);
    const [setNavigationExpanded] = useStoreActions((actions) => [actions.setNavigationExpanded]);
    const expanded = navigationExpanded || isHovered;

    useEffect(() => {
        const onResize = () => {
            if (window.innerWidth <= 1024) setNavigationExpanded(false);
        };

        window.addEventListener('resize', onResize);
        onResize();

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return (
        <Box
            onMouseOver={() => {
                setIsHovered(true);
            }}
            onMouseLeave={() => {
                setIsHovered(false);
            }}
            sx={{
                width: expanded ? '280px' : '80px',
                minWidth: expanded ? '280px' : '80px',
                height: 'calc(100% - 60px)',
                '& svg': {
                    margin: !expanded ? '0px 5px 0px 5px' : '0px 10px 0px 5px'
                }
            }}
            className={`bg-primary text-textDark z-[1000] mt-[60px] flex flex-col p-5 ${
                navigationExpanded ? 'relative' : 'absolute'
            } max-lg:absolute`}
        >
            <ToolsNav expanded={expanded} />
            <MainNav expanded={expanded} />
        </Box>
    );
}
