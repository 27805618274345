import { useState, useEffect, useCallback } from 'react';
import { Button } from '@agencykpi/components';
import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@mui/material';
import * as styles from './styles';
import { Icon } from '../../icons/icons';
import Loader from '../shared/Loader';
import { request } from '../../utils/request';
import toast from '../shared/toast';
import PendingActions from './PendingActions';

const PendingInvites = ({ agencyUuid, isInviting, refetch, setRefetch }) => {
    const [pendingInvitesList, setPendingInvitesList] = useState([]);
    const [isFetching, setIsFetching] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const copyLink = (link) => {
        let textField = document.createElement('textarea');
        textField.innerText = link;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        toast('Link Copied', 'success');
    };

    const isInviteValid = (expiryDate) => {
        const currentDate = new Date();
        return new Date(expiryDate) >= currentDate;
    };

    const getPendingInvites = useCallback(async () => {
        setIsLoading(true);
        try {
            const { body } = await request
                .get(`/api/invites/pending`)
                .query({ subscriberUuid: agencyUuid });
            setPendingInvitesList(body);
        } catch (err) {
            console.log(err);
        }
        setIsLoading(false);
        setIsFetching(false);
        setRefetch(false);
    }, [agencyUuid, setIsFetching, refetch]);

    useEffect(() => {
        if (isFetching || refetch) {
            getPendingInvites();
        }
    }, [getPendingInvites, isFetching, refetch]);

    return (
        <>
            {isLoading || refetch || isInviting ? (
                <Loader />
            ) : (
                <TableContainer component={Paper}>
                    <Table aria-label="active users table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>Invite Link</TableCell>
                                <TableCell>Valid</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pendingInvitesList.map((row) => (
                                <TableRow key={row.uuid}>
                                    <TableCell>{`${row.emailAddress}`}</TableCell>
                                    <TableCell>
                                        {isInviteValid(row.expiryDate) && (
                                            <Button
                                                css={styles.buttonLink}
                                                onClick={() => copyLink(row.invitationLink)}
                                            >
                                                Copy Link
                                            </Button>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <Icon
                                            type={isInviteValid(row.expiryDate) ? 'check' : 'close'}
                                            iWidth={'30px'}
                                            iColor={isInviteValid(row.expiryDate) ? 'green' : 'red'}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <PendingActions
                                            setIsLoading={setIsLoading}
                                            setIsFetching={setIsFetching}
                                            user={row}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
};

export default PendingInvites;
