import notify from 'devextreme/ui/notify';
import './toastStyles.css';

const toast = (message, type = 'info', time = 5000) => {
    notify({
        message: message,
        type: type,
        displayTime: time,
        height: 50,
        maxWidth: 500,
        position: {
            at: 'top',
            my: 'center',
            of: window,
            offset: { x: 0, y: 40 }
        }
    });
};

export default toast;
