import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { map, find, sortBy, last as _last } from 'lodash-es';
import Table from '../../Table/Table';
import { groupTableDataBy } from './manualEntryTableFunctions';
import { getTableColumns } from './manualEntryTableColumns';
import { Button, Select } from '@agencykpi/components';
import { useStoreActions, useStoreState } from 'easy-peasy';
import classNames from 'classnames';
import {
    StyledEntryContainer,
    StyledCurrencyColumn,
    StyledPolicyStatusColumn,
    StyledCompanyNameLabel,
    StyledPolicyTable,
    StyledLobEntry,
    StyledPolicyRow,
    StyledButtonContainer,
    StyledEntryTableHeader,
    StyledLobColumn,
    StyledSelect,
    TableContainer,
    DeleteIcon
} from './styles';
import { Icon } from '../../../icons/icons';
import ConfirmationModal from '../../shared/ConfirmationModal/index';
import { request } from '../../../utils/request';
import PropTypes from 'prop-types';

const ManualEntryTables = (props) => {
    const navigate = useNavigate();
    const data = props.manualEntryData;

    const [subCodes, subCodeTable] = useStoreState((state) => [
        state.subCodes,
        state.manualEntryTable.subCode,
        state.manualEntryTable.availableSubCodes,
        state.manualEntryTable.subCodeUuid
    ]);

    const [setSubCodeTable, setSubCodesTable, setSubCodeForm, setSubCodes] = useStoreActions(
        (actions) => [
            actions.setSubCodeTable,
            actions.setSubCodesTable,
            actions.setSubCodeForm,
            actions.setSubCodes
        ]
    );

    const renderDeleteCarrier = (rowEntry) => {
        return (
            <DeleteIcon className="delete-icon" onClick={() => handleDeleteCarrier(rowEntry)}>
                <Icon className="delete-icon" type="trash" iWidth="20px" />
            </DeleteIcon>
        );
    };

    function handleCompanyRowClick(rowEntry) {
        setSubCodes(getSubCodes(rowEntry));
        setTableDataView(rowEntry);
        navigate('/billingcarriers/edit');
    }

    const companyUuid = useStoreState((initialState) => initialState.manualEntryTable.companyUuid);
    const setCompanyTable = useStoreActions((actions) => actions.setCompanyTable);
    const companyNameTable = useStoreState(
        (initialState) => initialState.manualEntryTable.companyName
    );

    const setPolicyTable = useStoreActions((actions) => actions.setPolicyTable);
    const setPolicyForm = useStoreActions((actions) => actions.setPolicyForm);

    const [tableColumns, setTableColumns] = useState(
        getTableColumns(props.currentView, renderDeleteCarrier, handleCompanyRowClick)
    );
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [importUuidToDelete, setImportUuidToDelete] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const manualFormattedEntryData = useStoreState(
        (initialState) => initialState.manualEntryTable.manualFormattedEntryData
    );
    const year = useStoreState((initialState) => initialState.year);
    const setFormattedManualEntryData = useStoreActions(
        (actions) => actions.setFormattedManualEntryData
    );

    const fetchManualEntries = useStoreActions((actions) => actions.fetchManualEntries);

    const initTableDataView = (data, currentView) => {
        setTableColumns(getTableColumns(currentView, renderDeleteCarrier, handleCompanyRowClick));
        setFormattedManualEntryData(groupTableDataBy[currentView](data, companyUuid));
    };

    function setTableDataView(rowEntry) {
        const newCompany = {
            companyUuid: rowEntry.companyUuid,
            companyName: rowEntry.companyName
        };
        setFormattedManualEntryData(groupTableDataBy['entries'](data, rowEntry.companyUuid));
        setTableColumns(getTableColumns('entries', renderDeleteCarrier, handleCompanyRowClick));
        setCompanyTable(newCompany);
    }

    function handleBack() {
        initTableDataView(data, 'carriers');
        setTableColumns(getTableColumns('carriers', renderDeleteCarrier, handleCompanyRowClick));
        setCompanyTable({ companyUuid: '', companyName: '' });
        setSubCodeTable({ uuid: '', code: '' });
        setSubCodesTable([]);
        navigate(-1);
    }

    function getSubCodes(data) {
        let result = {};
        Object.keys(data.groupedPoliciesByCode).forEach((policyType) => {
            data.groupedPoliciesByCode[policyType].forEach((item) => {
                if (!result[item.agencyCode]) {
                    result[item.agencyCode] = {
                        name: item.agencyName,
                        uuid: item.agencyUuid,
                        code: item.agencyCode
                    };
                }
            });
        });
        return Object.values(result);
    }

    function formatSubCodes() {
        const availableSubCodes = getAvailableSubCodes(manualFormattedEntryData);
        let formattedSubCodes = [];
        //TODO convert to reduce.
        subCodes.map((element) => {
            if (availableSubCodes.includes(element.code)) {
                formattedSubCodes.push({ label: element.code, value: element.code });
            }
        });
        return formattedSubCodes;
    }

    function openDeleteConfirmationModal() {
        setIsDeleteConfirmationOpen(true);
    }

    async function deleteEntry() {
        try {
            setIsLoading(true);
            await Promise.all(
                importUuidToDelete.map(async (uuid) => {
                    return await request.post(`/api/carrierentry/delete/${uuid}`).send(uuid);
                })
            );
            await fetchManualEntries(year);
            isLastLineOfBusiness();
            setIsDeleteConfirmationOpen(false);
            setIsLoading(false);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
        }
    }

    function isLastLineOfBusiness() {
        if (
            !Array.isArray(manualFormattedEntryData) &&
            Object.keys(manualFormattedEntryData).length === 0
        ) {
            handleBack();
        }
    }

    const getTdPropsFunction = (state, rowInfo, column) => {
        if (column.id === 'delete') {
            return {
                onClick: () => {
                    setDeleteMessage('You are about to permanently delete a Details entry.');
                    setImportUuidToDelete([rowInfo.original.importUuid]);
                    openDeleteConfirmationModal();
                },
                style: {
                    cursor: ''
                }
            };
        } else {
            return {
                onClick: (e) => {
                    if (!rowInfo) return;
                    const newPolicy = {
                        policyUuid: rowInfo.original.policyTypeUuid,
                        policyTypeCode: rowInfo.original.policyTypeCode
                    };
                    setPolicyTable(newPolicy);
                    setPolicyForm(newPolicy);
                    props.handleModal(e, rowInfo.original, true, props.currentView);
                    return;
                }
            };
        }
    };

    const getInitialSubCodes = () => {
        const availableSubCodes = getAvailableSubCodes();
        let compiledSubCodes = [];
        //TODO convert to reduce.
        subCodes.map((element) => {
            if (availableSubCodes.includes(element.code)) {
                compiledSubCodes.push({ uuid: element.uuid, code: element.code });
            }
        });
        return compiledSubCodes;
    };

    const getUuidFromSubCode = (code) => {
        const foundSubCode = subCodes.find((element) => element.code === code);
        return foundSubCode.uuid;
    };

    const getAvailableSubCodes = () => {
        return Object.keys(manualFormattedEntryData);
    };

    useEffect(() => {
        initTableDataView(data, props.currentView);
    }, [data, props.currentView]);

    useEffect(() => {
        isLastLineOfBusiness();
    }, [manualFormattedEntryData]);

    useEffect(() => {
        if (subCodes.length > 0) {
            const initialSubCodes = getInitialSubCodes();
            const availableSubCodes = getAvailableSubCodes();
            if (initialSubCodes.length > 0 && !availableSubCodes.includes(subCodeTable)) {
                setSubCodesTable(formatSubCodes());
                //TODO Looking into possible rename and refactor to use only one variable.
                setSubCodeTable(initialSubCodes[0]);
                setSubCodeForm(initialSubCodes[0]);
            }
        }
    }, [subCodes, manualFormattedEntryData]);

    const handleDeleteCarrier = (rowEntry) => {
        setDeleteMessage(
            'You are about to permanently delete a Carrier entry. The Line of Business and Details related to this Carrier will also be removed.'
        );
        const LoBList = Object.keys(rowEntry.groupedPoliciesByCode).map((lineOfBusiness) => {
            return rowEntry.groupedPoliciesByCode[lineOfBusiness];
        });
        let importUuid = [];
        LoBList.map((LoB) => {
            LoB.map((line) => {
                importUuid.push(line.importUuid);
            });
        });
        setImportUuidToDelete(importUuid);
        openDeleteConfirmationModal();
    };

    const renderDeleteLineOfBusiness = (entry) => {
        return (
            <DeleteIcon
                className="delete-icon"
                onClick={() => {
                    setDeleteMessage(
                        'You are about to permanently delete a Line of Business entry. The Details related to this Line of Business will also be removed.'
                    );
                    const importUuid = entry.map((m) => {
                        return m.importUuid;
                    });
                    setImportUuidToDelete(importUuid);
                    openDeleteConfirmationModal();
                }}
            >
                <Icon className="delete-icon" type="trash" iWidth="20px" />
            </DeleteIcon>
        );
    };

    if (
        props.currentView === 'carriers' &&
        (!tableColumns.some((x) => x.Header === 'Carrier') ||
            !Array.isArray(manualFormattedEntryData) ||
            (manualFormattedEntryData.length !== 0 && 
                !manualFormattedEntryData.some((x) => x.companyName)))
    )
        return null;

    return (
        <>
            <ConfirmationModal
                confirmationButtonText={'Yes, I am sure'}
                cancelButtonText={'No'}
                headerText={'Are you sure?'}
                isOpen={isDeleteConfirmationOpen}
                setIsOpen={setIsDeleteConfirmationOpen}
                onSubmit={deleteEntry}
                message={deleteMessage}
                iconType="delete-circle"
                isLoading={isLoading}
                iconColor="#cc1f1a"
            ></ConfirmationModal>

            <StyledButtonContainer>
                <Icon
                    type="back-arrow"
                    styles={{
                        height: '30px',
                        width: '30px',
                        cursor: props.currentView !== 'carriers' ? 'pointer' : ''
                    }}
                    iColor={props.currentView !== 'carriers' ? '#666' : '#dbdbdb'}
                    onClick={handleBack}
                />
                <Button className={'newCprButton'} primary onClick={props.handleModal}>
                    New Entry
                </Button>
            </StyledButtonContainer>
            <TableContainer>
                {props.currentView === 'carriers' && (
                    <StyledPolicyTable>
                        <Table
                            className={classNames({
                                '-highlight': true
                            })}
                            key={Object.keys(manualFormattedEntryData).length}
                            data={manualFormattedEntryData}
                            minRows={1}
                            columns={tableColumns}
                            showPagination={false}
                        />
                    </StyledPolicyTable>
                )}
                {props.currentView === 'entries' && (
                    <>
                        <StyledCompanyNameLabel>
                            <span>{companyNameTable}</span>
                        </StyledCompanyNameLabel>
                        {subCodes.length > 0 && (
                            <StyledSelect>
                                <Select
                                    items={formatSubCodes()}
                                    onSelect={(selectedValue) => {
                                        setSubCodeTable({
                                            uuid: getUuidFromSubCode(selectedValue),
                                            code: selectedValue
                                        });
                                        setSubCodeForm({
                                            uuid: getUuidFromSubCode(selectedValue),
                                            code: selectedValue
                                        });
                                    }}
                                />
                            </StyledSelect>
                        )}
                        <StyledEntryContainer>
                            {subCodeTable &&
                                manualFormattedEntryData[subCodeTable] &&
                                map(
                                    manualFormattedEntryData[subCodeTable].groupedPoliciesByCode,
                                    (policies, key) => {
                                        const policyStatus = find(
                                            manualFormattedEntryData[subCodeTable].policyStatusObj,
                                            {
                                                type: key
                                            }
                                        );
                                        const latestPolicyEntry = _last(
                                            sortBy(
                                                manualFormattedEntryData[subCodeTable]
                                                    .groupedPoliciesByCode[key],
                                                'date'
                                            )
                                        );
                                        return (
                                            <StyledLobEntry>
                                                <StyledEntryTableHeader noBorder={true}>
                                                    <div>Line of business</div>
                                                    <div>Written Premium YTD</div>
                                                    <div>Current</div>
                                                    <div></div>
                                                </StyledEntryTableHeader>
                                                <StyledPolicyRow>
                                                    <StyledLobColumn>{key}</StyledLobColumn>
                                                    <StyledCurrencyColumn>
                                                        {new Intl.NumberFormat('en-US', {
                                                            style: 'currency',
                                                            currency: 'USD'
                                                        })
                                                            .format(latestPolicyEntry.dwp)
                                                            .replace(/\D00$/, '')}
                                                    </StyledCurrencyColumn>
                                                    <StyledPolicyStatusColumn>
                                                        {policyStatus && (
                                                            <Icon
                                                                type={
                                                                    policyStatus.current
                                                                        ? 'check'
                                                                        : 'warning-triangle'
                                                                }
                                                                iWidth="20px"
                                                                iColor={
                                                                    policyStatus.current
                                                                        ? 'green'
                                                                        : '#D69E2E'
                                                                }
                                                            />
                                                        )}
                                                    </StyledPolicyStatusColumn>
                                                    <div className="delete-icon">
                                                        {renderDeleteLineOfBusiness(
                                                            manualFormattedEntryData[subCodeTable]
                                                                .groupedPoliciesByCode[key]
                                                        )}
                                                    </div>
                                                </StyledPolicyRow>
                                                <StyledPolicyTable>
                                                    <Table
                                                        key={
                                                            Object.keys(manualFormattedEntryData)
                                                                .length
                                                        }
                                                        data={
                                                            manualFormattedEntryData[subCodeTable]
                                                                .groupedPoliciesByCode[key]
                                                        }
                                                        minRows={1}
                                                        columns={tableColumns}
                                                        showPagination={false}
                                                        defaultSorted={[{ id: 'date', desc: true }]}
                                                        getTdPropsFunction={getTdPropsFunction}
                                                    />
                                                </StyledPolicyTable>
                                            </StyledLobEntry>
                                        );
                                    }
                                )}
                        </StyledEntryContainer>
                    </>
                )}
            </TableContainer>
        </>
    );
};

ManualEntryTables.propTypes = {
    currentView: PropTypes.oneOf(['carriers', 'entries']),
    handleModal: PropTypes.func,
    manualEntryData: PropTypes.array
};

export default ManualEntryTables;
