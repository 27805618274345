import { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { Button } from '@agencykpi/components';
import { registerFormSchema } from './validators';
import { StyledForm } from './styles';
import { StyledError } from '../shared/form/styles';
import * as styles from '../MyProfile/styles';
import { MyCheckbox, MyTextInput, MyPhoneInput } from '../shared/form/formInputs';
import ErrorMessage from './errorMessage';
import { Grid, Typography, Link } from '@mui/material';
import { HtmlTooltip, getPasswordTooltipText, validate } from './htmlTooltip';

const RegisterForm = (props) => {
    const initialValues = {
        firstName: '',
        lastName: '',
        emailAddress: props.emailAddress,
        mobileNumber: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        password: '',
        confirmPassword: '',
        networkName: props.network.name,
        agencyName: props.agency.name,
        acceptedTerms: false
    };

    const [passwordBorderColor, setPasswordBorderColor] = useState('#cccccc');
    const [confirmPasswordBorderColor, setConfirmPasswordBorderColor] = useState('#cccccc');

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={props.onSubmit}
            validationSchema={registerFormSchema}
            validateOnBlur={true}
        >
            {(data) => (
                <>
                    {!props.showErrorMessage && (
                        <StyledForm>
                            <Form>
                                <Grid item xs={12} css={styles.cssTitle}>
                                    <Typography variant="h5">Registration Form</Typography>
                                </Grid>
                                <Grid container spacing={2} css={styles.cssContainer}>
                                    <Grid item xs={6}>
                                        <MyTextInput
                                            label="First Name"
                                            name="firstName"
                                            type="alpha"
                                            inputChanged={props.inputChanged}
                                            isRequired={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MyTextInput
                                            label="Last Name"
                                            name="lastName"
                                            type="alpha"
                                            isRequired={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MyTextInput
                                            label="Email Address"
                                            name="emailAddress"
                                            isRequired={true}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MyPhoneInput label="Mobile Number" name="mobileNumber" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MyTextInput
                                            label="Agency Name"
                                            name="agencyName"
                                            type="alpha"
                                            inputChanged={props.inputChanged}
                                            isRequired={true}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MyTextInput
                                            label="Network Name"
                                            name="networkName"
                                            type="alpha"
                                            isRequired={true}
                                            disabled={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} styles={styles.cssContainer}>
                                        <MyTextInput label="Address Line 1" name="address1" />
                                        <MyTextInput label="Address Line 2" name="address2" />
                                        <MyTextInput label="City" name="city" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MyTextInput label="State" name="state" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <MyTextInput
                                            label="Zip Code"
                                            name="postalCode"
                                            type="postalcode"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <HtmlTooltip
                                            title={getPasswordTooltipText({
                                                password: data.values.password
                                            })}
                                            arrow
                                            placement="top"
                                        >
                                            <MyTextInput
                                                label="Password"
                                                name="password"
                                                type="password"
                                                inputChanged={props.inputChanged}
                                                onChange={(e) => {
                                                    if (data.values.confirmPassword !== '') {
                                                        const password = e.target.value;
                                                        const confirmPassword =
                                                            data.values.confirmPassword;
                                                        const validConfirmPassword =
                                                            validate(confirmPassword) &&
                                                            password === confirmPassword;
                                                        setConfirmPasswordBorderColor(
                                                            validConfirmPassword
                                                                ? 'limegreen'
                                                                : 'red'
                                                        );
                                                    }
                                                }}
                                                style={{
                                                    border: '1px solid ' + passwordBorderColor
                                                }}
                                                onBlur={(e) => {
                                                    const valid = validate(data.values.password);
                                                    setPasswordBorderColor(
                                                        valid ? 'limegreen' : 'red'
                                                    );
                                                }}
                                                isRequired={true}
                                            />
                                        </HtmlTooltip>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <HtmlTooltip
                                            title={getPasswordTooltipText({
                                                password: data.values.confirmPassword,
                                                matchPassword: data.values.password,
                                                showPasswordMatch: true
                                            })}
                                            arrow
                                            placement="top"
                                        >
                                            <MyTextInput
                                                label="Confirm Password"
                                                name="confirmPassword"
                                                type="password"
                                                style={{
                                                    border:
                                                        '1px solid ' + confirmPasswordBorderColor
                                                }}
                                                onBlur={(e) => {
                                                    const password = data.values.password;
                                                    const confirmPassword =
                                                        data.values.confirmPassword;
                                                    const valid =
                                                        validate(confirmPassword) &&
                                                        password === confirmPassword;

                                                    setConfirmPasswordBorderColor(
                                                        valid ? 'limegreen' : 'red'
                                                    );
                                                }}
                                                isRequired={true}
                                            />
                                        </HtmlTooltip>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MyCheckbox
                                            onChange={props.inputChanged}
                                            isRequired={true}
                                            name="acceptedTerms"
                                            id="acceptedTerms"
                                            description={
                                                <Typography style={{ display: 'inline' }}>
                                                    I agree with the{' '}
                                                    <Link
                                                        href="https://www.agencykpi.com/t-and-c"
                                                        target="_blank"
                                                        rel="noopener"
                                                    >
                                                        {'Terms & Conditions'}
                                                    </Link>
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} css={styles.cssContainer}>
                                        <StyledError>{props.error}</StyledError>
                                        <Button
                                            disabled={!data.values.acceptedTerms}
                                            primary
                                            type="submit"
                                            style={{ width: '150px', marginTop: '20px' }}
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        </StyledForm>
                    )}
                    {props.showErrorMessage && <ErrorMessage reason={props.reason} />}
                </>
            )}
        </Formik>
    );
};

RegisterForm.propTypes = {
    fetching: PropTypes.bool,
    error: PropTypes.string,
    network: PropTypes.object,
    agency: PropTypes.object,
    onSubmit: PropTypes.func,
    isModalOpen: PropTypes.bool,
    modalText: PropTypes.string,
    inputChanged: PropTypes.func
};

export default RegisterForm;
