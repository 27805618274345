import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const NoLob = styled.div`
    margin: 8px 0 8px 20px;
    font-weight: 500;
`;

export const AgencyCompensationWrap = styled.div`
    .section {
        background-color: white;
        border: 1px solid rgb(221, 221, 221);
        padding: 20px;
        margin-bottom: 20px;

        .text-center {
            text-align: center;
            margin-bottom: 5px;
        }
    }
    .agency-scorecard {
        .text-center {
            text-align: center;
        }
        .additional-comp-button {
            align-self: center;
            margin-bottom: 10px;
        }
        .scorecard-table-container {
            margin-top: 30px;
            h3 {
                margin-bottom: 20px;
            }
        }
        .message-enter {
            opacity: 0.01;
        }
        .message-enter-active {
            opacity: 1;
            transition: all 250ms ease-out;
            transition-delay: 0.25s;
        }
        .message-exit {
            opacity: 1;
        }
        .message-exit-active {
            opacity: 0.01;
            transition: all 250ms ease-out;
        }
        .charts {
            display: flex;
            .chart-wrap {
                width: 45%;
                margin-right: 5%;
            }
        }
        .meter-summary,
        .agency-summary {
            position: relative;
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
        }
    }
    .ReactTable {
        .rt-thead {
            .rt-th.sub-header {
                background-color: #eef6fb;
                color: black;
                &.goal-target-subheader {
                    border-bottom: 4px solid rgba(52, 144, 220);
                }
                &.goal-plan-subheader {
                    border-bottom: 4px solid rgba(56, 193, 114);
                }
            }
            & .rt-th {
                font-weight: bold;
                background-color: #035fa1;
                text-align: center;
                border-right: solid 1px #ccc;
                color: white !important;
                &.sub-header {
                    background-color: #eeeeee;
                    color: black !important;
                }
            }
        }
    }
`;

export const modeSelectStyles = css`
    min-width: 100px;
    margin-top: 18px;
    .custom-radio {
        margin-bottom: 4px;
        div:last-of-type {
            margin-bottom: 0;
        }
    }
`;

export const flexGrow = css`
    flex-grow: 1;
`;
export const flex = css`
    display: flex;
`;

export const carrierFilterFieldGroup = css`
    display: flex;
    gap: 20px;

    .list-table-search {
        min-width: 250px;
        margin-top: 19px;
    }

    .slds-form-element {
        min-width: 114px;

        label {
            margin-bottom: 0 !important;
        }
    }

    .akpi-input > label {
        padding-top: 0;
    }
`;
export const lineOfBusinessFieldGroup = css`
    display: flex;
    .policytype-selector div {
        legend {
            margin-bottom: 0px !important;
        }
        div {
            line-height: 25px;
            margin-bottom: 0;
        }
    }
    .multiSelect_StandardTypesContainer {
        margin: 19px 0 0 0;
    }
`;

export const SelectedSummaryContainer = styled.div`
    .selection-summary {
        display: flex;
        justify-content: space-between;
        text-align: center;
        align-items: center;
        span {
            display: block;
        }
        &__title {
            color: #666;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: 14px;
        }
        &__subtitle {
            font-size: 12px;
        }
        &__label {
            color: #666;
        }
        &__amount {
            font-size: 16px;
        }
    }
    .visible {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.5s linear;
    }
    .hidden {
        display: none;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 0.2s, opacity 0.2s linear;
    }
`;

export const alignItemsCenter = css`
    align-items: center;
`;
