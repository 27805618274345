import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { Centered } from '../../shared';

import {
    NoResults,
    Result,
    ResultsContainer,
    ResultsList,
    SearchAndResultsWrapper,
    SearchContainer,
    SearchInput
} from './styles';

const CarrierSearch = ({
    results,
    searchTerm,
    onSelect,
    selectedCarrier,
    showResults,
    ...props
}) => {
    return (
        <SearchContainer>
            <SearchAndResultsWrapper>
                <SearchInput
                    name={props.inputName}
                    onChange={props.onInputChange}
                    placeholder="Search for a carrier..."
                    value={searchTerm}
                    type="text"
                    selectedCarrier={selectedCarrier}
                />
                {!!searchTerm.length && showResults && (
                    <ResultsContainer>
                        <Results
                            {...props}
                            results={results}
                            searchTerm={searchTerm}
                            onSelect={onSelect}
                        />
                    </ResultsContainer>
                )}
            </SearchAndResultsWrapper>
        </SearchContainer>
    );
};

const Results = ({ fetching, results, onSelect, searchTerm, handleRequestNewCarrierModal }) => {
    if (fetching)
        return (
            <Centered>
                {
                    searchTerm.length < 3 ? <h4>Enter at least 3 characters</h4> :
                        <Loader type="Oval" color="#f0f0f9" />}
            </Centered>
        );
    if (!results.length)
        return (
            <NoResults>
                Cannot find your Carrier?&nbsp;&nbsp;
                <a onClick={handleRequestNewCarrierModal}>Let us know</a>
            </NoResults>
        );

    return (
        <ResultsList>
            {results.map((carrier) => (
                <a onClick={onSelect.bind(null, carrier)} key={carrier.uuid}>
                    <Result>{carrier.displayName || carrier.name}</Result>
                </a>
            ))}
        </ResultsList>
    );
};

Results.propTypes = {
    fetching: PropTypes.bool,
    results: PropTypes.array,
    searchTerm: PropTypes.string,
    onSelect: PropTypes.func,
    handleRequestNewCarrierModal: PropTypes.func
};

CarrierSearch.propTypes = {
    searchTerm: PropTypes.string,
    companyType: PropTypes.string,
    results: PropTypes.array,
    Results: PropTypes.object,
    onTypeChange: PropTypes.func,
    onSelect: PropTypes.func,
    handleInputChange: PropTypes.func,
    selectedCarrier: PropTypes.any,
    inputName: PropTypes.string,
    onInputChange: PropTypes.func,
    showResults: PropTypes.bool
};

export default CarrierSearch;
