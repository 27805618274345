import { Formik, Form } from 'formik';
import { Button } from '@agencykpi/components';
import { profileFormSchema } from './validators';
import { StyledError } from '../shared/form/styles';
import { MyTextInput, MyPhoneInput } from '../shared/form/formInputs';
import { Grid, Typography } from '@mui/material';
import { cssContainer, cssTitle, cssField } from './styles';

const profileForm = (props) => {
    const initialValues = {
        firstName: props.user.firstName || '',
        lastName: props.user.lastName || '',
        emailAddress: props.user.email || '',
        mobileNumber: props.user.phoneCountryCode
            ? '1' + props.user.phoneAreaCode + props.user.phoneNumber
            : '',
        address1: props.user.streetAddress1 || '',
        address2: props.user.streetAddress2 || '',
        city: props.user.city || '',
        state: props.user.state || '',
        zipCode: props.user.postalCode || ''
    };
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
                props.onSubmit(values);
            }}
            validationSchema={profileFormSchema}
            validateOnChange={true}
        >
            {() => (
                <Form>
                    <Grid item xs={12} css={cssTitle}>
                        <Typography variant="h5">My Profile</Typography>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <MyTextInput
                                label="First Name"
                                name="firstName"
                                type="alpha"
                                inputChanged={props.inputChanged}
                                css={cssField}
                                isRequired={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MyTextInput
                                label="Last Name"
                                name="lastName"
                                type="alpha"
                                css={cssField}
                                isRequired={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MyTextInput
                                label="Email Address"
                                name="emailAddress"
                                css={cssField}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <MyPhoneInput
                                label="Mobile Number"
                                name="mobileNumber"
                                css={cssField}
                            />
                        </Grid>
                        <Grid item xs={12} css={cssContainer}>
                            <MyTextInput label="Address Line 1" name="address1" css={cssField} />
                            <MyTextInput label="Address Line 2" name="address2" css={cssField} />
                            <MyTextInput label="City" name="city" css={cssField} />
                        </Grid>
                        <Grid item xs={6}>
                            <MyTextInput label="State" name="state" css={cssField} />
                        </Grid>
                        <Grid item xs={6}>
                            <MyTextInput
                                label="Zip Code"
                                name="zipCode"
                                type="postalcode"
                                css={cssField}
                            />
                        </Grid>
                        <Grid item xs={12} css={cssContainer}>
                            <StyledError>{props.error}</StyledError>
                            <Button
                                primary
                                type="submit"
                                css={{ width: '150px', marginTop: '20px' }}
                                disabled={props.isFormDisabled}
                            >
                                Update
                            </Button>
                            <Button
                                onClick={() => {
                                    props.onResetPassword();
                                }}
                                css={{
                                    width: '150px',
                                    marginTop: '20px',
                                    marginLeft: '25px'
                                }}
                                disabled={props.isFormDisabled}
                            >
                                Reset password
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

profileForm.propTypes = {};

export default profileForm;
