import { createRef, useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useMatches, useNavigate } from 'react-router-dom';
import {
    HeaderContainer,
    PortalContainer,
    PortalContent,
    DropdownSection,
    DropdownText
} from './styles';
import SideNav from '../SideNav';
import PropTypes from 'prop-types';
import Logo from '../Logo';
import { DropdownMenu, DropdownItem } from '@agencykpi/components';
import AkpiLogo from '../../../assets/agencykpi-logo-550x69.png';
import { Icon } from '../../icons/icons';
import AnnounceKit from 'announcekit-react';
import CarrierSelectionModal from '../Modals/CarrierSelectionModal';
import ExitColaborationModal from '../Modals/ExitColaborationModal';
import { HELP_DESK_URL, LOCAL_STORAGE } from '../../constants';
import { ErrorBoundary } from '@sentry/react';
import ErrorMessage from '../Register/errorMessage';
import { IconButton } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getAdminPortUrl } from '../../utils/redirectionUtils';
import { useQuery } from '@tanstack/react-query';
import { queries } from '../../utils';

const Portal = ({ children, passive = false, isPublicPage = false }) => {
    const user = useStoreState((state) => state.user);
    const collaborationMode = useStoreState((state) => state.collaborationMode);
    const hasCollaborationMode = window.location.pathname.startsWith('/production');
    const [profileMenuOpen, setProfileMenuOpen] = useState(false);
    const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);
    const [isExitModal, setIsExitModal] = useState(false);
    const [setCollaborationMode] = useStoreActions((actions) => [actions.setCollaborationMode]);
    const menuRef = createRef();
    const navigate = useNavigate();
    const { newAdminPortal } = useFlags();
    document.addEventListener('mousedown', handleClickOutside);

    const { data: isAdmin } = useQuery(queries.permission(user, 'read', 'admin'));

    const openAdminPortal = () => {
        if (isAdmin && newAdminPortal) {
            const url = getAdminPortUrl();
            window.open(url, '_blank');
        } else {
            navigate('/my-subscription');
        }
    };

    async function handleOpenHelpPortal() {
        window.open(HELP_DESK_URL, '_blank');
    }

    useEffect(() => {
        window.addEventListener('mousedown', handleClickOutside, passive);
        return function cleanup() {
            window.removeEventListener('mousedown', handleClickOutside);
        };
    });

    useEffect(() => {
        const storedCollaborationMode = JSON.parse(sessionStorage.getItem('collaborationMode'));

        if (storedCollaborationMode != null) {
            setCollaborationMode(storedCollaborationMode);
        }
    }, []);

    const [announceKitUser, setAnnounceKitUser] = useState({});
    useEffect(() => {
        if (!user) return;

        const info = {
            id: user.id,
            email: user.email
        };

        if (user.name) {
            info.name = `${user.name.givenName} ${user.name.familyName}`;
        }

        setAnnounceKitUser(info);
    }, [user]);

    function handleClickOutside(e) {
        if (profileMenuOpen && menuRef.current !== null && !menuRef.current.contains(e.target)) {
            setProfileMenuOpen(false);
        }
    }

    function handleOpenProfileMenu() {
        setProfileMenuOpen(profileMenuOpen === false ? true : false);
    }

    const navigationExpanded = useStoreState((state) => state.navigationExpanded);
    const [setNavigationExpanded] = useStoreActions((actions) => [actions.setNavigationExpanded]);

    useEffect(() => {
        const userPreference = JSON.parse(localStorage.getItem(LOCAL_STORAGE.EXPANDED_NAV));
        setNavigationExpanded(userPreference ?? true);
    }, []);

    return (
        <>
            <ExitColaborationModal isOpen={isExitModal} setIsOpen={setIsExitModal} />
            <CarrierSelectionModal
                isOpen={isSelectModalOpen}
                setIsOpen={setIsSelectModalOpen}
                setIsExitModal={setIsExitModal}
            />
            <HeaderContainer>
                <IconButton
                    sx={{ marginLeft: '20px' }}
                    onClick={() => setNavigationExpanded(!navigationExpanded)}
                >
                    <MenuOpenIcon style={{ transform: !navigationExpanded && 'rotate(180deg)' }} />
                </IconButton>
                <div className="akpi-logo items-center max-lg:hidden">
                    <img src={AkpiLogo} />
                </div>
                <div className="header__agency-name flex grow items-center">
                    <span>{(user && user.agency && user.agency.name) || ''}</span>
                    <HeaderChildrenOutlet />
                </div>
                <div className={`mr-6 flex items-center max-md:hidden`}>
                    <AnnounceKit
                        widget="https://announcekit.co/widgets/v2/4EvStq"
                        user={announceKitUser}
                        data={{
                            understanding_user: true,
                            network_name: user.network ? user.network.abbreviation : undefined,
                            network_uuid: user.network ? user.network.uuid : undefined,
                            agency_name: user.agency ? user.agency.name : undefined,
                            agency_uuid: user.agency ? user.agency.uuid : undefined,
                            user_type: user.userType
                        }}
                    >
                        <span className={`mr-2`}>What’s new?</span>
                    </AnnounceKit>
                </div>
                <div className={`flex items-center justify-end`}>
                    <Logo />
                    <div
                        className="header__profile-menu-container"
                        style={{ display: isPublicPage ? 'none' : '' }}
                        ref={menuRef}
                    >
                        <div
                            className="header__profile-icon"
                            onClick={() => handleOpenProfileMenu()}
                        >
                            <Icon
                                type="user"
                                styles={{ height: '30px', width: '30px', cursor: 'pointer' }}
                            />
                        </div>
                        {profileMenuOpen && (
                            <DropdownMenu className="header__profile-dropdown">
                                <DropdownSection border="bottom">
                                    <DropdownItem
                                        onClick={() => {
                                            navigate('/my-profile');
                                        }}
                                    >
                                        <Icon
                                            type="profile"
                                            styles={{
                                                width: '20px',
                                                height: '20px',
                                                marginRight: '10px',
                                                opacity: 0.5,
                                                display: 'inline-flex'
                                            }}
                                        />
                                        <DropdownText>My Profile</DropdownText>
                                    </DropdownItem>
                                    {hasCollaborationMode &&
                                        collaborationMode &&
                                        collaborationMode.carriersUuid.length == 0 && (
                                            <DropdownItem
                                                style={{ marginLeft: 30 }}
                                                className={hasCollaborationMode ? '' : 'disabled'}
                                                onClick={() => setIsSelectModalOpen(true)}
                                            >
                                                <DropdownText>
                                                    Enter Collaboration Mode
                                                </DropdownText>
                                            </DropdownItem>
                                        )}

                                    {newAdminPortal ? (
                                        isAdmin ? (
                                            <DropdownItem onClick={openAdminPortal}>
                                                <Icon
                                                    type="my-subscription"
                                                    styles={{
                                                        width: '20px',
                                                        height: '20px',
                                                        marginRight: '10px',
                                                        opacity: 0.5,
                                                        display: 'inline-flex'
                                                    }}
                                                />
                                                <DropdownText>Admin Portal</DropdownText>
                                            </DropdownItem>
                                        ) : (
                                            <DropdownItem
                                                onClick={() => {
                                                    navigate('/users');
                                                }}
                                            >
                                                <Icon
                                                    type="users"
                                                    styles={{
                                                        width: '20px',
                                                        height: '20px',
                                                        marginRight: '10px',
                                                        opacity: 0.5,
                                                        display: 'inline-flex'
                                                    }}
                                                />
                                                <DropdownText>Users</DropdownText>
                                            </DropdownItem>
                                        )
                                    ) : (
                                        <>
                                            <DropdownItem onClick={openAdminPortal}>
                                                <Icon
                                                    type="my-subscription"
                                                    styles={{
                                                        width: '20px',
                                                        height: '20px',
                                                        marginRight: '10px',
                                                        opacity: 0.5,
                                                        display: 'inline-flex'
                                                    }}
                                                />
                                                <DropdownText>My Subscription</DropdownText>
                                            </DropdownItem>

                                            {user.isPrimaryUserOfSubscriber && (
                                                <DropdownItem
                                                    onClick={() => {
                                                        navigate('/users');
                                                    }}
                                                >
                                                    <Icon
                                                        type="user-management"
                                                        styles={{
                                                            width: '20px',
                                                            height: '20px',
                                                            marginRight: '10px',
                                                            opacity: 0.5,
                                                            display: 'inline-flex'
                                                        }}
                                                    />
                                                    <DropdownText>Users</DropdownText>
                                                </DropdownItem>
                                            )}
                                        </>
                                    )}
                                </DropdownSection>
                                <DropdownSection border="none">
                                    <DropdownItem
                                        onClick={() => {
                                            window.location.href = '/logout';
                                        }}
                                    >
                                        <Icon
                                            type="LogOut"
                                            styles={{
                                                width: '20px',
                                                height: '20px',
                                                marginRight: '10px',
                                                opacity: 0.5,
                                                display: 'inline-flex'
                                            }}
                                        />
                                        <DropdownText>Log Out</DropdownText>
                                    </DropdownItem>
                                    <DropdownItem onClick={handleOpenHelpPortal}>
                                        <Icon
                                            type="help"
                                            styles={{
                                                width: '20px',
                                                height: '20px',
                                                marginRight: '10px',
                                                opacity: 0.5,
                                                display: 'inline-flex'
                                            }}
                                        />
                                        <DropdownText>AgencyKPI Help Portal</DropdownText>
                                    </DropdownItem>
                                </DropdownSection>
                            </DropdownMenu>
                        )}
                    </div>
                </div>
            </HeaderContainer>
            <PortalContainer>
                {!isPublicPage && <SideNav />}
                <PortalContent
                    className={`${!navigationExpanded ? 'ml-[80px]' : 'ml-0'} max-lg:ml-[80px]`}
                >
                    <ErrorBoundary fallback={<ErrorFallback />}>
                        {typeof children === 'function' ? children({}) : children}
                    </ErrorBoundary>
                </PortalContent>
            </PortalContainer>
        </>
    );
};

Portal.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object,
        PropTypes.bool
    ]),
    network: PropTypes.string,
    showNotificationBell: PropTypes.bool,
    isPublicPage: PropTypes.bool,
    passive: PropTypes.bool
};

function ErrorFallback() {
    return (
        <ErrorMessage title="Something went wrong.">
            <p>
                Sorry, there was an error on this page. Our team has been informed about this and we
                will try to resolve this problem as soon as possible
            </p>
        </ErrorMessage>
    );
}

export default Portal;

function HeaderChildrenOutlet() {
    const matches = useMatches();
    let headerChildren;
    for (const match of matches) {
        if (match.handle && 'headerChildren' in match.handle) {
            headerChildren = match.handle.headerChildren;
        }
    }
    return headerChildren ?? null;
}
