export const calculateNewBusinessNumbers = ({ cpr, planData }) => {
    const rate = planData?.rate ? planData.rate / 100 : 0;
    const retention = planData?.retention ? planData.retention / 100 : 1;
    const growth = planData?.growthGoalPlan;

    const priorWP = cpr.priorWrittenPremiumYTD;
    const plannedWP = (growth / 100 + 1) * priorWP;
    const retainedPriorWP = priorWP * retention;

    const apNewBusiness = plannedWP - retainedPriorWP * (1 + rate);
    const apPercentNewToBook = (apNewBusiness / plannedWP) * 100;

    return { apNewBusiness, apPercentNewToBook };
};
