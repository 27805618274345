/**
 * This file and others in ./shared must be portable between Harmony and Understanding
 * Periodically this file will be copied to the other project
 */

import PropTypes from 'prop-types';

/**
 * @typedef {Object} YearMonth
 * @property {number} year
 * @property {number} month
 */

/**
 * PropType validator for YearMonth
 */
export const yearMonthPropType = PropTypes.shape({
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired
});

/**
 * @param {YearMonth} a
 * @param {YearMonth} b
 * @returns number
 */
export function differenceInMonths(a, b) {
    return a.year * 12 + a.month - (b.year * 12 + b.month);
}

/**
 * @param {YearMonth} a
 * @return {boolean}
 */
export function isEmptyYearMonth(a) {
    return a.year === 0 && a.month === 0;
}

/**
 * @param {Date} date
 * @return {YearMonth}
 */
export function getYearMonth(date) {
    return {
        year: date.getUTCFullYear(),
        month: date.getUTCMonth() + 1
    };
}

/**
 * @param {YearMonth} yearMonth
 * @return {string}
 */
export function formatYearMonth(yearMonth) {
    if (isEmptyYearMonth(yearMonth)) {
        return '…';
    }
    return `${yearMonth.month}/${String(yearMonth.year).substring(2)}`;
}

/**
 * @param {YearMonth} a
 * @param {YearMonth} b
 * @returns number - A negative number if `a` occurs before `b`,
 *     a positive number if `a` occurs after `b`, and `0` if `a` is the same as `b`.
 */
function compareYearMonth(a, b) {
    const c = a.year - b.year;
    if (c === 0) {
        return a.month - b.month;
    }
    return c;
}

/**
 *
 * @param {YearMonth} yearMonth
 * @param {{start: YearMonth, end: YearMonth}}timeframe
 * @return {'ok'|'warning'|'error'}
 */
export function statusCarrierReportDateInTimeframe(yearMonth, timeframe) {
    // timeframe ends before or on last report date
    if (compareYearMonth(timeframe.end, yearMonth) <= 0) {
        return 'ok';
    }
    // timeframe starts before or on last report date
    if (compareYearMonth(timeframe.start, yearMonth) <= 0) {
        return 'warning';
    }
    // last report date is before timeframe starts
    return 'error';
}
