import { css } from '@emotion/react';

export const title = css({
    borderBottom: '1px solid #d1d5d9',
    paddingBottom: 10,
    marginBottom: 16
});

export const buttonLink = css({
    backgroundColor: '#fbf9f4',
    border: '0 !important',
    padding: '0 !important',
    marginTop: '0 !important',
    outline: 'none !important'
});

export const confirmationDiv = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

export const confirmationButton = css({
    color: '#FFF',
    border: 'none',
    marginRight: 8,
    padding: '12px 30px'
});

export const button = css({
    marginTop: '25px' // the height of the label
});
