import styled from '@emotion/styled';

export const WrapperTxt = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Text = styled.p`
    max-width: 61%;
    text-align: center;
    font-size: 20px;
    color: #000;
    font-weight: ${(p) => (p.isBold ? 'bold' : 'normal')};
    padding: 0;
    margin: 0;
    margin-bottom: 12px;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    div {
        display: flex;
        width: 70%;
        align-items: center;
        justify-content: space-between;
    }
`;

export const Button = styled.button`
    width: 110px;
    height: 40px;
    background: ${(p) => (p.isOutlined ? 'transparent' : 'red')};
    border: ${(p) => (p.isOutlined ? '2px solid #3490DC' : 'none')};
    color: ${(p) => (p.isOutlined ? '#3490DC' : '#fff')};
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
`;

export const modalStyles = {
    overlay: {
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    content: {
        top: null,
        bottom: null,
        left: null,
        right: null,
        borderRadius: '8px',
        boxShadow: '3px 3px 10px -1px rgba(0,0,0,0.3)'
    }
};

export const ConfirmationModalContainer = styled.div`
    width: 330px;
    height: 170px;
    padding: 10px 8px;
    display: flex;
    flex-direction: column;
    justify-content: end;

    .title-container {
        width: 100%;
        text-align: center;
        padding: 20px 0px;
    }

    .buttons-container {
        display: flex;
        justify-content: space-around;
    }
`;

export const CancelIcon = styled.div`
    position: absolute;
    right: 26px;
    > .fa-times:hover {
        color: gray;
    }
    cursor: pointer;
`;
