import { useState } from 'react';
import { Formik, Form } from 'formik';
import { request } from '../../../utils/request';
import * as Yup from 'yup';
import {
    StyledSelect,
    FormSection,
    StyledMsgContainer,
    SubmitMsg,
    ButtonContainer,
    StyledModalHeading,
    MaxCharIndicator
} from './styles';
import { MyTextInput, MySelect } from '../NewCarrierDataForm/formInputs';
import { Button } from '@agencykpi/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons/faCheckCircle';
import { library } from '@fortawesome/fontawesome-svg-core';
import { StyledButtonContainer, StyledForm, ButtonWrapper } from '../NewCarrierDataForm/styles';

library.add(faCheckCircle);

const validationSchema = {
    additionalInfo: Yup.string().max(400),
    carrierName: Yup.string().max(120).required('Required'),
    carrierType: Yup.string().required('Required'),
    carrierWebsite: Yup.string()
        .url('Must be a valid URL.  eg. https://www.carrier.com')
        .required('Required')
};

const RequestNewCarrierForm = ({ email, name, handleRequestNewCarrierModal, ...props }) => {
    const formValues = {
        name: name,
        email: email,
        carrierName: '',
        carrierType: 'isStandard',
        carrierWebsite: '',
        additionalInfo: ''
    };
    const [submittingForm, setSubmittingForm] = useState(false);
    const [submittingFormMsg, setSubmittingMsg] = useState('');
    const [retryMsg1, setRetryMsg1] = useState('');
    const [retryMsg2, setRetryMsg2] = useState('');
    const [submitComplete, setSubmitComplete] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [additionalInfoLength, setAdditionalInfoLength] = useState(0);

    async function submitForm(formFields) {
        setSubmittingForm(true);
        setSubmittingMsg('Submitting your request...');
        const result = await request.post('api/carriers/jira-request').send(formFields);
        window.setInterval(() => {}, 600);
        setSubmitComplete(true);
        if (!result.body.error) {
            setSubmitSuccess(true);
            setSubmittingMsg(
                'Your request was successfully received and will be reviewed by our staff.'
            );
        } else {
            setSubmittingMsg('Oops!  Something went wrong.');
            setRetryMsg1('Please retry your request.');
            setRetryMsg2('If this issue persists, please contact us.');
        }
    }

    return (
        <>
            {submittingForm ? (
                <>
                    <StyledMsgContainer>
                        {submitSuccess && (
                            <div className="icon">
                                <FontAwesomeIcon color="green" icon={['far', 'check-circle']} />
                            </div>
                        )}
                        <SubmitMsg>{submittingFormMsg}</SubmitMsg>
                        {retryMsg1.length > 0 && <br></br>}
                        <SubmitMsg>{retryMsg1}</SubmitMsg>
                        <SubmitMsg>{retryMsg2}</SubmitMsg>
                        {submitComplete && (
                            <ButtonContainer>
                                <Button
                                    onClick={() => handleRequestNewCarrierModal('close')}
                                    type="button"
                                    value="cancel"
                                    primary
                                    className="dismiss"
                                    style={{ width: '100px', marginTop: '20px' }}
                                >
                                    Dismiss
                                </Button>
                            </ButtonContainer>
                        )}
                    </StyledMsgContainer>
                </>
            ) : (
                <>
                    <StyledModalHeading>
                        <h1>New Carrier Request</h1>
                    </StyledModalHeading>
                    <Formik
                        initialValues={formValues}
                        validateOnChange={true}
                        validationSchema={Yup.object(validationSchema)}
                        onSubmit={(values) => {
                            submitForm(values);
                        }}
                    >
                        {(props) => (
                            <StyledForm>
                                <Form
                                    autoComplete="off"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        width: '100%'
                                    }}
                                >
                                    <FormSection>
                                        <MyTextInput
                                            label="User Name"
                                            name="userName"
                                            className="textInput"
                                            type="text"
                                            placeholder={name}
                                            disabled={true}
                                        />
                                        <MyTextInput
                                            label="User Email Address"
                                            name="email"
                                            className="textInput"
                                            type="text"
                                            placeholder={email}
                                            disabled={true}
                                        />
                                    </FormSection>
                                    <FormSection>
                                        <MyTextInput
                                            label="Carrier Name"
                                            name="carrierName"
                                            className="textInput"
                                            type="text"
                                            placeholder=""
                                        />
                                        <StyledSelect>
                                            <MySelect
                                                label="Carrier Type"
                                                name="carrierType"
                                                className="select"
                                                handleChange={(selectedValue) => {}}
                                                items={[
                                                    { label: 'Multiline', value: 'isStandard' },
                                                    { label: 'Wholesaler', value: 'isMga' },
                                                    { label: 'Crop', value: 'isCrop' },
                                                    { label: 'Flood', value: 'isFlood' },
                                                    { label: 'Surety', value: 'isSurety' },
                                                    {
                                                        label: 'Workers Comp',
                                                        value: 'isWorkersComp'
                                                    }
                                                ]}
                                            />
                                        </StyledSelect>
                                    </FormSection>
                                    <FormSection>
                                        <MyTextInput
                                            label="Carrier Website"
                                            name="carrierWebsite"
                                            className="websiteInput"
                                            type="text"
                                            placeholder=""
                                        />
                                    </FormSection>
                                    <FormSection>
                                        <MyTextInput
                                            label="Additional Information"
                                            name="additionalInfo"
                                            className="infoInput"
                                            type="text"
                                            placeholder=""
                                            maxLength={400}
                                            handleChange={(e) => {
                                                if (e.target.value.length < 400) {
                                                    setAdditionalInfoLength(e.target.value.length);
                                                } else {
                                                    setAdditionalInfoLength(400);
                                                }
                                            }}
                                        />
                                        <MaxCharIndicator>
                                            ({additionalInfoLength}/400)
                                        </MaxCharIndicator>
                                    </FormSection>
                                    <StyledButtonContainer>
                                        <ButtonWrapper>
                                            <Button
                                                onClick={() =>
                                                    handleRequestNewCarrierModal('close')
                                                }
                                                type="button"
                                                value="cancel"
                                                style={{ width: '100px', marginTop: '20px' }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                disabled={
                                                    Object.keys(props.errors).length > 0 ||
                                                    Object.keys(props.touched).length === 0
                                                        ? true
                                                        : false
                                                }
                                                type={'submit'}
                                                primary
                                                style={{ width: '100px', marginTop: '20px' }}
                                            >
                                                Submit
                                            </Button>
                                        </ButtonWrapper>
                                    </StyledButtonContainer>
                                </Form>
                            </StyledForm>
                        )}
                    </Formik>
                </>
            )}
        </>
    );
};

export default RequestNewCarrierForm;
