import { request } from './request';

export class Permissions {
    constructor({ user }) {
        this.user = user;
    }

    async check(action, thing) {
        switch (thing) {
            case 'admin':
                return await this.#isAdminUser();
            default:
                return false;
        }
    }

    async #isAdminUser() {
        const { userType, isInternal } = this.user;
        if (userType === 'internal' || isInternal) {
            return true;
        }

        const { body: subscriber } = await request
            .get(`/api/subscription/subscribers`)
            .query({ subscriberUuid: this.user[userType]?.uuid });

        if (subscriber?.adminUser) {
            return subscriber.adminUser.uuid === this.user.userUuid;
        }

        return false;
    }
}
