import moment from 'moment';

export const HELP_DESK_URL = 'https://help.agencykpi.com/';

export const FILE_TYPES = {
    CPR: 'carrier_production'
};

export const COMPANY_TYPES = {
    GROUP: 'GROUP',
    BILLING: 'BILLING',
    WRITING: 'WRITING'
};

export const POLICY_TYPE_ORDER = {
    PL: 1,
    CL: 2,
    BOND: 3,
    CROP: 4,
    'P&C': 5
};

export const POLICY_TYPES = [
    { label: 'Line of Business', value: '' },
    { label: 'CL', value: 'CL' },
    { label: 'PL', value: 'PL' },
    { label: 'Bond', value: 'BOND' },
    { label: 'Crop', value: 'CROP' }
];

export const DELAY = 1500;

export const CURRENT_YEAR = new Date().getFullYear();

const TODAY = new moment();
export const ELIGIBLE_MONTH = TODAY.month();
export const ELIGIBLE_YEAR =
    TODAY.month() === 0 || (TODAY.month() === 1 && TODAY.date() <= 15) // If currently Jan or before Feb 16, use last year
        ? TODAY.year() - 1
        : TODAY.year();
export const ELIGIBLE_YEARS = [ELIGIBLE_YEAR, ELIGIBLE_YEAR - 1, ELIGIBLE_YEAR - 2];

export const KPI_FIELD_NAMES = {
    ytd: {
        writtenPremium: 'writtenPremiumYTD',
        corePremium: 'corePremiumYTD',
        nonCorePremium: 'nonCorePremiumYTD',
        baseCommission: 'baseCommissionYTD',
        coreBaseCommission: 'coreBaseCommissionYTD',
        nonCoreBaseCommission: 'nonCoreBaseCommissionYTD',
        baseCommissionPercent: 'baseCommissionPercentYTD',
        coreBaseCommissionPercent: 'coreBaseCommissionPercentYTD',
        nonCoreBaseCommissionPercent: 'nonCoreBaseCommissionPercentYTD',
        additionalCommission: 'additionalCommissionYTD',
        coreAdditionalCommission: 'coreAdditionalCommissionYTD',
        nonCoreAdditionalCommission: 'nonCoreAdditionalCommissionYTD',
        additionalCommissionPercent: 'additionalCommissionPercentYTD',
        coreAdditionalCommissionPercent: 'coreAdditionalCommissionPercentYTD',
        nonCoreAdditionalCommissionPercent: 'nonCoreAdditionalCommissionPercentYTD',
        totalCommission: 'totalCommissionYTD',
        coreTotalCommission: 'coreTotalCommissionYTD',
        nonCoreTotalCommission: 'nonCoreTotalCommissionYTD',
        totalCommissionPercent: 'totalCommissionPercentYTD',
        coreTotalCommissionPercent: 'coreTotalCommissionPercentYTD',
        nonCoreTotalCommissionPercent: 'nonCoreTotalCommissionPercentYTD'
    },
    trailing: {
        writtenPremium: 'trailingWrittenPremium',
        corePremium: 'trailingCorePremium',
        nonCorePremium: 'trailingNonCorePremium',
        baseCommission: 'trailingBaseCommission',
        coreBaseCommission: 'trailingCoreBaseCommission',
        nonCoreBaseCommission: 'trailingNonCoreBaseCommission',
        baseCommissionPercent: 'trailingBaseCommissionPercent',
        coreBaseCommissionPercent: 'trailingCoreBaseCommissionPercent',
        nonCoreBaseCommissionPercent: 'trailingNonCoreBaseCommissionPercent',
        additionalCommission: 'trailingAdditionalCommission',
        coreAdditionalCommission: 'trailingCoreAdditionalCommission',
        nonCoreAdditionalCommission: 'trailingNonCoreAdditionalCommission',
        additionalCommissionPercent: 'trailingAdditionalCommissionPercent',
        coreAdditionalCommissionPercent: 'trailingCoreAdditionalCommissionPercent',
        nonCoreAdditionalCommissionPercent: 'trailingNonCoreAdditionalCommissionPercent',
        totalCommission: 'trailingTotalCommission',
        coreTotalCommission: 'trailingCoreTotalCommission',
        nonCoreTotalCommission: 'trailingNonCoreTotalCommission',
        totalCommissionPercent: 'trailingTotalCommissionPercent',
        coreTotalCommissionPercent: 'trailingCoreTotalCommissionPercent',
        nonCoreTotalCommissionPercent: 'trailingNonCoreTotalCommissionPercent'
    }
};

export const TIMELINE_OPTIONS = {
    CUSTOM: 'Custom',
    MONTH: 'Monthly',
    YTD: 'YTD',
    MONTH_MOVER12: '12MM',
    MONTH_MOVER24: '24MM',
    MONTH_MOVER36: '36MM'
};

export const TIMELINE_SELECTED_OPTIONS = [
    { label: 'YTD', value: TIMELINE_OPTIONS.YTD },
    { label: 'Month', value: TIMELINE_OPTIONS.MONTH },
    { label: '12 Month Window', value: TIMELINE_OPTIONS.MONTH_MOVER12 },
    { label: '24 Month Window', value: TIMELINE_OPTIONS.MONTH_MOVER24 },
    { label: '36 Month Window', value: TIMELINE_OPTIONS.MONTH_MOVER36 },
    { label: 'Custom', value: TIMELINE_OPTIONS.CUSTOM }
];

export const COLLABORATION_EXCLUSIONS = {
    NAMES: 'names',
    ALL: 'everything'
};

export const LOCAL_STORAGE = {
    EXPANDED_NAV: 'akpi-expanded-navigation'
};

export const REPORTS = {
    MARKET_ASSISTANCE: {
        icon: 'market-assistance',
        key: 'understanding-market-assistance',
        label: 'Market Assistance',
        path: 'market-assistance'
    },
    MA_TEASER: {
        flag: 'pbiMarketAssistanceTeaser',
        icon: 'market-assistance',
        key: 'market-assistance-teaser',
        label: 'Market Assistance',
        path: 'market-assistance-teaser'
    },
    UNAFFILIATED_MA: {
        icon: 'market-assistance',
        key: 'unaffiliated-market-assistance',
        label: 'Market Assistance',
        path: 'marketassistance'
    },
    AGENCY_PRODUCTION: {
        icon: 'agency-compensation',
        key: 'understanding-agency-production',
        label: 'Agency Production',
        path: 'agency-production'
    },
    AP_TEASER: {
        flag: 'pbiAgencyProductionTeaser',
        icon: 'agency-compensation',
        key: 'agency-production-teaser',
        label: 'Agency Production',
        path: 'agency-production-teaser'
    },
    REVENUE_TUNER: {
        flag: 'siteRevenueTuner',
        icon: 'revenue-tuner',
        key: 'understanding-revenue-tuner',
        label: 'Revenue Tuner',
        path: 'revenue-tuner'
    },
    RT_TEASER: {
        flag: 'pbiRevenueTunerTeaser',
        icon: 'revenue-tuner',
        key: 'revenue-tuner-teaser',
        label: 'Revenue Tuner',
        path: 'revenue-tuner-teaser'
    },
    UNAFFILIATED_RT: {
        flag: 'siteRevenueTuner',
        icon: 'revenue-tuner',
        badge: 'New',
        key: 'unaffiliated-revenue-tuner',
        label: 'Revenue Tuner',
        path: 'revenuetuner'
    },
    AMS_DATA_HEALTH: {
        flag: 'siteAmsDataHealth',
        key: 'ams-data-health',
        label: 'AMS Data Health',
        path: 'ams-data-health'
    }
};
