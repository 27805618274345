import { isRouteErrorResponse, Link, useRouteError } from 'react-router-dom';
import { css } from '@emotion/react';
import { useMemo } from 'react';
import { captureException } from '@sentry/react';

const pageStyle = css`
    font-family: Rubik, system-ui, sans-serif;
    color: #3a3a3c;
    display: grid;
    grid-template-columns: 1fr 5fr 1fr 5fr 1fr;
    grid-template-rows: 8% 60px auto 60px 8%;
    height: 100%;
    width: 100%;

    > div {
        grid-column: 2 / span 1;
        grid-row: 3 / span 1;
    }

    h2 {
        font-weight: 700;
        font-size: 54px;
        line-height: 64px;
        margin: 0;
    }
    h3 {
        font-weight: 400;
        font-size: 54px;
        line-height: 64px;
        margin: 0;
    }
    .error-message {
        font-size: 18px;
        line-height: 30px;
    }
    .reference {
        margin: 20px 0;
    }
    .error-id {
        display: inline;
    }
`;

export function ErrorPage() {
    const error = useRouteError();
    const message = isRouteErrorResponse(error) ? (
        <p>{`${error.status} ${error.statusText}`}</p>
    ) : error instanceof Error ? (
        <p>{error.message}</p>
    ) : (
        <code>{JSON.stringify(error)}</code>
    );
    const eventId = useMemo(() => {
        return captureException(error);
    }, [error]);
    return (
        <div css={pageStyle}>
            <div>
                <h2>Oops!</h2>
                <h3>Something went wrong.</h3>
                <div className="error-message">{message}</div>
                <Link to="/" reloadDocument={true}>
                    Return to Understanding
                </Link>
                {eventId ? (
                    <div className="reference">
                        Reference ID: <pre className="error-id">{eventId}</pre>
                    </div>
                ) : null}
            </div>
        </div>
    );
}
