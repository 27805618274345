import { Fragment } from 'react';
import { toCurrency, toPercent } from '../../../utils';
import { CurrencyCell, PercentCell } from '../../Table/CellTypes';
import { Icon } from '../../../icons/icons';
import { HeaderWithIcon } from '../styles';
import { Tooltip } from '@mui/material';
import { HeaderWithInfoTooltip } from '../shared';

export const getSummaryColumns = () => {
    const summaryColumns = [
        {
            id: 'name-group',
            Header: '',
            fixed: 'left',
            columns: [
                {
                    id: 'name',
                    Header: 'Name',
                    headerClassName: 'sub-header',
                    accessor: 'name',
                    width: 300,
                    Cell: ({ value }) => {
                        const name = value;
                        return (
                            <Fragment>
                                <span className="planning-tool-warning">{name}</span>
                            </Fragment>
                        );
                    },
                    defaultSortDesc: false,
                    filterMethod: (filter, row, column, original) => {
                        if (!filter || (filter.value.length === 0 && filter.state.length === 0))
                            return true;
                        const textSearchFilter =
                            row.name.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
                        const stateSearchFilter = filter.state.includes(row._original.agencyState);
                        if (filter.value.length > 0 && filter.state.length > 0) {
                            return stateSearchFilter && textSearchFilter;
                        } else if (filter.value && filter.state.length === 0) {
                            return textSearchFilter;
                        } else {
                            return stateSearchFilter;
                        }
                    }
                }
            ]
        },
        {
            id: 'wpYtd',
            Header: 'Written Premium YTD',
            headerClassName: 'written-premium-column-group',
            columns: [
                {
                    id: 'wp-ytd',
                    Header: 'Current',
                    accessor: 'writtenPremiumYTD',
                    headerClassName: 'written-premium-column-group',
                    Cell: CurrencyCell
                },
                {
                    id: 'wp-change',
                    Header: 'Change',
                    accessor: (row) =>
                        row.priorWrittenPremiumYTD === 0 || row.priorWrittenPremiumYTD < 0
                            ? null
                            : ((row.writtenPremiumYTD - row.priorWrittenPremiumYTD) * 100) /
                              row.priorWrittenPremiumYTD,
                    headerClassName: 'written-premium-column-group',
                    Cell: (row) => {
                        const dataTipMessage = 'Prior value is either 0 or negative';
                        if (row.value === null) {
                            return (
                                <Tooltip title={dataTipMessage}>
                                    <div>{'N/A'}</div>
                                </Tooltip>
                            );
                        } else {
                            return (
                                <div>
                                    {`${row.value.toFixed(2)}%`}
                                    {row.value === 0 && (
                                        <div
                                            style={{
                                                marginLeft: '10px',
                                                verticalAlign: 'middle',
                                                display: 'initial'
                                            }}
                                        >
                                            -
                                        </div>
                                    )}
                                    {row.value !== 0 &&
                                        (row.value > 0 ? (
                                            <Icon
                                                type="arrow-up"
                                                iWidth="20px"
                                                iColor="#56ef44"
                                                styles={{
                                                    marginLeft: '10px',
                                                    verticalAlign: 'middle',
                                                    display: 'initial'
                                                }}
                                            />
                                        ) : (
                                            <Icon
                                                type="arrow-down"
                                                iWidth="20px"
                                                iColor="red"
                                                styles={{
                                                    marginLeft: '10px',
                                                    verticalAlign: 'middle',
                                                    display: 'initial'
                                                }}
                                            />
                                        ))}
                                </div>
                            );
                        }
                    }
                },
                {
                    id: 'wp-goal',
                    Header: () => {
                        return (
                            <HeaderWithInfoTooltip
                                title="Goal"
                                tooltip="Year-over-year written premium growth goal. (Current YTD - Prior YTD) / Prior YTD"
                            />
                        );
                    },
                    accessor: (row) => row.goal,
                    headerClassName: 'estimated-new-business-column-group',
                    Cell: (props) => {
                        const goalValue =
                            props.original.priorWrittenPremiumYTD * (1 + props.value / 100);
                        return (
                            <Tooltip title={`Written Premium Goal YTD: ${toCurrency(goalValue)}`}>
                                <div>
                                    <PercentCell {...props} />
                                </div>
                            </Tooltip>
                        );
                    }
                },
                {
                    id: 'wp-variance-to-goal',
                    Header: 'Variance to Goal',
                    accessor: 'wpVarianceToGoal',
                    headerClassName: 'estimated-new-business-column-group',
                    Cell: (row) => {
                        const dataTipMessage = 'Prior value is either 0 or negative';
                        if (row.value === null) {
                            return (
                                <Tooltip title={dataTipMessage}>
                                    <div>{'N/A'}</div>
                                </Tooltip>
                            );
                        } else {
                            return (
                                <div>
                                    {`${toCurrency(row.value)}`}
                                    {row.value === 0 && (
                                        <div
                                            style={{
                                                marginLeft: '10px',
                                                verticalAlign: 'middle',
                                                display: 'initial'
                                            }}
                                        >
                                            -
                                        </div>
                                    )}
                                    {row.value !== 0 &&
                                        (row.value > row.original.goal ? (
                                            <Icon
                                                type="arrow-up"
                                                iWidth="20px"
                                                iColor="#56ef44"
                                                styles={{
                                                    marginLeft: '10px',
                                                    verticalAlign: 'middle',
                                                    display: 'initial'
                                                }}
                                            />
                                        ) : (
                                            <Icon
                                                type="arrow-down"
                                                iWidth="20px"
                                                iColor="red"
                                                styles={{
                                                    marginLeft: '10px',
                                                    verticalAlign: 'middle',
                                                    display: 'initial'
                                                }}
                                            />
                                        ))}
                                </div>
                            );
                        }
                    }
                }
            ]
        },
        {
            id: 'nbYtd',
            Header: 'New Business YTD',
            headerClassName: 'new-business-column-group',
            columns: [
                {
                    id: 'nb-ytd',
                    Header: 'Current',
                    accessor: 'newWrittenPremiumYTD',
                    headerClassName: 'new-business-column-group',
                    Cell: CurrencyCell
                }
            ]
        },
        {
            id: 'estNbYtd',
            Header: 'Estimated New Business',
            headerClassName: 'estimated-new-business-column-group',
            columns: [
                {
                    id: 'new-business-goal',
                    Header: () => {
                        return (
                            <HeaderWithInfoTooltip
                                title="Goal"
                                tooltip="The amount of new written premium needed year-to-date to be on track to hit your yearly new business goal. Pro-rated for the current month."
                            />
                        );
                    },
                    accessor: 'newBusinessGoal',
                    headerClassName: 'estimated-new-business-column-group',
                    Cell: (props) => {
                        return (
                            <Tooltip
                                title={`Annual New Business Goal: ${toPercent(
                                    props.original.newBusinessGoalPercent
                                )}`}
                            >
                                <div>
                                    <CurrencyCell {...props} />
                                </div>
                            </Tooltip>
                        );
                    }
                },
                {
                    id: 'variance-to-goal',
                    Header: 'Variance to Goal',
                    accessor: 'varianceToGoal',
                    headerClassName: 'estimated-new-business-column-group',
                    Cell: (row) => {
                        return (
                            <div>
                                {
                                    <div style={{ display: 'inline-flex' }}>
                                        {toCurrency(row.value)}
                                    </div>
                                }
                                {row.value === 0 && (
                                    <div
                                        style={{
                                            marginLeft: '10px',
                                            verticalAlign: 'middle',
                                            display: 'initial'
                                        }}
                                    >
                                        -
                                    </div>
                                )}
                                {row.value !== 0 &&
                                    (row.value > 0 ? (
                                        <Icon
                                            type="arrow-up"
                                            iWidth="20px"
                                            iColor="#56ef44"
                                            styles={{
                                                marginLeft: '10px',
                                                verticalAlign: 'middle',
                                                display: 'initial'
                                            }}
                                        />
                                    ) : (
                                        <Icon
                                            type="arrow-down"
                                            iWidth="20px"
                                            iColor="red"
                                            styles={{
                                                marginLeft: '10px',
                                                verticalAlign: 'middle',
                                                display: 'initial'
                                            }}
                                        />
                                    ))}
                            </div>
                        );
                    }
                }
            ]
        }
    ];

    return summaryColumns;
};
