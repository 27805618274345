import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { appRoutes } from './components/App';
import {
    RouterProvider,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    createBrowserRouter,
    matchRoutes
} from 'react-router-dom';
import * as FullStory from '@fullstory/browser';
import { getConfigValues } from './utils/config';
import { initializePendo } from './utils/pendo';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

// replaced by babel-plugin-transform-inline-environment-variables during build, not runtime config.
// eslint-disable-next-line no-undef
const sentryRelease = process.env.SENTRY_RELEASE;

(async () => {
    const { config, get } = await getConfigValues();
    if (config.sentry.enabled) {
        let options = {
            // explicitly copy config to avoid new unknown properties being added automatically.
            dsn: config.sentry.dsn,
            environment: config.sentry.environment,
            enabled: config.sentry.enabled,
            release: sentryRelease
        };
        if (get('SENTRY_BROWSER_TRACING_ENABLED', true)) {
            options.integrations = [
                Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes
                })
            ];
            options.tracesSampleRate = get('SENTRY_TRACES_SAMPLE_RATE', 1.0);
        }
        Sentry.init(options);
    }

    if (config.pendo.enabled) {
        initializePendo(config.pendo.apiKey, config.pendo.environment);
    }

    if (config.fullStoryOrgId) {
        FullStory.init({ orgId: config.fullStoryOrgId });
    }

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false
            }
        }
    });

    const LDProvider = await asyncWithLDProvider({
        clientSideID: config['LD_CLIENT_ID'],
        options: {
            streaming: true
        }
    });

    const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
    const router = sentryCreateBrowserRouter(appRoutes, {
        future: {
            v7_normalizeFormMethod: true
        }
    });

    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(
        <QueryClientProvider client={queryClient}>
            <LDProvider>
                <RouterProvider router={router} future={{ v7_startTransition: true }} />
            </LDProvider>
        </QueryClientProvider>
    );
})().catch((e) => {
    console.error(e);
});
