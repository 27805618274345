import styled from '@emotion/styled';

export const AverageMeterWrap = styled.div`
    .average-meter {
        .label {
            text-transform: uppercase;
            font-family: Roboto;
            color: #777;
            font-size: 12px;
            letter-spacing: 1px;
            text-align: center;
        }
        .meters {
            flex-grow: 1;
            font-size: 24px;
            text-align: center;
        }
        max-width: 500px;
        .agency-summary {
            padding-top: 30px;
            padding-bottom: 30px;
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            position: relative;
            flex-direction: column;

            .summary-labels {
                display: flex;
                justify-content: space-between;
                color: rgb(119, 119, 119);
                text-transform: uppercase;
                font-size: 12px;
                letter-spacing: 1px;
                width: 300px;
            }
            .summary-values {
                display: flex;
                -webkit-box-pack: justify;
                justify-content: space-between;
                font-size: 16px;
                width: 300px;

                .values {
                    color: rgb(102, 102, 102);
                    font-size: 14px;
                    display: block;

                    .left {
                        text-align: left;
                    }
                    .right {
                        text-align: right;
                    }
                }
            }
        }
    }
`;
