import {
    CardContainer,
    Name,
    Amount,
    Description,
    Logo,
    Interval,
    BottomContainer,
    FeatureContainer
} from './styles';
import { Button } from '@agencykpi/components';
import { toCurrency } from '../../utils';
import * as utils from './utils';
import { Icon } from '../../icons/icons';

const SubscriptionCard = ({
    metadata,
    imageUrls,
    name,
    amount,
    interval,
    description,
    priceId,
    setIsLoading
}) => {
    const features = utils.getFeatures(metadata);
    return (
        <>
            <CardContainer>
                <div>
                    <Logo src={imageUrls.length > 0 ? imageUrls[0] : ''} />
                    <Name>{metadata.name || name}</Name>
                    <Amount>
                        {toCurrency(amount, 2)}
                        <Interval>{` /${interval.toUpperCase()}`}</Interval>
                    </Amount>
                    <FeatureContainer>
                        {features.map((feature) => {
                            return (
                                <div className="item">
                                    <Icon
                                        type="ribbon"
                                        iWidth="15px"
                                        iColor="yellowgreen"
                                        styles={{ marginRight: '5px' }}
                                    />
                                    {feature}
                                </div>
                            );
                        })}
                    </FeatureContainer>
                </div>
                <BottomContainer>
                    <Description>{metadata.description || description}</Description>
                    <Button
                        onClick={() => {
                            utils.goToCheckoutPage(priceId, setIsLoading);
                        }}
                        type="button"
                        value="select"
                        primary
                        style={{ width: '120px', marginTop: '20px' }}
                    >
                        Select
                    </Button>
                </BottomContainer>
            </CardContainer>
        </>
    );
};

export default SubscriptionCard;
