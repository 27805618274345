import { toCurrency } from '../../utils';
import { Tooltip } from '@mui/material';

export const PercentCell = (props) => {
    const value = props.value;
    if (value === null || value === undefined) {
        return <div>{'N/A'}</div>;
    }
    if (isNaN(value) && value !== undefined) {
        return <div>{value}</div>;
    }
    return <div>{`${value ? value.toFixed(2) : 0}%`}</div>;
};

export const CurrencyCell = (props) => {
    const value = props.value || 0;
    return <div>{toCurrency(value)}</div>;
};

export const ChangeCell = ({ value }) => {
    const dataTipMessage = 'Prior value is either 0 or negative';
    if (value === null) {
        return (
            <Tooltip title={dataTipMessage}>
                <div>{'N/A'}</div>
            </Tooltip>
        );
    } else {
        return <div>{`${value.toFixed(2)}%`}</div>;
    }
};
