import { useRef, useEffect } from 'react';

let _pendoEnabled = false;
let _environmentName = 'Unknown';
export function initializePendo(apiKey, environmentName) {
    _pendoEnabled = true;
    _environmentName = environmentName;
    // prettier-ignore
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
        y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
        z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
}

function mapUserToVisitor(user) {
    const pendoEnvironment = {
        product: 'Understanding',
        environment: _environmentName
    };
    if (user && 'email' in user) {
        return {
            ...pendoEnvironment,
            id: user.email,
            userUuid: user.userUuid,
            networkName: user.network ? user.network.abbreviation : undefined,
            networkUuid: user.network ? user.network.uuid : undefined,
            agencyName: user.agency ? user.agency.name : undefined,
            agencyUuid: user.agency ? user.agency.uuid : undefined,
            userType: user.userType
        };
    } else {
        return pendoEnvironment;
    }
}

export function usePendoTracking(user) {
    const isInitialized = useRef(false);
    useEffect(() => {
        if (!_pendoEnabled || !('pendo' in window) || !user) {
            return;
        }

        const visitor = mapUserToVisitor(user);
        if (isInitialized.current) {
            if (user) {
                window.pendo.identify({
                    visitor
                });
            }
        } else {
            window.pendo.initialize({
                visitor
            });
            isInitialized.current = true;
        }
    }, [user]);
}

export function trackPendoEvent(label, payload = {}) {
    if (!_pendoEnabled || !('pendo' in window)) {
        return;
    }

    window.pendo.track(label, payload);
}
