import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const cssTitle = css`
    border-bottom: 1px solid #d1d5d9;
    padding-bottom: 10px;
    margin-bottom: 30px;
`;

export const cssContainer = css`
    max-width: 1200px;
    margin: auto;
    padding: 0 8px;
`;

export const cssField = css``;

export const Link = styled.div`
    cursor: pointer;
    color: #599ad7;
    display: table-cell;
`;
