import Collapsible from 'react-collapsible';
import Logo from '../Logo';
import AkpiLogo from '../../../assets/AKPI Logo.jpg';
import { HeaderContainer, CollapsibleGroup, H2, H3 } from './style';

const HelpPage = (props) => {
    return (
        <div>
            <HeaderContainer>
                <div className="akpi-logo">
                    <img src={AkpiLogo} />
                </div>
                <Logo />
                {/* <div className="akpi-logo">
                    <img src="../../assets/AKPI Logo.jpg" />
                </div>
                <div className="header-logo">
                    <img src="../../assets/understanding-horiz.svg" />
                </div> */}
            </HeaderContainer>
            <CollapsibleGroup>
                <H2>How can we help you?</H2>
                <H3>Click help topics below</H3>
                <Collapsible trigger="Log in to Understanding" transitionTime={100}>
                    <ol>
                        <li>
                            Go to
                            <a href="https://understanding.agencykpi.com" target="_blank">
                                Understanding
                            </a>
                            login page
                        </li>
                        <li>Enter your Username*</li>
                        <li>Enter your Password</li>
                        <li>
                            Click <b>Log In</b>
                        </li>
                        <em>* Username is usually your email address</em>
                    </ol>
                </Collapsible>
                <Collapsible trigger="Reset Understanding Password" transitionTime={100}>
                    <ol>
                        <li>
                            Go to
                            <a href="https://understanding.agencykpi.com" target="_blank">
                                Understanding
                            </a>
                            login page
                        </li>
                        <li>
                            Click "<b>Don’t remember your password</b>"
                        </li>
                        <li>Enter your email address*</li>
                        <li>
                            Click <b>Send Email</b>
                        </li>
                        <li>Follow password reset instruction in your email</li>
                        <em>* Use email address you used to register your account</em>
                    </ol>
                </Collapsible>
                <Collapsible trigger="Request New Carrier" transitionTime={100}>
                    <ol>
                        <li>
                            Click <b>AKPI Tools</b>
                        </li>
                        <li>
                            Click <b>Input Additional Carrier Production</b>
                        </li>
                        <li>
                            Click <b>New Entry</b>
                        </li>
                        <li>Type in Carrier name</li>
                        <li>
                            Next to “Cannot find Carrier?” click <b>Let us know</b>
                        </li>
                        <li>Fill out "New Carrier Request" form</li>
                        <li>
                            Click <b>Submit</b>*{' '}
                        </li>
                        <em>* AgencyKPI will process your request</em>
                    </ol>
                </Collapsible>
                <Collapsible trigger="Add Non-Core Carrier CPR Data*" transitionTime={100}>
                    <ol>
                        <li>
                            Click <b>AKPI Tools</b>
                        </li>
                        <li>
                            Click <b>Input Additional Carrier Production</b>
                        </li>
                        <li>
                            Check to see if Carrier is already listed, if not, click{' '}
                            <b>New Entry</b>
                        </li>
                        <li>Add Carrier name in "New Carrier Data Entry"</li>
                        <li>
                            Choose existing or enter new agency appointment code in "Search existing
                            or enter new subcode"
                        </li>
                        <li>
                            Click <b>Next</b> or "<b>Click to create new subcode</b>"
                            <ul>
                                <li>
                                    If prompted, choose "Associated sub agency" from dropdown menu,
                                    then click <b>Next</b>
                                </li>
                            </ul>
                        </li>
                        <li>Complete "New Carrier Data Entry" form**</li>
                        <li>
                            Click <b>Submit</b>
                        </li>
                        <em>
                            *&nbsp;&nbsp;Help topic assumes Carrier is listed in our system. If not,
                            visit "Request New Carrier" topic
                        </em>
                        <br />
                        <em>**&nbsp;Assumes Year to Date values for form data entry</em>
                    </ol>
                </Collapsible>
                <Collapsible trigger="Create Agency Plan" transitionTime={100}>
                    <ol>
                        <li>
                            Click <b>Agency Planning</b>
                        </li>
                        <li>
                            Click <b>New Plan</b>
                        </li>
                        <li>Enter Plan Name</li>
                        <li>Select year from dropdown list</li>
                        <li>Enter Growth Goal (% or $) amount</li>
                        <li>
                            Click <b>Save</b>
                        </li>
                    </ol>
                </Collapsible>
                <Collapsible trigger="Modify Existing Agency Plan" transitionTime={100}>
                    <ol>
                        <li>
                            Click <b>Agency Planning</b>
                        </li>
                        <li>Click Plan Name you want to modify</li>
                        <li>Click Growth Goal (% or $) fields and enter adjustment amounts</li>
                        <li>
                            Click <b>Save</b>
                        </li>
                    </ol>
                </Collapsible>
                <Collapsible trigger="Add Carrier to Agency Plan" transitionTime={100}>
                    <ol>
                        <li>
                            Click <b>Agency Planning</b>
                        </li>
                        <li>Click Plan Name you want to modify</li>
                        <li>
                            Click <b>Add Carrier</b>
                        </li>
                        <li>Select Carrier from dropdown list</li>
                        <li>Select Line of Business from dropdown list</li>
                        <li>
                            Click <b>Add to Carrier</b>
                        </li>
                        <li>Click Growth Goal (% or $) fields and enter adjustment amounts</li>
                        <li>
                            Click <b>Save</b>*
                        </li>
                        <em>*&nbsp;For additional Lines of Business, repeat steps per Carrier</em>
                    </ol>
                </Collapsible>
                <H3>
                    If you have any other questions, please email us at&nbsp;
                    <a href="mailto:help@agencykpi.com?subject=Understanding Help">
                        help@agencykpi.com
                    </a>
                </H3>
            </CollapsibleGroup>
        </div>
    );
};

export default HelpPage;
