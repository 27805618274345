import styled from '@emotion/styled';

export const IconContainer = styled.div`
    display: contents;
    svg {
        width: ${(props) => props.iconWidth};
        path {
            stroke: ${(props) => (props.fillColor ? 'none' : props.iconColor)};
            fill: ${(props) => (props.fillColor ? props.fillColor : 'none')};
        }
    }
`;
