import styled from '@emotion/styled';

export const SplashImageContainer = styled.div``;

export const SplashImage = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
`;

export const SplashTextContainer = styled.div`
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    height: 180px;
`;

export const SplashContainer = styled.div`
    background: white;
    color: #666666;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 5000;
    top: 0;
    left: 0;
    float: left;
    text-align: justify;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
`;

export const ClickHere = styled.a`
    text-decoration: underline;
    cursor: pointer;
`;
