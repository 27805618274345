import { request, abortOnSignal } from '../../utils/request';
import moment from 'moment';

/**
 * @interface Timeframe
 * @property {Date} startDate
 * @property {Date} endDate
 * @property {boolean} isYtd
 */

/**
 * @param {object} options
 * @param {Timeframe} options.timeframe
 */
export function getNetworkSummary(options) {
    return request
        .get('/api/core-production/network/summary')
        .query({
            startDate: formatTimeframeDate(options.timeframe.startDate),
            endDate: formatTimeframeDate(options.timeframe.endDate)
        })
        .then(unwrapResponseBody);
}

/**
 * @param {object} options
 * @param {Timeframe} options.timeframe
 * @param {string[]} options.standardTypes
 * @param {string} options.policyTypeCode
 */
export function getNetworkSummaryByCarrier(options) {
    return request
        .get('/api/core-production/network/summary-by-carrier')
        .query({
            startDate: formatTimeframeDate(options.timeframe.startDate),
            endDate: formatTimeframeDate(options.timeframe.endDate),
            standardTypes: options.standardTypes,
            policyTypeCode: options.policyTypeCode
        })
        .then(unwrapResponseBody);
}

/**
 * @param {object} options
 * @param {string[]} options.levelUuid
 * @param {Timeframe} options.timeframe
 * @param {string[]} options.standardTypes,
 * @param {Record<string, boolean>} options.carrierComposites
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Record<string,any>>}
 */
export function getCoreProductionByCarrier(options) {
    return abortOnSignal(
        request.get('/api/core-production/by-carrier').query({
            startDate: formatTimeframeDate(options.timeframe.startDate),
            endDate: formatTimeframeDate(options.timeframe.endDate),
            standardTypes: options.standardTypes.join(','),
            ...options.carrierComposites,
            isYtd: options.timeframe.isYtd,
            levelUuid: options.levelUuid
        }),
        options.signal
    ).then(unwrapResponseBody);
}

/**
 * @param {object} options
 * @param {string[]} options.levelUuid
 * @param {Timeframe} options.timeframe
 * @param {string[]} options.standardTypes,
 * @param {Record<string, boolean>} options.carrierComposites
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Record<string,any>>}
 */
export function getCoreProductionByAgency(options) {
    return abortOnSignal(
        request.get('/api/core-production/by-agency').query({
            startDate: formatTimeframeDate(options.timeframe.startDate),
            endDate: formatTimeframeDate(options.timeframe.endDate),
            standardTypes: options.standardTypes.join(','),
            ...options.carrierComposites,
            isYtd: options.timeframe.isYtd,
            levelUuid: options.levelUuid
        }),
        options.signal
    ).then(unwrapResponseBody);
}

/**
 * @param {object} options
 * @param {string[]} options.levelUuid
 * @param {Timeframe} options.timeframe
 * @param {string[]} options.standardTypes,
 * @param {Record<string, boolean>} options.carrierComposites
 * @param {AbortSignal} [options.signal]
 * @return {Promise<Record<string,any>>}
 */
export function getCoreProductionByAgencyCode(options) {
    return abortOnSignal(
        request.get('/api/core-production/by-agencyCode').query({
            startDate: formatTimeframeDate(options.timeframe.startDate),
            endDate: formatTimeframeDate(options.timeframe.endDate),
            standardTypes: options.standardTypes.join(','),
            ...options.carrierComposites,
            isYtd: options.timeframe.isYtd,
            levelUuid: options.levelUuid
        }),
        options.signal
    ).then(unwrapResponseBody);
}

/**
 * @param {object} options
 * @param {Timeframe} timeframe
 * @return {Promise<{carrierReportedMonths: any[]}>}
 */
export function getCoreProductionReportedMonths({ timeframe }) {
    return request
        .get('/api/core-production/reported-months')
        .query({
            rangeStart: moment(timeframe.startDate).subtract(1, 'years').format('YYYY-MM'),
            rangeEnd: moment(timeframe.endDate).format('YYYY-MM')
        })
        .then(unwrapResponseBody);
}

export function getCarriers() {
    return request.get('/api/core-production/carriers').then(({ body: { carriers } }) => carriers);
}

export const carriersQuery = {
    queryKey: ['core-production', 'carriers'],
    queryFn() {
        return getCarriers().then(
            (entries) =>
                new Map(
                    entries.map((entry) => {
                        // hydrates serialized dates into Date instances
                        let mostRecentReportingDate;
                        let manualMostRecentReportingDate;
                        if (entry[1].mostRecentReportingDate) {
                            mostRecentReportingDate = new Date(entry[1].mostRecentReportingDate);
                        }
                        if (entry[1].manualMostRecentReportingDate) {
                            manualMostRecentReportingDate = new Date(
                                entry[1].manualMostRecentReportingDate
                            );
                        }
                        if (mostRecentReportingDate || manualMostRecentReportingDate) {
                            return [
                                entry[0],
                                {
                                    ...entry[1],
                                    mostRecentReportingDate,
                                    manualMostRecentReportingDate
                                }
                            ];
                        } else {
                            return entry;
                        }
                    })
                )
        );
    },
    staleTime: 10 * 60 * 1000
};

function unwrapResponseBody({ body }) {
    return body;
}

function formatTimeframeDate(date) {
    return moment(date).format('YYYY-MM-DD');
}
