import { useState, useCallback, useRef, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import isEmpty from 'lodash/isEmpty';
import PolicyTypeSelector from '../PolicyTypeSelector/PolicyTypeSelector';
import StandardTypeSelector from '../StandardTypeSelector';
import { ExpandAllButton } from '../shared/ExpandAllButton';
import { RadioButton, Select, Input } from '@agencykpi/components';
import PropTypes from 'prop-types';
import * as styles from './styles';
import FiltersSelector from '../shared/FiltersSelector';
import CarriersFilters from '../shared/CarriersFilters';
import cloneDeep from 'lodash/cloneDeep';
import {
    FilterContainer,
    DashboardControls,
    CarrierContainer,
    Filters,
    FiltersDisplay
} from '../CoreProduction/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';

const carrierTypeOptions = [
    {
        label: 'All',
        value: 'all'
    },
    {
        label: 'Core',
        value: 'core'
    },
    {
        label: 'Non-Core',
        value: 'noncore'
    }
];

export function TableForm({ showModeSelect, values, setValues, countRows, data }) {
    const [searchTerm, setSearchTerm] = useFieldValue('searchTerm', values, setValues);
    const [mode, setMode] = useFieldValue('mode', values, setValues);
    const [carrierType, setCarrierType] = useFieldValue('carrierType', values, setValues);
    const [policyType, setPolicyType] = useFieldValue('policyType', values, setValues);
    const [, setStandardTypes] = useFieldValue('standardTypes', values, setValues);
    const [expandedRows, setExpandedRows] = useFieldValue('expandedRows', values, setValues);
    const selectedFiltersRef = useRef([]);
    const [optionsList, setOptionsList] = useState([]);
    const [selectedIds, setSelectedIds] = useFieldValue('selectedIds', values, setValues);
    const flags = useFlags();

    const anyExpanded =
        !isEmpty(expandedRows) && Object.values(expandedRows).some((v) => v !== false);
    function toggleExpandedRows() {
        if (anyExpanded) {
            setExpandedRows({});
        } else {
            setExpandedRows(Array.prototype.fill.call({ length: countRows }, true, 0));
        }
    }

    const getSelectedCarrierNames = (list, selectedIds) => {
        const selectedNames = [];
        const parents = selectedIds.filter(
            (selected) => selected.parentId !== undefined && selected.isParent
        );

        selectedIds.forEach((selected) => {
            if (selected.id !== 1) {
                const parent = parents.find(
                    (p) => p.id === selected.id || p.id == selected.parentId
                );
                const data = list.find((aux) => aux.id === selected.id);
                const name = parent ? parent.uuid : data.text;
                if (!selectedNames.includes(name)) {
                    selectedNames.push(name);
                }
            }
        });
        return selectedNames;
    };

    const applyFilters = () => {
        const ids = selectedFiltersRef.current?.selected?.map((selected) => {
            return {
                uuid: selected.uuid === undefined ? selected.text : selected.uuid,
                isParent: selected.isParent,
                isCarrier: !selected.isMga,
                parentId: selected.parentId,
                id: selected.id
            };
        });
        setSelectedIds(ids || []);
    };

    function FiltersContent() {
        return (
            <DashboardControls>
                <CarrierContainer>
                    <CarriersFilters
                        carriersList={cloneDeep(optionsList)}
                        carriersFiltersRef={selectedFiltersRef}
                    />
                </CarrierContainer>
            </DashboardControls>
        );
    }

    useEffect(() => {
        let parsedRows = [];

        if (data) {
            const formattedData = Object.values(data);
            parsedRows = formattedData
                .map((row) => {
                    const policyType = Object.keys(row)[0];
                    let data = row[policyType];

                    let parentId = 0;
                    if (data.isMga) parentId = 3;
                    else parentId = 2;

                    return {
                        parentId,
                        id: data.carrierUuid,
                        text: data.carrierName,
                        selected: true,
                        isParent: false
                    };
                })
                .sort((a, b) => {
                    if (a.text < b.text) return -1;
                    if (a.text > b.text) return 1;

                    return 0;
                });
        }
        const sectionedData = [
            {
                id: 1,
                text: 'All Carriers & Wholesalers',
                expanded: true,
                selected: true,
                isParent: true
            },
            {
                id: 2,
                parentId: 1,
                text: 'All Carriers',
                expanded: false,
                selected: true,
                isParent: true
            },
            {
                id: 3,
                parentId: 1,
                text: 'All Wholesalers',
                expanded: false,
                selected: true,
                isParent: true
            },
            ...parsedRows
        ];
        setOptionsList(sectionedData);
        selectedFiltersRef.current.selected = sectionedData;
        selectedFiltersRef.current.all = sectionedData;
        const selected = sectionedData.map((row) => {
            return {
                uuid: row.carrierUuid === undefined ? row.text : row.carrierUuid,
                isParent: row.isParent,
                isCarrier: !row.isMga,
                parentId: row.parentId,
                id: row.id
            };
        });
        setSelectedIds(selected);
    }, [data]);

    useEffect(() => {
        if (data) {
            applyFilters();
        }
    }, [optionsList]);

    return (
        <div>
            <div css={styles.flex}>
                <div css={[styles.carrierFilterFieldGroup, styles.flexGrow]}>
                    <div className="list-table-search">
                        <Input
                            id="table-search"
                            label=""
                            placeholder={'Search for carrier...'}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <Select
                        label="Carrier Type"
                        items={carrierTypeOptions}
                        onSelect={(value) => setCarrierType(value)}
                        value={carrierType}
                    />
                </div>
                {showModeSelect && <ModeSelect value={mode} setValue={setMode} />}
            </div>

            <div css={[styles.flex, styles.alignItemsCenter]}>
                <div css={[styles.lineOfBusinessFieldGroup, styles.flexGrow]}>
                    <PolicyTypeSelector
                        label="Line of Business"
                        type={policyType}
                        onChange={(e) => {
                            setPolicyType(e.target.value);
                            setStandardTypes([]);
                        }}
                    />
                    <PolicyTypeLineOfBusinessMultiSelect
                        setPolicyTypes={setStandardTypes}
                        parentPolicyType={policyType}
                    />
                </div>

                <Filters>
                    <FiltersDisplay>
                        <div className="title">
                            Filters:
                            {getSelectedCarrierNames(optionsList, selectedIds).map(
                                (carrierName) => {
                                    return (
                                        <div className="text" key={carrierName}>
                                            {carrierName}
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </FiltersDisplay>

                    <FilterContainer>
                        <FiltersSelector
                            content={<FiltersContent />}
                            applyAction={() => setOptionsList(selectedFiltersRef.current.all)}
                            displayText="Filters"
                            iconType="filter"
                            buttonText="Clear Filters"
                            buttonAction={() => {
                                selectedFiltersRef.current.ref?.current?.instance.selectAll();
                            }}
                            applyButtonText="Apply Filters"
                        />
                        <ExpandAllButton onToggle={toggleExpandedRows} expanded={anyExpanded} />
                    </FilterContainer>
                </Filters>
            </div>
        </div>
    );
}

TableForm.propTypes = {
    showModeSelect: PropTypes.bool,
    values: PropTypes.shape({}),
    setValues: PropTypes.func,
    countRows: PropTypes.number,
    data: PropTypes.object
};

export function useFieldValue(fieldName, values, setValues) {
    const set = useCallback(
        (value) => {
            setValues((values) => {
                return {
                    ...values,
                    [fieldName]: value
                };
            });
        },
        [fieldName]
    );
    return [values[fieldName], set];
}

function ModeSelect({ value, setValue }) {
    return (
        <div css={styles.modeSelectStyles}>
            <RadioButton
                size="small"
                label="12M Book"
                id="mode-trailing"
                name="mode"
                value="trailing"
                checked={value === 'trailing'}
                onChange={(e) => setValue(e.target.value)}
            />
            <RadioButton
                size="small"
                label="YTD"
                id="mode-ytd"
                name="mode"
                value="ytd"
                checked={value === 'ytd'}
                onChange={(e) => setValue(e.target.value)}
            />
        </div>
    );
}
ModeSelect.propTypes = {
    value: PropTypes.oneOf(['trailing', 'ytd']),
    setValue: PropTypes.func.isRequired
};

function PolicyTypeLineOfBusinessMultiSelect({ parentPolicyType, setPolicyTypes }) {
    const [canApplyStandardTypes, setCanApplyStandardTypes] = useState(false);
    const [selectedStandardTypes, setSelectedStandardTypes] = useState([]);
    const standardTypeOptions = useStoreState((state) => state.standardTypes);
    function handleStandardTypeSelect(newValues) {
        if (newValues.length === 0) {
            setPolicyTypes([]);
            setSelectedStandardTypes([]);
            setCanApplyStandardTypes(false);
        } else {
            setSelectedStandardTypes(newValues);
            setCanApplyStandardTypes(true);
        }
    }
    return parentPolicyType === 'PL' || parentPolicyType === 'CL' ? (
        <StandardTypeSelector
            policyType={parentPolicyType}
            allStandardTypes={standardTypeOptions}
            onSelect={handleStandardTypeSelect}
            onApply={() => setPolicyTypes(selectedStandardTypes)}
            canApplyStandardTypes={canApplyStandardTypes}
        />
    ) : null;
}

PolicyTypeLineOfBusinessMultiSelect.propTypes = {
    parentPolicyType: PropTypes.string.isRequired,
    setPolicyTypes: PropTypes.func.isRequired
};
