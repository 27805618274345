import { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { Icon } from '../../icons/icons';
import MyModal from '../shared/form/modal';
import toast from '../shared/toast';
import { request } from '../../utils/request';

const ITEM_HEIGHT = 48;

const ActiveActions = ({ setIsLoading, setIsFetching, user, adminUuid }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalText, setModalText] = useState('');
    const [confirmationType, setConfirmationType] = useState('');
    const open = Boolean(anchorEl);

    const resetPassword = async () => {
        try {
            setIsModalOpen(false);
            const response = await request
                .post(`/api/invites/reset-password`)
                .query({ userUuid: user.id });
            if (response.body.status === 200) {
                toast('Reset password email sent', 'success');
            } else {
                toast(response.body.message, 'error');
            }
        } catch (err) {
            toast('There was an error reseting password', 'error');
        }
    };

    const deleteUser = async () => {
        setIsLoading(true);
        setIsModalOpen(false);
        try {
            const response = await request
                .delete(`/api/invites/delete`)
                .query({ userUuid: user.id });
            if (response.body.status === 200) {
                setIsFetching(true);
                toast('User deleted', 'success');
            } else {
                toast(response.body.message, 'error');
            }
        } catch (err) {
            toast('There was an error deleting user', 'error');
        }
    };

    const handleOnClick = (type) => {
        const message = type === 'reset' ? 'reset the password of' : 'delete';
        setAnchorEl(null);
        setModalText(`Are you sure you want to ${message} ${user.firstName} ${user.lastName}?`);
        setIsModalOpen(true);
        setConfirmationType(type);
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) => setAnchorEl(e.currentTarget)}
            >
                <Icon type="popup-menu" iWidth={'30px'} iColor="grey" />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={() => setAnchorEl(null)}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch'
                    }
                }}
            >
                <MenuItem key="reset-password" onClick={() => handleOnClick('reset')}>
                    Reset Password
                </MenuItem>
                {adminUuid !== user.id && (
                    <MenuItem key="delete-user" onClick={() => handleOnClick('delete')}>
                        Delete User
                    </MenuItem>
                )}
            </Menu>
            <MyModal
                isModalOpen={isModalOpen}
                handleModalConfirmation={confirmationType === 'reset' ? resetPassword : deleteUser}
                handleModalDecline={() => setIsModalOpen(false)}
                modalText={modalText}
                isConfirmation
            />
        </div>
    );
};

export default ActiveActions;
