import { IconContainer } from './styles';
import PropTypes from 'prop-types';
import Book from './bookofbusiness.svg';
import PerformanceChart from './svg/Performance Charts & Graphs.svg';
import Toolbox from './svg/AKPI Tools.svg';
import CarrierProduction from './svg/Carrier Production Data.svg';
import DownChevron from './svg/Drop Down Menu - Down.svg';
import UpChevron from './svg/Drop Down Menu - Up.svg';
import Logout from './svg/Logout.svg';
import User from './svg/Profile.svg';
import Contract from './svg/Option - Contract.svg';
import CoreCarrier from './svg/Core Carrier.svg';
import Trash from './svg/Trash.svg';
import DeleteCircle from './svg/Option-Stop.svg';
import Warning from './svg/Caution.svg';
import Download from './svg/Download.svg';
import BackArrow from './svg/BackArrow.svg';
import Help from './svg/Help.svg';
import MySubscription from './svg/My Subscription.svg';
import Close from './svg/CloseOutScreen.svg';
import Check from './svg/Option-Check.svg';
import Check2 from './svg/Check.svg';
import WarningTriangle from './svg/WarningTriangle.svg';
import Stop from './svg/Stop.svg';
import TimeframeSelector from './svg/Timeframe-Selector2.svg';
import Profile from './svg/User Management.svg';
import UserManagement from './svg/Option - User Management 1.svg';
import ReportBug from './svg/Report Bug.svg';
import Info from './svg/Learn More.svg';
import InfoFill from './svg/Learn More Fill.svg';
import PopupMenu from './svg/Pop-Up Menu.svg';
import Filter from './svg/Filter.svg';
import Carriers from './svg/Carriers.svg';
import Growth from './svg/Growth.svg';
import LossRatio from './svg/Growth.svg';
import NewToBook from './svg/new to book.svg';
import PremiumRetention from './svg/Premium retention.svg';
import WrittenPremium from './svg/written premium.svg';
import Ribbon from './svg/Extra - Ribbon.svg';
import ArrowUp from './svg/arrow_upward.svg';
import ArrowDown from './svg/arrow_downward.svg';
import PlanMeasurement from './svg/Plan Measurement.svg';
import AgencyCompensation from './svg/Agency Compensation.svg';
import Certified from './svg/Certified.svg';
import Circle from './svg/Circle.svg';
import Configuration from './svg/Settings.svg';
import Exit from './svg/Exit.svg';
import Search from './svg/Search.svg';
import ProducerDashboard from './svg/Producer Dashboard.svg';
import MarketAssistance from './svg/MarketAssistance.svg';
import DataHealth from './svg/DataHealth.svg';
import RevenueTuner from './svg/Revenue Tuner.svg';
import Users from './svg/Users.svg';
import CustomerAccounts from './svg/customerAccounts.svg';

export const iconList = {
    'agency-compensation': AgencyCompensation,
    'arrow-down': ArrowDown,
    'arrow-up': ArrowUp,
    'back-arrow': BackArrow,
    bookofbusiness: Book,
    'carrier-production': CarrierProduction,
    carriers: Carriers,
    check: Check,
    check2: Check2,
    'chevron-down': DownChevron,
    'chevron-up': UpChevron,
    close: Close,
    contract: Contract,
    corecarrier: CoreCarrier,
    'cpr-data-health': DataHealth,
    'delete-circle': DeleteCircle,
    download: Download,
    filter: Filter,
    growth: Growth,
    help: Help,
    info: Info,
    'info-fill': InfoFill,
    logout: Logout,
    'loss-ratio': LossRatio,
    'market-assistance': MarketAssistance,
    'revenue-tuner': RevenueTuner,
    'my-subscription': MySubscription,
    'new-to-book': NewToBook,
    'performance-chart': PerformanceChart,
    'plan-measurement': PlanMeasurement,
    'popup-menu': PopupMenu,
    'premium-retention': PremiumRetention,
    profile: Profile,
    'report-bug': ReportBug,
    ribbon: Ribbon,
    stop: Stop,
    'timeframe-selector': TimeframeSelector,
    toolbox: Toolbox,
    trash: Trash,
    user: User,
    'user-management': UserManagement,
    warning: Warning,
    'warning-triangle': WarningTriangle,
    'written-premium': WrittenPremium,
    certified: Certified,
    circle: Circle,
    configuration: Configuration,
    exit: Exit,
    search: Search,
    'Producer Dashboard': ProducerDashboard,
    users: Users,
    customerAccounts: CustomerAccounts,
};

const fillType = {
    'arrow-down': 'fillColor',
    'arrow-up': 'fillColor',
    filter: 'fillColor',
    growth: 'fillColor',
    'loss-ratio': 'fillColor',
    'new-to-book': 'fillColor',
    'premium-retention': 'fillColor',
    ribbon: 'fillColor',
    'timeframe-selector': 'fillColor',
    'written-premium': 'fillColor',
    certified: 'fillColor',
    circle: 'fillColor',
    configuration: 'fillColor',
    exit: 'fillColor',
    info: 'fillColor',
    'info-fill': 'fillColor'
};

const MyIcon = ({ iWidth, Component, style, onClick, colorProps, className }) => {
    return (
        <IconContainer
            {...colorProps}
            iconWidth={iWidth}
            style={style}
            onClick={onClick}
            className={className}
        >
            <Component />
        </IconContainer>
    );
};

MyIcon.propTypes = {
    className: PropTypes.string,
    colorProps: PropTypes.object,
    Component: PropTypes.func,
    iWidth: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object
};

export function Icon({ type, styles, iColor = '#000', iWidth = '30px', onClick, className }) {
    const svg = iconList[type];
    const colorProps = fillType[type] ? { [fillType[type]]: iColor } : { iconColor: iColor };
    return svg ? (
        <MyIcon
            iColor={iColor}
            colorProps={colorProps}
            iWidth={iWidth}
            Component={iconList[type]}
            style={styles}
            onClick={onClick}
            className={className}
        />
    ) : null;
}

Icon.propTypes = {
    className: PropTypes.string,
    iColor: PropTypes.string,
    iWidth: PropTypes.string,
    onClick: PropTypes.func,
    styles: PropTypes.object,
    type: PropTypes.string
};
