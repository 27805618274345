import { request } from './request';
import { useQuery } from '@tanstack/react-query';

export function useClarityUser() {
    return useQuery({
        queryKey: ['clarity-user'],
        queryFn() {
            return request
                .get('/api/clarity/user')
                .then(({ body }) => body)
                .catch((err) => {
                    console.log(err);
                    if (err.status === 403) {
                        return {
                            permissions: []
                        };
                    }
                    throw err;
                });
        },
        select(data) {
            return {
                network: data.network,
                agency: data.agency,
                primaryAmsName: data.primaryAms ? data.primaryAms.name : null,
                permittedToUpload: data.permissions.includes('clarity:access:basic')
            };
        }
    });
}
