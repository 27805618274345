import CarrierSearch from '../CarrierSearch';
import SubCodeSearch from '../SubCodeSearch';
import { useField } from 'formik';
import { Button, Input, Select, RadioButton } from '@agencykpi/components';
import {
    StyledErrorMessage,
    StyledLabel,
    StyledTextInput,
    StyledRadio,
    StyledButtonContainer,
    StyledSelect,
    ButtonWrapper
} from './styles';

function formatValueToDollar(value, oldValue = null) {
    //This function will convert/format given value to USD currency with initial values '', $, char
    let newValue = value;
    if (oldValue === '-$' && newValue === '-') return '';
    if (newValue === '$' || newValue === '') return '';
    if (
        newValue === '-' ||
        newValue === '--' ||
        newValue === '-$' ||
        newValue === '-$$' ||
        newValue === '-$0' ||
        newValue === '-$-'
    ) {
        return '-$';
    }
    if (newValue === '0' || newValue === '$0' || newValue === '$00') return '$0';
    if (!Number.isInteger(value)) {
        newValue = !parseInt(value.replace(/\$|,/g, ''))
            ? '$'
            : parseInt(value.replace(/\$|,/g, ''));
    }
    if (!Number.isInteger(newValue)) return newValue;
    return parseInt(newValue).toLocaleString(undefined, {
        style: 'currency',
        currency: 'USD',
        maximumSignificantDigits: 15
    });
}

function formatValueToPercent(value, oldValue = '') {
    //This function will convert/format given value to percents with initial values '', $, char
    //Backspace is getting rid of % but we need to get rid of char in front of %
    value =
        oldValue.includes('%') && !value.includes('%') ? value.slice(0, value.length - 1) : value;

    if (value === '' || value === null) {
        return '';
    }

    if (value === '-' || value === '.' || value === '-.') {
        return value;
    }

    const normalizeDecimals = (value) => {
        const re = /(^-?(\d+)?\.?)|(\d+)/g;
        const matchingString = value.toString().match(re);
        return matchingString.length > 0 ? matchingString.join('').substring(0, 5) : value;
    };

    const toNumber = (value) => {
        if (!value) {
            return 0;
        } else if (value === '-') {
            return value;
        }
        // removes % because formatter puts it into value.
        value = value.toString().replace(/[%]/g, '');
        // decimal values are a special case.
        if (value.includes('.')) {
            return normalizeDecimals(value);
        }
        return Number(value.toString().replace(/[^0-9/-]/g, ''));
    };

    const inputNumber = toNumber(value);

    const re = new RegExp(/\d/);
    const hasNumbers = re.exec(value);
    // remove incorrect inputs
    if (!hasNumbers) {
        return '';
    }
    if (typeof inputNumber === 'string' && inputNumber.includes('.')) {
        return `${inputNumber}%`;
    }

    const inputNumberTruncated =
        inputNumber < 0
            ? inputNumber.toString().substring(0, 4)
            : inputNumber.toString().substring(0, 5);
    return (inputNumberTruncated / 100).toLocaleString('en-US', {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
}

export const MyTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta, helpers] = useField(props);
    if (props.type === 'dollar') {
        field.value = field.value.length === 0 ? '' : formatValueToDollar(field.value);
    }
    if (props.type === 'percent') {
        field.value =
            !field.value || field.value.length === 0 ? '' : formatValueToPercent(field.value);
    }
    return (
        <StyledTextInput>
            <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
            <Input
                className="text-input"
                {...field}
                {...props}
                onChange={(e) => {
                    switch (props.type) {
                        case 'dollar':
                            helpers.setValue(formatValueToDollar(e.target.value, field.value));
                            break;
                        case 'percent':
                            //TODO percent manip function
                            helpers.setValue(formatValueToPercent(e.target.value, field.value));
                            if (props.handleChange) {
                                props.handleChange(e);
                            }
                            break;
                        default:
                            if (props.max) {
                                helpers.setValue(e.target.value.slice(0, props.max));
                            } else {
                                helpers.setValue(e.target.value);
                            }
                            if (props.handleChange) {
                                props.handleChange(e);
                            }
                    }
                }}
                error={meta.touched && meta.error ? meta.error : ''}
            />
        </StyledTextInput>
    );
};

export const MySelect = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props);
    return (
        <StyledSelect>
            <Select
                {...field}
                {...props}
                label={label}
                onSelect={(selectedValue) => {
                    helpers.setTouched(true);
                    helpers.setValue(selectedValue);
                    if (props.handleChange) {
                        props.handleChange(selectedValue);
                    }
                }}
            />
            {meta.touched && meta.error ? (
                <StyledErrorMessage>{meta.error}</StyledErrorMessage>
            ) : null}
        </StyledSelect>
    );
};

export const MyCarrierSearch = ({ label, ...props }) => {
    const [field, meta, helpers] = useField('carrier');
    return (
        <>
            <CarrierSearch
                onSelect={(selectedCarrier) => {
                    helpers.setTouched(true);
                    helpers.setValue(selectedCarrier);
                    props.onCarrierSelect(selectedCarrier);
                }}
                selectedCarrier={field.value}
                className="text-input"
                {...field}
                {...props}
            />
            {meta.touched && meta.error && field.value !== '' ? (
                <StyledErrorMessage className="error">{meta.error.name}</StyledErrorMessage>
            ) : null}
        </>
    );
};

export const MySubCodeSearch = ({ label, ...props }) => {
    const [field, meta, helpers] = useField('subcode');
    return (
        <>
            <SubCodeSearch
                onSelect={(selectedSubCode) => {
                    helpers.setTouched(true);
                    helpers.setValue(selectedSubCode);
                    props.onSubCodeSelect(selectedSubCode);
                }}
                selectedSubCode={field.value}
                className="text-input"
                {...field}
                {...props}
            />
            {meta.touched && meta.error && field.value !== '' ? (
                <StyledErrorMessage className="error">{meta.error.name}</StyledErrorMessage>
            ) : null}
        </>
    );
};

export const MyRadio = ({ children, setRadioGroup, ...props }) => {
    // We need to tell useField what type of input this is
    // since React treats radios and checkboxes differently
    // than inputs/select/textarea.
    const [field, meta] = useField({ ...props, type: 'radio' });
    setRadioGroup(field, meta);
    return (
        <StyledRadio>
            <label className="radio">
                <RadioButton type="radio" {...field} {...props} />
                {children}
            </label>
            {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
        </StyledRadio>
    );
};

export const MyFormNavButtonContainer = (props) => {
    const formErrors = props.formProps.errors;
    const formStages = props.formStages;
    const touched = props.formProps.touched;
    const isDisabled =
        Object.keys(formErrors).length > 0 || Object.keys(touched).length === 0 ? true : false;
    const type = Object.keys(formErrors).length === 0 ? 'submit' : '';

    return (
        <StyledButtonContainer>
            <StyledErrorMessage>{props.formMsg}</StyledErrorMessage>
            <ButtonWrapper>
                <Button
                    onClick={props.closeModal}
                    type="button"
                    value="cancel"
                    style={{ width: '100px', marginTop: '20px' }}
                >
                    Cancel
                </Button>
                <Button
                    disabled={formStages[0].active && isDisabled}
                    type={type}
                    primary
                    style={{ width: '100px', marginTop: '20px' }}
                >
                    {formStages[1].active ? 'Submit' : 'Next'}
                </Button>
            </ButtonWrapper>
        </StyledButtonContainer>
    );
};
