/**
 * This file and others in ./shared must be portable between Harmony and Understanding
 * Periodically this file will be copied to the other project
 */

export function calculateChangeRateAsPercent(currentValue, priorValue) {
    return priorValue ? ((currentValue - priorValue) * 100) / priorValue : 0;
}

export function calculateLossRatio(earnedPremium, incurredLoss) {
    return earnedPremium ? (incurredLoss * 100) / earnedPremium : 0;
}
