import styled from '@emotion/styled';

import theme from '../../theme';

export const LoginLink = styled.a`
    background-color: ${theme.colors.backgroundLight};
    margin: 25px;
    border-radius: 5px;
    text-decoration: none;
    color: ${theme.colors.primary};
    text-align: center;
    border: 1px solid #ccc;
    display: inline-block;
    font-size: 18px;
    padding: 10px 20px;
    cursor: pointer;
    &:hover {
        background-color: #f0f0f0;
    }
`;
