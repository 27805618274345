import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

export const SubscriptionList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const Title = styled.div`
    margin: 15px;
    text-align: center;
    font-size: 28px;
    color: #5e595c;
`;

export const CardContainer = styled.div`
    background: #ffffff;
    box-shadow: 3px 3px 10px -1px rgb(0 0 0 / 30%);
    width: 350px;
    height: 600px;
    margin: 20px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

export const Name = styled.div`
    font-size: 20px;
    color: #5e595c;
    text-align: center;
`;

export const Amount = styled.div`
    color: #e8b700;
    font-size: 40px;
    text-align: center;
    padding: 30px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Description = styled.div`
    font-size: 16px;
    text-align: center;
    padding: 20px 15px;
    color: #5e595c;
`;

export const Logo = styled.img`
    width: 90px;
    display: flex;
    margin: auto auto 20px auto;
`;

export const Interval = styled.div`
    font-size: 16px;
    color: #5e595c;
`;

export const BottomContainer = styled.div`
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgb(204, 204, 204);
`;

export const LoaderContainer = styled.div`
    z-index: 999;
    position: absolute;
    top: 40%;
    left: 49%;
`;

export const FeatureContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 30px;
    .item {
        margin: 10px;
        font-size: 12px;
        display: flex;
        svg {
            margin-right: 5px;
        }
    }
`;
