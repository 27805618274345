import { getTotal, getPercentTotal } from '../../utils';
import { CurrencyCell, PercentCell } from '../Table/CellTypes';
import CoreCarrierIcon from '../CoreCarrierIcon/index';

export const getSummaryColumns = (subcolumns = false) => {
    const summaryColumns = [
        {
            id: 'name-group',
            Header: '',
            fixed: 'left',
            columns: [
                {
                    id: 'name',
                    Header: 'Company',
                    headerClassName: 'sub-header',
                    accessor: 'carrierName',
                    defaultSortDesc: false,
                    width: 200,
                    Footer: <span className="footer">Totals:</span>,
                    Cell: ({ value, original }) => {
                        return (
                            <div className="titleSection">
                                <span className="name">{value}</span>
                                {original.isCoreCarrier && (
                                    <CoreCarrierIcon tooltip={'Core carrier'} />
                                )}
                            </div>
                        );
                    }
                },
                {
                    id: 'wp-ytd',
                    Header: 'Written Premium',
                    headerClassName: 'sub-header',
                    accessor: 'writtenPremiumYTD',
                    Footer: (row) => <span>{getTotal(row.data, 'wp-ytd')}</span>,
                    Cell: CurrencyCell
                }
            ]
        },
        {
            Header: 'Base Commission',
            columns: [
                {
                    id: 'base-commission',
                    Header: 'Amount',
                    headerClassName: 'sub-header',
                    accessor: 'baseCommissionYTD',
                    Footer: (row) => {
                        return <span>{getTotal(row.data, 'base-commission')}</span>;
                    },
                    Cell: CurrencyCell
                },
                {
                    id: 'base-commission-percent',
                    Header: '%',
                    headerClassName: 'sub-header',
                    accessor: 'baseCommissionPercentYTD',
                    Footer: (row) => (
                        <span>
                            {getPercentTotal(row.data, 'baseCommissionYTD', 'writtenPremiumYTD')}
                        </span>
                    ),
                    Cell: PercentCell
                }
            ]
        },
        {
            Header: 'Additional Compensation',
            columns: [
                {
                    id: 'additional-commission',
                    Header: 'Amount',
                    headerClassName: 'sub-header',
                    accessor: 'additionalCommissionYTD',
                    Footer: (row) => <span>{getTotal(row.data, 'additional-commission')}</span>,
                    Cell: CurrencyCell
                },
                {
                    id: 'additional-commission-percent',
                    Header: '%',
                    headerClassName: 'sub-header',
                    accessor: 'additionalCommissionPercentYTD',
                    Footer: (row) => (
                        <span>
                            {getPercentTotal(
                                row.data,
                                'additionalCommissionYTD',
                                'writtenPremiumYTD'
                            )}
                        </span>
                    ),
                    Cell: PercentCell
                }
            ]
        },
        {
            Header: 'Total',
            columns: [
                {
                    id: 'total-commission',
                    Header: 'Amount',
                    headerClassName: 'sub-header',
                    accessor: 'totalCommissionYTD',
                    Footer: (row) => <span>{getTotal(row.data, 'total-commission')}</span>,
                    Cell: CurrencyCell
                },
                {
                    id: 'total-commission-percent',
                    Header: '%',
                    headerClassName: 'sub-header',
                    accessor: 'totalCommissionPercentYTD',
                    Footer: (row) => (
                        <span>
                            {getPercentTotal(row.data, 'totalCommissionYTD', 'writtenPremiumYTD')}
                        </span>
                    ),
                    Cell: PercentCell
                }
            ]
        }
    ];

    return summaryColumns;
};
