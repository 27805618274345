import { useState, useEffect } from 'react';
import Loader from '../shared/Loader';
import { request } from '../../utils/request';
import ProfileForm from './profileForm';
import MyModal from '../shared/form/modal';
import { splitUSPhoneNumber } from '../../utils/index';

const MyProfile = ({ userUuid }) => {
    const [userData, setUserData] = useState({});
    const [fetching, setFetching] = useState(true);
    const [modalText, setModalText] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    async function getUserData(withSpinner) {
        setUserData({});
        if (withSpinner) {
            setFetching(true);
        }
        try {
            const reponse = await request.get('/api/profile/users').query({
                userUuid
            });
            setUserData(reponse.body || {});
            if (withSpinner) {
                setFetching(false);
            }
        } catch (err) {
            console.log(err);
        }
    }

    async function onSubmit(values) {
        const phoneInfo = splitUSPhoneNumber(values.mobileNumber);
        if (phoneInfo.phoneNumber === phoneInfo.countryCode) {
            phoneInfo.areaCode = '';
            phoneInfo.phoneNumber = '';
        }

        setFetching(true);

        const result = await request.put('/api/profile/users').send({
            userUuid,
            firstName: values.firstName.trim(),
            lastName: values.lastName.trim(),
            addressLine1: values.address1.trim(),
            addressLine2: values.address2.trim(),
            city: values.city.trim(),
            state: values.state.trim(),
            postalCode: values.zipCode.trim(),
            countryCode: phoneInfo.countryCode,
            areaCode: phoneInfo.areaCode,
            phoneNumber: phoneInfo.phoneNumber,
            country: 'United States'
        });
        if (result.body && result.body.response && result.body.response.status !== 200) {
            let err = JSON.parse(result.body.response.text);
            setModalText(err.message);
        } else {
            setModalText('Your profile has been updated successfully!');
        }
        await getUserData(false);
        setIsModalOpen(true);
        setFetching(false);
    }

    async function onResetPassword() {
        setFetching(true);
        const result = await request.post('/api/profile/reset-password');
        if (result.body && result.body.response && result.body.response.status !== 200) {
            let err = JSON.parse(result.body.response.text);
            setModalText(err.message);
        } else {
            setModalText("We've just sent you an email to reset your password.");
        }
        setIsModalOpen(true);
        setFetching(false);
    }

    function handleModalClick() {
        setIsModalOpen(false);
    }

    useEffect(() => {
        getUserData(true);
    }, []);

    return (
        <>
            {fetching ? (
                <Loader />
            ) : (
                <>
                    <ProfileForm
                        onSubmit={onSubmit}
                        user={userData}
                        onResetPassword={onResetPassword}
                    />
                    <MyModal
                        isModalOpen={isModalOpen}
                        handleModalClick={handleModalClick}
                        modalText={modalText}
                    />
                </>
            )}
        </>
    );
};

export default MyProfile;
