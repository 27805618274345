import { Tooltip } from '@mui/material';
import { Icon } from '../../icons/icons';
import { HeaderWithIcon } from './styles';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const infoIconStyle = css`
    margin-left: 15px;
    vertical-align: middle;
    display: initial;
`;
export function HeaderWithInfoTooltip(props) {
    return (
        <HeaderWithIcon>
            <div>{props.title}</div>
            <Tooltip title={props.tooltip}>
                <div>
                    <Icon type="info" iWidth="16px" css={infoIconStyle} />
                </div>
            </Tooltip>
            <div></div>
        </HeaderWithIcon>
    );
}
HeaderWithInfoTooltip.propTypes = {
    title: PropTypes.string,
    tooltip: PropTypes.string
};
